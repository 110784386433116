import React from 'react';
import {FormControlLabel, Radio} from '@mui/material';
import { categories } from '@utils/mockData';

export default function CategoryItem({id, name, color,}) {
    
    const getCategoryColor = goal => {
        let color = categories.filter(item => item.id === goal);
        return color[0].color;
      };
    
  return (
    <FormControlLabel
      sx={{
        marginTop: "1 0 0 2px",
        fontSize: 8,
      }}
      value={id}
      control={
        <Radio
          sx={{
            fontSize: 12.5,
            p: 0.5,
            color: getCategoryColor(id),
            '&.Mui-checked': {
            color: getCategoryColor(id),
            },
          }}
          size="small"
        />
      }
      label={name}
    >
      {' '}
    </FormControlLabel>
  );
}
