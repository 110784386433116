import React from 'react';
import DataGrid from '../components/DataGrid';
import Panel from '../components/Panel';
import moment from 'moment';
import {
  faCommentAlt,
  faSearch,
  faUser,
  faUnlock,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import CircleImage from '../components/CircleImage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {HttpRequest} from '../utils/http';
import ActivityIndicator from '../components/ActivityIndicator';
import {Link} from 'react-router-dom';
import {store} from '../store';
import IconImage from '../assets/logo192.png';
import Swal from 'sweetalert2';
import NoData from '../components/NoData';

class SignUpRequest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      users: [],
      selectedUsers: null,
      searchText: '',
    };
  }

  componentDidMount() {
    let user = store.getState().loginReducer.user;
    if (user) {
      this.loadInactiveProviders();
    }
  }

  loadInactiveProviders() {
    let user = store.getState().loginReducer.user;

    this.setState({isLoading: true});

    HttpRequest.getInactiveProvider()
      .then(res => {
        let users = res.data.results;
        this.setState({
          isLoading: false,
          users,
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
      });
  }

  handleApproveProvider(id) {
    this.setState({isLoading: true});
    let user = store.getState().loginReducer.user;
    let data = {
        user_id : id
    };
    HttpRequest.approveProvider(data)
      .then(() => {
        const updatedusers = this.state.users.filter(user => user.user.id !== id);

        this.setState({
          isLoading: false,
          users: updatedusers,
        });
        Swal.fire('Success', 'User active successfully.', 'success');
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot active user.', 'error');
      });
  }

  applyFilters() {
    const {searchText, users} = this.state;

    let filteredUsers = users;

    if (searchText) {
      filteredUsers = filteredUsers.filter(
        item =>
          item.user.name &&
          item.user.name.toLowerCase().includes(searchText.toLowerCase()),
      );
    }

    this.setState({selectedUsers: filteredUsers});
  }

  render() {
    return (
      <>
        <Panel>
          <div className="section-title">SignUp Requests</div>
          {this.state.isLoading && <ActivityIndicator />}
          {!this.state.isLoading && (
            <DataGrid
              filters={[
                {
                  type: 'textinput',
                  label: 'Search contacts',
                  icon: faSearch,
                  onChange: text => {
                    this.setState({searchText: text}, this.applyFilters);
                  },
                  className: 'col-md-12 col-sm-12',
                  style: {flex: 1},
                },
              ]}
              columns={[
                {
                  name: 'image',
                  label: 'Picture',
                  renderItem: item => {
                    return (
                      <CircleImage src={item.user.image || IconImage} size={30} />
                    );
                  },
                },
                {
                  name: 'name',
                  label: 'Name',
                  renderItem: item => {
                    return (
                      <p>
                        {item.user?.name + ' ' + item.user?.last_name}
                      </p>
                    );
                  },
                },
                {
                  name: 'title',
                  label: 'Title',
                  renderItem: item => {
                    return (
                        <p>
                        {item.provider_data?.title}
                      </p>
                    );
                  },
                },
                {
                  name: 'email',
                  label: 'Email',
                  renderItem: item => {
                    return (
                        <p>
                        {item.user?.email}
                      </p>
                    );
                  },
                },
                {
                  name: 'controls',
                  label: 'Controls',
                  renderItem: item => {
                    return (
                        <span
                            onClick={() => this.handleApproveProvider(item.user.id)}
                            style={styles.control}>
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </span>
                    );
                  },
                },
              ]}
              data={
                this.state.selectedUsers
                  ? this.state.selectedUsers
                  : this.state.users || []
              }
            />
          )}
        </Panel>
      </>
    );
  }
}

const styles = {
  control: {
    marginLeft: 8,
    cursor: 'pointer',
  },
};

export default SignUpRequest;
