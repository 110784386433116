import React from 'react';
import ChallengeOption from './ChallengeOption';
import {FormLabel, TextField, useRadioGroup} from '@mui/material';

export default function FieldsetQuestion({value, onChange = () => {}, id}) {
  const radioGroup = useRadioGroup();
  const checked = radioGroup?.value === id;

  const handleChangeText = e => {
    onChange(e.target.value);
  };
  return (
    <ChallengeOption label="Challenge Question" value={id} help="Tell what will be the question of the challenge?">
      {checked ? (
        <TextField
          sx={
            {
              width: "100%",
            }
          }
          size="small"
          hiddenLabel
          id="question_challenge"
          variant="outlined"
          value={value}
          onChange={handleChangeText}
        />
      ) : (
        <FormLabel>{value}</FormLabel>
      )}
    </ChallengeOption>
  );
}