import React from 'react';
import DataGrid from '../components/DataGrid';
import Panel from '../components/Panel';
import moment from 'moment';
import {
    faCommentAlt,
    faEdit,
    faLock,
    faPaste,
    faSearch,
    faTrash,
    faUnlock,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import CircleImage from '../components/CircleImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HttpRequest } from '../utils/http';
import ActivityIndicator from '../components/ActivityIndicator';
import { Link } from 'react-router-dom';
import { store } from '../store';
import Swal from 'sweetalert2';
import NoData from '../components/NoData';
import PanelTab from '../components/PanelTab';

class User extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoadingAgent: false,
            isLoadingProvider: false,
            selectedIndex: 0,
            searchText: '',
            selectedProviders: null,
            agents: [],
            providers: [],
        };
    }

    componentDidMount() {
        let user = store.getState().loginReducer.user;
        if (user) {
            this.loadAgent();
            this.loadProvider();
        }
    }

    loadAgent() {
        this.setState({ isLoadingAgent: true });

        let { query } = this.state;

        HttpRequest.agentList(query)
            .then(res => {
                let data = res.data.results;

                this.setState({
                    isLoadingAgent: false,
                    agents: data,
                });
            })
            .catch(err => {
                this.setState({ isLoadingAgent: false });
                Swal.fire('Error', 'Cannot load agent data.', 'error');
            });
    }

    loadProvider() {
        this.setState({ isLoadingProvider: true });

        const { query } = this.state;
        const providers = [];

        HttpRequest.providerList(query)
            .then(res => {
                const data = res.data.results;

                // Create an array of promises for assignedPatients calls
                const assignedPatientsPromises = data.map(providerData => {
                    return HttpRequest.assigedPatients(providerData.user.id)
                        .then(res => {
                            // Update the providerData object with assignedPatients data
                            providerData.assignedPatients = res.data.results;
                            providers.push(providerData);
                        })
                        .catch(err => {
                            // Handle errors for individual assignedPatients calls
                            console.error(`Error for provider ${providerData.user.id}:`, err);
                        });
                });

                // Wait for all assignedPatients calls to complete
                return Promise.all(assignedPatientsPromises)
                    .then(() => {
                        this.setState({
                            isLoadingProvider: false,
                            providers: providers,
                        });
                    });
            })
            .catch(err => {
                this.setState({ isLoadingProvider: false });
                Swal.fire('Error', 'Cannot load provider data.', 'error');
            });
    }



    // handleDelete = () => {
    //     this.setState((prevState) => ({
    //         providers: prevState.providers.filter((item) => item.id != id),
    //     }));
    // };
    handleBlockUser(id) {
        this.setState({ isLoading: true });
        let user = store.getState().loginReducer.user;
        let data = {
            user: id,
            role: user.user.role,
            is_active: false
        }
        HttpRequest.blockProvider(id, data)
            .then(() => {
                // const updatedusers = this.state.users.filter(
                //     user => user.user !== id,
                // );

                this.setState({
                    isLoading: false,
                    // users: updatedusers,
                });
                Swal.fire('Success', 'User blocked successfully.', 'success').then(() =>   this.loadProvider())
            })
            .catch(err => {
                this.setState({ isLoading: false });
                Swal.fire('Error', 'Cannot block user.', 'error');
            });
    }
    handleUnblockUser(id) {
        this.setState({ isLoading: true });
        let user = store.getState().loginReducer.user;
        let data = {
            user: id,
            role: user.user.role,
            is_active: true
        }
        HttpRequest.unblockProvider(id, data)
            .then(() => {
                // const updatedusers = this.state.users.filter(
                //     user => user.user !== id,
                // );

                
                this.setState({
                    isLoading: false,
                    // users: updatedusers,
                });
                Swal.fire('Success', 'User Unblocked successfully.', 'success').then(() => this.loadProvider()  )
                
            })
            .catch(err => {
                this.setState({ isLoading: false });
                Swal.fire('Error', 'Cannot Unblock user.', 'error');
            });
        }
        handleDelete(id) {
            this.setState({ isLoading: true });
            
        HttpRequest.deleteProvider(id)
            .then(() => {
                const updatedproviders = this.state.providers.filter(
                    provider => provider.user.id !== id
                );

                this.setState({
                    providers: updatedproviders,
                    isLoading: false
                });

                Swal.fire('Success', 'Provider deleted successfully.', 'success');
            })
            .catch(err => {
                this.setState({ isLoading: false });
                Swal.fire('Error', 'Cannot delete provider.', 'error');
            });
    }

    applyProviderFilters = () => {
        const { providers, searchText } = this.state;

        const filteredProviders = providers.filter((provider) =>
            provider.user.name.toLowerCase().includes(searchText.toLowerCase())
        );

        this.setState({ selectedProviders: filteredProviders });
    }


    render() {
        return (
            <>
                <PanelTab
                    tabs={['Providers']}
                    selectedIndex={this.state.selectedIndex}
                //   onChange={selectedIndex => {
                //     this.setState({selectedIndex});
                //   }}
                />

                {this.state.selectedIndex == 1 && (
                    <Panel>
                        {this.state.isLoadingProvider && (<ActivityIndicator />)}
                        {!this.state.isLoadingProvider && (
                            <DataGrid
                                isLoading={this.state.isLoadingAgent}
                                filters={[
                                    {
                                        type: 'textinput',
                                        label: 'Search agents',
                                        icon: faSearch,
                                        onChange: query => {
                                            this.setState({ query }, () => {
                                                this.loadAgent();
                                            });
                                        },
                                        className: 'col-sm-12 col-md-12',
                                    },
                                ]}
                                columns={[
                                    {
                                        label: 'Name',
                                        renderItem: item => {
                                            return item.user.name + ' ' + item.user.last_name;
                                        },
                                    },
                                    {
                                        label: 'Assigned',
                                        renderItem: item => {
                                            return (
                                                item.assignedPatients.map((data) => {
                                                    return data.user.name;
                                                })
                                            );
                                        },
                                    },
                                    {
                                        label: 'Last Activity',
                                        renderItem: item => {
                                            return 'N/A';
                                        },
                                    },
                                    {
                                        label: 'Last Contact',
                                        renderItem: item => {
                                            return 'N/A';
                                        },
                                    },
                                    {
                                        label: 'Controls',
                                        renderItem: item => {
                                            return (
                                                <>
                                                    <Link
                                                        to={'/user/detail/agent/' + item.id}
                                                        style={styles.control}>
                                                        <FontAwesomeIcon icon={faUser} />
                                                    </Link>

                                                    <Link
                                                        to={'/user/edit/agent/' + item.id}
                                                        style={styles.control}>
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Link>

                                                    <a
                                                        href="#"
                                                        style={styles.control}
                                                        onClick={e => {
                                                        }}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </a>
                                                </>
                                            );
                                        },
                                    },
                                ]}
                                data={this.state.agents}
                            />)}
                    </Panel>
                )}

                {this.state.selectedIndex == 0 && (
                    <Panel>
                        <DataGrid
                            isLoading={this.state.isLoadingProvider}
                            filters={[
                                {
                                    type: 'textinput',
                                    label: 'Search provider',
                                    icon: faSearch,
                                    onChange: query => {
                                        this.setState({ searchText: query }, () => {
                                            this.applyProviderFilters(); // Call the filtering function
                                        });
                                    },
                                    className: 'col-sm-12 col-md-12',
                                },
                            ]}
                            columns={[
                                {
                                    label: 'Name',
                                    renderItem: item => {
                                        return <Link to={'/user/detail/provider/' + item.user.id}> {item.user.name}  {item.user.last_name}</Link>
                                    },
                                },
                                {
                                    label: 'Assigned',
                                    renderItem: item => {
                                        if (item.assignedPatients.length === 0) {
                                            return <div style={{ color: "red" }}>No patient assigned</div>;
                                        } else {
                                            return item.assignedPatients.map(data => (
                                                <div className='my-3'>{data.user.name + ' ' + data.user.last_name}</div>
                                            ));
                                        }
                                    },
                                },
                                {
                                    label: 'Status',
                                    renderItem: item => {
                                        return item.is_active ? "Active": "Blocked";
                                    },
                                },
                                {
                                    label: 'Controls',
                                    renderItem: item => {
                                        return (
                                            <>
                                                <Link
                                                    to={'/user/detail/provider/' + item.user.id}
                                                    data-toggle="tooltip" data-placement="bottom" title="View User"
                                                    style={styles.control}>
                                                    <FontAwesomeIcon icon={faUser} />
                                                </Link>

                                                <Link
                                                    to={'/user/edit/provider/' + item.user.id}
                                                    data-toggle="tooltip" data-placement="bottom" title="Edit USer"
                                                    style={styles.control}>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Link>

                                                <span
                                                    style={styles.deleteBtn}
                                                    onClick={() => this.handleDelete(item.user.id)}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </span>
                                                {item.is_active ? <span
                                                    style={styles.deleteBtn}
                                                    data-toggle="tooltip" data-placement="bottom" title="Block User"
                                                    onClick={() => this.handleBlockUser(item.user.id)}>
                                                    <FontAwesomeIcon icon={faUnlock} />
                                                </span> : <span
                                                    style={styles.deleteBtn}
                                                    data-toggle="tooltip" data-placement="bottom" title="Unblock User"
                                                    onClick={() => this.handleUnblockUser(item.user.id)}>
                                                    <FontAwesomeIcon icon={faLock} />
                                                </span> }
                                            </>
                                        );
                                    },
                                },
                            ]}
                            data={this.state.selectedProviders ? this.state.selectedProviders : this.state.providers || []}
                        />
                    </Panel>
                )}
            </>
        );
    }
}

const styles = {
    control: {
        marginLeft: 8,
        cursor: "pointer"
    },
    deleteBtn: {
        marginLeft: 8,
        cursor: "pointer"
    }
};

export default User;
