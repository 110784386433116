import React, { useState } from 'react';
import ChallengeOption from './ChallengeOption';
import { Box, FormControl, FormLabel, MenuItem, useRadioGroup } from '@mui/material';
import UISelect from '@components/UISelect';

export default function FieldsetFrequency({  onChange = () => {}, id }) {
  const radioGroup = useRadioGroup();
  const checked = radioGroup?.value === id;

  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onChange(selectedValue);
  };

  function CondisionalRendering() {
    if (checked) {
      return (
        <Box sx={{
                      
            width: "35%"                  
          }}>
          <UISelect value={selectedOption} onChange={handleChange} displayEmpty fullHeight>
            <MenuItem value="daily">Daily</MenuItem>
            <MenuItem value="weekly">Weekly</MenuItem>
            <MenuItem value="monthly">Monthly</MenuItem>
          </UISelect>
        </Box>
      );
    } else {
      let label = '';
      if (selectedOption === 'daily') {
        label = 'Daily';
      } else if (selectedOption === 'weekly') {
        label = 'Weekly';
      } else if (selectedOption === 'monthly') {
        label = 'Monthly';
      }

      return (
        <FormControl component="fieldset" sx={{ marginTop: 1 }}>
          <FormLabel component="legend">{label}</FormLabel>
        </FormControl>
      );
    }
  }

  return (
    <ChallengeOption label="Frequency" value={id} help="Tell what will be the frequency of the challenge?">
      <CondisionalRendering />
    </ChallengeOption>
  );
}
