import React, { Component } from 'react';
import FlexibleInputGroup from './FlexibleInputGroup';
import Panel from './Panel';
import Switch from './Switch';
import { store } from '../store';
import moment from 'moment';
import ToolbarInput from './ToolbarInput';
import NoData from './NoData';
import ActivityIndicator from './ActivityIndicator';

export default class DataGrid extends Component {
    // constructor(props) {
    //   super(props);
  
    //   this.state = {
    //     isHeaderCheckboxChecked: false,
    //     selectedItems: [],
    //     rowCheckboxStatus: {},
    //   };
    // }
    constructor(props) {
        super(props);
      
        this.state = {
          isHeaderCheckboxChecked: false,
          selectedItems: [],
          rowCheckboxStatus: {},
        };
      
        // Initialize rowCheckboxStatus based on the data length
        const initialRowCheckboxStatus = {};
        if (props.data) {
          props.data.forEach((_, key) => {
            initialRowCheckboxStatus[key] = false;
          });
        }
      
        this.state.rowCheckboxStatus = initialRowCheckboxStatus;
      }
    componentDidMount() {
        let user = store.getState().loginReducer.user;
        const {exportSelect} = this.props;
            if(exportSelect) {
                this.exportSelectedRows();
        }
    }
  
    toggleHeaderCheckbox = () => {
      this.setState((prevState) => {
        const isHeaderCheckboxChecked = !prevState.isHeaderCheckboxChecked;
        const rowCheckboxStatus = {};
  
        this.props.data.forEach((_, key) => {
          rowCheckboxStatus[key] = isHeaderCheckboxChecked;
        });
  
        return {
          isHeaderCheckboxChecked,
          rowCheckboxStatus,
        };
      });
    };
  
    handleRowCheckboxChange = (key) => {
      this.setState((prevState) => {
        const rowCheckboxStatus = { ...prevState.rowCheckboxStatus };
        rowCheckboxStatus[key] = !rowCheckboxStatus[key];
  
        const isHeaderCheckboxChecked = Object.values(rowCheckboxStatus).every((status) => status);
  
        return {
          isHeaderCheckboxChecked,
          rowCheckboxStatus,
        };
      });
    };
  
    exportSelectedRows = () => {
      const { rowCheckboxStatus } = this.state;
      const {data} = this.props
  
      // Filter selected rows
      const selectedRows = data.filter((_, key) => rowCheckboxStatus[key]);
  
      if (selectedRows.length === 0) {
        alert('No rows selected for export.');
        return;
      }
  
      // Create a CSV string from the selected rows
      const csv = this.createCSV(selectedRows);
  
      // Create a Blob from the CSV string
      const blob = new Blob([csv], { type: 'text/csv' });
  
      // Generate a temporary download link and trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'messaging.csv';
      a.click();
      window.URL.revokeObjectURL(url);
    };
  
    createCSV = (rows) => {
      const header = ['Name', 'Last Message', 'Date', 'Sent To', 'Status'].join(',');
  
      const lines = rows.map((datum, key) => {
        const senderName = datum.sender ? datum.sender.name : '';
        const lastMessage = datum.lastMessage || '';
        const date = moment(datum.created).format('MMM DD, YYYY, hh:mm A');
        const sentTo = datum.receiver[0] ? datum.receiver[0].name : '';
        const status = datum.is_reported ? 'Reported' : 'Not Reported';
  
        return [senderName, lastMessage, date, sentTo, status]
          .map((value) => (value.includes(',') ? `"${value}"` : value))
          .join(',');
      });
  
      return [header, ...lines].join('\n');
    };
  
    render() {
      const { filters, columns, data, isLoading } = this.props;
      const { isHeaderCheckboxChecked, rowCheckboxStatus } = this.state;
  
      return (
        <>
          <div className="row">
            {filters?.map((filter, key) => {
              return (
                <div className={'grid-column ' + filter.className} key={key}>
                  <ToolbarInput
                    title={filter.label}
                    type={filter.type}
                    data={filter.data}
                    onChange={(val) => {
                      filter.onChange?.(val);
                    }}
                  />
                </div>
              );
            })}
          </div>
          <div style={{ margin: '0 -16px 0 -16px' }}>
            <table style={{ width: '100%' }} className="grid">
              <thead style={{ borderBottom: '1px solid #ccc' }}>
                <tr>
                  {columns?.map((column, key) => {
                    return (
                      <th key={key}>
                        {column.checkable && (
                          <input
                            type="checkbox"
                            className="mr-2"
                            checked={isHeaderCheckboxChecked}
                            onChange={this.toggleHeaderCheckbox}
                          />
                        )}
                        {column.label?.toUpperCase()}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {isLoading === true && (
                  <tr>
                    <td colSpan={columns?.length}>
                      <ActivityIndicator />
                    </td>
                  </tr>
                )}
                {isLoading !== true && (
                  <>
                    {(data == null || data?.length === 0) && (
                      <tr>
                        <td colSpan={columns?.length}>
                          <NoData>No data available</NoData>
                        </td>
                      </tr>
                    )}
                    {data?.map((datum, key) => {
                      return (
                        <tr
                          key={key}
                          className={
                            (key % 2 === 0 ? '' : 'odd') + ' ' + (datum?.is_reported ? 'tr-danger' : '')
                          }
                        >
                          {columns?.map((column, key2) => {
                            let content = null;
                            if (column?.renderItem != null) {
                              content = column?.renderItem(datum);
                            } else {
                              content = datum?.[column?.name];
                            }
                            return (
                              <td key={key + '.' + key2}>
                                {column.checkable && (
                                  <input
                                    type="checkbox"
                                    checked={rowCheckboxStatus?.[key]}
                                    className="position-absolute"
                                    style={styles.checkbox}
                                    onChange={() => this.handleRowCheckboxChange(key)}
                                  />
                                )}
                                {content}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </>
                )}
              </tbody>
            </table>
          </div>
          <div>
          </div>
        </>
      );
    }
  }
  
  
const styles = {
    topWrapper: {
        marginBottom: 16,
        display: 'flex',
        flexDirection: 'row',
    },
    topColumn: {
        marginLeft: 16,
    },
    sectionTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 16,
    },
    image: {
        borderRadius: 20,
        width: 35,
        height: 35,
        objectFit: 'cover',
        marginRight: 10,
    },
    checkbox: {
        marginTop: "15px"
    }
};
