import React from 'react';
import {useState} from 'react';
import ChallengeOption from '../ChallengeOption';
import {
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  useRadioGroup,
} from '@mui/material';
import ChallengeModal from "./ChallengeModal"
import UISelect from '@components/UISelect';
import TextField from '@mui/material/TextField';
import { HttpRequest } from '@utils/http';

export default function FieldsetGoal({onChange = () => {}, id, onModalOpen, choosedCategory }) {
  const radioGroup = useRadioGroup();
  const [content, setContent] = useState('');
  const [goal, setGoal] = React.useState('');
  const [showChallenge, setShowChallenge] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState('');
  const handleChange = event => {
    setGoal(event.target.value);
    // onChange(event.target.value);
    setSelectedValue(event.target.value);
    // setContent(event.target.value);
  };

  const handleChange2 = value => {
    onChange(value.id);
    setContent(value.goal);
  };

  const handleChangeGoal = event => {
    setSelectedValue(event.target.value);
    setContent(event.target.value);
  };

  const handleSaveCustomGoal = () => {
    HttpRequest.addChallengesGoal({ category: choosedCategory, goal: selectedValue })
      .then((res) => {
        onChange(res.data.id);
        setContent(res.data.goal);
      })
      .catch((err) => {
        console.error('Error posting custom goal:', err);
      });
  };

  const checked = radioGroup?.value === id;

  return (
    <>
      <ChallengeOption label="Goal of the challenge" value={id} help="Tell what will be the goal of the challenge?">
        {checked ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            //   paddingRight: '2em',
              gap: '3em',
            }}>
            <FormControl sx={{width: '80%'}}>
              <UISelect value={goal} id="goal" onChange={handleChange} fullHeight>
                <MenuItem sx={{marginRight: 1}} value={'Custom challenge goal'}>
                  Custom challenge goal
                </MenuItem>
                <MenuItem value={'Select from existing'}>
                  Select from existing
                </MenuItem>
              </UISelect>
            </FormControl>
            {goal === 'Custom challenge goal' ? (
              <TextField
                large
                id="outlined-basic"
                variant="outlined"
                value={selectedValue}
                onChange={handleChangeGoal}
                sx={{
                  height: '40px',
                  width: '70%',
                  '& .css-24rejj-MuiInputBase-input-MuiOutlinedInput-input': {
                    padding: '0.5em',
                  },
                }}
                onBlur={handleSaveCustomGoal} 
              />
            ) : (
              <FormControl
                sx={{width: '80%'}}
                onMouseEnter={()=>setShowChallenge(true)}
                  onMouseLeave={()=>setShowChallenge(false)}
                >
                <UISelect
                  value={content}
                  label="fromExistingChallenges"
                  onChange={handleChangeGoal}
                  fullHeight>
                  <MenuItem value={content}  >
                    {content}
                  </MenuItem>
                </UISelect>
                {
                    showChallenge && <ChallengeModal handleChange={handleChange2} choosedCategory={choosedCategory} /> 
                }
              </FormControl>
            )}
          </Box>
        ) : selectedValue || content ? (
          <FormControl component="fieldset" sx={{marginTop: 1}}>
            <FormLabel component="legend">{content}</FormLabel>
          </FormControl>
        ) : null}
      </ChallengeOption>
    </>
  );
}
