import React from 'react';
import DataGrid from '../components/DataGrid';
import Panel from '../components/Panel';
import moment from 'moment';
import {faCommentAlt} from '@fortawesome/free-solid-svg-icons';
import CircleImage from '../components/CircleImage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {HttpRequest} from '../utils/http';
import ActivityIndicator from '../components/ActivityIndicator';
import {Link} from 'react-router-dom';
import {store} from '../store';
import Swal from 'sweetalert2';
import NoData from '../components/NoData';
import MilestoneList from '../components/MilstoneList';
import Switch from '../components/Switch';

class Notification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 1,
      notify_new_booking_schedule: true,
      notify_new_message: true,
      notify_new_milestone: true,

      isLoading: false,
      userLogs: [],

      notifications: [
        {label: 'New Message', value: 0},
        {label: 'New Milestone', value: 0},
        {label: 'New Booking Schedule', value: 0},
      ],
    };

    this.loadNotification();
  }

  componentDidMount() {
    let user = store.getState().loginReducer.user;
    if (user) {
      this.loadUserLog();
    }
  }

  loadNotification() {
    HttpRequest.getNotificationSetting()
      .then(res => {
        let notif = res.data.results[0];
        this.setState(notif);
      })
      .catch(err => {});
  }
  loadUserLog() {
    let user = store.getState().loginReducer.user;
    this.setState({isLoading: true});
    HttpRequest.getActivityLog(user.user.id)
      .then(res => {
        let userLogs = res.data.results.map((log, key) => {
          return {
            title: log.action_flag_desc,
            type: log.content_type_desc,
            created: log.action_time,
            description: log.change_message_json,
          };
        });

        this.setState({userLogs});
        this.setState({isLoading: false});
      })
      .catch(err => {
        Swal.fire('Error', 'Cannot load user log data.', 'error');
        this.setState({isLoading: false});
      });
  }

  saveNotification(stateVarName, value) {
    let obj = {};
    obj[stateVarName] = value;
    this.setState(obj);

    HttpRequest.updateNotificationSetting(this.state.id, stateVarName, value)
      .then(res => {
        Swal.fire(
          'Success',
          'Notification setting has been updated',
          'success',
        );
      })
      .catch(err => {
        Swal.fire('Error', 'Cannot update notification setting', 'error');
      });
  }

  renderNotification(stateVarName, label) {
    let currentVal = this.state[stateVarName];
    return (
      <div className="col-md-4">
        <Panel>
          <div className="row">
            <div className="col-md-7">{label}</div>
            <div className="col-md-5">
              <Switch
                data={['Off', 'On']}
                selectedIndex={currentVal}
                onChange={selectedIndex => {
                  let obj = {};
                  obj[stateVarName] = !currentVal;
                  this.setState(obj);

                  this.saveNotification(stateVarName, !currentVal);
                }}
              />
            </div>
          </div>
        </Panel>
      </div>
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-8">
          <div className="section-title">
            Turn on or off global notifications for all users
          </div>
          <div className="row">
            {this.renderNotification(
              'notify_new_booking_schedule',
              'New Booking Schedule',
            )}
            {this.renderNotification('notify_new_message', 'New Message')}
            {this.renderNotification('notify_new_milestone', 'New Milestone')}
          </div>
        </div>
        <div className="col-md-4 hide-scrollbar" style={styles.activityLogs}>
          <Panel>
            <div style={styles.activityLogsPanel}>
              <div className="section-title">Your activity logs</div>
              {this.state.isLoading && <ActivityIndicator />}
              {!this.state.isLoading && (
                <MilestoneList data={this.state.userLogs} />
              )}
            </div>
          </Panel>
        </div>
      </div>
    );
  }
}

const styles = {
  control: {
    marginLeft: 8,
  },
  activityLogs: {
    overflow: 'auto',
    maxHeight: '80vh',
  },
  activityLogsPanel: {
    overflowX: 'auto',
  },
};

export default Notification;
