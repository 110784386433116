import React, {useState} from 'react';
import Button from '@mui/material/Button';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import TooltipBox from '../TooltipBox';
import ChallengeModal from '../ChallengeModalEmotions';
import ChallengeModalWeight from '../ChallengeModalWeight';
import ChallengeModalDistance from '../ChallengeModalDistance';
import Challengemodalyesno from '../Challengemodalyesno';

const Button1 = ({
  status,
  data,
  answerData,
  note,
  activity,
  raw,
  userProfile,
  completeChallenge,
  week
}) => {
  const ButtonStyle = {
    backgroundColor: status
      ? status
        ? '#9BD173ff'
        : '#D2DCE2ff'
      : '#D2DCE2ff',
    textTransform: 'none',
    fontSize: '15px',
    color: '#274F66ff',
    width: '140px',
    flexShrink: '2',
  };

  const [isShown, setIsShown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleButtonModalClick = index => {
    setShowModal(true);
  };
  return (
    <div style={{height: '50px'}}>
      <Button
        variant="contained"
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        onClick={() => handleButtonModalClick()}
        style={ButtonStyle}
        sx={{
          '&:hover': {
            border: '1px solid currentColor',
          },
        }}>
        {' '}
        <StickyNote2OutlinedIcon
          sx={{fontSize: '19px', marginRight: '15px', color: '#274F66ff'}}
        />{' '}
        {data}
      </Button>
      {isShown && answerData?.date && (
        <div style={{marginTop: '8px'}}>
          <TooltipBox
            date={answerData?.date}
            status={answerData?.status}
            activity={activity}
            details={note}
          />
        </div>
      )}
      {showModal &&
        (completeChallenge?.scale_measurement === 'Emotion' ? (
          <ChallengeModal
            show={showModal}
            setIsShown={setShowModal}
            data={raw}
            userProfile={userProfile}
            completeChallenge={completeChallenge}
            week={week}
          />
        ) : completeChallenge?.scale_measurement === 'Distance' ? (
          <ChallengeModalDistance
            show={showModal}
            setIsShown={setShowModal}
            data={raw}
            userProfile={userProfile}
            completeChallenge={completeChallenge}
            week={week}
          />
        ) : completeChallenge?.scale_measurement === 'Yes or No' ? (
          <Challengemodalyesno
            show={showModal}
            setIsShown={setShowModal}
            data={raw}
            userProfile={userProfile}
            completeChallenge={completeChallenge}
            week={week}
          />
        ) : completeChallenge?.scale_measurement === 'Weight' ? (
          <ChallengeModalWeight
            show={showModal}
            setIsShown={setShowModal}
            data={raw}
            userProfile={userProfile}
            completeChallenge={completeChallenge}
            week={week}
          />
        ) : completeChallenge?.scale_measurement === 'Time Spent' ? (
          <ChallengeModalWeight
            show={showModal}
            setIsShown={setShowModal}
            data={raw}
            userProfile={userProfile}
            completeChallenge={completeChallenge}
            week={week}
          />
        ) : completeChallenge?.scale_measurement === 'Custom Template' ? (
          <ChallengeModalWeight
            show={showModal}
            setIsShown={setShowModal}
            data={raw}
            userProfile={userProfile}
            completeChallenge={completeChallenge}
            week={week}
          />
        ) : null)}
    </div>
  );
};

export default Button1;
