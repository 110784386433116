import React from 'react';
import Select from 'react-select';
import {green} from '@mui/material/colors';

const ReactPatientSelect = ({ options, handleChange }) => {
  const getOptionLabel = (option) => option?.user?.name + " " + option?.user?.last_name;
  const getOptionValue = (option) => option?.user?.id;

  const customStyles = {
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: green[500], 
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'white', 
    }),
  };


  return (
    <Select
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      isMulti
      onChange={handleChange}
      isClearable={true}
      styles={customStyles}
      menuPosition="fixed"
    />
  );
};

export default ReactPatientSelect;
