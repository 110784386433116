import { Box, MenuItem, OutlinedInput, Select, TextField } from '@mui/material'
import React, { useState } from 'react'

const MissedCheckIns = ({setNotificationTempValues}) => {
    const [missedCheckIns, setMissedCheckIns] = useState('');

    const handleMissedCheckInsChange = (event) => {
      const newValue = event.target.value;
      setMissedCheckIns(newValue);
      setNotificationTempValues(newValue);
    };
  
    return (
        <Box
            sx={styles.option1Styles}>
            <Box>
                <Select
                    input={
                        <OutlinedInput
                            inputProps={{
                                'aria-label': 'Without label',
                                hiddenlabel: 'true',
                            }}
                            hiddenlabel
                        />
                    }
                    sx={styles.option1SelectStyles}
                    displayEmpty={true}
                    value={missedCheckIns}
                    onChange={handleMissedCheckInsChange}>
                    <MenuItem value="" disabled>
                        %
                    </MenuItem>
                    <MenuItem value="10%">10%</MenuItem>
                    <MenuItem value="20%">20%</MenuItem>
                    <MenuItem value="30%">30%</MenuItem>
                    <MenuItem value="40%">40%</MenuItem>
                    <MenuItem value="50%">50%</MenuItem>
                    <MenuItem value="60%">60%</MenuItem>
                    <MenuItem value="70%">70%</MenuItem>
                    <MenuItem value="80%">80%</MenuItem>
                    <MenuItem value="90%">90%</MenuItem>
                    <MenuItem value="100%">100%</MenuItem>
                </Select>
            </Box>
            <Box
                sx={{
                    width: '70%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <TextField
                    sx={styles.textFieldStyle}
                    size="small"
                    hiddenLabel
                    id="question_challenge"
                    variant="outlined"
                    value={missedCheckIns}
                    // onChange={handlePercentage}
                />
            </Box>
        </Box>
    )
}

export default MissedCheckIns


const styles = {
    main: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 2,
        backgroundColor: 'white',
    },
    option1Styles: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#E9E9E9',
        borderRadius: '0.3em',
        height: '100%',
        width: '20%',
        '@media (max-width: 1000px)': {
            width: '15vw',
        },
        '@media (max-width: 720px)': {
            width: '20vw',
        },
    },
    option1SelectStyles: {
        width: '100%',
        background: 'white',
        '& .MuiSelect-select': {
            fontSize: 12,
            outline: 0,
            color: 'darkGray',
            fontWeight: 600,
            borderStyle: 'none',
            paddingY: 1,
            backgroundColor: '#E9E9E9',
        },
    },
    textFieldStyle: {
        width: '100%',
        backgroundColor: 'white',
        borderRadius: '0.3em',
        height: '90%',
    },
}