import React, { useCallback, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { HttpRequest } from '@utils/http';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight, faLessThanEqual } from '@fortawesome/free-solid-svg-icons';
import ActivityIndicator from '@components/ActivityIndicator';

function createData(goal) {
  return { goal, deleteIcon: <DeleteOutlineIcon /> };
}

export default function ColumnGroupingTable({ handleChange, choosedCategory}) {
  const [searchText, setSearchText] = React.useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [challengesGoal, setChallengesGoal] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const itemsPerPage = 5;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= Math.ceil(challengesGoal.length / itemsPerPage)) {
      setCurrentPage(newPage);
    }
  };

  const loadChallenges = useCallback(() => {
    setIsLoading(true);
    HttpRequest.getChallengesGoal()
      .then((res) => {
        let data = res.data.results;
        // data = data?.filter((item) => item?.category === parseInt(choosedCategory));
        setChallengesGoal(data);
        setIsLoading(false);
      })
      .catch((err) => {
        Swal.fire('Error', 'Cannot Challenges Goal Providers', 'error');
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    loadChallenges();
  }, []);

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleDeleteRow = (id) => {
    HttpRequest.deleteChallengesGoal(id)
      .then((res) => {
        let updatedGoals = challengesGoal.filter((item) => item.id !== id);
        setChallengesGoal(updatedGoals);
      })
      .catch((err) => {
      });
  };

  const handleRowClick = (row) => {
    setSelectedRow(row);
    handleChange(row);
  };

  const filteredRows = challengesGoal.filter((row) =>
    row.goal.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <Paper sx={{ top: '105%', width: '100%', position: 'absolute', borderRadius: '0.8em', padding: '0.5em', minHeight: "80vh" }}>
      <h3>Challenges</h3>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={5}>
                <TextField
                  id="outlined-basic"
                  label="Search Challenge"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={searchText}
                  onChange={handleSearchChange}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (<ActivityIndicator/>) : (
          <TableBody>
            {filteredRows
              .slice(startIndex, endIndex)
              .map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    onClick={() => handleRowClick(row)}
                    selected={selectedRow === row}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell align="left">{row.goal}</TableCell>
                    <TableCell align="right">
                      <DeleteOutlineIcon
                        onClick={() => handleDeleteRow(row.id)}
                        sx={{ cursor: 'pointer' }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>)}
        </Table>
      </TableContainer>
      <div className="d-flex justify-content-end mt-2 mb-4">
        <FontAwesomeIcon
          icon={faCaretLeft}
          style={{
            height: 24,
            width: 28,
            cursor: 'pointer',
            fontSize: '26px',
            color: currentPage === 1 ? '#ACB9C2' : '#274F66',
          }}
          onClick={() => handlePageChange(currentPage - 1)}
          // Disable if currentPage is 1
          {...(currentPage === 1 ? { onClick: null } : {})}
        />

        Page {currentPage} of {Math.ceil(challengesGoal.length / itemsPerPage)}

        <FontAwesomeIcon
          icon={faCaretRight}
          style={{
            height: 24,
            width: 28,
            cursor: 'pointer',
            fontSize: '26px',
            color: endIndex >= challengesGoal.length ? '#ACB9C2' : '#274F66',
          }}
          onClick={() => handlePageChange(currentPage + 1)}
          // Disable if endIndex is greater than or equal to challengesGoal.length
          {...(endIndex >= challengesGoal.length ? { onClick: null } : {})}
        />
      </div>
    </Paper>
  );
}
