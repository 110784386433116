import React, { Component } from 'react';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faChevronRight,
    faEdit,
    faEllipsisH,
    faLink,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import Switch from 'react-switch';
import { store } from '../store';
import { HttpRequest, HttpResponse } from '../utils/http';
import Swal from 'sweetalert2';
import _ from 'lodash';
import DoctorImage from '../assets/icon-doctor.jpg';
import {
    BOOKING_DAILY,
    BOOKING_MONTHLY,
    BOOKING_WEEKLY,
    BOOKING_YEARLY,
} from '../store/constants';
import CircleImage from './CircleImage';

export default class BigCalendar extends Component {
    constructor(props) {
        super(props);

        let today = moment();
        let yesterday = moment().subtract(1, 'day');

        this.state = {
            timeList: [],
            startTime: '',
            endTime: '',

            bookings: [],
            popoverPosition: null,
            title: '',
            status: 'open',
            type: 'task',
            notes: '',
            contact: null,
            checked: true,

            patients: [],
            currentDate: moment(),

            currentDateString: moment().format('YYYY-MM-DD'),
            selectedTime: null,
            bookingId: null,
            isRecurring: false,
            recurringType: BOOKING_DAILY,
            endRecurringDate: moment().format('YYYY-MM-DD'),
        };
        this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
        this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
    }

    createTimeArray(startTime, endTime) {
        const times = [];

        let current = new Date(`2023-02-21T${startTime}`);

        while (current <= new Date(`2023-02-21T${endTime}`)) {
            const timeString = current.toLocaleTimeString('en-US', { hour12: false });
            times.push(timeString);
            current.setHours(current.getHours() + 1);
        }

        return times;
    }
    handleStartTimeChange(event) {
        const hour = event.target.value.split(':')[0];
        const time = new Date();
        time.setHours(hour);
        time.setMinutes(0);
        const startTime = time.toTimeString().slice(0, 5);

        const timeList = this.createTimeArray(startTime, this.state.endTime);
        this.setState({ startTime, timeList });
    }

    handleEndTimeChange(event) {
        const hour = event.target.value.split(':')[0];
        const time = new Date();
        time.setHours(hour);
        time.setMinutes(0);
        const endTime = time.toTimeString().slice(0, 5);
        const timeList = this.createTimeArray(this.state.startTime, endTime);

        this.setState({ endTime, timeList });
    }
    componentDidMount() {
        let user = store.getState().loginReducer.user;
        if (user) {
            this.loadPatient();
            this.loadBooking();
        }
    }

    loadPatient() {
        let user = store.getState().loginReducer.user;

        this.setState({ isLoading: true });

        HttpRequest.patientList()
            .then(res => {
                let data = res.data.results;
                this.setState({
                    isLoading: false,
                    patients: data,
                });
            })
            .catch(err => {
                this.setState({ isLoading: false });
                Swal.fire('Error', 'Cannot load patient list.', 'error');
            });
    }

    loadBooking() {
        let user = store.getState().loginReducer.user;
        var startOfWeek = moment(this.state.currentDateString)
            .startOf('week')
            .format('YYYY-MM-DD');
        var endOfWeek = moment(this.state.currentDateString)
            .endOf('week')
            .format('YYYY-MM-DD');

        this.setState({ isLoading: true });

        HttpRequest.getBooking(user.user.id)
            .then(res => {
                let bookings = res.data.results;

                bookings = bookings.map(booking => {
                    booking.time = moment(booking.start)
                        .tz('UTC')
                        .format('YYYY-MM-DD HH:mm:ss');
                    return booking;
                });
                this.setState({ isLoading: false, bookings });
            })
            .catch(err => {
                this.setState({ isLoading: false });
                Swal.fire('Error', 'Cannot load task list.', 'error');
            });
    }

    saveBooking(zoomMeeting = null) {
        if (this.state.contact == null) {
            Swal.fire('Error', 'Please choose contact.', 'error');
            return;
        }
    
        // Create a data object with the common fields
        let data = {
            provider: store.getState().loginReducer.user.user.id,
            patient: parseInt(this.state.contact),
            title: this.state.notes,
            note: this.state.notes,
            status: 'approved',
            frequency: 'DAILY',
        };
    
        if (this.state.bookingId) {
            data.start = moment(this.state.selectedTime).format('YYYY-MM-DD HH:mm:ss');
            data.end = moment(this.state.selectedTime).add(59, 'minute').format('YYYY-MM-DD HH:mm:ss');
        } else {
            data.start = moment(this.state.selectedTime).add(1, 'minute').format('YYYY-MM-DD HH:mm:ss');
            data.end = moment(this.state.selectedTime).add(1, 'hour').format('YYYY-MM-DD HH:mm:ss');
        }
        
        if (zoomMeeting) {
            data.start_meeting_uri = zoomMeeting.start_url;
            data.meeting_uri = zoomMeeting.join_url;
        }
    
        if (this.state.isRecurring) {
            data.rule = this.state.recurringType;
            data.end = this.state.endRecurringDate + ' 23:59';
        }
    
        this.setState({ isLoading: true });
    
        // Check if we are in edit mode (updating) or create mode (inserting)
        if (this.state.bookingId) {
            // Updating an existing record
            HttpRequest.editBooking(this.state.bookingId, data)
                .then(res => {
                    let data = res.data.data;
                    this.setState({ isLoading: false });
    
                    if (zoomMeeting) {
                        Swal.fire('Information', 'Update meeting success.', 'success');
                    } else {
                        Swal.fire('Information', 'Update task success.', 'success');
                    }
    
                    this.loadBooking();
                })
                .catch(err => {
                    this.setState({ isLoading: false });
    
                    Swal.fire(
                        'Error',
                        HttpResponse.processError(err.response, 'Update task failed'),
                        'error'
                    );
                });
        } else {
            // Creating a new record
            HttpRequest.saveBooking(data)
                .then(res => {
                    let data = res.data.data;
                    this.setState({ isLoading: false });
    
                    if (zoomMeeting) {
                        Swal.fire('Information', 'Save meeting success.', 'success');
                    } else {
                        Swal.fire('Information', 'Save task success.', 'success');
                    }
    
                    this.loadBooking();
                })
                .catch(err => {
                    this.setState({ isLoading: false });
    
                    Swal.fire(
                        'Error',
                        HttpResponse.processError(err.response, 'Save task failed'),
                        'error'
                    );
                });
        }
    
        // Reset the state after saving
        this.setState({
            contact: null,
            notes: '',
            editedMapElementId: null,
        });
        window.jQuery('#myModal').modal('hide');
    }
    

    addMeeting() {
        let { contact, notes, selectedTime } = this.state;
        if (contact == null) {
            Swal.fire('Error', 'Please choose contact.', 'error');
            return;
        }

        let time = moment(selectedTime).utc().format('YYYY-MM-DD HH:mm:ss');

        HttpRequest.createZoomMeeting(notes, time)
            .then(res => {
                let data = res.data;
                this.saveBooking(data);
            })
            .catch(err => {
                Swal.fire('Error', 'Create meeting failed.', 'error');
            });

        window.jQuery('#modal-meeting').modal('hide');
    }

    handleDelete(id) {
        Swal.fire({
            title: 'Confirm Delete',
            text: 'Are you sure you want to delete this booking?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({ isLoading: true });
                HttpRequest.deleteBooking(id)
                    .then(() => {
                        const updatedBookings = this.state.bookings.filter(
                            (booking) => booking.id !== id
                        );

                        this.setState({
                            isLoading: false,
                            bookings: updatedBookings,
                        });

                        Swal.fire('Success', 'Resource deleted successfully.', 'success');
                    })
                    .catch((err) => {
                        this.setState({ isLoading: false });
                        Swal.fire('Error', 'Cannot delete booking.', 'error');
                    });
            }
        })
    }

    handleEdit(e, mapElement) {
        e.preventDefault();

        // Populate the edit form with data from mapElement
        this.setState({
            selectedTime: mapElement.start,
            notes: mapElement.title,
            contact: mapElement.patient.id,
            bookingId: mapElement.id // Assuming this corresponds to the contact field
            // other fields you want to edit
        });
        if (mapElement.start_meeting_uri) {
            window.jQuery('#modal-meeting').modal('show');
        } else {
            window.jQuery('#myModal').modal('show');
        }
    }

    render() {
        var startOfWeek = moment(this.state.currentDateString)
            .startOf('week')
            .toDate();
        var endOfWeek = moment(this.state.currentDateString).endOf('week').toDate();

        let days = [];
        for (let i = 0; i < 7; i++) {
            let curDay = moment(startOfWeek).add(i, 'day');
            days.push(curDay.format('YYYY-MM-DD'));
        }

        let today = moment().format('YYYY-MM-DD');
        let mapArray = {};
        this.state.bookings.forEach(task => {
            let hour = parseInt(moment(task.time).format('HH'));
            if (hour < 6) {
                hour = 6;
            }
            if (hour > 17) {
                hour = 17;
            }
            let time =
                moment(task.time).format('YYYY-MM-DD') +
                ' ' +
                hour.toString().padStart(2, '0') +
                ':00:00';
            task.length = 1;
            mapArray[time] = task;
        });

        let removeCellArray = {};

        let popoverPosition = this.state.popoverPosition;

        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        width: '100%',
                    }}>
                    <div>
                        start time:
                        <input
                            type="time"
                            value={this.state.startTime}
                            onChange={this.handleStartTimeChange}
                        />
                    </div>
                    <div>
                        End time:
                        <input
                            type="time"
                            value={this.state.endTime}
                            onChange={this.handleEndTimeChange}
                        />
                    </div>
                </div>
                <div className="text-center m-2">
                    <a
                        href="#"
                        onClick={e => {
                            e.preventDefault();
                            let currentDateString = moment(this.state.currentDateString)
                                .subtract(1, 'week')
                                .format('YYYY-MM-DD');
                            this.setState({ currentDateString }, () => {
                                this.loadBooking();
                            });
                        }}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </a>
                    <span style={{ margin: '0px 20px' }}>
                        {moment(startOfWeek).format('MMM DD, YYYY')} -{' '}
                        {moment(endOfWeek).format('MMM DD, YYYY')}
                    </span>
                    <a
                        href="#"
                        onClick={e => {
                            e.preventDefault();
                            let currentDateString = moment(this.state.currentDateString)
                                .add(1, 'week')
                                .format('YYYY-MM-DD');
                            this.setState({ currentDateString }, () => {
                                this.loadBooking();
                            });
                        }}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </a>
                </div>
                <div style={{ overflowX: 'auto' }}>
                    <table
                        className="big-calendar"
                        border="1"
                        cellPadding="0"
                        cellSpacing="0">
                        <thead>
                            <tr>
                                <th style={{ width: 80 }}></th>
                                {days.map((day, key) => {
                                    let dayMoment = moment(day);
                                    return (
                                        <th
                                            className={today == day ? 'active' : ''}
                                            key={'day' + key}>
                                            <div>{dayMoment.format('ddd')}</div>
                                            <div>{dayMoment.format('MMM DD')}</div>
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.timeList.map((time, key) => {
                                let d = moment('2020-01-01 ' + time);

                                return (
                                    <tr key={key}>
                                        <td className="time">
                                            <div>{d.format('HH:mm')}</div>
                                        </td>

                                        {days.map((day, key2) => {
                                            //check if exist in mapArray
                                            let mapElement = mapArray[day + ' ' + time];
                                            let dayMoment = moment(day);
                                            let dayName = dayMoment.format('ddd');
                                            if (mapElement) {
                                                if (mapElement.length > 1) {
                                                    let curCellTime = moment(day + ' ' + time);
                                                    for (let i = 1; i < mapElement.length; i++) {
                                                        curCellTime.add(1, 'hour');
                                                        removeCellArray[
                                                            curCellTime.format('YYYY-MM-DD HH:mm:ss')
                                                        ] = 1;
                                                    }
                                                }
                                                return (
                                                    <td
                                                        rowSpan={mapElement.length}
                                                        className="content "
                                                        key={key + '-' + key2}>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                position: 'relative',
                                                            }}>
                                                            <CircleImage
                                                                src={
                                                                    mapElement.patient.image
                                                                        ? mapElement.patient.image
                                                                        : DoctorImage
                                                                }
                                                                size={30}
                                                            />
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flex: 1,
                                                                    flexDirection: 'column',
                                                                    marginLeft: 4,
                                                                }}>
                                                                <div className="title">
                                                                    Patient:{' '}
                                                                    {mapElement.patient.name
                                                                        ? mapElement.patient.name
                                                                        : 'missing data'}{' '}
                                                                    {mapElement.patient.last_name
                                                                        ? mapElement.patient.last_name
                                                                        : 'missing data'}
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        position: 'absolute',
                                                                        width: '30%',
                                                                        top: '0',
                                                                        right: '0',
                                                                        display: 'flex',
                                                                        justifyContent: 'space-around',
                                                                    }}>
                                                                    <FontAwesomeIcon
                                                                        icon={faEdit}
                                                                        onClick={(e) => this.handleEdit(e, mapElement)}
                                                                    />
                                                                    <FontAwesomeIcon
                                                                        icon={faTrash}
                                                                        onClick={() => this.handleDelete(mapElement.id)}
                                                                    />
                                                                </div>

                                                                {mapElement.meeting_uri != null && (
                                                                    <a
                                                                        className="meetinglink"
                                                                        href={mapElement.start_meeting_uri}
                                                                        target="_blank">
                                                                        <FontAwesomeIcon icon={faLink} /> Zoom Link
                                                                    </a>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </td>
                                                );
                                            } else {
                                                let removeCellElement =
                                                    removeCellArray[day + ' ' + time];
                                                if (removeCellElement) {
                                                    return null;
                                                } else {
                                                    if (dayName == 'Sun' || dayName == 'Sat') {
                                                        return (
                                                            <td
                                                                key={key + '-' + key2}
                                                                style={{ background: '#ddd' }}></td>
                                                        );
                                                    } else {
                                                        return (
                                                            <td
                                                                key={key + '-' + key2}
                                                                className="calendar-hover">
                                                                <a
                                                                    href="#"
                                                                    className="ellipsis"
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        this.setState({
                                                                            popoverPosition: {
                                                                                x: e.clientX,
                                                                                y: e.clientY,
                                                                            },
                                                                            selectedTime: day + ' ' + time,
                                                                        });
                                                                    }}>
                                                                    <FontAwesomeIcon icon={faEllipsisH} />
                                                                </a>
                                                            </td>
                                                        );
                                                    }
                                                }
                                            }
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    {popoverPosition && (
                        <div className="popover-overlay">
                            <div
                                className="popover-clickable"
                                onClick={e => {
                                    this.setState({ popoverPosition: null });
                                }}></div>
                            <div
                                className="popover-modal"
                                style={{
                                    left: popoverPosition.x - 30,
                                    top: popoverPosition.y + 20,
                                }}>
                                <div
                                    className="flex-row align-center"
                                    style={{ marginBottom: 10 }}>
                                    <span style={{ marginRight: 10, fontWeight: 'bold' }}>
                                        Unavailable
                                    </span>

                                    <Switch
                                        height={20}
                                        width={40}
                                        checkedIcon={false}
                                        uncheckedIcon={false}
                                        onChange={checked => {
                                            this.setState({ checked });
                                        }}
                                        checked={this.state.checked}
                                    />

                                    <span style={{ marginLeft: 10, fontWeight: 'bold' }}>
                                        Available
                                    </span>
                                </div>

                                <div>
                                    <a
                                        href="#"
                                        onClick={e => {
                                            e.preventDefault();
                                            this.setState({ popoverPosition: null });
                                            window.jQuery('#myModal').modal('show');
                                        }}>
                                        Add task
                                    </a>
                                </div>
                                <div>
                                    <a
                                        href="#"
                                        onClick={e => {
                                            e.preventDefault();
                                            this.setState({ popoverPosition: null });
                                            window.jQuery('#modal-meeting').modal('show');
                                        }}>
                                        Add meeting
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="modal" tabindex="-1" role="dialog" id="myModal">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add task</h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Patient</label>
                                        <div className="col-sm-9">
                                            <select
                                                className="form-control"
                                                value={this.state.contact || ''}
                                                onChange={event => {
                                                    this.setState({ contact: event.target.value });
                                                }}>
                                                <option value="">Choose Contact</option>
                                                {this.state.patients.map((user, key) => {
                                                    return (
                                                        <option value={user.user.id} key={key}>
                                                            {user.user.name} {user.user.last_name} (
                                                            {user.user.email ?? 'N/A'})
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Notes</label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.state.notes}
                                                onChange={event => {
                                                    this.setState({ notes: event.target.value });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Is Repeat</label>
                                        <div className="col-sm-9">
                                            <input
                                                type="checkbox"
                                                checked={this.state.isRecurring}
                                                onChange={event => {
                                                    this.setState({ isRecurring: event.target.checked });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {this.state.isRecurring && (
                                        <>
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label">
                                                    Repeat Type
                                                </label>
                                                <div className="col-sm-4">
                                                    <select
                                                        className="form-control"
                                                        value={this.state.recurringType}
                                                        onChange={event => {
                                                            this.setState({
                                                                recurringType: event.target.value,
                                                            });
                                                        }}>
                                                        <option value={BOOKING_DAILY}>Daily</option>
                                                        <option value={BOOKING_WEEKLY}>Weekly</option>
                                                        <option value={BOOKING_MONTHLY}>Monthly</option>
                                                        <option value={BOOKING_YEARLY}>Yearly</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label">
                                                    Repeat Until
                                                </label>
                                                <div className="col-sm-6">
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={this.state.endRecurringDate}
                                                        onChange={event => {
                                                            this.setState({
                                                                endRecurringDate: event.target.value,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        this.saveBooking();
                                    }}>
                                    Save changes
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                    onClick={() => this.setState({ contact: null, notes: '', })}>
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal" tabindex="-1" role="dialog" id="modal-meeting">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add meeting</h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label">Patient</label>
                                        <div className="col-sm-10">
                                            <select
                                                className="form-control"
                                                value={this.state.contact}
                                                onChange={event => {
                                                    this.setState({ contact: event.target.value });
                                                }}>
                                                <option value="">Choose Contact</option>
                                                {this.state.patients.map((user, key) => {
                                                    return (
                                                        <option value={user.user.id} key={key}>
                                                            {user.user?.name} {user.user?.last_name} (
                                                            {user.user.email ?? 'N/A'})
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label">Notes</label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={this.state.notes}
                                                onChange={event => {
                                                    this.setState({ notes: event.target.value });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        this.addMeeting();
                                    }}>
                                    Save changes
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const styles = {

};
