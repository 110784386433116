import React, {useState} from 'react';
import Swal from 'sweetalert2';
import moment from 'moment';
import {HttpRequest} from '@utils/http';

function CreateMeeting() {
  const [meeting, setMeeting] = useState(null);
  const [title, setTitle] = useState('');
  const [startTime, setStartTime] = useState(null);

  const handleTitleChange = event => {
    setTitle(event.target.value);
  };

  const handleStartTimeChange = event => {
    setStartTime(event.target.value);
  };

  const addMeeting = async () => {
    if (!title || !startTime) {
      Swal.fire('Error', 'Please provide both title and start time.', 'error');
      return;
    }

    // Convert the selected start time to a moment object
    const selectedTime = moment(startTime);

    // Check if the selected time is in the past
    if (selectedTime.isBefore(moment())) {
      Swal.fire('Error', 'Meeting start time cannot be in the past.', 'error');
      return;
    }

    const time = selectedTime.utc().format('YYYY-MM-DD HH:mm:ss');

    try {
      const res = await HttpRequest.createZoomMeeting(title, time);
      let data = res.data;
      setMeeting(data);
      Swal.fire('Success', 'Meeting Created.', 'success');
    } catch (err) {
      Swal.fire('Error', 'Create meeting failed.', 'error');
    }
  };

  return (
    <div className="d-flex justify-center flex-column align-center">
      <input
        type="text"
        placeholder="Meeting Title:"
        value={title}
        onChange={handleTitleChange}
        style={{width: '600px', padding: '8px', margin: '5px 0px'}}
      />

      <input
        type="datetime-local"
        placeholder="Meeting Start Time:"
        value={startTime}
        onChange={handleStartTimeChange}
        style={{width: '600px', padding: '8px', margin: '5px 0px'}}
      />
      <button
        onClick={addMeeting}
        style={{
          backgroundColor: '#438BF3',
          color: 'white',
          outline: 'none',
          border: 'none',
          padding: '8px 20px',
          borderRadius: '10px',
          cursor: 'pointer',
        }}>
        Create Meeting
      </button>

      {meeting && (
        <div>
          <h2>Meeting created!</h2>
          <p>Meeting ID: {meeting.id}</p>
          <p>Password: {meeting.password}</p>
          <a href={meeting.join_url}>Join URL</a>
        </div>
      )}
    </div>
  );
}

export default CreateMeeting;
