import React, {useState, useEffect, useCallback} from 'react';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PanelTab from '../components/PanelTab';
import PatientChallengesList from '@components/challenge/patientChallenges/Table';
import { Link } from 'react-router-dom';

const PatientChallenges = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <>
    <div style={{display: "flex", justifyContent: "space-between", overflow: 'hidden'}}>
      <PanelTab
        tabs={['Patients']}
        selectedIndex={selectedIndex}
        onChange={selectedIndex => {
          setSelectedIndex(selectedIndex);
        }}
      />
        <div className="flex-row" style={{marginBottom: 10}}>
            <Link to="/challenge/create" className="btn btn-secondary btn-round">
            <FontAwesomeIcon icon={faPlus} /> Create a challenge
            </Link>
            <div className="flex-column flex" />
            </div>
        </div>

      <div className='bg-white p-2 overflow-hidden'>
        <PatientChallengesList />
      </div>
    </>
  );
};

export default PatientChallenges;
