import ProfilePic from '../assets/logo192.png';

export default {
  getImage(url) {
    let imageExist = false;
    if (url != null && url != '') {
      imageExist = true;
    }

    if (imageExist) {
      if (url.indexOf('http') == -1) {
        url = 'https://ybgrconnect-24189.s3.amazonaws.com/media/' + url;
      }

      if (url.indexOf('?') > 0) {
        url = url.substring(0, url.indexOf('?'));
      }

      return url;
    } else {
      return ProfilePic;
    }
    
  },
};
