import UISelect from '@components/UISelect';
import { Box, MenuItem, TextField } from '@mui/material';
import React, { useState } from 'react';

export default function TimeSpentSelect({ setNotificationTempValues }) {
    const [selectedType, setSelectedType] = useState('');
    const [value, setValue] = useState('');
    const [timeUnit, setTimeUnit] = useState('');
    const [timeValues, setTimeValues] = useState({
      "More than": { value: '', unit: '' },
      "Less than": { value: '', unit: '' }
    });
  
    const handleTypeChange = (event) => {
      const newSelectedType = event.target.value;
      setSelectedType(newSelectedType);
    };
  
    const handleValueChange = (event) => {
      const newValue = event.target.value;
      setValue(newValue);
  
      if (selectedType !== '') {
        setTimeValues((prevTimeValues) => ({
          ...prevTimeValues,
          [selectedType]: {
            value: newValue,
          },
        }));
  
        if (setNotificationTempValues) {
          setNotificationTempValues((prevValues) => ({
            ...prevValues,
            [selectedType]: {
              value: newValue,
            },
          }));
        }
      }
    };
  
    const handleTimeUnitChange = (event) => {
      const newTimeUnit = event.target.value;
      setTimeUnit(newTimeUnit);
  
      if (selectedType !== '') {
        setTimeValues((prevTimeValues) => ({
          ...prevTimeValues,
          [selectedType]: {
            unit: newTimeUnit,
          },
        }));
  
        if (setNotificationTempValues) {
          setNotificationTempValues((prevValues) => ({
            ...prevValues,
            [selectedType]: {
              unit: newTimeUnit,
            },
          }));
        }
      }
    };
  
  return (
    <Box sx={styles.main}>
      <Box sx={styles.valueType}>
        <UISelect
          value={selectedType}
          onChange={handleTypeChange}
          sx={styles.selectStyle}
          fullHeight
        >
          <MenuItem value="More than">More than</MenuItem>
          <MenuItem value="Less than">Less than</MenuItem>
        </UISelect>
      </Box>
      <Box sx={styles.minMaxStyle}>
        <TextField
          sx={styles.textFieldStyles}
          size="small"
          hiddenLabel
          id="question_challenge"
          variant="outlined"
          value={value}
          onChange={handleValueChange}
        />
        <UISelect
          value={timeUnit}
          onChange={handleTimeUnitChange}
          fullHeight
        >
          <MenuItem value="Minutes">Minutes</MenuItem>
          <MenuItem value="Hours">Hours</MenuItem>
          <MenuItem value="Days">Days</MenuItem>
        </UISelect>
      </Box>
      {/* Now, `timeValues` contains the updated values for "More than" and "Less than" */}
    </Box>
  );
}

const styles = {
  main: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '32%',
    gap: '0.15em',
    '@media (max-width: 1000px)': {
      width: '45%',
    },
    '@media (max-width: 720px)': {
      width: '55%',
    },
  },
  valueType: {
    backgroundColor: '#E9E9E9',
    width: '45%',
    borderRadius: '0.3em 0 0 0.3em',
  },
  selectStyle: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  minMaxStyle: {
    display: 'flex',
    width: '60%',
    backgroundColor: '#E9E9E9',
    borderRadius: '0.3em',
  },
  textFieldStyles: {
    width: '65%',
    backgroundColor: 'white',
    borderRadius: '0.3em',
    height: '100%',
  },
};
