import { Box } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import IconPic from "../../assets/logo192.png"
import { Link } from 'react-router-dom';

export default function UIImageList({ list }) {
    const recentPatients = list.slice(0, 3);
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {recentPatients.map(item => (
                <Link key={item?.id} to={'/patient/detail/' + item.id} className="link">
                <Box sx={{ mr: -1 }}>
                    <img src={item.user.image || IconPic} style={styles.root} alt="user" />
                </Box>
                </Link>
            ))}
            <Link
                to="/patient/create">
            <IconButton aria-label="add" style={styles.root}>
                <AddIcon fontSize="small" />
            </IconButton>
            </Link>
        </Box>
    );
}

const styles = {
    root: {
        color: '#FFF',
        background: '#DDE9F3',
        border: 'solid',
        borderWidth: '2px',
        borderRadius: '50%',
        position: 'relative',
        width: '40px',
        height: '40px',
    },
};
