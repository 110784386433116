import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {HttpRequest} from '../utils/http'; 
import CircleImage from './CircleImage';
import NoData from './NoData';
import UIImageList from './challenge/UIImageList';
import IconUser from "../assets/logo192.png";
import { Link } from 'react-router-dom';

class SearchBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            query: '',
            patients: [],
            selectedIndex: -1,
            isShowResult: false,
        };
    }

    componentDidMount() {
        this.loadProfile();
    }

    loadProfile() {
        HttpRequest.showPatientList(null, null).then(res => {
            let data = res.data.results;
            this.setState({
                patients: data,
            });
        }).catch(err => {
            console.error('Error', 'Cannot load patient data.', err);
        });
    }

    doneTyping = () => {
        const searchTerm = this.state.query.toLowerCase();
        HttpRequest.searchPatient(searchTerm)
            .then((res) => {
                let patients = res.data.results.filter(
                    (patient) =>
                        (patient.user.name + patient.user.last_name)
                            .toLowerCase()
                            .includes(searchTerm)
                );
                this.setState({ patients, selectedIndex: -1, isShowResult: true });
            })
            .catch((err) => {
                console.error('Error', 'Search error', err);
            });
    };

    render() {
        let typingTimer;
        return (
            <div className="d-flex justify-content-between bg-white align-items-center">
                <div className="top-search-bar d-none d-md-flex col-md-9">
                    <FontAwesomeIcon icon={faSearch} className="icon" />
                    <input
                        type="text"
                        value={this.state.query}
                        placeholder="Quick search for contact"
                        onBlur={evt => {
                            // this.setState({ isShowResult: false });
                        }}
                        onChange={event => {
                            this.setState({ query: event.target.value });
                        }}
                        onKeyUp={event => {
                            if (event.code === 'ArrowDown') {
                                let selectedIndex = this.state.selectedIndex;
                                selectedIndex++;
                                if (selectedIndex > this.state.patients.length - 1) {
                                    selectedIndex = this.state.patients.length - 1;
                                }
                                this.setState({ selectedIndex });
                            } else if (event.code === 'ArrowUp') {
                                let selectedIndex = this.state.selectedIndex;
                                selectedIndex--;
                                if (selectedIndex < -1) {
                                    selectedIndex = -1;
                                }
                                this.setState({ selectedIndex });
                            } else if (event.code === 'Enter') {
                                if (this.state.selectedIndex >= 0) {
                                    const selectedPatient = this.state.patients[this.state.selectedIndex];
                                    const patientId = selectedPatient.id;
                                    this.props.history.push(`/patient/detail/${patientId}`);
                                    this.setState({ isShowResult: false, query: '' });
                                    // window.location.reload()
                                }
                            } else {
                                clearTimeout(typingTimer);
                                typingTimer = setTimeout(this.doneTyping, 500);
                            }
                        }}
                        onKeyDown={event => {
                            clearTimeout(typingTimer);
                        }}
                    />
                </div>

                {this.state.isShowResult && (
                    <>
                        <div
                            className="search-overlay"
                            onClick={e => {
                                this.setState({ isShowResult: false, query: '' });
                            }}
                        ></div>
                        <div className="search-result">
                            {this.state.patients.map((patient, key) => {
                                let selectedClass = null;
                                if (key === this.state.selectedIndex) {
                                    selectedClass = 'active';
                                }
                                return (
                                    <Link
                                        to={'/patient/detail/' + patient.id}
                                        className={'flex flex-row search-item ' + selectedClass}
                                        key={key}
                                        onClick={() => {
                                            this.setState({ isShowResult: false, query: '' });
                                            // window.location.reload()
                                        }}
                                    >
                                        <CircleImage size={30} src={null} />
                                        <span style={{ marginLeft: 10 }}>
                                            {patient.user.name + ' ' + patient.user.last_name}
                                        </span>
                                    </Link>
                                );
                            })}
                            {this.state.patients.length === 0 && <NoData />}
                        </div>
                    </>
                )}
                Recent Contacts
                <UIImageList list={this.state.patients} />
            </div>
        );
    }
}

export default withRouter(SearchBar);
