import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import moment from 'moment';

export default function TooltipBox(props) {
  return (
    <Card sx={{ minWidth: 275, maxWidth: 350, zIndex: 1000 ,position: "absolute", overflow: 'hidden'}}>
      <CardContent>
      {<Typography variant="h6" component="div" sx={{fontSize: "15px", fontWeight: 'bold'}}>{moment(props.date).format('DD-MM-YYYY')} - {props.status}</Typography>}  
        <Typography variant="body2">
          Activity: {props?.activity}
        </Typography>
        <Typography variant="body2">
          Note
        </Typography>
        <Typography variant="body2">
        {props.details}
        </Typography>
      </CardContent>
    </Card>
  );
}