import {Box, Button, MenuItem} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import Button1 from './Button';
import WeekSubButton from './WeekSubButton';
import UISelect from '@components/UISelect';
import moment from 'moment';
import {HttpRequest} from '@utils/http';
import Swal from 'sweetalert2';
import ActivityIndicator from '@components/ActivityIndicator';

const SubTableRow = ({
  data,
  userChallenge,
  user,
  challenge,
  userProfile,
  completeChallenge,
}) => {
    const [selectedWeek, setSelectedWeek] = useState(() => {
        const currentDate = moment();
        const startOfMonth = currentDate.clone().startOf('month');
        return currentDate.isoWeek() - startOfMonth.isoWeek();
      });

  const [challengeAnswer, setChallengeAnswer] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [dailyChallengeAnswer, setDailyChallengeAnswer] = useState([]);
  const [showTooltip, setshowTooltip] = useState(true);
  const [activeLabelButton, setActiveLabelButton] = useState('Daily');
  const buttonLabels = ['Daily', 'Weekly'];
  const currentDate = moment();

  const currentMonth = currentDate.month();
  const currentYear = currentDate.year();

  const daysInMonth = currentDate.daysInMonth();
  const getDayOfWeek = dateString => {
    return moment(dateString).format('dddd');
  };

  const loadChallengeAnswer = useCallback(() => {
    setIsLoading(true);
    HttpRequest.getChallengeAnswer(null, user, challenge)
      .then(res => {
        let data = res.data.results;

        let newEntry = {
          answer: 'new_answer',
          journal: 'New journal entry\n',
        };

        let arr = data.map(item => {
          let existingDate = moment(item.meta_data.date).format('YYYY-MM-DD');

          data.forEach(existingItem => {
            if (existingItem.meta_data) {
              let day = getDayOfWeek(
                moment(existingItem.meta_data?.date).format('YYYY-MM-DD'),
              );

              let keys = Object.keys(existingItem.meta_data);
              keys.forEach(key => {
                if (key === existingDate) {
                  existingItem.meta_data[key] = newEntry;
                }
              });
            }
          });

          return item;
        });

        setDailyChallengeAnswer(arr);
        setChallengeAnswer(data);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        Swal.fire('Error', 'Cannot load Providers', 'error');
      });
  }, []);

  useEffect(() => {
    loadChallengeAnswer();
  }, []);

  const daysArray = [];
  const today = moment();

  for (let day = 1; day <= daysInMonth; day++) {
    const dateObj = moment(
      `${currentYear}-${currentMonth + 1}-${day}`,
      'YYYY-MM-DD',
    );
    const isToday = dateObj.isSame(today, 'day'); 
    const dayObj = {
      id: day,
      day: dateObj.format('dddd'),
      date: dateObj.format('YYYY-MM-DD'),
      today: isToday, 
    };

    dailyChallengeAnswer.forEach(item => {
      if (moment(item?.meta_data?.date).format('YYYY-MM-DD') === dayObj.date) {
        dayObj.data = item;
      }
    });

    daysArray.push(dayObj);
  }

  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);

  const handleButtonClick = buttonLabel => {
    setActiveLabelButton(buttonLabel);
  };

  const handleButtonModalClick = index => {
    setSelectedButtonIndex(index);
  };

  const filteredDays = daysArray.slice(
    (selectedWeek - 1) * 7,
    selectedWeek * 7,
  );

  return (
    <div>
      <Box sx={{py: 1}}>
        <div className="sub-table-head-main">
          <div className="sub-table-head">
            <div className="select-options">
              {buttonLabels.map(label => {
                return (
                  <Button
                    key={label}
                    onClick={() => handleButtonClick(label)}
                    sx={{
                      ...styles.tabButton,
                      color: label === activeLabelButton ? 'white' : 'grey',
                      backgroundColor:
                        label === activeLabelButton ? '#274F66ff' : 'white',
                    }}>
                    {label}
                  </Button>
                );
              })}
            </div>
            <Box sx={{width: '200px'}}>
              <UISelect
                value={selectedWeek}
                onChange={e => setSelectedWeek(e.target.value)}>
                <MenuItem value={1}>Week 1</MenuItem>
                <MenuItem value={2}>Week 2</MenuItem>
                <MenuItem value={3}>Week 3</MenuItem>
                <MenuItem value={4}>Week 4</MenuItem>
                <MenuItem value={5}>Remaining</MenuItem>
              </UISelect>
            </Box>
          </div>
          {/* <p id="view-details">View details</p> */}
        </div>
        {isLoading && <ActivityIndicator />}
        {!isLoading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              gap: '1%',
              mt: 3,
            }}>
            {activeLabelButton === 'Daily' ? (
              filteredDays.map((item, index) => (
                <Box key={item.id}>
                  <Button1
                    data={item?.day}
                    answerData={item?.data?.meta_data}
                    status={item?.data}
                    note={item?.data?.journal_activity}
                    activity={item?.data?.value}
                    onClick={() => handleButtonModalClick(item?.id)}
                  />
                </Box>
              ))
            ) : activeLabelButton === 'Weekly' ? (
              <Box>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                  <WeekSubButton
                    data={daysArray}
                    userProfile={userProfile}
                    completeChallenge={completeChallenge}
                  />
                </Box>
              </Box>
            ) : null}
          </Box>
        )}
      </Box>
    </div>
  );
};

export default SubTableRow;

const styles = {
  tabButton: {
    height: '30px',
    minWidth: '65px',
    textAlign: 'center',
    borderRadius: '50px',
    textTransform: 'none',
    fontSize: '13px',
    outline: 'none',
    padding: '5px 15px',
    '&:hover': {
      backgroundColor: '#156180',
      color: 'white',
    },
  },
};
