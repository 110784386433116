import React from 'react';
import Panel from '../components/Panel';
import {faChevronLeft, faSave} from '@fortawesome/free-solid-svg-icons';
import CircleImage from '../components/CircleImage';
import MilestoneList from '../components/MilstoneList';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {HttpRequest} from '../utils/http';
import ActivityIndicator from '../components/ActivityIndicator';
import {Link, withRouter} from 'react-router-dom';
import {store} from '../store';
import Swal from 'sweetalert2';
import TextInput from '../components/TextInput';
import HorizontalLine from '../components/HorizontalLine';
import ProfileIcon from "../assets/logo192.png"
class UserDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      title: '',
      name: '',
      last_name: '',
      username: '',
      email: '',
      address: '',
      birth_date: '',
      gender: '',
      phone: '',
      security_question: '',
      security_answer: '',
      contact_info: '',
      image: null,
      userLogs: [],
      department : '',
    };

    this.id = props.match?.params?.id;
    this.type = props.match?.params?.type;
  }

  componentDidMount() {
    let user = store.getState().loginReducer.user;
    if (user && this.id != null) {
      this.loadUser();
      this.loadUserLog();
    }
  }

  loadUser() {
    this.setState({isLoading: true});

    let promise =
      this.type == 'agent'
        ? HttpRequest.getAgent(this.id)
        : HttpRequest.getProvider(this.id);

    promise
      .then(res => {
        let data = res.data.data;

        this.setState({
          isLoading: false,
          name: data.user.name,
          last_name: data.user.last_name,
          username: data.user.username,
          email: data.user.email,
          address: data.address,
          birth_date: data.birth_date,
          gender: data.gender,
          phone: data.phone,
          security_question: data.security_question,
          security_answer: data.security_answer,
          contact_info: data.contact_info,
          image: data.image,
          department: data.provider_data.department,
          title: data.provider_data.title,

        });

      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load user data.', 'error');
      });
  }

  loadUserLog() {
    this.setState({isLoadingLog: true});

    HttpRequest.getActivityLog(this.id)
      .then(res => {
        let userLogs = res.data.results.map((log, key) => {
          return {
            status: null,
            // title: 'Update Data',
            title: log.action_flag_desc,
            created: log.action_time,
            description: log.change_message,
          };
        });
        this.setState({isLoadingLog: false, userLogs});
      })
      .catch(err => {
        this.setState({isLoadingLog: false});
        Swal.fire('Error', 'Cannot load user log data.', 'error');
      });
  }

  save() {
    let user = store.getState().loginReducer.user;

    this.setState({isLoading: true});

    let {
      title,
      name,
      last_name,
      username,
      email,
      address,
      birth_date,
      gender,
      phone,
      security_question,
      security_answer,
      contact_info,
    } = this.state;

    let data = {
      user: {
        username: username,
        email: email,
        name: name,
        last_name: last_name,
      },
      provider: {
        title: title,
        default_photo: null,
        department: 1,
      },
      image: null,
      birth_date: null,
      address: address,
      contact_info: contact_info,
      phone: phone,
      gender: gender,
      security_question: security_question,
      security_answer: security_answer,
    };

    let promise =
      this.type == 'agent'
        ? HttpRequest.saveAgent(this.id, data)
        : HttpRequest.saveProvider(this.id, data);

    promise
      .then(res => {
        this.setState({
          isLoading: false,
        });
        Swal.fire('Information', this.type + ' saved.', 'success').then(res => {
        //   window.location = '/user';
        this.props.history.push('/userS');
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot update profile data.', 'error');
      });
  }

  render() {
    if (this.state.isLoading) {
      return <ActivityIndicator />;
    }

    return (
      <>
        <div className="flex-row" style={{marginBottom: 10}}>
          <Link to="/user" className="btn btn-secondary btn-round">
            <FontAwesomeIcon icon={faChevronLeft} /> Back to agents/provider
          </Link>
        </div>

        <div className="row">
          <div className="col-md-9">
            <div className="flex-row" style={{marginBottom: 30}}>
              <CircleImage src={this.state.image || ProfileIcon} size={150} maxSize={'30vw'} />

              <div
                className="flex-column flex justify-center"
                style={{marginLeft: 32}}>
                <h1>
                  {this.state.name} {this.state.last_name}
                </h1>
                {/* <div className='flex-row' style={{ marginBottom: 16 }}>
                                    <span className='tag'>Tag 1</span>
                                    <span className='tag tag-danger'>Tag 1</span>
                                </div> */}
                <div className="flex-row">
                  <Link
                    to={'/user/edit/' + this.type + '/' + this.id}
                    className="btn btn-primary">
                    Edit profile
                  </Link>
                </div>
              </div>
            </div>

            <Panel>
              <div className="row" style={{paddingTop: 10}}>
                <div className="col-md-6">
                  <TextInput
                    value={this.state.name}
                    title="Name"
                    readOnly={true}
                    placeholder="Enter your name"
                    onChange={event => {
                      this.setState({name: event.target.value});
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    value={this.state.last_name}
                    title="Last Name"
                    readOnly={true}
                    placeholder="Enter your last name"
                    onChange={event => {
                      this.setState({last_name: event.target.value});
                    }}
                  />
                </div>
              </div>

              <HorizontalLine title="Professional Information" />

              <div className="row" style={{paddingTop: 10}}>
                <div className="col-md-6">
                  <TextInput
                    value={this.state.title}
                    title="Title"
                    readOnly={true}
                    placeholder="Enter your title"
                    onChange={event => {
                      this.setState({title: event.target.value});
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    value={this.state.department}
                    title="Department"
                    readOnly={true}
                    placeholder="Enter your department"
                    onChange={event => {
                      this.setState({department: event.target.value});
                    }}
                  />
                </div>
              </div>

              <HorizontalLine title="Contact Information" />

              <div className="row" style={{paddingTop: 10}}>
                <div className="col-md-6">
                  <TextInput
                    value={this.state.phone}
                    title="Phone number"
                    readOnly={true}
                    placeholder="Enter your phone number"
                    onChange={event => {
                      this.setState({phone: event.target.value});
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                    value={this.state.email}
                    title="Email"
                    readOnly={true}
                    placeholder="Enter your email"
                    onChange={event => {
                      this.setState({email: event.target.value});
                    }}
                  />
                </div>
              </div>
            </Panel>
          </div>
          <div className="col-md-3 hide-scrollbar-thin">
            <Panel>
              <div className="section-title">Recent activity logs</div>
              <MilestoneList data={this.state.userLogs} />
            </Panel>
          </div>
        </div>
      </>
    );
  }
}

const styles = {
  control: {
    marginLeft: 8,
  },
};

export default withRouter(UserDetail);
