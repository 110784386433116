import React, {useState, useEffect, useCallback, useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';
import moment from 'moment';
import ActivityIndicator from '../components/ActivityIndicator';
import DataGrid from '../components/DataGrid';
import Panel from '../components/Panel';
import {HttpRequest} from '../utils/http';
import Mime from 'mime-types';
import Swal from 'sweetalert2';
import {Link} from 'react-router-dom';
import {
  faCaretLeft,
  faCaretRight,
  faCommentDots,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CoPresentOutlined} from '@mui/icons-material';
import Pubnub from 'pubnub';
import {appConfig} from 'src/config/app';
import StringHelper from '@utils/StringHelper';

const SCHEDULE_CELL_WIDTH = 70;

let _bookingListPerHour = {};
for (let i = 9; i <= 16; i++) {
  _bookingListPerHour[i.toString().padStart(2, '0')] = null;
}

const am = [
  {time: '09', label: '9'},
  {time: '10', label: '10'},
  {time: '11', label: '11'},
  {time: '12', label: '12'},
];
const pm = [
  {time: '13', label: '1'},
  {time: '14', label: '2'},
  {time: '15', label: '3'},
  {time: '16', label: '4'},
];

const switchLabels = ['All', 'Task', 'Todo'];

export default function Home(props) {
  const user = useSelector(state => state.loginReducer.user);
  const patients = useSelector(state => state.profiles);
  const [isLoadingChatRequest, setIsLoadingChatRequest] = useState(false);
  const [messageRequest, setMessageRequest] = useState([]);
  const [messageRequestBadge, setMessageRequestBadge] = useState(0);
  const [isLoadingBooking, setIsLoadingBooking] = useState(false);
  const [bookingList, setBookingList] = useState([]);
  const [bookingListPerDate, setBookingListPerDate] = useState([]);
  const [bookingListPerHour, setBookingListPerHour] =
    useState(_bookingListPerHour);
  const [isLoadingMilestone, setIsLoadingMilestone] = useState(false);
  const [milestones, setMilestones] = useState([]);
  const [isLoadingRoom, setIsLoadingRoom] = useState(false);
  const [isLoadingUnassigned, setIsLoadingUnassigned] = useState(false);
  const [unassignedPatients, setUnassignedPatients] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [providers, setProviders] = useState([]);
  const [regarding, setRegarding] = useState([]);
  const [chats, setChats] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageChat, setCurrentPageChat] = useState(1);
  const [totalPage, setTotal] = useState(1);
  const [next, setNext] = useState(null);
  const itemsPerPage = 2; // Number of items to display per page
  const [searchCriteria, setSearchCriteria] = useState('Sender Name'); // Default to 'Sender Name'
  const [searchText, setSearchText] = useState('');
  const [searchTaskText, setSearchTaskText] = useState('');
  const [selectedStatusFilter, setSelectedStatusFilter] = useState('All'); // Default to 'All'
  const [checkedStatus, setCheckedStatus] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [exportSelect, setExportSelect] = useState(false);
  // Calculate the index range for the current page in unassigned patients pagination
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Handle page change when clicking next or previous
  const handlePageChange = newPage => {
    if (
      newPage > 0 &&
      newPage <= Math.ceil(unassignedPatients.length / itemsPerPage)
    ) {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    if (user) {
      // loadBooking();
      // loadTasks();
      // loadMilestone();
      //   chatRequests();
      // loadProviders();
      loadFcmRoom();
      // loadUnassignedPatient();
    }
  }, [user]);

  const [selectedItems, setSelectedItems] = useState([]);

  const handleCheckboxChange = roomId => {
    const isSelected = selectedItems.includes(roomId);

    if (isSelected) {
      // If the room is already selected, remove it from the selected items
      setSelectedItems(selectedItems.filter(item => item !== roomId));
    } else {
      // If the room is not selected, add it to the selected items
      setSelectedItems([...selectedItems, roomId]);
    }

    // Check "Select All" if all items are selected
    setSelectAll(selectedItems.length === chats.length);
  };

  const pubnub = useMemo(() => {
    if (user) {
      return new Pubnub({
        publishKey: appConfig.pubPublishKey,
        subscribeKey: appConfig.pubSubscribeKey,
        uuid: user.user.id + '',
      });
    }

    return null;
  }, [user, currentPageChat]);

  function loadFcmRoom(page) {
    setIsLoadingRoom(true);
    HttpRequest.roomList(null, null, null, page)
      .then(res => {
        let next = res.data.next;
        const nextPageNumber = next ? next.split('page=')[1] : null;
        const count = res.data.count;
        const roundedPage = Math.ceil(count / 20);
        let data = res.data.results.map(roomObj => {
          let roomParts = roomObj.room.split('-');

          roomObj.newRoom = `private.${roomParts[1]}-${roomParts[2]}`;

          const channelName = `private.${roomParts[1]}-${roomParts[2]}`;
          pubnub?.subscribe({
            channels: [channelName],
            withPresence: true,
          });

          pubnub?.addListener({
            message: function (message) {},
            presence: function (presenceEvent) {},
            status: function (status) {
              if (status.category === 'PNConnectedCategory') {
                pubnub.history(
                  {
                    channel: channelName,
                    count: 1,
                  },
                  function (status, response) {
                    if (status.error) {
                      console.error(status.errorData);
                      return;
                    }
                    if (response?.messages[0]?.entry?.text) {
                      roomObj.lastMessage = response?.messages[0]?.entry?.text;
                    } else if (response?.messages[0]?.entry?.image) {
                      roomObj.lastMessage = response?.messages[0]?.entry?.image;
                    } else if (response?.messages[0]?.entry?.audio) {
                      roomObj.lastMessage = response?.messages[0]?.entry?.audio;
                    } else if (response?.messages[0]?.entry?.video) {
                      roomObj.lastMessage = response?.messages[0]?.entry?.video;
                    }
                  },
                );
              }
            },
          });

          return roomObj;
        });
        setChats(data);
        setNext(nextPageNumber);
        setTotal(roundedPage);
        setTimeout(() => {
          setIsLoadingRoom(false);
        }, 1000);
      })
      .catch(err => {
        Swal.fire('Error', 'Cannot load room data.', 'error');
        setIsLoadingRoom(false);
      });
  }

  const loadPage = (page, loadFcmRoom) => {
    setCurrentPageChat(page);
    loadFcmRoom(page);
  };

  const loadNextPage = () => {
    loadPage(currentPageChat + 1, loadFcmRoom);
  };

  const loadPrevPage = () => {
    if (currentPageChat > 1) {
      loadPage(currentPageChat - 1, loadFcmRoom);
    }
  };

  const filterChats = () => {
    const searchTextLower = searchText?.toLowerCase();
    return chats.filter(chat => {
      const chatText = chat.lastMessage ? chat?.lastMessage?.toLowerCase() : '';

      if (selectedStatusFilter === 'is_accepted' && !chat.is_accepted) {
        return false;
      } else if (selectedStatusFilter === 'is_reported' && !chat.is_reported) {
        return false;
      }

      if (searchCriteria === 'Sender Name' && chat.sender) {
        return chat?.sender?.name?.toLowerCase().includes(searchTextLower);
      } else if (
        searchCriteria === 'Receiver Name' &&
        chat.receiver &&
        chat.receiver[0]
      ) {
        return chat?.receiver[0]?.name?.toLowerCase().includes(searchTextLower);
      } else if (searchCriteria === 'Last Message') {
        return chatText.includes(searchTextLower);
      }

      return true;
    });
  };

  const dataGridRef = useRef(null);

  const handleExportSelected = () => {
    if (dataGridRef.current) {
      dataGridRef.current.exportSelectedRows();
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        {isLoadingChatRequest && <ActivityIndicator />}
        {!isLoadingChatRequest && (
          <>
            <div className="d-flex justify-content-between">
              <div className="section-title">
                Messages{' '}
                {messageRequestBadge != 0
                  ? '(' + messageRequestBadge + ')'
                  : ''}
                {/* <span className='text-danger'>(2 Urgent)</span> */}
              </div>
              <div>
                <a
                  href="#"
                  className="btn btn-primary btn-round"
                  onClick={handleExportSelected}>
                  Export Selected
                </a>
              </div>
            </div>
            {/* <button onClick={handleExportSelected}>Export Selected</button> */}

            <Panel>
              {isLoadingRoom && <ActivityIndicator />}
              {!isLoadingRoom && (
                <DataGrid
                  ref={dataGridRef}
                  exportSelect={exportSelect}
                  onCheckboxChange={handleCheckboxChange}
                  checkedStatus={checkedStatus}
                  data={filterChats()}
                  filters={[
                    {
                      type: 'textinput',
                      label: 'Search contacts',
                      icon: faSearch,
                      onChange: text => {
                        setSearchText(text);
                      },
                      className: 'col-md-5 col-sm-12',
                      style: {flex: 1},
                    },
                    {
                      type: 'select',
                      label: 'Search by',
                      data: [
                        {id: 'Sender Name', label: 'Sender Name'},
                        {id: 'Receiver Name', label: 'Receiver Name'},
                        {id: 'Last Message', label: 'Last Message'},
                      ],
                      onChange: selectedCriteria => {
                        setSearchCriteria(selectedCriteria);
                      },
                      className: 'col-md-4 col-sm-4',
                      style: {width: 400},
                    },
                    {
                      type: 'select',
                      label: 'Status',
                      data: [
                        {id: 'All', label: 'All'},
                        {id: 'is_accepted', label: 'Accepted'},
                        {id: 'is_reported', label: 'Reported'},
                      ],
                      onChange: text => {
                        setSelectedStatusFilter(text);
                      },
                      className: 'col-md-3 col-sm-4',
                      style: {width: 200},
                    },
                  ]}
                  columns={[
                    {
                      name: 'checkable',
                      label: 'Name',
                      checkable: true,
                      renderItem: item => {
                        let fullName = item.sender?.name;
                        if (
                          fullName == null ||
                          fullName.trim() == 'null' ||
                          fullName.trim() == ''
                        ) {
                          fullName = '(Guest #' + item.sender.id + ')';
                        }

                        return (
                          <div>
                            <img
                              src={
                                item.sender?.image == null
                                  ? 'https://img.icons8.com/stickers/100/null/user.png'
                                  : item.sender?.image
                              }
                              alt="senderImage"
                              width="40px"
                              height="40px"
                              style={{
                                margin: '0 10px 0 20px',
                                borderRadius: '50px',
                              }}
                            />
                            <Link
                              to={'/admin-messaging/messaging/#' + item.room}
                              className="link">
                              {fullName}
                            </Link>
                          </div>
                        );
                      },
                    },
                    {
                      label: 'Last Message',
                      renderItem: item => {
                        let lastMessage = '(No Message)';
                        if (item?.lastMessage) {
                          lastMessage = item?.lastMessage;
                          if (StringHelper.isValidURL(lastMessage)) {
                            let mimeFile =
                              Mime.lookup(
                                StringHelper.pathBaseName(lastMessage),
                              ) || 'image/jpeg';
                            let start = mimeFile.substr(0, 5);
                            if (start == 'image') {
                              lastMessage = (
                                <img
                                  src={item?.lastMessage}
                                  alt="last_message"
                                  width="40px"
                                  height="40px"
                                />
                              );
                            } else if (start == 'video') {
                              lastMessage = ':movie_camera: (Video)';
                            } else if (start == 'audio') {
                              lastMessage = ':musical_note: (Audio)';
                            }
                          }
                        }
                        return lastMessage;
                      },
                    },
                    {
                      label: 'Date',
                      renderItem: item => {
                        return moment(item.created).format(
                          'MMM DD, YYYY, hh:mm A',
                        );
                      },
                    },
                    {
                      label: 'Sent To',
                      renderItem: item => {
                        return (
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <img
                              src={
                                item.receiver[0]?.image == null
                                  ? 'https://img.icons8.com/stickers/100/null/user.png'
                                  : item.receiver[0]?.image
                              }
                              alt="senderImage"
                              width="40px"
                              style={{marginRight: '10px'}}
                            />{' '}
                            <div>{item.receiver[0]?.name}</div>{' '}
                          </div>
                        );
                      },
                    },
                    {
                      label: 'Status',
                      renderItem: item => {
                        return item.is_reported ? (
                          <span style={{color: '#DC5A52'}}>
                            <Link
                              to={'/admin-messaging/messaging/#' + item.room}
                              className="link">
                              <FontAwesomeIcon icon={faCommentDots} />
                            </Link>
                          </span>
                        ) : (
                          <span style={{color: '#000000'}}>
                            <Link
                              to={'/admin-messaging/messaging/#' + item.room}
                              className="link">
                              <FontAwesomeIcon icon={faCommentDots} />
                            </Link>
                          </span>
                        );
                      },
                    },
                  ]}
                />
              )}
              <div className="d-flex justify-content-end align-items-center gap-8">
                <FontAwesomeIcon
                  onClick={currentPageChat === 1 ? null : loadPrevPage}
                  icon={faCaretLeft}
                  style={{
                    ...styles.pagination,
                    color: currentPageChat === 1 ? '#ACB9C2' : '#274F66',
                  }}
                  disabled={currentPageChat === 1}
                />
                Page {currentPageChat} of {totalPage}
                <FontAwesomeIcon
                  onClick={!next ? null : loadNextPage}
                  icon={faCaretRight}
                  style={{
                    ...styles.pagination,
                    color: !next ? '#ACB9C2' : '#274F66',
                  }}
                  disabled={!next}
                />
              </div>
            </Panel>
          </>
        )}
      </div>
    </div>
  );
}

const styles = {
  milestone: {
    color: '#D6D6D6',
    height: 130,
    position: 'relative',
  },
  bgMilestone: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 0.3,
    top: 0,
    left: 0,
    backgroundSize: 'cover',
    borderRadius: 10,
  },
  type: {
    color: 'var(--color-text)',
    height: 25,
  },
  name: {
    color: 'var(--color-text)',
    fontSize: 16,
    fontWeight: '600',
    lineHeight: '19px',
  },

  scrollHorizontal: {
    overflowX: 'scroll',
  },
  scheduleWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  cell: {
    width: SCHEDULE_CELL_WIDTH,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: 8,
  },
  scheduleHeader: {
    paddingLeft: 16,
    paddingTop: 8,
    paddingBottom: 8,
  },
  pagination: {
    height: 24,
    width: 28,
    cursor: 'pointer',
    fontSize: '26px',
  },
};
