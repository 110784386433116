import React, { useCallback, useEffect, useState } from 'react';
import ChallengeOption from '../ChallengeOption';
import {
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  useRadioGroup,
} from '@mui/material';
import UISelect from '@components/UISelect';
import GradientSlider from './GradientSlider';
import { HttpRequest } from '@utils/http';
import Swal from 'sweetalert2';

const selectStyles = {
  width: "50%",
  height: 40,
  background: 'white',
  '& .MuiSelect-select': {
    fontSize: 17,
    outline: 0,
    color: 'darkGray',
    fontWeight: 600,
    borderStyle: 'none',
    paddingY: 1,
  },
};

const mapScale = {
  1: <div></div>,
  2: <div></div>,
  3: <div></div>,
  4: <div></div>,
  5: <div></div>,
  6: <div></div>,
  7: <div></div>,
};

export default function FieldsetScoringScale({value, onChange = () => {}, id, setScoreTempValues, scoreTempValues, setSelectedScore}) {
  const [scoreTemplates, setScoreTemplate] = useState([]);
  const [scoreTemplatesValue, setScoreTemplateValue] = useState("");
  const scaleTemplate = scoreTemplates;
  const scaleMenuItems1 = scaleTemplate[0]?.measurement_choices;
    // const scaleMenuItems2 = scaleTemplate[1]?.measurement_choices;
  const scaleMenuItems3 = scaleTemplate[2]?.measurement_choices;
  const scaleMenuItems4 = scaleTemplate[3]?.measurement_choices;
  const scaleMenuItems5 = scaleTemplate[4]?.measurement_choices;
  const scaleMenuItems6 = scaleTemplate[5]?.measurement_choices;
  const radioGroup = useRadioGroup();
  const checked = radioGroup?.value === id;

  const loadChallengesScoreTemplate = useCallback(() => {
      HttpRequest.getChallengesScaleTemplate()
        .then(res => {
          let data = res.data.results;
          setScoreTemplate(data);
        })
        .catch(err => {
          Swal.fire('Error', 'Cannot Challenges Goal Providers', 'error');
        });
    }, []);
  
    useEffect(() => {
        loadChallengesScoreTemplate();
    }, []);

  const handleValueChange = event => {
    setScoreTemplateValue(event.target.value);
    setScoreTempValues(event.target.value);
  };

  const handleChange = (value) => {
    const selectedId = value;
    const selectedName = scoreTemplates.find((data) => data.id === value);
    setSelectedScore(value);
    onChange(
        {
            "scoreTemplate": selectedId, 
            "scoreTemplateValues": {
            "name": selectedName?.name,
        }
    }); 
    // setScoreTempValues(null);
  };

  function ConditionalRendering() {
    if (checked) {
      return (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1em',
              paddingRight: '2em',
              width: '100%',
            }}>
            <Box
              sx={{
                width: '35%',
              }}>
              <UISelect value={value?.scoreTemplate} label="" onChange={(e) => handleChange(e.target.value)} fullHeight>
                {scaleTemplate.map(({id, name}) => (
                  <MenuItem key={id} value={id} name={name} data-value={name} >
                    {name}
                  </MenuItem>
                ))}
              </UISelect>
            </Box>

            {mapScale[value?.scoreTemplate]}
            {value?.scoreTemplate === 2 ? (
              <>
                <Box
                  sx={{
                    width: '60%',
                  }}>
                  <GradientSlider handleChangeData={setScoreTempValues} scoreTemplatesValue={scoreTempValues} />
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '0.3em',
                    width: '20vw',
                    left: 0,
                  }}>
                  {value?.scoreTemplate === 1 ? (
                    <Select
                      input={
                        <OutlinedInput
                          inputProps={{
                            'aria-label': 'Without label',
                            hiddenlabel: 'true',
                          }}
                          hiddenlabel
                        />
                      }
                      sx={selectStyles}
                      value={scoreTemplatesValue}
                      onChange={handleValueChange}>
                      {scaleMenuItems1.map((measurementChoice, index) => (
                        <MenuItem key={index} value={measurementChoice}>
                          {measurementChoice}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : value?.scoreTemplate === 3 ? (
                    <Select
                      input={
                        <OutlinedInput
                          inputProps={{
                            'aria-label': 'Without label',
                            hiddenlabel: 'true',
                          }}
                          hiddenlabel
                        />
                      }
                      sx={selectStyles}
                      value={scoreTemplatesValue}
                      onChange={handleValueChange}>
                      {scaleMenuItems3.map((measurementChoice, index) => (
                        <MenuItem key={index} value={measurementChoice}>
                          {measurementChoice}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : value?.scoreTemplate === 4 ? (
                    <Select
                      input={
                        <OutlinedInput
                          inputProps={{
                            'aria-label': 'Without label',
                            hiddenlabel: 'true',
                          }}
                          hiddenlabel
                        />
                      }
                      sx={selectStyles}
                      value={scoreTemplatesValue}
                      onChange={handleValueChange}>
                      {scaleMenuItems4.map((measurementChoice, index) => (
                        <MenuItem key={index} value={measurementChoice}>
                          {measurementChoice}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : value?.scoreTemplate === 5 ? (
                    <Select
                      input={
                        <OutlinedInput
                          inputProps={{
                            'aria-label': 'Without label',
                            hiddenlabel: 'true',
                          }}
                          hiddenlabel
                        />
                      }
                      sx={selectStyles}
                      value={scoreTemplatesValue}
                      onChange={handleValueChange}>
                      {scaleMenuItems5.map((measurementChoice, index) => (
                        <MenuItem key={index} value={measurementChoice}>
                          {measurementChoice}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : value?.scoreTemplate === 6 ? (
                    <Select
                      input={
                        <OutlinedInput
                          inputProps={{
                            'aria-label': 'Without label',
                            hiddenlabel: 'true',
                          }}
                          hiddenlabel
                        />
                      }
                      sx={selectStyles}
                      value={scoreTemplatesValue}
                      onChange={handleValueChange}>
                      {scaleMenuItems6.map((measurementChoice, index) => (
                        <MenuItem key={index} value={measurementChoice}>
                          {measurementChoice}
                        </MenuItem>
                      ))}
                    </Select>
                  ) :value?.scoreTemplate === 7 ? (  
                      <TextField
                        sx={{ width: '100%', backgroundColor: 'white' }}
                        size="small"
                        hiddenLabel
                        id="question_challenge"
                        variant="outlined"
                        placeholder='Enter hyperlink address'
                        value={scoreTemplatesValue}
                        onChange={handleValueChange}
                      />
                  ) : (
                    ''
                  )}
                </Box>
              </>
            )}
          </Box>
        </>
      );
    } else {
      return (
        <FormControl component="fieldset" sx={{marginTop: 1}}>
          <FormLabel component="legend">
            {scaleTemplate.find(item => item.id === value?.scoreTemplate)?.name}
          </FormLabel>
        </FormControl>
      );
    }
  }

  return (
    <ChallengeOption label="Scoring Scale" value={id} help="Tell what will be the scoring scale of the challenge?">
      <ConditionalRendering />
    </ChallengeOption>
  );
}
