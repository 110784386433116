import React from 'react';
import { withRouter } from 'react-router';
import { isWithinInterval } from 'date-fns'; // Removed unused imports
import CircleImage from '../components/CircleImage';
import Panel from '../components/Panel';
import FlexibleInputGroup from '../components/FlexibleInputGroup';
import MilestoneList from '../components/MilstoneList';
import { Link } from 'react-router-dom';
import { HttpRequest } from '../utils/http';
import { store } from '../store';
import ActivityIndicator from '../components/ActivityIndicator';

import Swal from 'sweetalert2';
import ProfilePic from '../assets/logo192.png';
import NoData from '@components/NoData';

class ReportDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoadingProfile: false,
            isLoadingMilestone: false,

            name: '',
            last_name: '',
            image: null,
            patientId: null,
            last_activity: '',
            userId: null,
            tags: [],
            filteredMilestones: [],
        };

        this.id = props.match.params.id;
    }

    componentDidMount() {
        let user = store.getState().loginReducer.user;
        if (user) {
            this.loadProfile();
            // this.loadMilestone();
            this.loadProvider();
        }
    }

    loadProfile() {
        let user = store.getState().loginReducer.user;

        this.setState({ isLoadingProfile: true });
        HttpRequest.getPatient(this.id)
            .then(res => {
                let data = res.data.data;

                this.setState({
                    isLoadingProfile: false,
                    name: data.user.name,
                    last_name: data.user.last_name,
                    patientId: data.user.id,
                    image: data.user.image,
                    tags: data.tags,
                    last_activity: data.last_activity,
                    // userId: data.user.id
                    // assignedProviders: data.providers
                });
                this.loadMilestone(data.user.id)
                HttpRequest.getProfile(data.user.id)
                    .then(res => {
                        let profile = res.data.results[0];
                        this.setState({
                            phone: profile.phone,
                            gender: profile.gender,
                            email: profile.user.email,
                        })
                    }).catch(err => {
                        this.setState({ isLoadingProfile: false });
                        Swal.fire('Error', 'Cannot load Profile Data', 'error');
                    }
                    )
            })
            .catch(err => {
                this.setState({ isLoadingProfile: false });
                Swal.fire('Error', 'Cannot load patient data. Detial', 'error');
            });
    }

    loadMilestone(id) {
        let user = store.getState().loginReducer.user;

        this.setState({ isLoadingMilestone: true });

        HttpRequest.getActivityLog(id)
            .then((res) => {
                let data = res.data.results;

                const userLogs = res.data.results.map((log, key) => {
                    return {
                        title: log.action_flag_desc,
                        type: log.content_type_desc,
                        created: log.action_time,
                        description: log.change_message_json,
                    };
                });

                // Initialize filteredMilestones with all milestones
                this.setState({
                    isLoadingMilestone: false,
                    milestones: userLogs,
                    filteredMilestones: userLogs, // Initialize filteredMilestones with all milestones
                });
            })
            .catch((err) => {
                this.setState({ isLoadingMilestone: false });
                Swal.fire('Error', 'Cannot load milestone data.', 'error');
            });
    }

    filterMilestones() {
        const { dateFilter, typeFilter, milestones } = this.state;

        const filteredMilestones = milestones.filter((milestone) => {
            // Parse milestone date as a Date object
            const milestoneDate = new Date(milestone.created);
            const currentDate = new Date();

            let dateFilterCondition = true;
            if (dateFilter === 'lessThan1Day') {
                dateFilterCondition = isWithinInterval(
                    milestoneDate,
                    {
                        start: new Date(currentDate.getTime() - 24 * 60 * 60 * 1000),
                        end: currentDate,
                    }
                );
            } else if (dateFilter === 'lessThan1Week') {
                dateFilterCondition = isWithinInterval(
                    milestoneDate,
                    {
                        start: new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000),
                        end: currentDate,
                    }
                );
            } else if (dateFilter === 'lessThan1Month') {
                dateFilterCondition = isWithinInterval(
                    milestoneDate,
                    {
                        start: new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000),
                        end: currentDate,
                    }
                );
            }

            let typeFilterCondition = true;
            if (typeFilter !== 'Any') {
                typeFilterCondition = milestone.title === typeFilter;
            }

            return dateFilterCondition && typeFilterCondition;
        });

        this.setState({ filteredMilestones });
    }
    handleDateFilterChange = (event) => {
        const dateFilter = event.target.value;
        this.setState({ dateFilter }, () => {
            this.filterMilestones();
        });
    };

    handleTypeFilterChange = (event) => {
        const typeFilter = event.target.value;
        this.setState({ typeFilter }, () => {
            this.filterMilestones();
        });
    };



    loadProvider() {
        let user = store.getState().loginReducer.user;

        this.setState({ isLoadingProvider: true });

        HttpRequest.providerList()
            .then(res => {
                let data = res.data.results;

                this.setState({
                    isLoadingProvider: false,
                    providers: data,
                });
            })
            .catch(err => {
                this.setState({ isLoadingProvider: false });
                Swal.fire('Error', 'Cannot load provider list. fvvrvv', 'error');
            });
    }


    render() {
        return (
            <div className="row">
                <div className="col-md-8">
                    {this.state.isLoadingProfile && <ActivityIndicator />}

                    {!this.state.isLoadingProfile && (
                        <>
                            <div className="flex-row" style={{ marginBottom: 30 }}>
                                <CircleImage
                                    src={this.state.image ? this.state.image : ProfilePic}
                                    size={200}
                                    maxSize={'30vw'}
                                />

                                <div
                                    className="flex-column flex justify-center"
                                    style={{ marginLeft: 32 }}>
                                    <h3>
                                        {this.state.name} {this.state.last_name}{' '}
                                    </h3>
                                    <div className="flex-row" style={{ marginBottom: 16 }}>
                                        {this.state.tags.map((tag, key) => {
                                            return (
                                                <span className="tag" key={key}>
                                                    {tag}
                                                </span>
                                            );
                                        })}
                                    </div>
                                    <div className="flex-row">
                                        <Link
                                            to={'/patient/edit/' + this.state.patientId}
                                            className="btn btn-primary"
                                            style={{ marginRight: 10 }}>
                                            Edit Profile
                                        </Link>
                                        <Link
                                            to={'/messaging'}
                                            className="btn btn-warning">
                                            Send message
                                        </Link>
                                    </div>
                                </div>
                            </div>

                        </>
                    )}
                </div>

                <div className="col-md-12">
                    
                    <Panel style={{ height: "80vh", overflow: "auto" }}>
                        <div className="section-title">Recent activity</div>

                        <div className="row" style={{ marginBottom: 16 }}>
                            <div className="col-md-2">
                                <FlexibleInputGroup title="Date Filter">
                                    <select
                                        className="grid"
                                        value={this.state.dateFilter}
                                        onChange={this.handleDateFilterChange}
                                    >
                                        <option value="">Any</option>
                                        <option value="lessThan1Day">Less than 1 day</option>
                                        <option value="lessThan1Week">Less than 1 week</option>
                                        <option value="lessThan1Month">Less than 1 month</option>
                                    </select>
                                </FlexibleInputGroup>
                            </div>


                            <div className="col-md-2">
                                <FlexibleInputGroup title="Type">
                                    <select className="grid" value={this.state.typeFilter} onChange={this.handleTypeFilterChange}>
                                        <option value="Any">Any</option>
                                        <option value="Addition">Addition</option>
                                        <option value="Change">Change</option>
                                        <option value="Deletion">Deletion</option>
                                    </select>
                                </FlexibleInputGroup>
                            </div>
                        </div>

                        {this.state.isLoadingMilestone && <ActivityIndicator />}

                        {!this.state.isLoadingMilestone && (
                            <MilestoneList data={this.state.filteredMilestones} />
                        )}

                    </Panel>
                </div>


            </div>
        );
    }
}

const styles = {
    pronoun: {
        fontSize: 13,
        marginLeft: 20,
    },
    lastLogin: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
};

export default withRouter(ReportDetails);