
import React from 'react';
import moment from 'moment';
import CircleImage from './CircleImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faSmile } from '@fortawesome/free-solid-svg-icons';
import {
    MILESTONE_NEGATIVE,
    MILESTONE_NEUTRAL,
    MILESTONE_POSITIVE,
} from '../store/constants';
import HappyFace from "../assets/icon-happy.png"
import SadFace from "../assets/icon-sad.png"
import NeutralFace from "../assets/icon-neutral.png"
import NoData from './NoData';
import Mime from 'mime-types';
import StringHelper from '../utils/StringHelper';

export default class MilestoneList extends React.Component {
    constructor(props) {
        super(props);
    }

    renderFace(face) {
        let circleImageSize = { width: '20px', height: '20px', color: 'green' };
    
        if (face === MILESTONE_NEUTRAL) {
            return <CircleImage src={NeutralFace}  size={25} />;
        } else if (face === MILESTONE_POSITIVE) {
            return <CircleImage src={HappyFace}  size={25} />;
        } else if (face === MILESTONE_NEGATIVE) {
            return <CircleImage src={SadFace}  size={25} />;
        } else {
            return <CircleImage src={face}  size={25} />;
        }
    }
    render() {
        let { data } = this.props;

        return (
            <div className="flex-column">
                {(data == null || data.length == 0) && (
                    <NoData>No Data Available</NoData>
                )}

                {data.map((item, key) => {

                    // let description = item?.file;
                    
                    let file = item?.description?.file;

                    let content = file;

                    if (StringHelper?.isValidURL(file)) {
                        file = StringHelper?.clearUrl(file);
                        let mimeFile =
                            Mime.lookup(StringHelper?.pathBaseName(file)) || 'image';
                        let start = mimeFile.substr(0, 5);

                        if (start == 'image') {
                            content = (
                                <div>
                                    <img src={file} style={{ height: 150, display: 'block' }} />
                                </div>
                            );
                        } else if (start == 'video') {
                            content = (
                                <div>
                                    <video style={{ width: '100%' }} height="240" controls>
                                        <source src={file} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            );
                        } else if (start == 'audio') {
                            content = (
                                <div>
                                    <audio style={{ width: '100%' }} controls>
                                        <source src={file} type="audio/ogg" />
                                        Your browser does not support the audio tag.
                                    </audio>
                                </div>
                            );
                        }
                    }

                    return (
                        <div key={key} className="flex-row" >
                            <div className="flex-column align-center">
                                {this.renderFace(item?.description?.status)}
                                {key != data.length - 1 && (
                                    <div className="flex-column" style={styles.line} />
                                )}
                            </div>
                            <div className="flex-column flex" style={styles.wrapper}>
                                <div className="flex-row align-center">
                                    <div className="flex-column flex">
                                        <div style={styles.title}>{item.title} {item.type}</div>
                                        <div style={styles.time}>
                                            {moment(item.created).format('MMM DD ha')}
                                        </div>
                                    </div>
                                    {/* <a>
                                        <FontAwesomeIcon icon={faEllipsisH} />
                                    </a> */}
                                </div>
                                <div style={styles.description}>{content}</div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

let styles = {
    circle: {
        border: '1px solid #cfd7db',
        borderRadius: 20,
        width: 40,
        height: 40,
        color: 'green',
    },
    line: {
        width: 1,
        background: '#cfd7db',
        flex: 1,
    },
    wrapper: {
        marginLeft: 16,
    },
    title: {
        fontSize: 18,
        fontWeight: '600',
    },
    time: {
        fontSize: 12,
        fontWeight: 'normal',
    },
    description: {
        fontSize: 16,
        marginTop: 10,
        marginBottom: 20,
    },
};
