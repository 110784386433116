export default {
  convertToSimpleObject(senderOrReceiver) {
    let obj = null;
    if (Array.isArray(senderOrReceiver)) {
      obj = senderOrReceiver[0];
    } else {
      obj = senderOrReceiver;
    }

    return {
      id: obj.user_id,
      name: obj.name,
      image: obj.image,
    };
  },
};
