import React, {useCallback, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ReplayIcon from '@mui/icons-material/Replay';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import './Table.css';
import {Button, Menu, MenuItem} from '@mui/material';
import LinearWithValueLabel from './ProgressBar';
import SubTableRow from './SubTableRow';
import UserIcon from '../../../assets/logo192.png';
import UISelect from '@components/UISelect';
import Swal from 'sweetalert2';
import {HttpRequest} from '@utils/http';
import moment from 'moment';
import {categories} from '@utils/mockData';
import { Link } from 'react-router-dom';

const challengeButtonLabel = ['All', 'Active', 'Completed', 'Archived'];

function Row(props) {
  let {row, deleteUserChallenge, reAssignUserChallenge} = props;

  const [open, setOpen] = useState(false);
  const [openRows, setOpenRows] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [challengeAnswer, setChallengeAnswer] = useState([]);
  const [dailyChallengeAnswer, setDailyChallengeAnswer] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState('All');
  const [selectedStatus, setSelectedStatus] = useState('All');

  //To Handle Opening of drop down on clicking on arrow icon
  const openMenu = Boolean(anchorEl);

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  //Handle Change of Filter All | Active | Done | Archievd
  const handleChallengeButtonClick = buttonLabel => {
    setSelectedStatus(buttonLabel);
  };

  const handleClick = index => {
    const isOpen = openRows.includes(index);
    if (isOpen) {
      setOpenRows(openRows.filter(rowIndex => rowIndex !== index));
    } else {
      setOpenRows([...openRows, index]);
    }
  };
  //Add color according to selected goal category
  const getCategoryColor = goal => {
    let color = categories.filter(item => item.id === goal);
    return color[0].color;
  };

  //Function to delete a user challenge
  const handleDeleteUserChallenge = id => {
    setAnchorEl(null);
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this user challenge!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel',
    }).then(result => {
      if (result.isConfirmed) {
        deleteUserChallenge(id);
      }
    });
  };
  //Function to re assign a user challenege
  const handleReassignUserChallenge = (row, challenge, id) => {
    let data = {
      id: id,
      patient: row.user.id,
      challenge: challenge,
    };

    setAnchorEl(null);
    Swal.fire({
      title: 'Are you sure?',
      text: 'Are you sure to re-assign this challenge!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, re-assign it!',
      cancelButtonText: 'No, cancel',
    }).then(result => {
      if (result.isConfirmed) {
        reAssignUserChallenge(data);
      }
    });
  };

  const filteredChallenges = row?.all_challenges?.filter((challenge, index) => {
    const startDate = moment.utc(challenge?.challenge?.start);
    const startDayOfMonth = startDate.date();

    const isWithinSelectedWeek =
      selectedWeek === 'All' ||
      (selectedWeek !== 5 &&
        startDayOfMonth >= (selectedWeek - 1) * 7 + 1 &&
        startDayOfMonth <= selectedWeek * 7);

    let isInLastWeek = false;
    if (selectedWeek === 5) {
      const daysInMonth = moment(startDate).daysInMonth();
      isInLastWeek = startDayOfMonth + 7 >= daysInMonth;
    }

    return (
      (isWithinSelectedWeek || isInLastWeek) &&
      (selectedStatus === 'All' ||
        (selectedStatus === 'Completed' && challenge?.progress === 100) ||
        (selectedStatus === 'Active' && challenge?.progress > 0) ||
        (selectedStatus === 'Archived' && challenge?.progress === 0))
    );
  });

  return (
    <React.Fragment>
      <StyledTableRow
        key={row?.id}
        sx={{display: 'flex', flexDirection: 'row', borderBottom: '0'}}>
        <TableCell
          component="th"
          scope="row"
          sx={{minWidth: '550px'}}
          style={TableCellStyle}>
          <img src={row?.user.image || UserIcon} className="avatar" alt="" />
          {row?.user?.name} {row?.user?.last_name}
        </TableCell>
        <TableCell
          sx={{width: '150px'}}
          style={{...TableCellStyle, borderBottom: '0px'}}>
          {row?.all_challenges.length}{' '}
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? (
              <ArrowDropUpIcon sx={{color: '#274F66ff'}} />
            ) : (
              <ArrowDropDownIcon
                sx={{color: '#274F66ff', transform: 'rotate(270deg)'}}
              />
            )}
          </IconButton>
        </TableCell>
        <TableCell
          sx={{width: '150px'}}
          style={{...styles.tableCell, borderBottom: '0px'}}>
          {row?.all_challenges.filter(item => item?.progress !== 0).length}/
          {row?.all_challenges.length}
        </TableCell>
        <TableCell
          style={{...styles.tableCell, padding: '6px', borderBottom: '0px', width: '130px'}}>
          {row?.all_status}{' '}
        </TableCell>
        <TableCell style={{...styles.tableCell, padding: '6px', borderBottom: '0px'}}>
          <Link to={'/challenge/create/' + row?.user?.id}>
            <AddCircleOutlineIcon
              sx={{color: 'grey', cursor: 'pointer'}}
            />
          </Link>
        </TableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{padding: 0}} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                backgroundColor: '#F6F6F6ff',
                p: 1,
                boxShadow:
                  'inset 0px 10px 20px -10px #CCC,  inset 0px -5px 10px 0px #CCC ',
              }}>
              <div className="challenge-section-main">
                <div className="sub-table-head">
                  <label
                    htmlFor="showChallenges"
                    style={{whiteSpace: 'nowrap'}}>
                    Show Challenges
                  </label>
                  <div className="select-options">
                    {challengeButtonLabel.map(label => {
                      return (
                        <Button
                          sx={{
                            height: '30px',
                            minWidth: '65px',
                            textAlign: 'center',
                            borderRadius: '50px',
                            color: label === selectedStatus ? 'white' : 'grey',
                            backgroundColor:
                              label === selectedStatus ? '#274F66ff' : 'white',
                            textTransform: 'none',
                            fontSize: '13px',
                            outline: 'none',
                            padding: '5px 15px',
                            '&:hover': {
                              backgroundColor: '#156180',
                              color: 'white',
                            },
                          }}
                          key={label}
                          onClick={() => handleChallengeButtonClick(label)}>
                          {label}
                        </Button>
                      );
                    })}
                  </div>
                  <div className="challenge-section">
                    <label htmlFor="search" className="label-style">
                      Time
                    </label>
                    <Box sx={{width: '200px'}}>
                      <UISelect value={selectedWeek} onChange={e => setSelectedWeek(e.target.value)}>
                        <MenuItem value={"All"}>All</MenuItem>
                        <MenuItem value={1}>Week 1</MenuItem>
                        <MenuItem value={2}>Week 2</MenuItem>
                        <MenuItem value={3}>Week 3</MenuItem>
                        <MenuItem value={4}>Week 4</MenuItem>
                        <MenuItem value={5}>Remaining</MenuItem>
                      </UISelect>
                    </Box>
                  </div>
                </div>
                <div></div>
              </div>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>CHALLENGE</StyledTableCell>
                    <StyledTableCell>START DATE</StyledTableCell>
                    <StyledTableCell>END DATE</StyledTableCell>
                    <StyledTableCell>LAST ACTIVITY</StyledTableCell>
                    <StyledTableCell>COMPLETENESS</StyledTableCell>
                    <StyledTableCell>STATUS</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredChallenges.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        No data found
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredChallenges.map((historyRow, index) => (
                      <React.Fragment key={index}>
                        <StyledTableRow>
                          <StyledTableCell>
                            <div
                              style={{
                                display: 'flex',
                                gap: '5%',
                                alignItems: 'center',
                              }}>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => handleClick(index)}>
                                {openRows.includes(index) ? (
                                  <ArrowDropUpIcon sx={{color: '#274F66ff'}} />
                                ) : (
                                  <ArrowDropDownIcon
                                    sx={{
                                      transform: 'rotate(270deg)',
                                      color: '#274F66ff',
                                    }}
                                  />
                                )}
                              </IconButton>{' '}
                              <div
                                className="challenge-avatar"
                                style={{
                                  backgroundColor: getCategoryColor(
                                    historyRow?.challenge?.challenge_goal
                                      ?.category,
                                  ),
                                }}></div>
                              {historyRow?.challenge?.challenge_goal?.goal}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell>
                            {historyRow?.challenge?.start
                              ? moment(historyRow?.challenge?.start).format(
                                  'YYYY-MM-DD',
                                )
                              : '-'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {historyRow?.challenge?.end
                              ? moment(historyRow?.challenge?.end).format(
                                  'YYYY-MM-DD',
                                )
                              : '-'}
                          </StyledTableCell>
                          <StyledTableCell>
                            {historyRow?.last_activity
                              ? moment(historyRow?.last_activity).format(
                                  'YYYY-MM-DD',
                                )
                              : '-'}
                          </StyledTableCell>
                          <StyledTableCell>
                            <LinearWithValueLabel
                              value={historyRow?.progress}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              color:
                                historyRow?.progress === 100
                                  ? 'green !important'
                                  : historyRow?.progress === 0
                                  ? 'red !important'
                                  : '#274F66ff !important',
                            }}>
                            {historyRow?.progress === 100
                              ? 'Completed'
                              : historyRow?.progress !== 0
                              ? 'In Progress'
                              : 'Pending'}
                          </StyledTableCell>
                          <StyledTableCell sx={{whiteSpace: 'nowrap'}}>
                            {historyRow.status === 'Completed' ||
                            historyRow.status === 'Abandon' ? (
                              <ReplayIcon sx={{color: 'grey'}} />
                            ) : (
                              <>
                                {' '}
                                <DeleteOutlineOutlinedIcon
                                  onClick={() =>
                                    handleDeleteUserChallenge(historyRow?.id)
                                  }
                                  sx={{color: 'grey', cursor: 'pointer'}}
                                />{' '}
                                <ReplayIcon
                                  onClick={() =>
                                    handleReassignUserChallenge(
                                      row,
                                      historyRow?.challenge?.id,
                                      historyRow?.id,
                                    )
                                  }
                                  sx={{color: 'grey', cursor: 'pointer'}}
                                />{' '}
                              </>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                        <TableRow>
                          <StyledTableCell
                            style={{paddingBottom: 0, paddingTop: 0}}
                            colSpan={7}>
                            <Collapse
                              in={openRows.includes(index)}
                              timeout="auto"
                              unmountOnExit>
                              <SubTableRow
                                userChallenge={row?.id}
                                user={row?.user?.id}
                                userProfile={row?.user}
                                completeChallenge={historyRow?.challenge}
                                challenge={historyRow?.challenge?.id}
                              />
                            </Collapse>
                          </StyledTableCell>
                        </TableRow>
                      </React.Fragment>
                    ))
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default Row;

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'white',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#D1D8DDff',
    backgroundColor: '#F6F6F6ff',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: '500',
    padding: '12px 20px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    backgroundColor: '#F6F6F6ff',
    color: '#274F66ff',
    fontWeight: 500,
  },
}));

const TableCellStyle = {
  padding: '12px 20px',
  display: 'flex',
  color: '#274F66ff',
  alignItems: 'center',
  gap: '5%',
  borderBottom: '0',
};
const styles = {
    tableCell: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}