import moment from 'moment';
import React from 'react';
import Calendar from 'react-calendar';
import ActivityIndicator from '../components/ActivityIndicator';
import ImagePanel from '../components/ImagePanel';
import Panel from '../components/Panel';
import { store } from '../store';
import { HttpRequest } from '../utils/http';
import { Link } from 'react-router-dom';
import ProfilePic from '../assets/logo192.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import ProviderAnalyticsModal from '@components/ProviderAnalyticsModal';

const SCHEDULE_CELL_WIDTH = 70;

class Analytic extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDate: new Date(),
      am: [
        { time: '09', label: '9' },
        { time: '10', label: '10' },
        { time: '11', label: '11' },
        { time: '12', label: '12' },
      ],
      pm: [
        { time: '13', label: '1' },
        { time: '14', label: '2' },
        { time: '15', label: '3' },
        { time: '16', label: '4' },
      ],

      isLoading1: false,
      isLoading4: false,

      analytics: {},
      start_time: moment().subtract(7, 'days'),
      end_time: moment(),
      unassignedPatients: [],
      itemsPerPage: 2,
      currentPage: 1,
      isModalOpen: false,
      selectedProviders: [],
      modalLabel: '',
    };
  }

  componentDidMount() {
    let user = store.getState().loginReducer.user;
    if (user) {
      this.loadUnassignedPatient();
      this.loadAnalytics();
    }
  }

  convertToMinute(time) {
    let minute = moment('2021-01-01 ' + time).get('minute');
    let hour = moment('2021-01-01 ' + time).get('hour');
    return hour * 60 + minute;
  }

  loadUnassignedPatient() {
    this.setState({ isLoading4: true });
    let user = store.getState().loginReducer.user;
    HttpRequest.unassignedPatient(user.user.id)
      .then((res) => {
        this.setState({ unassignedPatients: res.data });
        this.setState({ isLoading4: false });
      })
      .catch((err) => {
        this.setState({ isLoading4: true });
        Swal.fire('Error', 'Cannot load unassigned data.', 'error');
      });
  }

  loadAnalytics() {
    this.setState({ isLoading1: true });
    let user = store.getState().loginReducer.user;
    HttpRequest.getAnalytics()
      .then((res) => {
        this.setState({ analytics: res.data });
        this.setState({ isLoading1: false });
      })
      .catch((err) => {
        this.setState({ isLoading1: true });
        Swal.fire('Error', 'Cannot load analytics data.', 'error');
      });
  }

  openModal = (providers, label) => {
    this.setState({
      isModalOpen: true,
      selectedProviders: providers,
      modalLabel: label,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
      selectedProviders: [],
      modalLabel: '',
    });
  };

  exportData() {
    const { analytics } = this.state;

    const csvData = [
      ['Active Providers', analytics?.provider_active?.length],
      ['Inactive Providers', analytics?.provider_inactive?.length],
      ['Active in Last Seven Days', analytics?.active_last_7_days?.length],
      ['Active Last 8 to 30 Days', analytics?.active_8_30_days?.length],
      ['Active Last 31 to 180 Days', analytics?.active_31_180_days?.length],
      ['Active Last 181 to 365 Days', analytics?.active_181_365_days?.length],
      ['Active Over a Year Ago', analytics?.active_over_1_year?.length],
      ['Never Active', analytics?.never_active?.length],
      [
        'Logged in but not active last 7 days',
        analytics?.logged_in_not_active_last_7_days?.length,
      ],
    ];

    const csvContent = csvData.map((row) => row.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'analytics_data.csv';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }

  handlePageChange(newPage) {
    const { unassignedPatients, itemsPerPage } = this.state;

    if (
      newPage > 0 &&
      newPage <= Math.ceil(unassignedPatients.length / itemsPerPage)
    ) {
      this.setState({ currentPage: newPage });
    }
  }

  render() {
    let user = store.getState().loginReducer.user;
    const {
      currentPage,
      itemsPerPage,
      analytics,
      isLoading1,
      modalLabel,
    } = this.state;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return (
      <>
        <div className="row">
          <div className="col-md-8">
            {isLoading1 && <ActivityIndicator />}
            {!isLoading1 && (
              <>
                <div className="row">
                  <div
                    className="col-md-4"
                    style={styles.providerPanel}
                    onClick={() =>
                      this.openModal(
                        analytics?.provider_active,
                        'Active Providers'
                      )
                    }
                  >
                    <Panel>
                      <div style={styles.title}>Active Providers</div>
                      <div style={styles.minute}>
                        {analytics?.provider_active?.length}
                      </div>
                    </Panel>
                  </div>
                  <div
                    className="col-md-4"
                    style={styles.providerPanel}
                    onClick={() =>
                      this.openModal(
                        analytics?.provider_inactive,
                        'Inactive Providers'
                      )
                    }
                  >
                    <Panel>
                      <div style={styles.title}>Inactive Providers</div>
                      <div style={styles.minute}>
                        {analytics?.provider_inactive?.length}
                      </div>
                    </Panel>
                  </div>
                  <div
                    className="col-md-4"
                    style={styles.providerPanel}
                    onClick={() =>
                      this.openModal(
                        analytics?.active_last_7_days,
                        'Active in Last Seven Days'
                      )
                    }
                  >
                    <Panel>
                      <div style={styles.title}>
                        Active in Last Seven Days
                      </div>
                      <div style={styles.minute}>
                        {analytics?.active_last_7_days?.length}
                      </div>
                    </Panel>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-4"
                    style={styles.providerPanel}
                    onClick={() =>
                      this.openModal(
                        analytics?.active_8_30_days,
                        'Active Last 8 to 30 Days'
                      )
                    }
                  >
                    <Panel>
                      <div style={styles.title}>
                        Active Last 8 to 30 Days
                      </div>
                      <div style={styles.minute}>
                        {analytics?.active_8_30_days?.length}
                      </div>
                    </Panel>
                  </div>
                  <div
                    className="col-md-4"
                    style={styles.providerPanel}
                    onClick={() =>
                      this.openModal(
                        analytics?.active_31_180_days,
                        'Active Last 31 to 180 Days'
                      )
                    }
                  >
                    <Panel>
                      <div style={styles.title}>
                        Active Last 31 to 180 Days
                      </div>
                      <div style={styles.minute}>
                        {analytics?.active_31_180_days?.length}
                      </div>
                    </Panel>
                  </div>
                  <div
                    className="col-md-4"
                    style={styles.providerPanel}
                    onClick={() =>
                      this.openModal(
                        analytics?.active_181_365_days,
                        'Active Last 181 to 365 Days'
                      )
                    }
                  >
                    <Panel>
                      <div style={styles.title}>
                        Active Last 181 to 365 Days
                      </div>
                      <div style={styles.minute}>
                        {analytics?.active_181_365_days?.length}
                      </div>
                    </Panel>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-4"
                    style={styles.providerPanel}
                    onClick={() =>
                      this.openModal(
                        analytics?.active_over_1_year,
                        'Active Over a Year Ago'
                      )
                    }
                  >
                    <Panel>
                      <div style={styles.title}>Active Over a Year Ago</div>
                      <div style={styles.minute}>
                        {analytics?.active_over_1_year?.length}
                      </div>
                    </Panel>
                  </div>
                  <div
                    className="col-md-4"
                    style={styles.providerPanel}
                    onClick={() =>
                      this.openModal(
                        analytics?.never_active,
                        'Never Active'
                      )
                    }
                  >
                    <Panel>
                      <div style={styles.title}>Never Active</div>
                      <div style={styles.minute}>
                        {analytics?.never_active?.length}
                      </div>
                    </Panel>
                  </div>
                  <div
                    className="col-md-4"
                    style={styles.providerPanel}
                    onClick={() =>
                      this.openModal(
                        analytics?.logged_in_not_active_last_7_days,
                        'Logged in but not active last 7 days'
                      )
                    }
                  >
                    <Panel>
                      <div style={styles.title}>
                        Logged in but not active last 7 days
                      </div>
                      <div style={styles.minute}>
                        {analytics?.logged_in_not_active_last_7_days?.length}
                      </div>
                    </Panel>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="col-md-4">
            <Panel>
              <div className="section-title">Calendar</div>

              <Calendar
                calendarType="US"
                onChange={selectedDate => {
                  this.setState({selectedDate});
                }}
                value={this.state.selectedDate}
              />

              <div style={styles.scrollHorizontal}>
                <div
                  style={{
                    width: 8 * SCHEDULE_CELL_WIDTH,
                    ...styles.scheduleWrapper,
                  }}>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div
                      style={{
                        background: '#F4F7F9',
                        width: 4 * SCHEDULE_CELL_WIDTH,
                        ...styles.scheduleHeader,
                      }}>
                      AM
                    </div>
                    <div
                      style={{
                        background: '#DDE9F3',
                        width: 4 * SCHEDULE_CELL_WIDTH,
                        ...styles.scheduleHeader,
                      }}>
                      PM
                    </div>
                  </div>
                </div>
              </div>
            </Panel>

            {user.user.role === 1 && (
              <>
                <div className="section-title">Unassigned children</div>
                {this.state.isLoading4 && <ActivityIndicator />}
                {!this.state.isLoading4 && (
                  <>
                    {this.state.unassignedPatients
                      .slice(startIndex, endIndex)
                      .map((patient, key) => {
                        return (
                          <Link to={'/patient/detail/' + patient.id} key={key}>
                            <ImagePanel
                              imgSource={patient.user.image || ProfilePic}
                              title={
                                patient.user.name + ' ' + patient.user.last_name
                              }
                              description="New Patient"
                            />
                          </Link>
                        );
                      })}
                    <div className="d-flex justify-content-end">
                      <FontAwesomeIcon
                        icon={faCaretLeft}
                        style={{
                          ...styles.pagination,
                          color:
                            this.state.currentPage === 1
                              ? '#ACB9C2'
                              : '#274F66',
                        }}
                        onClick={() =>
                          this.handlePageChange(this.state.currentPage - 1)
                        }
                        disabled={this.state.currentPage === 1}
                      />
                      Page {this.state.currentPage} of{' '}
                      {Math.ceil(
                        this.state.unassignedPatients.length /
                          this.state.itemsPerPage,
                      )}
                      <FontAwesomeIcon
                        icon={faCaretRight}
                        style={{
                          ...styles.pagination,
                          color:
                            endIndex >= this.state.unassignedPatients.length
                              ? '#ACB9C2'
                              : '#274F66',
                        }}
                        onClick={() =>
                          this.handlePageChange(this.state.currentPage + 1)
                        }
                        disabled={
                          endIndex >= this.state.unassignedPatients.length
                        }
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <ProviderAnalyticsModal
          isOpen={this.state.isModalOpen}
          closeModal={this.closeModal}
          providers={this.state.selectedProviders}
          label={this.state.modalLabel}
        />
      </>
    );
  }
}

const styles = {
  title: {
    fontSize: 16,
    color: '#a1b0ba',
  },
  minute: {
    fontSize: 30,
  },

  scrollHorizontal: {
    overflowX: 'scroll',
  },
  scheduleWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  cell: {
    width: SCHEDULE_CELL_WIDTH,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: 8,
  },
  scheduleHeader: {
    paddingLeft: 16,
    paddingTop: 8,
    paddingBottom: 8,
  },
  pagination: {
    height: 24,
    width: 28,
    cursor: 'pointer',
    fontSize: '26px',
  },
  exportButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  providerPanel: {
    cursor: 'pointer',
    borderRadius: '8px',
    transition: 'border 0.3s ease', 
    '&:hover': {
      border: '1px solid #23465a',
    },
  },
  
};

export default Analytic;