import * as React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props?.value
        )}%`}</Typography>
      </Box>
      <Box sx={{ width: "100%", ml: 2 }}>
        <LinearProgress
          variant="determinate"
          sx={{
            backgroundColor: "#E5EFF7ff",
            "& .MuiLinearProgress-bar": {
              backgroundColor:
              props?.value === 100 ? "#6a994e" : "#274F66ff",
              borderRadius: "50px",
            },
            height: "6px",
            borderRadius: "50px",
          }}
          {...props}
        />
      </Box>
    </Box>
  );
}
