import React, { Component } from 'react';
import ToolbarInput from './ToolbarInput';
import NoData from './NoData';
import ActivityIndicator from './ActivityIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';

const styles = {
  topWrapper: {
    marginBottom: 16,
    display: 'flex',
    flexDirection: 'row',
  },
  topColumn: {
    marginLeft: 16,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  image: {
    borderRadius: 20,
    width: 35,
    height: 35,
    objectFit: 'cover',
    marginRight: 10,
  },
};
export default class DataGrid extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        sortColumn: null,
        sortOrder: 'asc',
      };
    }
  
    handleSort = (columnName) => {
        const { sortColumn, sortOrder } = this.state;
      
        if (sortColumn === columnName) {
          this.setState({ sortOrder: sortOrder === 'asc' ? 'desc' : 'asc' });
        } else {
          this.setState({ sortColumn: columnName, sortOrder: 'asc' });
        }
      };
      
  
    render() {
      const { filters, columns, data, isLoading } = this.props;
      const { sortColumn, sortOrder } = this.state;
  
      const sortedData = data ? [...data] : [];
  
      
      if (sortColumn === "user") {
        sortedData.sort((a, b) => {
            const valA = a[sortColumn]?.name?.toUpperCase().charAt(0); 
            const valB = b[sortColumn]?.name?.toUpperCase().charAt(0); 
            
            if (valA < valB) {
              return sortOrder === 'asc' ? -1 : 1;
            }
            if (valA > valB) {
              return sortOrder === 'asc' ? 1 : -1;
            }
            return 0;
          });
      } else  if (sortColumn === "last_activity" || sortColumn === "last_contact") {
        sortedData.sort((a, b) => {
          const valA = new Date(a[sortColumn]);
          const valB = new Date(b[sortColumn]);
      
          if (valA < valB) {
            return sortOrder === 'asc' ? -1 : 1;
          }
          if (valA > valB) {
            return sortOrder === 'asc' ? 1 : -1;
          }
          return 0;
        });
      } else if (sortColumn === "providers") {
        sortedData.sort((a, b) => {
            const valA = a[sortColumn][0]?.name?.toUpperCase().charAt(0); 
            const valB = b[sortColumn][0]?.name?.toUpperCase().charAt(0); 
      
          if (valA < valB) {
            return sortOrder === 'asc' ? -1 : 1;
          }
          if (valA > valB) {
            return sortOrder === 'asc' ? 1 : -1;
          }
          return 0;
        });
      } else if (sortColumn === "tags") {
        sortedData.sort((a, b) => {
            const valA = a[sortColumn][0]?.toUpperCase().charAt(0); 
            const valB = b[sortColumn][0]?.toUpperCase().charAt(0); 
      
          if (valA < valB) {
            return sortOrder === 'asc' ? -1 : 1;
          }
          if (valA > valB) {
            return sortOrder === 'asc' ? 1 : -1;
          }
          return 0;
        });
      } else if (sortColumn === "patient_profile") {
        sortedData.sort((a, b) => {
            const valA = a[sortColumn]?.user?.name.toUpperCase().charAt(0); 
            const valB = b[sortColumn]?.user?.name.toUpperCase().charAt(0); 
      
          if (valA < valB) {
            return sortOrder === 'asc' ? -1 : 1;
          }
          if (valA > valB) {
            return sortOrder === 'asc' ? 1 : -1;
          }
          return 0;
        });
      } else {
        sortedData.sort((a, b) => {
            const valA = a[sortColumn]?.toUpperCase().charAt(0); 
            const valB = b[sortColumn]?.toUpperCase().charAt(0); 
      
          if (valA < valB) {
            return sortOrder === 'asc' ? -1 : 1;
          }
          if (valA > valB) {
            return sortOrder === 'asc' ? 1 : -1;
          }
          return 0;
        });
      }
  
      return (
        <>
          <div className="row">
            {filters.map((filter, key) => (
              <div className={'col-md-3 ' + filter.className} key={key}>
                <ToolbarInput
                  title={filter.label}
                  type={filter.type}
                  data={filter.data}
                  onChange={(val) => {
                    filter.onChange(val);
                  }}
                />
              </div>
            ))}
          </div>
  
          <div style={{ margin: '0 -16px 0 -16px' }}>
            <table style={{ width: '100%' }} className="grid">
              <thead style={{ borderBottom: '1px solid #ccc' }}>
                <tr>
                  {columns.map((column, key) => {
                    if (column.name === 'controls') {
                      return (
                        <th key={key}>
                          {column.label.toUpperCase()}
                        </th>
                      );
                    }
  
                    return (
                      <th
                        key={key}
                        onClick={() => {
                          column.sortable && this.handleSort(column.name);
                        }}
                        style={{ cursor: column.sortable ? 'pointer' : 'default' }}
                      >
                        {column.label.toUpperCase()}
                        {column.sortable && (
                          <span className="ml-1">
                            {/* {sortColumn === column.name && sortOrder === 'asc' && ( */}
                              <FontAwesomeIcon icon={faSort} />
                            {/* // )} */}
                            {/* {sortColumn === column.name && sortOrder === 'desc' && ( */}
                              {/* <FontAwesomeIcon icon={faSort} />
                            )} */}
                          </span>
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan={columns.length}>
                      <ActivityIndicator />
                    </td>
                  </tr>
                ) : (
                  <>
                    {(!data || data.length === 0) && (
                      <tr>
                        <td colSpan={columns.length}>
                          <NoData>No data available</NoData>
                        </td>
                      </tr>
                    )}
                    {sortedData.map((datum, key) => (
                      <tr
                        key={key}
                        className={`${key % 2 === 0 ? '' : 'odd'} ${datum.is_reported ? 'tr-danger' : ''}`}
                      >
                        {columns.map((column, key2) => (
                          <td key={key + '.' + key2}>
                            {column.renderItem ? column.renderItem(datum) : datum[column.name]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </>
      );
    }
  }
