import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import moment from 'moment';
import Calendar from 'react-calendar';
import ActivityIndicator from '../components/ActivityIndicator';
import CircleImage from '../components/CircleImage';
import DataGrid from '../components/DataGrid';
import ImagePanel from '../components/ImagePanel';
import Panel from '../components/Panel';
import PanelTab from '../components/PanelTab';
import {HttpRequest} from '../utils/http';
import Mime from 'mime-types';
import Swal from 'sweetalert2';
import MilestoneBox from '../components/MilestoneBox';
import {Link} from 'react-router-dom';
import ProfilePic from '../assets/logo192.png';
import IconUser from '../assets/icon-user.jpg';
import {
  faCaretLeft,
  faCaretRight,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CoPresentOutlined} from '@mui/icons-material';
import Pubnub from 'pubnub';
import {appConfig} from 'src/config/app';
import StringHelper from '@utils/StringHelper';

const SCHEDULE_CELL_WIDTH = 70;

let _bookingListPerHour = {};
for (let i = 9; i <= 16; i++) {
  _bookingListPerHour[i.toString().padStart(2, '0')] = null;
}

const am = [
  {time: '09', label: '9'},
  {time: '10', label: '10'},
  {time: '11', label: '11'},
  {time: '12', label: '12'},
];
const pm = [
  {time: '13', label: '1'},
  {time: '14', label: '2'},
  {time: '15', label: '3'},
  {time: '16', label: '4'},
];

const switchLabels = ['All', 'Task', 'Todo'];

export default function Home(props) {
  const user = useSelector(state => state.loginReducer.user);
  const patients = useSelector(state => state.profiles);
  const [isLoadingChatRequest, setIsLoadingChatRequest] = useState(false);
  const [messageRequest, setMessageRequest] = useState([]);
  const [messageRequestBadge, setMessageRequestBadge] = useState(0);
  const [isLoadingBooking, setIsLoadingBooking] = useState(false);
  const [bookingList, setBookingList] = useState([]);
  const [bookingListPerDate, setBookingListPerDate] = useState([]);
  const [bookingListPerHour, setBookingListPerHour] =
    useState(_bookingListPerHour);
  const [isLoadingMilestone, setIsLoadingMilestone] = useState(false);
  const [milestones, setMilestones] = useState([]);
  const [isLoadingRoom, setIsLoadingRoom] = useState(false);
  const [isLoadingUnassigned, setIsLoadingUnassigned] = useState(false);
  const [unassignedPatients, setUnassignedPatients] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [tasks, setTasks] = useState([]);
  const [providers, setProviders] = useState([]);
  const [regarding, setRegarding] = useState([]);
  const [chats, setChats] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageChat, setCurrentPageChat] = useState(1);
  const [totalPage, setTotal] = useState(1);
  const [next, setNext] = useState(null);
  const itemsPerPage = 5;
  const [searchCriteria, setSearchCriteria] = useState('Sender Name');
  const [searchText, setSearchText] = useState('');
  const [searchTaskText, setSearchTaskText] = useState('');
  const [selectedStatusFilter, setSelectedStatusFilter] = useState('All');
  const [selectedStatusFilterTask, setSelectedStatusFilterTask] = useState('');
  const [selectedIssuedByFilter, setSelectedIssuedByFilter] = useState('');
  const [selectedRegardingFilter, setSelectedRegardingFilter] = useState('');

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = newPage => {
    if (
      newPage > 0 &&
      newPage <= Math.ceil(unassignedPatients.length / itemsPerPage)
    ) {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    if (user) {
      loadBooking();
      loadTasks();
      loadMilestone();
      //   chatRequests();
      loadProviders();
      loadFcmRoom();
      loadUnassignedPatient();
    }
  }, [user]);

  const loadBooking = useCallback(() => {
    setIsLoadingBooking(true);

    HttpRequest.getBooking(user.user.id)
      .then(res => {
        let bookingListPerDate = {};
        //group by date
        res.data.results.forEach(data => {
          let date = moment(data.start).format('YYYY-MM-DD');
          if (bookingListPerDate[date] == null) {
            bookingListPerDate[date] = [];
          }
          bookingListPerDate[date].push(data);
        });

        // setBookingListPerDate(bookingListPerDate);
        setBookingList(res.data.results);
        // setMessageRequest(res.data.results);
        setIsLoadingBooking(false);
      })
      .catch(err => {
        Swal.fire('Error', 'Cannot load booking data.', 'error');
        setIsLoadingBooking(false);
      });
  }, [user]);

  const pubnub = useMemo(() => {
    if (user) {
      return new Pubnub({
        publishKey: appConfig.pubPublishKey,
        subscribeKey: appConfig.pubSubscribeKey,
        uuid: user.user.id + '',
      });
    }

    return null;
  }, [user, currentPageChat]);

  function loadFcmRoom(page) {
    setIsLoadingRoom(true);
    if (user.user.role === 1) {
      HttpRequest.roomList(null, null, null, page)
        .then(res => {
          let next = res.data.next;
          const nextPageNumber = next ? next.split('page=')[1] : null;
          const count = res.data.count;
          const roundedPage = Math.ceil(count / 20);
          let data = res.data.results.map(roomObj => {
            let roomParts = roomObj.room.split('-');

            roomObj.newRoom = `private.${roomParts[1]}-${roomParts[2]}`;

            const channelName = `private.${roomParts[1]}-${roomParts[2]}`;
            pubnub?.subscribe({
              channels: [channelName],
              withPresence: true,
            });

            pubnub?.addListener({
              message: function (message) {},
              presence: function (presenceEvent) {},
              status: function (status) {
                if (status.category === 'PNConnectedCategory') {
                  pubnub.history(
                    {
                      channel: channelName,
                      count: 1,
                    },
                    function (status, response) {
                      if (status.error) {
                        console.error(status.errorData);
                        return;
                      }
                      if (response?.messages[0]?.entry?.text) {
                        roomObj.lastMessage =
                          response?.messages[0]?.entry?.text;
                      } else if (response?.messages[0]?.entry?.image) {
                        roomObj.lastMessage =
                          response?.messages[0]?.entry?.image;
                      } else if (response?.messages[0]?.entry?.audio) {
                        roomObj.lastMessage =
                          response?.messages[0]?.entry?.audio;
                      } else if (response?.messages[0]?.entry?.video) {
                        roomObj.lastMessage =
                          response?.messages[0]?.entry?.video;
                      }
                    },
                  );
                }
              },
            });

            return roomObj;
          });
          setChats(data);
          setNext(nextPageNumber);
          setTotal(roundedPage);
          setTimeout(() => {
            setIsLoadingRoom(false);
          }, 1000);
        })
        .catch(err => {
          Swal.fire('Error', 'Cannot load room data.', 'error');
          setIsLoadingRoom(false);
        });
    } else {
      HttpRequest.roomList(user.user.id, null, null, page)
        .then(res => {
          let next = res.data.next;
          const nextPageNumber = next ? next.split('page=')[1] : null;
          const count = res.data.count;
          const roundedPage = Math.ceil(count / 20);
          let data = res.data.results.map(roomObj => {
            let roomParts = roomObj.room.split('-');

            roomObj.newRoom = `private.${roomParts[1]}-${roomParts[2]}`;

            const channelName = `private.${roomParts[1]}-${roomParts[2]}`;
            pubnub?.subscribe({
              channels: [channelName],
              withPresence: true,
            });

            pubnub?.addListener({
              message: function (message) {},
              presence: function (presenceEvent) {},
              status: function (status) {
                if (status.category === 'PNConnectedCategory') {
                  pubnub.history(
                    {
                      channel: channelName,
                      count: 1,
                    },
                    function (status, response) {
                      if (status.error) {
                        console.error(status.errorData);
                        return;
                      }
                      if (response?.messages[0]?.entry?.text) {
                        roomObj.lastMessage =
                          response?.messages[0]?.entry?.text;
                      } else if (response?.messages[0]?.entry?.image) {
                        roomObj.lastMessage =
                          response?.messages[0]?.entry?.image;
                      } else if (response?.messages[0]?.entry?.audio) {
                        roomObj.lastMessage =
                          response?.messages[0]?.entry?.audio;
                      } else if (response?.messages[0]?.entry?.video) {
                        roomObj.lastMessage =
                          response?.messages[0]?.entry?.video;
                      }
                    },
                  );
                }
              },
            });

            return roomObj;
          });
          setChats(data);
          setNext(nextPageNumber);
          setTotal(roundedPage);
          setTimeout(() => {
            setIsLoadingRoom(false);
          }, 1000);
        })
        .catch(err => {
          Swal.fire('Error', 'Cannot load room data.', 'error');
          setIsLoadingRoom(false);
        });
    }
  }

  const loadTasks = useCallback(() => {
    setIsLoadingBooking(true);

    HttpRequest.taskList()
      .then(res => {
        let data = res?.data?.results;

        if (user.user.role === 1) {
          // If the user has role 1, set all tasks
          setTasks(data);
        } else {
          // If the user has a different role, filter tasks
          const filteredTasks = data.filter(
            task => task?.assigned?.id === user?.user?.id,
          );
          setTasks(filteredTasks);
        }

        setIsLoadingBooking(false);
      })
      .catch(err => {
        Swal.fire('Error', 'Cannot load Tasks data.', 'error');
        setIsLoadingBooking(false);
      });
  }, [user]);

  const loadProviders = useCallback(() => {
    setIsLoadingBooking(true);

    HttpRequest.providerList()
      .then(res => {
        let data = res.data.results;
        setProviders(data);
        setIsLoadingBooking(false);
      })
      .catch(err => {
        Swal.fire('Error', 'Cannot load Providers data.', 'error');
        setIsLoadingBooking(false);
      });
  }, [user]);

  const loadMilestone = useCallback(() => {
    setIsLoadingMilestone(true);

    HttpRequest.milestoneList(user.user.id)
      .then(res => {
        let milestones = res.data.results;
        setMilestones(milestones);
        setIsLoadingMilestone(false);
      })
      .catch(err => {
        setIsLoadingMilestone(false);
        Swal.fire('Error', 'Cannot load milestone data.', 'error');
      });
  }, [user]);

  const loadUnassignedPatient = useCallback(() => {
    setIsLoadingUnassigned(true);

    HttpRequest.unassignedPatient(user.user.id)
      .then(res => {
        // let unassignedPatients = res.data;
        setUnassignedPatients(res.data);
        setIsLoadingUnassigned(false);
      })
      .catch(err => {
        setIsLoadingUnassigned(false);
        Swal.fire('Error', 'Cannot load unassigned data.', 'error');
      });
  }, []);

  const selectDateOnCalendar = useCallback(
    selectedDate => {
      let dt = moment(selectedDate);
      let bookingList = bookingListPerDate[dt.format('YYYY-MM-DD')];
      let bookingListPerHour = {};
      for (let i = 9; i <= 16; i++) {
        bookingListPerHour[i.toString().padStart(2, '0')] = null;
      }

      if (bookingList == null || bookingList.length == 0) {
      } else {
        bookingList.forEach(booking => {
          let date = moment(booking.start).format('HH');
          let dateInt = parseInt(date);
          if (dateInt < 9) {
            date = '09';
          }
          if (dateInt > 16) {
            date = '16';
          }
          bookingListPerHour[date] = booking;
        });
      }

      setBookingListPerHour(bookingListPerHour);
    },
    [bookingListPerDate],
  );
  const loadPage = (page, loadFcmRoom) => {
    setCurrentPageChat(page);
    loadFcmRoom(page);
  };

  const loadNextPage = () => {
    loadPage(currentPageChat + 1, loadFcmRoom);
  };

  const loadPrevPage = () => {
    if (currentPageChat > 1) {
      loadPage(currentPageChat - 1, loadFcmRoom);
    }
  };

  const filterChats = () => {
    const searchTextLower = searchText?.toLowerCase();
    return chats?.filter(chat => {
      const chatText = chat.lastMessage ? chat.lastMessage?.toLowerCase() : '';

      if (selectedStatusFilter === 'is_accepted' && !chat.is_accepted) {
        return false;
      } else if (selectedStatusFilter === 'is_reported' && !chat.is_reported) {
        return false;
      }

      if (searchCriteria === 'Sender Name' && chat.sender) {
        return chat.sender.name?.toLowerCase().includes(searchTextLower);
      } else if (
        searchCriteria === 'Receiver Name' &&
        chat.receiver &&
        chat.receiver[0]
      ) {
        return chat.receiver[0].name?.toLowerCase().includes(searchTextLower);
      } else if (searchCriteria === 'Last Message') {
        return chatText.includes(searchTextLower);
      }

      return true;
    });
  };
  const filteredTasks = tasks.filter(task => {
    // Apply the Status filter
    if (
      selectedStatusFilterTask &&
      selectedStatusFilterTask !== 'all' &&
      task.status !== selectedStatusFilterTask
    ) {
      return false;
    }

    // Apply the Issued By filter
    if (
      selectedIssuedByFilter &&
      selectedIssuedByFilter !== 'all' && // Check for "All"
      task.issued_by.username !== selectedIssuedByFilter
    ) {
      return false;
    }

    // Apply the Regarding filter
    if (
      selectedRegardingFilter &&
      selectedRegardingFilter !== 'all' && // Check for "All"
      task.assigned.username !== selectedRegardingFilter
    ) {
      return false;
    }

    // Apply the search filter
    if (
      searchTaskText &&
      !task.title?.toLowerCase().includes(searchTaskText?.toLowerCase())
    ) {
      return false;
    }

    return true; // Task meets all filter criteria
  });

  return (
    <div className="row">
      <div className="col-md-8">
        {isLoadingMilestone && <ActivityIndicator />}
        {!isLoadingMilestone && (
          <div className="row">
            {milestones.map((milestone, key) => {
              return (
                <div className="col-2" key={key}>
                  <MilestoneBox milestone={milestone} />
                </div>
              );
            })}
          </div>
        )}
        {isLoadingChatRequest && <ActivityIndicator />}
        {!isLoadingChatRequest && (
          <>
            <div className="section-title">
              Messages{' '}
              {messageRequestBadge != 0 ? '(' + messageRequestBadge + ')' : ''}
              {/* <span className='text-danger'>(2 Urgent)</span> */}
            </div>
            <div className="row">
              {messageRequest.map((item, key) => {
                if (key < 3) {
                  //   id: item.id,
                  //   room: item.room,
                  //   sender,
                  //   receiver,
                  //   last_message: item.last_message,
                  //   modified: moment().format("YYYY-MM-DD HH:mm:ss")

                  return (
                    <div className="col-4" key={key}>
                      <ImagePanel
                        imgSource={null}
                        title={item.sender.name}
                        description={item.last_message}
                        highlight={true}
                      />
                    </div>
                  );
                }
              })}
            </div>

            <PanelTab
              tabs={['Chat requests#' + messageRequestBadge, 'Tasks']}
              selectedIndex={selectedIndex}
              onChange={selectedIndex => {
                setSelectedIndex(selectedIndex);
              }}
            />

            {selectedIndex == 0 && (
              <Panel>
                {isLoadingRoom && <ActivityIndicator />}
                {!isLoadingRoom && (
                  <DataGrid
                    data={filterChats()}
                    filters={[
                      {
                        type: 'textinput',
                        label: 'Search contacts',
                        icon: faSearch,
                        onChange: text => {
                          setSearchText(text);
                        },
                        className: 'col-md-5 col-sm-12',
                        style: {flex: 1},
                      },
                      {
                        type: 'select',
                        label: 'Search by',
                        data: [
                          {id: 'Sender Name', label: 'Sender Name'},
                          {id: 'Receiver Name', label: 'Receiver Name'},
                          {id: 'Last Message', label: 'Last Message'},
                        ],
                        onChange: selectedCriteria => {
                          setSearchCriteria(selectedCriteria);
                        },
                        className: 'col-md-4 col-sm-4',
                        style: {width: 400},
                      },
                      {
                        type: 'select',
                        label: 'Status',
                        data: [
                          {id: 'All', label: 'All'},
                          {id: 'is_accepted', label: 'Accepted'},
                          {id: 'is_reported', label: 'Reported'},
                        ],
                        onChange: text => {
                          setSelectedStatusFilter(text);
                        },
                        className: 'col-md-3 col-sm-4',
                        style: {width: 200},
                      },
                    ]}
                    columns={[
                      {
                        label: 'Message',
                        renderItem: item => {
                          let lastMessage = '(No Message)';
                          if (item?.lastMessage) {
                            lastMessage = item?.lastMessage;
                            if (StringHelper.isValidURL(lastMessage)) {
                              let mimeFile =
                                Mime.lookup(
                                  StringHelper.pathBaseName(lastMessage),
                                ) || 'image/jpeg';
                              let start = mimeFile.substr(0, 5);
                              if (start == 'image') {
                                lastMessage = (
                                  <img
                                    src={item?.lastMessage}
                                    alt="last_message"
                                    width="40px"
                                    height="40px"
                                  />
                                );
                              } else if (start == 'video') {
                                lastMessage = ':movie_camera: (Video)';
                              } else if (start == 'audio') {
                                lastMessage = ':musical_note: (Audio)';
                              }
                            }
                          }
                          return lastMessage;
                        },
                      },
                      {
                        label: 'Sent By',
                        renderItem: item => {
                          return (
                            <>
                              <CircleImage
                                src={item.sender?.image || ProfilePic}
                                size={30}
                              />
                              <span style={{marginLeft: 10}}>
                                {item.sender.name}
                              </span>
                            </>
                          );
                        },
                      },
                      {
                        label: 'Answered By',
                        renderItem: item => {
                          return (
                            <>
                              <CircleImage
                                src={item.receiver[0]?.image || ProfilePic}
                                size={30}
                              />
                              <span style={{marginLeft: 10}}>
                                {item.receiver[0]?.name}
                              </span>
                            </>
                          );
                        },
                      },
                      {
                        label: 'Date',
                        renderItem: item => {
                          return moment(item.modified).fromNow();
                        },
                      },
                    ]}
                  />
                )}
                <div className="d-flex justify-content-end align-items-center gap-8 mt-2">
                  <FontAwesomeIcon
                    onClick={currentPageChat === 1 ? null : loadPrevPage}
                    icon={faCaretLeft}
                    style={{
                      ...styles.pagination,
                      color: currentPageChat === 1 ? '#ACB9C2' : '#274F66',
                    }}
                    disabled={currentPageChat === 1}
                  />
                  Page {currentPageChat} of {totalPage}
                  <FontAwesomeIcon
                    onClick={!next ? null : loadNextPage}
                    icon={faCaretRight}
                    style={{
                      ...styles.pagination,
                      color: !next ? '#ACB9C2' : '#274F66',
                    }}
                    disabled={!next}
                  />
                </div>
              </Panel>
            )}
            {selectedIndex == 1 && (
              <Panel>
                <DataGrid
                  filters={[
                    {
                      type: 'textinput',
                      label: 'Search Task',
                      icon: faSearch,
                      onChange: text => {
                        setSearchTaskText(text);
                      },
                      className: 'col-md-5 col-sm-12',
                      style: {flex: 1},
                    },
                    {
                      type: 'select',
                      label: 'Status',
                      data: [
                        {id: 'all', label: 'All'},
                        {id: 'open', label: 'Open'},
                        {id: 'closed', label: 'Closed'},
                      ],
                      onChange: text => {
                        setSelectedStatusFilterTask(text);
                      },
                      className: 'col-sm-6 col-md-3',
                    },
                    {
                      type: 'select',
                      label: 'Issued By',
                      data: [
                        {id: 'all', label: 'All'}, // Add "All" option
                        ...providers?.map(data => ({
                          id: data.user.username,
                          label: data.user.username,
                        })),
                      ],
                      onChange: text => {
                        setSelectedIssuedByFilter(text);
                      },
                      className: 'col-sm-6 col-md-3',
                    },
                    {
                      type: 'select',
                      label: 'Regarding',
                      data: [
                        {id: 'all', label: 'All'}, // Add "All" option
                        ...filteredTasks.map(data => ({
                          id: data.assigned.username,
                          label: data.assigned.username,
                        })),
                      ],
                      onChange: text => {
                        setSelectedRegardingFilter(text);
                      },
                      className: 'col-sm-6 col-md-3',
                    },
                  ]}
                  columns={[
                    {
                      name: 'name',
                      label: 'Task Name',
                      renderItem: item => {
                        return item.title;
                      },
                    },
                    {
                      name: 'agent',
                      label: 'Issued By',
                      renderItem: item => {
                        return item.issued_by.username;
                      },
                    },
                    {
                      label: 'Regarding',
                      renderItem: item => {
                        return (
                          <>
                            <CircleImage
                              src={item.assigned?.image || IconUser}
                              size={30}
                            />
                            {item.assigned.username}
                          </>
                        );
                      },
                    },
                    {
                      label: 'Date',
                      renderItem: item => {
                        return moment(item.created).format('MMM DD, YYYY');
                      },
                    },
                  ]}
                  data={filteredTasks || []}
                />
              </Panel>
            )}
          </>
        )}
      </div>
      <div className="col-md-4">
        <Panel>
          <div className="section-title">Calendar</div>

          <Calendar
            calendarType="US"
            onChange={selectedDate => {
              setSelectedDate(selectedDate);
              selectDateOnCalendar(selectedDate);
            }}
            value={selectedDate}
            tileClassName={({activeStartDate, date, view}) => {
              if (view === 'month') {
                let dt = moment(date);
                if (bookingListPerDate[dt.format('YYYY-MM-DD')]) {
                  return 'selected-day';
                }
              }
              return null;
            }}
          />

          <div style={styles.scrollHorizontal}>
            <div
              style={{
                width: 8 * SCHEDULE_CELL_WIDTH,
                ...styles.scheduleWrapper,
              }}>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <div
                  style={{
                    background: '#F4F7F9',
                    width: 4 * SCHEDULE_CELL_WIDTH,
                    ...styles.scheduleHeader,
                  }}>
                  AM
                </div>
                <div
                  style={{
                    background: '#DDE9F3',
                    width: 4 * SCHEDULE_CELL_WIDTH,
                    ...styles.scheduleHeader,
                  }}>
                  PM
                </div>
              </div>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                {Object.keys(bookingListPerHour)
                  .sort()
                  .map((hour, key) => {
                    let booking = bookingListPerHour[hour];
                    return (
                      <div style={styles.cell} key={key}>
                        {hour}
                        {booking && <CircleImage src={ProfilePic} size={40} />}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </Panel>
        {user.user.role === 1 && (
          <>
            <div className="section-title">Unassigned children</div>
            {isLoadingUnassigned && <ActivityIndicator />}
            {unassignedPatients
              .slice(startIndex, endIndex)
              .map((patient, key) => {
                return (
                  <Link to={'/patient/detail/' + patient.id} key={key}>
                    <ImagePanel
                      imgSource={patient.user?.image || ProfilePic}
                      title={patient.user.name + ' ' + patient.user.last_name}
                      description="New Patient"
                    />
                  </Link>
                );
              })}
            <div className="d-flex justify-content-end">
              <FontAwesomeIcon
                icon={faCaretLeft}
                style={{
                  ...styles.pagination,
                  color: currentPage === 1 ? '#ACB9C2' : '#274F66',
                }}
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              Page {currentPage} of{' '}
              {Math.ceil(unassignedPatients.length / itemsPerPage)}
              <FontAwesomeIcon
                icon={faCaretRight}
                style={{
                  ...styles.pagination,
                  color:
                    endIndex >= unassignedPatients.length
                      ? '#ACB9C2'
                      : '#274F66',
                }}
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={endIndex >= unassignedPatients.length}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const styles = {
  milestone: {
    color: '#D6D6D6',
    height: 130,
    position: 'relative',
  },
  bgMilestone: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    opacity: 0.3,
    top: 0,
    left: 0,
    backgroundSize: 'cover',
    borderRadius: 10,
  },
  type: {
    color: 'var(--color-text)',
    height: 25,
  },
  name: {
    color: 'var(--color-text)',
    fontSize: 16,
    fontWeight: '600',
    lineHeight: '19px',
  },

  scrollHorizontal: {
    overflowX: 'scroll',
  },
  scheduleWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  cell: {
    width: SCHEDULE_CELL_WIDTH,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: 8,
  },
  scheduleHeader: {
    paddingLeft: 16,
    paddingTop: 8,
    paddingBottom: 8,
  },
  pagination: {
    height: 24,
    width: 28,
    cursor: 'pointer',
    fontSize: '26px',
  },
};
