import {Box, Tooltip as MuiTooltip} from '@mui/material';
import React, {useState} from 'react';
import Button from '@mui/material/Button';
import {styled} from '@mui/system';
import {BarChart, Bar, CartesianGrid, YAxis, Tooltip} from 'recharts';
import {ReactComponent as NotesIcon} from './../../assets/icons/notes.svg';
import styled2 from '@emotion/styled';

const formatYAxisTick = value => {
  return value === 0 ? value : value.toLocaleString();
};

const StyledButton = styled(Button)(({isActive}) => ({
  backgroundColor: isActive ? '#274F66' : '#D2DCE2',
  color: isActive ? 'white' : '#274F66',
  borderRadius: '5px',
  padding: '3px',
  height: '30px',
  width: '30px',
  minWidth: '0px',
  '&:hover': {
    backgroundColor: '#156180',
    color: 'white',
  },
}));

const BarChartSection = ({data}) => {
  const [selectedWeek, setSelectedWeek] = useState(1);
  const WeekButtons = ['W1', 'W2', 'W3', 'W4'];
  const [activeWeek, setActiveWeek] = useState('W1');
  const handleWeekButton = (label) => {
    setActiveWeek(label);
    setSelectedWeek(parseInt(label.slice(1)));
  };
  const [hoveredNote, setHoveredNote] = useState(null);
  const handleNoteHover = index => {
    setHoveredNote(index);
  };

  const handleNoteLeave = () => {
    setHoveredNote(null);
  };

  const weeklySums = {};

WeekButtons.forEach((week) => {
  const weekData = data
    ?.slice((parseInt(week.slice(1)) - 1) * 7, parseInt(week?.slice(1)) * 7)
    .map((day) => {
      const numericValue = parseInt(day?.data?.value);
      return isNaN(numericValue) ? 0 : numericValue; 
    });

  const sum = weekData.reduce((acc, value) => acc + value, 0);
  weeklySums[week] = sum;
});
  

  const weeklySumsChartData = WeekButtons.map((week) => ({
    week,
    value: weeklySums[week],
  }));

  const filteredDays = data.slice((selectedWeek - 1) * 7, selectedWeek * 7).map(day => ({
    ...day,
    value: parseInt(day?.data?.value), 
  }));

  return (
    <>
      <Box>
        <BarChart
          width={310}
          height={150}
          data={weeklySumsChartData}
          barGap={10}
          categoryGap={20}>
          <CartesianGrid vertical={false} height={200} strokeDasharray="1" />
          <YAxis
            axisLine={false}
            tickLine={false}
            tickFormatter={formatYAxisTick}
          />
          <Tooltip />
          <Bar dataKey="value" barSize={13} radius={20} fill="#9CD272" />
        </BarChart>
        <Box
          sx={{
            display: 'flex',
            gap: '30px',
            mt: 1,
            mb: 2,
            ml: 7,
            px: 3,
          }}>
          {WeekButtons.map((data, index) => {
            return (
              <StyledButton
                key={index}
                onClick={() => handleWeekButton(data)}
                isActive={data === activeWeek ? true : false}>
                {data}
              </StyledButton>
            );
          })}
        </Box>
      </Box>
      <BarChart width={480} height={150} data={filteredDays}>
        <CartesianGrid vertical={false} height={200} strokeDasharray="1" />
        <YAxis
          axisLine={false}
          tickLine={false}
          tickFormatter={formatYAxisTick}
        />
        <Tooltip />
        <Bar dataKey="value" barSize={13} radius={20} fill="#9CD272" />
      </BarChart>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 2,
          ml: 7,
          px: 4,
        }}>
       {filteredDays?.map((data, index) => {
          const isHovered = hoveredNote === index;
          return (
            <Box key={index}>
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
              <MuiTooltip title={data?.data?.journal_activity} arrow>
                  <NotesIcon
                    style={{
                      fill: data?.data?.journal_activity ? '#274F66' : '#ACB9C2',
                      cursor: data?.data?.journal_activity ? 'pointer' : '',
                    }}
                    onMouseOver={() => handleNoteHover(index)}
                    onMouseLeave={handleNoteLeave}
                  />
                </MuiTooltip>
              </Box>
              <Text today={data?.today}>{data?.day?.charAt(0)}</Text>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default BarChartSection;

const Text = styled2.div`
  margin-top: 5px;
  width: 25px;
  height: 25px;
  font-size: 14px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  border-width: 2px;
  border-color: ${props => (props.today ? '#274F66' : 'transparent')};
  border-style: solid;
`;
