import React from 'react';
import {
    faSearch,
    faCommentDots,
    faUserEdit,
    faUserMinus,
    faUser,
    faUserPlus,
    faEnvelopeOpenText,
    faCaretRight,
    faCaretLeft,
} from '@fortawesome/free-solid-svg-icons';
import DataGrid from '../components/DataGridSorting';
import ActivityIndicator from '@components/ActivityIndicator';
import Panel from '../components/Panel';
import CircleImage from '../components/CircleImage';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { HttpRequest } from '../utils/http';
import { store } from '../store';
import Swal from 'sweetalert2';
import IconImage from '../assets/logo192.png'

class Patient extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            patients: [],
            FullArray: [],
            phoneNumber: '',
            SelectAssign: '',
            phoneNumberValid: true,
            currentPage: 1,
            next: null,
            previous: null,
            totalPages: 1,
            patients: [],
            searchText: '',
            tagdata: [],
            tagsFilter: '',
            providers: [],
        };
    }

    componentDidMount() {
        let user = store.getState().loginReducer.user;
        if (user) {
            this.loadProfile('');
            this.loadProviders();
        }
    }

    loadProviders = () => {
        this.setState({ isLoadingBooking: true });

        HttpRequest.providerList()
            .then((res) => {
                let data = res.data.results;
                this.setState({ providers: data, isLoadingBooking: false });
            })
            .catch((err) => {
                Swal.fire('Error', 'Cannot load Providers data.', 'error');
                this.setState({ isLoadingBooking: false });
            });
    };


    loadProfile(q) {
        let user = store.getState().loginReducer.user;

        this.setState({ isLoading: true });
        if (user.user.role === 1) {
            HttpRequest.showPatientList(this.state.currentPage, null).then(res => {
                let next = res.data.next;
                const nextPageNumber = next ? next.split('page=')[1] : null;
                const count = res.data.count;
                const roundedPage = Math.ceil(count / 20)
                let data = res.data.results;

                let ids = data.map(item => item.user.id);

                this.setState({
                    isLoading: false,
                    patients: data,
                    next: nextPageNumber,
                    totalPages: roundedPage
                }, () => {
                    ids.forEach(id => {
                        this.loadAssignedData(id);
                    });
                });
            })
                .catch(err => {
                    this.setState({ isLoading: false });
                    Swal.fire('Error', 'Cannot load patient data.', 'error');
                });
        } else {
            HttpRequest.showPatientList(this.state.currentPage, user.user.id)
                .then(res => {
                    let next = res.data.next;
                    const nextPageNumber = next ? next.split('page=')[1] : null;
                    const count = res.data.count;
                    const roundedPage = Math.ceil(count / 20)
                    let data = res.data.results;

                    let ids = data.map(item => item.user.id);

                    this.setState({
                        isLoading: false,
                        patients: data,
                        next: nextPageNumber,
                        totalPages: roundedPage
                    }, () => {
                        ids.forEach(id => {
                            this.loadAssignedData(id);
                        });
                    });
                })
                .catch(err => {
                    this.setState({ isLoading: false });
                    Swal.fire('Error', 'Cannot load patient data.', 'error');
                });
        }
    }
    loadPage = page => {
        this.setState({ currentPage: page }, this.loadProfile);
    };

    loadNextPage = () => {
        this.loadPage(this.state.currentPage + 1);
    };

    loadPrevPage = () => {
        this.loadPage(this.state.currentPage - 1);
    };
    SearchloadProfile(q) {
        const NodePatient = this.state.FullArray; // Use the full patient array for searching

        if (q === null || q.trim() === '') {
            this.setState({ patients: NodePatient });
        } else {
            const searchQuery = q.toLowerCase(); // Convert search query to lowercase
            const result = NodePatient.filter(item => {
                const fullName = (item.user.name + ' ' + item.user.last_name).toLowerCase(); // Convert name to lowercase
                return fullName.includes(searchQuery);
            });

            this.setState({ patients: result });
        }
    }

    invitePatient() {
        let { phoneNumber } = this.state;
        phoneNumber = phoneNumber.replace(/[^0-9]/g, '');
        HttpRequest.invitePatient(phoneNumber)
            .then(res => {
                let data = res.data.results;

                Swal.fire(
                    'Information',
                    'Invitation has been sent via SMS.',
                    'success',
                );
            })
            .catch(err => {
                Swal.fire('Error', 'Cannot send invitation.', 'error');
            });
    }

    loadAssignedData(patient_id) {
        HttpRequest.assignedPatient(patient_id)
            .then(res => {
                let oldAssignedProviders = res.data.results;
                let assignedProviders = [];
                oldAssignedProviders?.map(item => {
                    if (item.assigned) {
                        assignedProviders.push(item);
                    }
                });

                let patients = this.state.patients;
                patients = patients?.map(patient => {
                    if (patient.id == patient_id) {
                        patient.assignedProviders = assignedProviders;
                    }
                    return patient;
                });

                this.setState({ patients });
                this.setState({ FullArray: patients });
            })
            .catch(err => {
                this.setState({ isLoading: false });
                Swal.fire('Error', 'Cannot load patient data', 'error');
            });
    }
    loadAssignedtoFilter(id) {
        if (id === 'All') {
            this.setState({ patients: this.state.FullArray }); // Reset to the full array
        } else {
            const res = this.state.FullArray.filter(x =>

                x.providers[0]?.name + " " + x.providers[0]?.last_name === id
            );
            this.setState({ patients: res });
        }
    }
    loadContactFilter(id) {
        if (id === 'Any') {
            this.loadProfile('');
        } else {
            let res;

            const now = moment(); // Get the current date and time

            if (id === '<24h') {
                res = this.state.FullArray.filter(
                    x => now.diff(moment(x.last_contact), 'hours') <= 24
                );
            } else if (id === '<1w') {
                res = this.state.FullArray.filter(
                    x => now.diff(moment(x.last_contact), 'days') <= 7
                );
            } else if (id === '<9month') {
                res = this.state.FullArray.filter(
                    x => now.diff(moment(x.last_contact), 'months') <= 9
                );
            }

            this.setState({ patients: res });
        }
    }
    loadActivityFilter(id) {
        if (id === 'Any') {
            this.loadProfile('');
        } else {
            let res;
            const now = moment(); // Get the current date and time
            if (id === '<1h' || id === 'added') {
                res = this.state.FullArray.filter(
                    x => now.diff(moment(x.last_activity), 'minutes') <= 60
                );
            } else if (id === '<24h' || id === 'added') {
                res = this.state.FullArray.filter(
                    x => now.diff(moment(x.last_activity), 'hours') <= 24
                );
            } else if (id === '<1month') {
                res = this.state.FullArray.filter(
                    x => now.diff(moment(x.last_activity), 'months') <= 1
                );
            } else if (id === '<9month') {
                res = this.state.FullArray.filter(
                    x => now.diff(moment(x.last_activity), 'months') <= 9
                );
            }
            this.setState({ patients: res });
        }
    }
    loadTagFilter(id) {
        if (this.state.patients.length > 1) {
            this.setState({ FullArray: this.state.patients });
        }
        if (id === 'Any') {
            this.loadProfile('');
        } else {
            var res = this.state.FullArray.filter(item =>
                item.tags.some(tag => tag === id),
            );
            this.setState({ patients: res });
        }
    }
    render() {

        return (
            <>
                <Panel>
                    <div className="section-title">All contacts</div>
                    {this.state.isLoading && <ActivityIndicator />}
                    {!this.state.isLoading && (<DataGrid
                        filters={[
                            {
                                type: 'textinput',
                                label: 'Search contacts',
                                icon: faSearch,
                                onChange: (text) => {
                                    // Update the state with the search text
                                    this.setState({ searchText: text }, () => {
                                        // Call your search function here with the updated search text
                                        this.SearchloadProfile(this.state.searchText);
                                    });
                                },
                                className: 'col-md-6 col-sm-12',
                                style: { flex: 1 },
                            },
                            {
                                type: 'select',
                                label: 'Assigned to',
                                data: [
                                    { id: 'All', label: 'All' }, // Add "All" option
                                    ...this.state.providers?.map((data) => ({
                                        id: data.user.name + " " + data.user.last_name,
                                        label: data.user.name + " " + data.user.last_name
                                    })),
                                ],
                                onChange: text => {
                                    this.loadAssignedtoFilter(text);
                                },
                                className: 'col-md-2 col-sm-4',
                                style: { width: 200 },
                            },
                            {
                                type: 'select',
                                label: 'Last contact',
                                data: [
                                    { id: 'Any', label: 'Any' },
                                    { id: '<24h', label: '<24h' },
                                    { id: '<1w', label: '<1w' },
                                    { id: '<9month', label: '<9month' },
                                ],
                                onChange: text => {
                                    this.loadContactFilter(text);
                                },
                                className: 'col-md-2 col-sm-4',
                                style: { width: 200 },
                            },
                            {
                                type: 'select',
                                label: 'Last activity',
                                data: [
                                    { id: 'Any', label: 'Any' },
                                    { id: '<1h', label: '<1h' },
                                    { id: '<24h', label: '<24h' },
                                    { id: '<1month', label: '<1month' },
                                ],
                                onChange: text => {
                                    this.loadActivityFilter(text);
                                },
                                className: 'col-md-2 col-sm-4 mt-2',
                                style: { width: 200 },
                            },
                        ]}
                        columns={[
                            {
                                name: 'image',
                                label: 'Picture',
                                renderItem: item => {
                                    return (
                                        <CircleImage
                                            src={
                                                item.user.image === null ? IconImage : item.user.image
                                            }
                                            size={30}
                                        />
                                    );
                                },
                            },
                            {
                                name: 'user',
                                label: 'Name',
                                sortable: true,
                                renderItem: item => {
                                    return (
                                        <Link to={'/report/detail/' + item.id} className="link">
                                            {item.user.name} {item.user.last_name}
                                        </Link>
                                    );
                                },
                            },
                            {
                                name: "providers",
                                label: 'Assigned',
                                sortable: true,
                                renderItem: item => {
                                    if (item.providers != null) {
                                        if (item.providers.length != 0) {
                                            return item.providers
                                                .map(assign => {
                                                    return assign.name + " " + assign.last_name;
                                                })
                                                .join(', ');
                                        }
                                    }
                                    return 'None';
                                },
                            },
                            {
                                name: "last_activity",
                                label: 'Last Activity',
                                sortable: true,
                                renderItem: item => {
                                    if (item.last_activity) {
                                        return moment(item.last_activity).fromNow();
                                    }
                                    return 'N/A';
                                },
                            },
                            {
                                name: "last_contact",
                                label: 'Last Contact',
                                sortable: true,
                                renderItem: item => {
                                    if (item.last_contact) {
                                        return moment(item.last_activity).format('MM.DD.YYYY - ha');
                                    }
                                    return 'N/A';
                                },
                            },
                            {
                                label: 'Actions',
                                renderItem: item => {
                                    return (
                                        <>
                                            <Link
                                                to={'/messaging'}
                                                style={{ margin: '0 5px' }}>
                                                <FontAwesomeIcon icon={faCommentDots} />
                                            </Link>
                                            <Link
                                                to={'/patient/edit/' + item.user.id}
                                                style={{ margin: '0 5px' }}>
                                                <FontAwesomeIcon icon={faUserEdit} />
                                            </Link>
                                            <Link
                                                to={'/report/detail/' + item.id}
                                                style={{ margin: '0 5px' }}>
                                                <FontAwesomeIcon icon={faUser} />
                                            </Link>
                                        </>
                                    );
                                },
                            },
                        ]}
                        data={this.state.patients}
                    />

                    )}
                    <div className="d-flex justify-content-end align-items-center gap-8">

                        <FontAwesomeIcon
                            onClick={this.state.currentPage === 1 ? null : this.loadPrevPage}

                            icon={faCaretLeft}
                            style={{
                                ...styles.pagination,
                                color: this.state.currentPage === 1 ? '#ACB9C2' : '#274F66',
                            }}
                            disabled={this.state.currentPage === 1}
                        />

                        Page {this.state.currentPage} of {this.state.totalPages}

                        <FontAwesomeIcon
                            onClick={!this.state.next ? null : this.loadNextPage}
                            icon={faCaretRight}
                            style={{
                                ...styles.pagination,
                                color: !this.state.next ? '#ACB9C2' : '#274F66',
                            }}
                            disabled={!this.state.next}
                        />
                    </div>
                </Panel>
            </>
        );
    }
}

const styles = {
    pagination: {
        height: 24,
        width: 28,
        cursor: 'pointer',
        fontSize: '26px',
    }
};

export default Patient;
