import { Box, Tooltip as MuiTooltip } from '@mui/material';
import React, { useState } from 'react';
import {
  BarChart,
  Bar,
  CartesianGrid,
  YAxis,
} from 'recharts';
import { ReactComponent as NotesIcon } from './../../assets/icons/notes.svg';
import styled from '@emotion/styled';

const formatYAxisTick = (value) => {
  return value === 0 ? value : value.toLocaleString();
};

const BarChartSection = ({ data }) => {
  const [hoveredNote, setHoveredNote] = useState(null);

  const handleNoteHover = (index) => {
    setHoveredNote(index);
  };

  const handleNoteLeave = () => {
    setHoveredNote(null);
  };

  const convertedData = data?.map((item) => ({
    ...item,
    value: parseFloat(item?.data?.value),
  }));

  return (
    <>
      <BarChart width={480} height={150} data={convertedData}>
        <CartesianGrid vertical={false} height={200} strokeDasharray="1" />
        <YAxis
          axisLine={false}
          tickLine={false}
          tickFormatter={formatYAxisTick}
        />
        <Bar dataKey="value" barSize={13} radius={20} fill="#9CD272" />
      </BarChart>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, ml: 7, px: 3 }}>
        {data?.map((data, index) => {
          const isHovered = hoveredNote === index;
          return (
            <Box key={index}>
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                <MuiTooltip title={data?.data?.journal_activity} arrow>
                  <NotesIcon
                    style={{
                      fill: data?.data?.journal_activity ? '#274F66' : '#ACB9C2',
                      cursor: data?.data?.journal_activity ? 'pointer' : '',
                    }}
                    onMouseOver={() => handleNoteHover(index)}
                    onMouseLeave={handleNoteLeave}
                  />
                </MuiTooltip>
              </Box>
              <Text today={data?.today}>{data?.day?.charAt(0)}</Text>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default BarChartSection;

const Text = styled.div`
  margin-top: 5px;
  width: 25px;
  height: 25px;
  font-size: 14px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  border-width: 2px;
  border-color: ${(props) => (props.today ? '#274F66' : 'transparent')};
  border-style: solid;
`;
