import React from 'react';
import DataGrid from '../components/DataGrid';
import Panel from '../components/Panel';
import moment from 'moment';
import {
  faCommentAlt,
  faSearch,
  faUser,
  faUnlock,
} from '@fortawesome/free-solid-svg-icons';
import CircleImage from '../components/CircleImage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {HttpRequest} from '../utils/http';
import ActivityIndicator from '../components/ActivityIndicator';
import {Link} from 'react-router-dom';
import {store} from '../store';
import IconImage from '../assets/logo192.png';
import Swal from 'sweetalert2';
import NoData from '../components/NoData';

class BlockUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      users: [],
      selectedUsers: null,
      searchText: '',
    };
  }

  componentDidMount() {
    let user = store.getState().loginReducer.user;
    if (user) {
      this.loadBlockedUser();
    }
  }

  loadBlockedUser() {
    let user = store.getState().loginReducer.user;

    this.setState({isLoading: true});

    HttpRequest.getBlockedProvider()
      .then(res => {
        let users = res.data.results;
        this.setState({
          isLoading: false,
          users,
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
      });
  }

  handleBlockUser(id) {
    this.setState({isLoading: true});
    let user = store.getState().loginReducer.user;
    let data = {
      user: id,
      role: user.user.role,
      is_active: false,
    };
    HttpRequest.blockProvider(id, data)
      .then(() => {
        const updatedusers = this.state.users.filter(user => user.user !== id);

        this.setState({
          isLoading: false,
          users: updatedusers,
        });
        Swal.fire('Success', 'User blocked successfully.', 'success');
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot block user.', 'error');
      });
  }

  applyFilters() {
    const {searchText, users} = this.state;

    let filteredUsers = users;

    if (searchText) {
      filteredUsers = filteredUsers.filter(
        item =>
          item.user_desc &&
          item.user_desc.toLowerCase().includes(searchText.toLowerCase()),
      );
    }

    this.setState({selectedUsers: filteredUsers});
  }

  render() {
    return (
      <>
        <Panel>
          <div className="section-title">All blocked contact</div>
          {this.state.isLoading && <ActivityIndicator />}
          {!this.state.isLoading && (
            <DataGrid
              filters={[
                {
                  type: 'textinput',
                  label: 'Search contacts',
                  icon: faSearch,
                  onChange: text => {
                    this.setState({searchText: text}, this.applyFilters);
                  },
                  className: 'col-md-12 col-sm-12',
                  style: {flex: 1},
                },
              ]}
              columns={[
                {
                  name: 'image',
                  label: 'Picture',
                  renderItem: item => {
                    return (
                      <CircleImage src={item.image || IconImage} size={30} />
                    );
                  },
                },
                {
                  name: 'name',
                  label: 'Name',
                  renderItem: item => {
                    return (
                      <Link
                        to={'/user/detail/provider/' + item.user}
                        className="link">
                        {item.user_desc}
                      </Link>
                    );
                  },
                },
                {
                  label: 'Actions',
                  renderItem: item => {
                    return (
                      <>
                        <Link
                          to={'/user/detail/provider/' + item.user}
                          style={{margin: '0 5px'}}>
                          <FontAwesomeIcon icon={faUser} />
                        </Link>
                        <span
                          style={styles.control}
                          onClick={() => this.handleBlockUser(item.user)}>
                          <FontAwesomeIcon
                            icon={faUnlock}
                            style={{
                              // ...styles.pagination,
                              color: '#ACB9C2',
                            }}
                          />
                        </span>
                      </>
                    );
                  },
                },
              ]}
              data={
                this.state.selectedUsers
                  ? this.state.selectedUsers
                  : this.state.users || []
              }
            />
          )}
        </Panel>
      </>
    );
  }
}

const styles = {
  control: {
    marginLeft: 8,
    cursor: 'pointer',
  },
};

export default BlockUser;
