import React from 'react';
import DataGrid from '../components/DataGrid';
import Panel from '../components/Panel';
import moment from 'moment';
import {
  faChevronLeft,
  faCommentAlt,
  faEdit,
  faEyeSlash,
  faFileExport,
  faPaste,
  faPlus,
  faSearch,
  faTrash,
  faUserPlus,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import CircleImage from '../components/CircleImage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {HttpRequest} from '../utils/http';
import ActivityIndicator from '../components/ActivityIndicator';
import {Link, withRouter} from 'react-router-dom';
import {store} from '../store';
import Swal from 'sweetalert2';

class SurveyMember extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      patients: [],
      patient_id: null,
      title: '',
      questions: [],
      searchText: '',
      members: [],
      selectedMembers: null
    };

    this.id = props.match?.params?.id;
  }

  componentDidMount() {
    let user = store.getState().loginReducer.user;
    if (user && this.id != null) {
      this.loadSurvey();
      this.loadPatientSurvey();
    }
    this.loadPatients();
  }

  loadSurvey() {
    this.setState({isLoading: true});

    HttpRequest.getSurvey(this.id)
      .then(res => {
        let data = res.data.data;

        this.setState({
          isLoading: false,
          title: data.title,
          questions: data.questions,
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load survey data.', 'error');
      });
  }

  loadPatientSurvey() {
    HttpRequest.getPatientSurvey(this.id).then(res => {
      let data = res.data.results;

      this.setState({members: data});
    });
  }

  loadPatients() {
    let user = store.getState().loginReducer.user;

    this.setState({isLoading: true});

    HttpRequest.patientList()
      .then(res => {
        let data = res.data.results;

        this.setState({isLoading: false, patients: data});
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load patient data. bkj', 'error');
      });
  }

  assignSurvey() {
    let {patients, patient_id} = this.state;

    window.jQuery('#myModal').modal('hide');

    let patient = patients.find(item => item.user.id == patient_id);

    let data = {
      tags: ['tags'],
      is_removed: false,
      status: 'Incomplete',
      user: parseInt(patient_id),
      // user: {
      // 	id: patient_id,
      // 	name: patient.user.name,
      // 	first_name: '',
      // 	last_name: patient.user.last_name,
      // },
      survey_desc: parseInt(this.id),
    };

    HttpRequest.addPatientSurvey(data)
      .then(res => {
        Swal.fire(
          'Information',
          'Successfully assign PowerUp member',
          'success',
        );
        this.loadPatientSurvey();
      })
      .catch(err => {
        Swal.fire('Error', 'Cannot assign PowerUp member.', 'error');
      });
  }

  unassignSurvey(id) {
    HttpRequest.removePatientSurvey(id)
      .then(() => {
        Swal.fire(
          'Information',
          'Successfully delete PowerUp member',
          'success',
        );
        this.loadPatientSurvey();
      })
      .catch(err => {
        Swal.fire('Error', 'Cannot delete powerup member.', 'error');
      });
  }
  
  applyFilter() {
    let {members, searchText} = this.state;

    let filteredMembers = members;
    if(searchText) {
        filteredMembers = members.filter((member)=>(
            member.user.name.toLowerCase().includes(this.state.searchText.toLowerCase())
        ))
    }
    this.setState({selectedMembers: filteredMembers})
  }
  

  render() {
    return (
      <div className="row">
        <div className="col-md-9">
          <div className="flex-row" style={{marginBottom: 10}}>
            <Link to="/survey" className="btn btn-secondary btn-round">
              <FontAwesomeIcon icon={faChevronLeft} /> Back to surveys
            </Link>
            <div className="flex-column flex" />
            {/* <a href="#" className='btn btn-primary btn-round' onClick={(e) => {
							e.preventDefault();
						}}>
							<FontAwesomeIcon icon={faFileExport} /> Export results
						</a> */}
            <a
              href="#"
              className="btn btn-primary btn-round"
              onClick={e => {
                window.jQuery('#myModal').modal('show');
                e.preventDefault();
              }}>
              <FontAwesomeIcon icon={faUserPlus} /> Add Member
            </a>
          </div>
          <Panel>
            {this.state.isLoading && <ActivityIndicator />}
            {!this.state.isLoading && (
              <DataGrid
                filters={[
                    {
                        type: 'textinput',
                        label: 'Search name',
                        icon: faSearch,
                        value: this.state.searchText, 
                        onChange: text => {
                            this.setState({ searchText: text }, this.applyFilter)
                        },
                        className: 'col-sm-12 col-md-12',
                      }
                ]}
                columns={[
                  {
                    label: 'Picture',
                    renderItem: item => {
                      return <CircleImage src={item.user.image} size={30} />;
                    },
                  },
                  {
                    label: 'Name',
                    renderItem: item => {
                      return item.user.name;
                    },
                  },
                  {name: 'status', label: 'Completion Status'},
                  {
                    label: 'Controls',
                    renderItem: item => {
                      return (
                        <>
                          <Link
                            to={'#'}
                            style={styles.control}
                            onClick={async (e) => {
                                e.preventDefault();
                              
                                const result = await Swal.fire({
                                  title: 'Are you sure?',
                                  text: 'You won\'t be able to revert this!',
                                  icon: 'warning',
                                  showCancelButton: true,
                                  confirmButtonText: 'Yes, delete it!'
                                });
                                if (result.isConfirmed) {
                                  this.unassignSurvey(item.id);
                                }
                              }}>
                            <FontAwesomeIcon icon={faTrash} />
                          </Link>
                        </>
                      );
                    },
                  },
                ]}
                data={this.state.selectedMembers ? this.state.selectedMembers :this.state.members || []}
              />
            )}
          </Panel>
        </div>
        <div className="col-md-3">
          <div className="flex-row" style={{marginBottom: 10}}>
            <div className="flex-column flex" />
            <Link
              to={'/survey/edit/' + this.id}
              className="btn btn-primary btn-round">
              <FontAwesomeIcon icon={faEdit} /> Edit survey
            </Link>
          </div>

          {this.state.isLoading && <ActivityIndicator />}
          {!this.state.isLoading && (
            <>
              {this.state.questions.map((question, key) => {
                return (
                  <Panel key={key}>
                    <div style={styles.questionNum}>Question #{key + 1}</div>
                    <div style={styles.questionTitle}>{question.title}</div>
                  </Panel>
                );
              })}
            </>
          )}
        </div>

        <div className="modal" tabindex="-1" role="dialog" id="myModal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Assign a Patient</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">Patient</label>
                    <div className="col-sm-10">
                      <select
                        className="form-control"
                        value={this.state.patient_id}
                        onChange={event => {
                          this.setState({patient_id: event.target.value});
                        }}>
                        <option value="">Choose Patient</option>
                        {this.state.patients.map((user, key) => {
                          return (
                            <option value={user.user.id} key={key}>
                              {user.user.name} {user.user.last_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    this.assignSurvey();
                  }}>
                  Save changes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  control: {
    marginLeft: 8,
  },
  questionNum: {
    // fontWeight: 'bold',
    fontSize: 18,
  },
  questionTitle: {
    // fontWeight: 'bold'
  },
};

export default withRouter(SurveyMember);
