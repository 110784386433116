import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';

const selectStyles = {
  width: '70%',
  background: 'white',
  '& .MuiSelect-select': {
    fontSize: 17,
    outline: 0,
    left: 0,
    color: 'darkGray',
    fontWeight: 600,
    borderStyle: 'none',
    paddingY: 1,
  },
};

export default function GradientSlider({
  handleChangeData,
  scoreTemplatesValue,
}) {
  const [selectedValues, setSelectedValues] = React.useState(null);

  const handleChange = (category, value) => {
    if (category === 'Good' || category === 'Bad') {
      setSelectedValues(prevValues => ({
        ...prevValues,
        [category]: value,
      }));
      handleChangeData(prevValues => ({
        ...prevValues,
        [category]: value,
      }));

      const otherCategory = category === 'Good' ? 'Bad' : 'Good';
      setSelectedValues(prevValues => ({
        ...prevValues,
        [otherCategory]: value === 'Yes' ? 'No' : 'Yes',
      }));
      handleChangeData(prevValues => ({
        ...prevValues,
        [otherCategory]: value === 'Yes' ? 'No' : 'Yes',
      }));
    } else {
      setSelectedValues(prevValues => ({
        ...prevValues,
        [category]: value,
      }));
      handleChangeData(prevValues => ({
        ...prevValues,
        [category]: value,
      }));
    }
  };

  return (
    <Box sx={styles.main}>
      <Box sx={styles.submain}>
        <Select
          value={scoreTemplatesValue?.Bad}
          onChange={event => handleChange('Bad', event.target.value)}
          input={
            <OutlinedInput
              inputProps={{
                'aria-label': 'Without label',
                hiddenlabel: 'true',
              }}
              hiddenlabel
            />
          }
          sx={selectStyles}>
          <MenuItem value={'Yes'}>Yes</MenuItem>
          <MenuItem value={'No'}>No</MenuItem>
        </Select>
        <Box sx={styles.typeStyle}>Bad</Box>
      </Box>
      <FormControl
        sx={{
          width: '40%',
          '@media (max-width: 920px)': {
            width: '94%',
          },
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5em',
            marginX: '1em',
          }}>
          <Box
            sx={{
              width: '33.3%',
              height: 3,
              background: '#F05354',
            }}
          />
          <Box
            sx={{
              width: '33.3%',
              height: 3,
              background: '#FFCC2A',
            }}
          />
          <Box
            sx={{
              width: '33.3%',
              height: 3,
              background: '#8CE446',
            }}
          />
        </Box>
      </FormControl>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#E9E9E9',
          borderRadius: '0.3em',
          width: '30%',
          '@media (max-width: 920px)': {
            width: '80%',
            marginBottom: '0.5em',
          },
        }}>
        <Box sx={styles.typeStyle}>Good</Box>

        <Select
          value={scoreTemplatesValue?.Good}
          onChange={event => handleChange('Good', event.target.value)}
          input={
            <OutlinedInput
              inputProps={{
                'aria-label': 'Without label',
                hiddenlabel: 'true',
              }}
              hiddenlabel
              placeholder="Please enter text"
            />
          }
          sx={selectStyles}>
          <MenuItem value={'Yes'}>Yes</MenuItem>
          <MenuItem value={'No'}>No</MenuItem>
        </Select>
      </Box>
    </Box>
  );
}

const styles = {
  main: {
    minWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '@media (max-width: 920px)': {
      flexDirection: 'column',
      gap: '0.5em',
    },
  },
  submain: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#E9E9E9',
    borderRadius: '0.3em',
    width: '30%',
    '@media (max-width: 920px)': {
      width: '80%',
      marginTop: '0.5em',
    },
  },
  typeStyle: {
    width: '30%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
