import React from 'react';
import {withRouter} from 'react-router';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import CircleImage from '../components/CircleImage';
import DataGrid from '../components/DataGrid';
import HorizontalLine from '../components/HorizontalLine';
import ImagePanel from '../components/ImagePanel';
import Panel from '../components/Panel';
import PanelTab from '../components/PanelTab';
import TextInput from '../components/TextInput';
import FlexibleInputGroup from '../components/FlexibleInputGroup';
import MilestoneList from '../components/MilstoneList';
import {Link} from 'react-router-dom';
import Chat from '../components/Chat';

import IconUser from '../assets/icon-user.jpg';

class PatientChat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notes: [
        {
          id: 1,
          userImage: IconUser,
          message: 'Lorem ipsum dolor sit amet',
          doctorName: 'Kirstie Whitaker',
          date: '2021-02-01 09:00:00',
        },
        {
          id: 3,
          userImage: IconUser,
          message: 'Lorem ipsum dolor sit amet',
          doctorName: 'Gol D. Roger',
          date: '2021-02-01 09:00:00',
        },
        {
          id: 4,
          userImage: IconUser,
          message: 'Lorem ipsum dolor sit amet',
          doctorName: 'Edward Newgate',
          date: '2021-02-01 09:00:00',
        },
        {
          id: 5,
          userImage: IconUser,
          message: 'Lorem ipsum dolor sit amet',
          doctorName: 'Mihawk',
          date: '2021-02-01 09:00:00',
        },
      ],
      selectedIndex: 0,
      userId: 1,
      messages: [],
    };

    this.id = props.match.params.id;
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-8">
          <p>
            <Link
              to={'/patient/detail/' + this.id}
              className="btn btn-secondary btn-round">
              Back to profile
            </Link>
          </p>

          <Panel>
            <div className="section-title">
              Message history for <b>Marie Kline</b> and <b>Paul Stanley</b>
            </div>

            <Chat
              messages={this.state.messages}
              userId={this.state.userId}
              onSend={msg => {}}
            />
          </Panel>
        </div>
        <div className="col-md-4">
          <PanelTab
            tabs={['Messages', 'Notes']}
            selectedIndex={this.state.selectedIndex}
            onChange={selectedIndex => {
              this.setState({selectedIndex});
            }}
          />

          <Panel>
            <DataGrid
              filters={[]}
              columns={[
                {name: 'message', label: 'Note'},
                {
                  label: 'Added',
                  renderItem: item => {
                    return (
                      <>
                        <CircleImage src={item.userImage} size={30} />
                      </>
                    );
                  },
                },
              ]}
              data={this.state.notes}
            />
          </Panel>
        </div>
      </div>
    );
  }
}

const styles = {};

export default withRouter(PatientChat);
