import { faEdit, faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Calendar from 'react-calendar';
import Swal from 'sweetalert2';
import ActivityIndicator from '../components/ActivityIndicator';
import CircleImage from '../components/CircleImage';
import HorizontalLine from '../components/HorizontalLine';
import Panel from '../components/Panel';
import TextInput from '../components/TextInput';
import { store } from '../store';
import { HttpRequest, HttpResponse } from '../utils/http';
import imageUtil from '../utils/imageUtil';
import ProfileIcon from "../assets/logo192.png"
const SCHEDULE_CELL_WIDTH = 70;

class Profile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoadingProfile: false,
            isSaving: false,
            name: '',
            last_name: '',
            title: '',
            department: '',
            phone: '',
            email: '',
            image: null,
            detail: null,

            selectedDate: new Date(),
            am: [
                { time: '09', label: '9' },
                { time: '10', label: '10' },
                { time: '11', label: '11' },
                { time: '12', label: '12' },
            ],
            pm: [
                { time: '13', label: '1' },
                { time: '14', label: '2' },
                { time: '15', label: '3' },
                { time: '16', label: '4' },
            ],
        };
    }

    componentDidMount() {
        let user = store.getState().loginReducer.user;

        if (user) {
            this.loadProfile();
        }
    }

    loadProfile() {
        let user = store.getState().loginReducer.user;
        this.setState({ isLoadingProfile: true, isError: false });

        HttpRequest.getProvider(user.user.id)
            .then(res => {
                let data = res.data.data;
                this.setState({
                    isLoadingProfile: false,
                    detail: data,
                    name: data.user.name,
                    last_name: data.user.last_name,
                    title: data.provider_data.title,
                    department: data.provider_data.department,
                    email: data.user.email,
                    phone: data.phone,
                    image: data.image,
                });
            })
            .catch(err => {
                this.setState({ isLoadingProfile: false, isError: true });
                Swal.fire('Error', 'Cannot load profile data.', 'error');
            });
    }

    updateProfile() {
        let user = store.getState().loginReducer.user;
        let detail = this.state.detail;

        this.setState({ isSaving: true });

        let data = {
            user: {
                // "id": detail.user.id,
                username: detail.user.username,
                name: this.state.name,
                last_name: this.state.last_name,
                // last_name: detail.user.last_name,
                // email: this.state.email,
            },
            provider: {
                title: this.state.title,
                user: detail.user.id,
                department: this.state.department
            },
            // image: detail.image,
            birth_date: detail.birth_date,
            address: detail.address,
            contact_info: detail.contact_info,
            phone: this.state.phone,
            gender: detail.gender,
            security_question: detail.security_question,
            security_answer: detail.security_answer,
        };

        HttpRequest.saveProvider(user.user.id, data)
            .then(res => {
                this.setState({
                    isSaving: false,
                });
                Swal.fire('Success', 'Profile updated.', 'success');
            })
            .catch(err => {
                this.setState({ isSaving: false });
                Swal.fire(
                    'Error',
                    HttpResponse.processError(
                        err.response,
                        'Cannot update profile data.',
                    ),
                    'error',
                );
            });
    }

    render() {
        let { image } = this.state;
        return (
            <div className="row">
                <div className="col-md-8">
                    {this.state.isLoadingProfile && <ActivityIndicator />}
                    {!this.state.isLoadingProfile && (
                        <>
                            <Panel>
                                <div className="section-title">My profile</div>

                                <div style={{ position: 'relative' }}>
                                    <CircleImage
                                        src={this.state.image ? this.state.image : ProfileIcon}
                                        // src={imageUtil.getImage(image)}
                                        size={200}
                                        style={{
                                            border: '10px solid #F9F9F9',
                                        }}
                                    />

                                    <div style={styles.editWrapper}>
                                        <FontAwesomeIcon icon={faImage} />
                                        <input
                                            style={styles.editButton}
                                            type="file"
                                            onChange={e => {
                                                HttpRequest.uploadImage(e.target.files[0])
                                                    .then(res => {
                                                        this.loadProfile();
                                                    })
                                                    .catch(err => { });
                                            }}
                                        />
                                    </div>
                                </div>

                                <HorizontalLine title="Personal Information" />

                                <div className="row" style={{ paddingTop: 10 }}>
                                    <div className="col-md-6">
                                        <TextInput
                                            value={this.state.name}
                                            title="Name"
                                            readOnly={false}
                                            onChange={event => {
                                                this.setState({ name: event.target.value });
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <TextInput
                                            value={this.state.last_name}
                                            title="Last Name"
                                            readOnly={false}
                                            onChange={event => {
                                                this.setState({ last_name: event.target.value });
                                            }}
                                        />
                                    </div>
                                </div>

                                <HorizontalLine title="Professional Information" />

                                <div className="row" style={{ paddingTop: 10 }}>
                                    <div className="col-md-6">
                                        <TextInput
                                            value={this.state.title}
                                            title="Title"
                                            readOnly={false}
                                            onChange={event => {
                                                this.setState({ title: event.target.value });
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <TextInput
                                            value={this.state.department}
                                            title="Department"
                                            readOnly={false}
                                            onChange={event => {
                                                this.setState({ department: event.target.value });
                                            }}
                                        />
                                    </div>
                                </div>

                                <HorizontalLine title="Contact Information" />

                                <div className="row" style={{ paddingTop: 10 }}>
                                    <div className="col-md-6">
                                        <TextInput
                                            value={this.state.phone}
                                            title="Phone number"
                                            readOnly={false}
                                            onChange={event => {
                                                this.setState({ phone: event.target.value });
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <TextInput
                                            value={this.state.email}
                                            title="Email"
                                            readOnly={true}
                                            onChange={event => {
                                                this.setState({ email: event.target.value });
                                            }}
                                        />
                                    </div>
                                </div>
                            </Panel>
                            <button
                                className="btn btn-primary btn-round"
                                type="submit"
                                onClick={e => {
                                    e.preventDefault();
                                    this.updateProfile();
                                }}>
                                Save changes
                            </button>
                        </>
                    )}
                </div>
                <div className="col-md-4">
                    <Panel>
                        <div className="section-title">YBGR dashboard preferences</div>

                        <HorizontalLine title="Set availability" />

                        <Calendar
                            calendarType="US"
                            onChange={selectedDate => {
                                this.setState({ selectedDate });
                            }}
                            value={this.state.selectedDate}
                        />

                        <div style={styles.scrollHorizontal}>
                            <div
                                style={{
                                    width: 8 * SCHEDULE_CELL_WIDTH,
                                    ...styles.scheduleWrapper,
                                }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div
                                        style={{
                                            background: '#F4F7F9',
                                            width: 4 * SCHEDULE_CELL_WIDTH,
                                            ...styles.scheduleHeader,
                                        }}>
                                        AM
                                    </div>
                                    <div
                                        style={{
                                            background: '#DDE9F3',
                                            width: 4 * SCHEDULE_CELL_WIDTH,
                                            ...styles.scheduleHeader,
                                        }}>
                                        PM
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    {this.state.am.map((time, key) => {
                                        return (
                                            <div style={styles.cell} key={key}>
                                                <div style={styles.circle}>{time.label}</div>
                                            </div>
                                        );
                                    })}
                                    {this.state.pm.map((time, key) => {
                                        return (
                                            <div style={styles.cell} key={key}>
                                                <div style={styles.circle}>{time.label}</div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>

                        <label
                            style={{
                                marginTop: 16,
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                            }}>
                            <input type="checkbox" style={{ marginRight: 8 }} />
                            Allow around the clock communication
                        </label>

                        <HorizontalLine title="Dashboard setting" />
                    </Panel>
                </div>
            </div>
        );
    }
}

const styles = {
    scrollHorizontal: {
        overflowX: 'scroll',
    },
    scheduleWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    cell: {
        width: SCHEDULE_CELL_WIDTH,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 8,
        paddingBottom: 8,
    },
    scheduleHeader: {
        paddingLeft: 16,
        paddingTop: 8,
        paddingBottom: 8,
    },
    circle: {
        width: 40,
        height: 40,
        borderRadius: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'var(--color-text)',
        color: '#fff',
        cursor: 'pointer',
    },
    editWrapper: {
        width: 50,
        height: 50,
        borderRadius: 25,
        position: 'absolute',
        left: 140,
        top: 140,
        background: '#23465a',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        fontSize: 20,
    },
    editButton: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: 50,
        height: 50,
        opacity: 0,
        cursor: 'pointer',
    },
};

export default Profile;
