import React, {useState} from 'react';
import Button from '@mui/material/Button';
import CircleIcon from '@mui/icons-material/Circle';
import TooltipBox from '../TooltipBox';
import {Box} from '@mui/material';
import Button1 from './Button';

const WeekButtonGroup = ({
  weekData,
  data,
  userProfile,
  completeChallenge,
  key,
}) => {
  return (
    <Box>
      <Button1
        data={weekData.label}
        raw={data}
        userProfile={userProfile}
        completeChallenge={completeChallenge}
        week={weekData.label.match(/\d+/)[0]}
      />
      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        {weekData.data.map((item, index) => (
          <WeekButton key={index} item={item} />
        ))}
      </Box>
    </Box>
  );
};

const WeekButton = ({item}) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <div style={{position: 'relative'}}>
      <Button
        variant="contained"
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        style={{
          ...ButtonStyle,
          backgroundColor: item?.data ? '#9BD173ff' : '#D2DCE2',
        }}>
        <CircleIcon style={{fontSize: '5px'}} />
      </Button>
      {isShown && item?.data && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            marginTop: '8px',
          }}>
          <TooltipBox
            date={item?.data?.meta_data?.date}
            status={item?.data?.meta_data?.status}
            details={item?.data?.journal_activity}
            activity={item?.data?.value}
          />
        </div>
      )}
    </div>
  );
};

const WeekSubButton = ({data, userProfile, completeChallenge}) => {
  const weekRanges = [
    {label: 'Week 1', data: data.slice(0, 7)},
    {label: 'Week 2', data: data.slice(7, 14)},
    {label: 'Week 3', data: data.slice(14, 21)},
    {label: 'Week 4', data: data.slice(21, 28)},
  ];

  return (
    <Box sx={{display: 'flex', gap: '10px'}}>
      {weekRanges.map((weekData, index) => (
        <WeekButtonGroup
          key={index}
          weekData={weekData}
          data={data}
          userProfile={userProfile}
          completeChallenge={completeChallenge}
        />
      ))}
    </Box>
  );
};

export default WeekSubButton;

const ButtonStyle = {
  height: '10px',
  minWidth: '0px',
  padding: '0px',
  display: 'flex',
  alignItems: 'center',
  width: '20px',
  textTransform: 'none',
  fontSize: '15px',
  color: '#274F66ff',
};
