import React, {useEffect, useState} from 'react';
import ChallengeOption from './ChallengeOption';
import {
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
  useRadioGroup,
} from '@mui/material';
import UISelect from '@components/UISelect';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const days = Array.from({length: 31}, (_, index) => (index + 1).toString());

const currentYear = new Date().getFullYear();
const years = Array.from({length: 5}, (_, index) =>
  (currentYear + index).toString(),
);

export default function FieldsetDuration({value, onChange = () => {}, id}) {
  const radioGroup = useRadioGroup();
  const checked = radioGroup?.value === id;

  const [durationNumber, setDurationNumber] = useState('');
  const [fieldDuration, setFieldDuration] = useState('');
  const [durationValue, setDurationValue] = useState('');
  const [duration, setDuration] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endDurationValue, setEndDurationValue] = useState({
    month: '',
    day: '',
    year: '',
  });

  const handleEndValueChange = (field, event) => {
    setEndDurationValue(prevValue => ({
      ...prevValue,
      [field]: event.target.value,
    }));
  };

  const handleNumberChange = event => {
    const selectedValue = event.target.value;
    setDurationNumber(selectedValue);
  };

  const handleValueChange = event => {
    const selectedValue = event.target.value;
    setDurationValue(selectedValue);
  };
  useEffect(() => {
    if (durationNumber === '3') {
      let data = `${duration} ${durationValue}`;
      const today = new Date();

      const [number, unit] = data.split(' ');
      const numberOfUnits = parseInt(number, 10);

      let endDate;

      if (unit === 'days') {
        endDate = new Date(today.setDate(today.getDate() + numberOfUnits));
      } else if (unit === 'weeks') {
        endDate = new Date(today.setDate(today.getDate() + numberOfUnits * 7));
      } else if (unit === 'months') {
        endDate = new Date(today.setMonth(today.getMonth() + numberOfUnits));
      }
      setFieldDuration(data);
      setStartDate(new Date());
      setEndDate(endDate);
      onChange({startDate: new Date(), endDate});
    } else if (durationNumber === '4') {
      let data = `${endDurationValue.month} ${endDurationValue.day}, ${endDurationValue.year}`;
      const endDate = new Date(data);
      setFieldDuration(data);
      setStartDate(new Date());
      setEndDate(endDate);
      onChange({startDate: new Date(), endDate});
    }
  }, [duration, durationNumber, durationValue, endDurationValue]);

  function ConditionalRendering() {
    if (checked) {
      return (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            gap: '1.5em',
          }}>
          <Box
            sx={{
              width: '35%',
            }}>
            <UISelect
              value={durationNumber}
              onChange={handleNumberChange}
              displayEmpty
              fullHeight>
              <MenuItem value="3">Fixed duration</MenuItem>
              <MenuItem value="4">End date</MenuItem>
            </UISelect>
          </Box>
          {durationNumber === '3' ? (
            <>
              <TextField
                sx={{
                    height: '40px',
                    width: '10%',
                    '& .css-24rejj-MuiInputBase-input-MuiOutlinedInput-input': {
                      padding: '0.5em',
                    },
                  }}
                size="small"
                hiddenLabel
                id="question_challenge"
                variant="outlined"
                type="number"
                inputProps={{min: 0, max: 100}}
                min={0}
                max={100}
                value={duration}
                onChange={event => setDuration(event.target.value)}
              />
              <Box
                sx={{
                  width: '20%',
                }}>
                <UISelect
                  value={durationValue}
                  onChange={handleValueChange}
                  displayEmpty
                  fullHeight>
                  <MenuItem value="days">Days</MenuItem>
                  <MenuItem value="weeks">Weeks</MenuItem>
                  <MenuItem value="months">Months</MenuItem>
                </UISelect>
              </Box>
            </>
          ) : durationNumber === '4' ? (
            <>
              <Box
                sx={{
                  width: '60%',
                  display: 'flex',
                  gap: '1em',
                }}>
                {/* Month */}
                <Box sx={{width: '45%'}}>
                  <UISelect
                    value={endDurationValue.month}
                    onChange={e => handleEndValueChange('month', e)}
                    label="Month"
                    displayEmpty
                    fullHeight>
                    {months.map(month => (
                      <MenuItem key={month} value={month}>
                        {month}
                      </MenuItem>
                    ))}
                  </UISelect>
                </Box>

                {/* Day */}
                <Box sx={{width: '20%'}}>
                  <UISelect
                    value={endDurationValue.day}
                    onChange={e => handleEndValueChange('day', e)}
                    displayEmpty
                    fullHeight>
                    {days.map(day => (
                      <MenuItem key={day} value={day}>
                        {day}
                      </MenuItem>
                    ))}
                  </UISelect>
                </Box>

                {/* Year */}
                <Box sx={{width: '20%'}}>
                  <UISelect
                    value={endDurationValue.year}
                    onChange={e => handleEndValueChange('year', e)}
                    displayEmpty
                    fullHeight>
                    {years.map(year => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </UISelect>
                </Box>
              </Box>
            </>
          ) : (
            ''
          )}
        </Box>
      );
    } else {
      let label = '0';
      let dwm = '';
      if (durationNumber === '3') {
        label = '3';
      }
      if (durationValue === 'daily') {
        dwm = 'Days';
      } else if (durationValue === 'weekly') {
        dwm = 'Weeks';
      } else if (durationValue === 'monthly') {
        dwm = 'Months';
      }
      if (fieldDuration) {
        return (
          <FormControl
            component="fieldset"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 1,
            }}>
            <FormLabel component="legend">{fieldDuration}</FormLabel>
          </FormControl>
        );
      } else {
        return null;
      }
    }
  }
  return (
    <ChallengeOption label="Challenge duration" value={id} help="Tell what will be the duration of the challenge?">
      <ConditionalRendering />
    </ChallengeOption>
  );
}
