import * as React from 'react';
import Box from '@mui/material/Box';
import {TextField} from '@mui/material';

export default function BasicSelect({setTarget,target, checked}) {
  const [selectedValues, setSelectedValues] = React.useState({
    No: null,
    Yes: null,
  });

//   const handleChange = (category, value) => {
//     const complementaryValue = 100 - parseInt(value, 10);

//     setSelectedValues(prevValues => ({
//       ...prevValues,
//       [category]: parseInt(value),
//       [category === 'No' ? 'Yes' : 'No']: complementaryValue,
//     }));
//     setTarget({
//       ...target,
//       [category]: parseInt(value),
//       [category === 'No' ? 'Yes' : 'No']: complementaryValue,
//     });
//   };
const handleChange = (category, value) => {
    // Ensure the value is a valid number
    const parsedValue = parseInt(value, 10);

    // If the value is not a number or exceeds 100, set it to 0
    const clampedValue = isNaN(parsedValue) || parsedValue > 100 ? 0 : parsedValue;

    const complementaryValue = 100 - clampedValue;

    setSelectedValues((prevValues) => ({
      ...prevValues,
      [category]: clampedValue,
      [category === 'No' ? 'Yes' : 'No']: complementaryValue,
    }));
    setTarget({
      ...target,
      [category]: clampedValue,
      [category === 'No' ? 'Yes' : 'No']: complementaryValue,
    });
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1.5em',
        width: '100%',
        '@media (max-width: 1000px)': {
          flexDirection: 'column',
        },
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#E9E9E9',
          borderRadius: '0.3em',
          height: '100%',
          width: '60%',
          '@media (max-width: 1000px)': {
            width: '70%',
            marginTop: '0.5em',
          },
        }}>
        <Box sx={{padding: '3%', width: '40%', textAlign: 'center'}}>No</Box>
        <TextField
          sx={{
            width: '60%',
            backgroundColor: 'white',
            borderRadius: '0.3em',
            height: '100%',
          }}
          size="small"
          hiddenLabel
          id="question_challenge"
          variant="outlined"
          type="number"
          inputProps={{min: 0, max: 100}}
          InputProps={{
            endAdornment: '%',
            min:0,
            max:100
          }}
          value={selectedValues?.No?.toString()}
          onChange={event => handleChange('No', event.target.value)}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#E9E9E9',
          borderRadius: '0.3em',
          height: '100%',
          width: '60%',
          '@media (max-width: 1000px)': {
            width: '70%',
            marginBottom: '0.5em',
          },
        }}>
        <Box sx={{padding: '3%', width: '40%', textAlign: 'center'}}>Yes</Box>
        <TextField
          sx={{
            width: '60%',
            backgroundColor: 'white',
            borderRadius: '0.3em',
            height: '100%',
          }}
          size="small"
          hiddenLabel
          id="question_challenge"
          variant="outlined"
          type="number"
          inputProps={{min: 0, max: 100}}
          min={0}
          max={100}
          InputProps={{
            endAdornment: '%',
          }}
          value={selectedValues?.Yes?.toString()}
          onChange={event => handleChange('Yes', event.target.value)}
        />
      </Box>
    </Box>
  );
}
