import React from 'react';
import DataGrid from '../components/DataGrid';
import Panel from '../components/Panel';
import moment from 'moment';
import {faCommentAlt} from '@fortawesome/free-solid-svg-icons';
import CircleImage from '../components/CircleImage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {HttpRequest} from '../utils/http';
import ActivityIndicator from '../components/ActivityIndicator';
import {Link} from 'react-router-dom';
import {store} from '../store';
import Swal from 'sweetalert2';
import NoData from '../components/NoData';

class ServerSetting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <Panel>
          <NoData>Under construction</NoData>
        </Panel>
      </>
    );
  }
}

const styles = {
  control: {
    marginLeft: 8,
  },
};

export default ServerSetting;
