import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import Avatar from '@mui/material/Avatar';
import {Divider} from '@mui/material';
import UISelect from '@components/UISelect';
import {MenuItem} from '@mui/material';
import BarChartSection from './BarChartSection';
import AnswerSection from './AnswerSection';
import WeekBarSection from './WeekBarSection';
import { categories } from '@utils/mockData';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 510,
  bgcolor: 'background.paper',
  borderRadius: '15px',
  boxShadow: 24,
  maxHeight: "90vh",
  overflowY: 'auto'
};
export default function ChallengeModal2({show, data, userProfile, completeChallenge, setIsShown, week}) {

  const handleOpen = () => setOpen(true);
  const buttonLabel = ['Daily', 'Weekly'];
  const [activeLabelButton, setActiveLabelButton] = useState('Daily');
  const [open, setOpen] = React.useState(show);
  const [selectedWeek, setSelectedWeek] = useState(week || 1);
  const handleButtonClick = buttonLabel => {
    setActiveLabelButton(buttonLabel);
  };
  const handleClose = () => {
    setOpen(false)
    setIsShown(false);
}

  const filteredDays = data?.slice((selectedWeek - 1) * 7, selectedWeek * 7);

  const getCategoryColor = goal => {
    let color = categories.filter(item => item.id === goal);
    return color[0]?.color;
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
        <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: 3,
            }}>
            {' '}
            <div style={{display: 'flex', gap: '20px', alignItems: 'center'}}>
              {' '}
              <Avatar alt="Remy Sharp" src={userProfile?.image} />
              <Typography variant="body2">{`${userProfile?.name} ${userProfile?.last_name}`}</Typography>
            </div>
            <CancelIcon
              style={{fontSize: '30px', color: '#274F66ff', cursor: 'pointer'}}
              onClick={handleClose}
            />
          </Box>
          <Divider />
          <Box sx={{my: 3}}>
            <Box sx={{display: 'flex', px: 3, mb: 2, gap: '10px'}}>
              <div
                style={{
                  height: '25px',
                  width: '25px',
                  borderRadius: '100%',
                  backgroundColor: getCategoryColor(
                    completeChallenge?.challenge_goal?.category,
                  ),
                }}></div>

              <Typography variant="body">
                {completeChallenge?.challenge_goal?.goal}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              px: 3,
              mt: 3,
              justifyContent: 'space-between',
            }}>
            <div className="select-options">
              {buttonLabel.map(data => {
                return (
                  <Button
                    onClick={() => handleButtonClick(data.toString())}
                    sx={{
                      height: '30px',
                      minWidth: '65px',
                      textAlign: 'center',
                      borderRadius: '50px',
                      color: data === activeLabelButton ? 'white' : 'grey',
                      backgroundColor:
                        data === activeLabelButton ? '#274F66ff' : 'white',
                      textTransform: 'none',
                      fontSize: '13px',
                      outline: 'none',
                      padding: '5px 15px',
                    }}>
                    {data}
                  </Button>
                );
              })}
            </div>
            <Box sx={{width: '200px'}}>
            <UISelect
                value={selectedWeek}
                onChange={e => setSelectedWeek(e.target.value)}>
                <MenuItem value={1}>Week 1</MenuItem>
                <MenuItem value={2}>Week 2</MenuItem>
                <MenuItem value={3}>Week 3</MenuItem>
                <MenuItem value={4}>Week 4</MenuItem>
              </UISelect>
            </Box>
          </Box>
          <Box sx={{mt: 4, px: 1}}>
            {activeLabelButton === 'Daily' && <BarChartSection data={filteredDays}  />}
            {activeLabelButton === 'Weekly' && <WeekBarSection data={data}  />}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
