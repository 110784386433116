import {createTheme} from '@mui/material';
import {outlinedInputClasses} from '@mui/material/OutlinedInput';

// https://mui.com/customization/default-theme/

export const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          [`& .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: '#E9E9E9',
          },
        },
        // also works
        // notchedOutline: {
        //   borderColor: 'green',
        //   // '& $notchedOutline': {
        //   //   borderColor: 'green',
        //   // },
        //   // '&:hover $notchedOutline': {
        //   //   borderColor: 'red',
        //   // },
        //   // '&$focused $notchedOutline': {
        //   //   borderColor: 'purple',
        //   // },
        // },
      },
    },
  },
  typography: {
    fontFamily: [
      'Cairo',
      '"Segoe UI"',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      '"Fira Sans"',
      '"Droid Sans"',
      '"Helvetica Neue"',
      'sans-serif',
    ].join(','),
  },
  palette: {
    background: {
      paper: '#FFF',
      checked: '#FAFAFA',
    },
    shadows: [
      '0px 10px 30px rgba(0, 0, 0, 0.05),0px 10px 30px rgba(0, 0, 0, 0.05),0px 10px 30px rgba(0, 0, 0, 0.05)',
    ],
    // b25b8dcdacc827d5d1fb91ce3dff47d946f5e253
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#274F66',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
    },
    darkGray: '#ACB9C2',
    lightGray: '#E9E9E9',
    darkBlue: '#274F66',
    lightBlue: '#0E82C8',
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});
