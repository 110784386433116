import React from 'react';
import DataGrid from '../components/DataGrid';
import Panel from '../components/Panel';
import moment from 'moment';
import {
  faCommentAlt,
  faEdit,
  faEyeSlash,
  faPaste,
  faPlus,
  faSearch,
  faTrash,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import CircleImage from '../components/CircleImage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {HttpRequest} from '../utils/http';
import ActivityIndicator from '../components/ActivityIndicator';
import {Link} from 'react-router-dom';
import {store} from '../store';
import Swal from 'sweetalert2';
import {MILESTONE_NEUTRAL, MILESTONE_POSITIVE} from '../store/constants';
import MilestoneBox from '../components/MilestoneBox';
import MilestoneList from '@components/MilstoneList';

class Milestone extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      milestones: [],

      patientObject: {},

      query: '',
      type: '',
    };
  }

  componentDidMount() {
    let user = store.getState().loginReducer.user;
    if (user) {
      this.loadMilestone();
    }
  }

  loadMilestone() {
    let user = store.getState().loginReducer.user;

    this.setState({isLoading: true});

    HttpRequest.milestoneList(user.user.id)
      .then(res => {
        let milestones = res.data.results;
        // milestones.forEach((milestone) => {
        //     this.loadPatientDetail(milestone.patient);
        // });

        this.setState({isLoading: false, milestones});
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load milestone data.', 'error');
      });
  }

  loadPatientDetail(patient_id) {
    let {patientObject} = this.state;

    if (patientObject[patient_id + ''] == null) {
      HttpRequest.getPatient(patient_id)
        .then(res => {
          let data = res.data.data;

          let patientObject = this.state.patientObject;
          patientObject[patient_id + ''] = data;

          this.setState({patientObject});
        })
        .catch(err => {
          Swal.fire('Error', 'Cannot load patient data.', 'error');
        });
    }
  }

  SearchloadProfile(q) {
    if (q === '') {
      this.loadMilestone();
    }
    const result = this.state.milestones.filter(item =>
      item.title.toLowerCase().includes(q.toLowerCase()),
    );
    this.setState({milestones: result});
  }
  deleteMilestone(id) {
    HttpRequest.deleteMilestone(id)
      .then(() => {
        this.loadMilestone();
        Swal.fire('Success', 'Milestone deleted successfully', 'success');
      })
      .catch(error => {
        Swal.fire('Error', 'Failed to delete milestone', 'error');
      });
  }

  render() {
    return (
      <>
        <Panel>
          <DataGrid
            isLoading={this.state.isLoading}
            filters={[
              {
                type: 'textinput',
                label: 'Search milestone',
                icon: faSearch,
                onChange: text => {
                  this.SearchloadProfile(text);
                },
                className: 'col-sm-12 col-md-12',
              },
            ]}
            columns={[
              {
                label: 'Milestone',
                renderItem: item => {
                  return (
                    <MilestoneBox
                      milestone={item}
                      renderDisplay={() => {
                        return (
                          <div style={{textDecorationLine: 'underline'}}>
                            {item.title}
                          </div>
                        );
                      }}
                      onSuccess={() => {
                        this.loadMilestone();
                      }}
                    />
                  );
                },
              },
              {
                label: 'Posted By',
                renderItem: item => {
                  let patientObject = this.state.patientObject;
                  let selectedPatient = patientObject[item.patient + ''];
                  if (selectedPatient) {
                    return (
                      <>
                        <CircleImage src={item.patient.image} size={30} />
                        <span style={{marginLeft: 5}}>
                          {selectedPatient.user.name}{' '}
                          {selectedPatient.user.last_name}
                        </span>
                      </>
                    );
                  }
                  return item.patient.name;
                },
              },

              {
                label: 'Type',
                renderItem: item => {
                  if (item.status == MILESTONE_POSITIVE) {
                    return 'Positive';
                  } else if (item.status == MILESTONE_NEUTRAL) {
                    return 'Neutral';
                  } else {
                    return 'Negative';
                  }
                },
              },
              {
                label: 'Care',
                renderItem: item => {
                  return item.likes;
                },
              },
              {
                label: 'Date Posted',
                renderItem: item => {
                  return moment(item.created).format('MMM DD');
                },
              },
              {
                label: 'Controls',
                renderItem: item => {
                  const handleDeleteClick = () => {
                    Swal.fire({
                      title: 'Delete Milestone?',
                      text: 'Are you sure you want to delete this milestone?',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonText: 'Delete',
                      cancelButtonText: 'Cancel',
                    }).then(result => {
                      if (result.isConfirmed) {
                        this.deleteMilestone(item.id);
                      }
                    });
                  };
                  return (
                    <>
                      <a
                        href="#"
                        style={styles.control}
                        onClick={handleDeleteClick}>
                        <FontAwesomeIcon icon={faTrash} />
                      </a>
                    </>
                  );
                },
              },
            ]}
            data={this.state.milestones}
          />
        </Panel>

        {/* <MilestoneList data={this.state.milestones} /> */}
      </>
    );
  }
}

const styles = {
  control: {
    marginLeft: 8,
  },
};

export default Milestone;
