import React from 'react';
import DataGrid from '../components/DataGrid';
import Panel from '../components/Panel';
import moment from 'moment';
import {faChevronLeft, faSave} from '@fortawesome/free-solid-svg-icons';
import CircleImage from '../components/CircleImage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {HttpRequest, HttpResponse} from '../utils/http';
import ActivityIndicator from '../components/ActivityIndicator';
import {Link, withRouter} from 'react-router-dom';
import {store} from '../store';
import RichTextEditor from '../components/RichTextEditor';
import Swal from 'sweetalert2';

class UserEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      title: '',
      name: '',
      last_name: '',
      username: '',
      password: '',
      email: '',
      address: '',
      birth_date: '',
      gender: '',
      phone: '',
      security_question: '',
      security_answer: '',
      contact_info: '',
    };

    //

    this.id = props.match?.params?.id;
    this.type = props.match?.params?.type;
  }

  componentDidMount() {
    let user = store.getState().loginReducer.user;
    if (user && this.id != null) {
      this.loadUser();
    }
  }

  loadUser() {
    this.setState({isLoading: true});

    let promise =
      this.type == 'agent'
        ? HttpRequest.getAgent(this.id)
        : HttpRequest.getProvider(this.id);

    promise
      .then(res => {
        let data = res.data.data;

        this.setState({
          isLoading: false,
          name: data.user.name,
          last_name: data.user.last_name,
          username: data.user.username,
          email: data.user.email,
          address: data.address,
          birth_date: data.birth_date,
          gender: data.gender,
          phone: data.phone,
          security_question: data.security_question,
          security_answer: data.security_answer,
          contact_info: data.contact_info,
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load user data.', 'error');
      });
  }

  save() {
    let user = store.getState().loginReducer.user;

    this.setState({isLoading: true});

    let {
      title,
      name,
      last_name,
      username,
      email,
      address,
      birth_date,
      gender,
      phone,
      security_question,
      security_answer,
      contact_info,
      password,
    } = this.state;

    let data = {
      user: {
        username: username,
        email: email,
        name: name,
        last_name: last_name,
      },
      provider: {
        title: title,
        // "default_photo": null,
        department: 1,
      },
      // "image": null,
      birth_date: null,
      address: address,
      contact_info: contact_info,
      phone: phone,
      gender: gender,
      security_question: security_question,
      security_answer: security_answer,
    };

    if (this.id == null) {
      data.user.password = password;
    }

    let promise =
      this.type == 'agent'
        ? HttpRequest.saveAgent(this.id, data)
        : HttpRequest.saveProvider(this.id, data);

    promise
      .then(res => {
        this.setState({
          isLoading: false,
        });
        Swal.fire('Information', this.type + ' saved.', 'success').then(res => {
            this.props.history.push('/user');
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire(
          'Error',
          HttpResponse.processError(
            err.response,
            'Cannot update profile data.',
          ),
          'error',
        );
      });
  }

  render() {
    if (this.state.isLoading) {
      return <ActivityIndicator />;
    }

    return (
      <>
        <div className="flex-row" style={{marginBottom: 10}}>
          <Link to="/user" className="btn btn-secondary btn-round">
            <FontAwesomeIcon icon={faChevronLeft} /> Back to agents/provider
          </Link>
          <div className="flex-column flex" />
          <a
            href="#"
            className="btn btn-warning btn-round"
            onClick={e => {
              e.preventDefault();
              this.save();
            }}>
            <FontAwesomeIcon icon={faSave} /> Save
          </a>
          <Link
            to="/user"
            className="btn btn-primary btn-round"
            style={{marginLeft: 10}}>
            Cancel
          </Link>
        </div>
        <Panel>
          <form>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Name</label>
              <div className="col-sm-3">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.name}
                                readOnly={true}
                  onChange={event => {
                    this.setState({name: event.target.value});
                  }}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Last Name</label>
              <div className="col-sm-3">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.last_name}
                                readOnly={true}
                  onChange={event => {
                    this.setState({last_name: event.target.value});
                  }}
                />
              </div>
            </div>

            {this.id == null && (
              <>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">Email</label>
                  <div className="col-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.email}
                      onChange={event => {
                        this.setState({
                          email: event.target.value,
                          username: event.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">Password</label>
                  <div className="col-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.password}
                      onChange={event => {
                        this.setState({password: event.target.value});
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            {this.id != null && (
              <>
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">Username</label>
                  <div className="col-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      readOnly={true}
                      value={this.state.username}
                      onChange={event => {
                        this.setState({username: event.target.value});
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">Email</label>
                  <div className="col-sm-3">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.email}
                      onChange={event => {
                        this.setState({email: event.target.value});
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Phone</label>
              <div className="col-sm-3">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.phone}
                                readOnly={true}
                  onChange={event => {
                    this.setState({phone: event.target.value});
                  }}
                />
              </div>
            </div>

          </form>
        </Panel>
      </>
    );
  }
}

const styles = {
  control: {
    marginLeft: 8,
  },
};

export default withRouter(UserEdit);
