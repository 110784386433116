import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {Chip} from '@mui/material';
import {green} from '@mui/material/colors';

export default function LimitTagAutoComplete({
  tagData,
  handleTagsChange,
  value,
  label,
  onBlur,
}) {
  if (value) {
    let matchedTagData = [];
    let filteredTagData = [];

    if (label === 'Patients') {
      matchedTagData = tagData.filter(tag =>
        value?.some(item => item?.user?.id === tag?.user?.id),
      );

      filteredTagData = tagData.filter(
        tag => !value?.some(item => item?.user?.id === tag?.user?.id),
      );
    } else {
      matchedTagData = tagData.filter(tag => value?.includes(tag?.name));
      filteredTagData = tagData.filter(tag => !value?.includes(tag?.name));
    }

    return (
      <>
        <Autocomplete
          multiple
          limitTags={2}
          id="multiple-limit-tags"
          options={tagData}
          getOptionLabel={option => option.name || option.user.name + " " + option.user.last_name}
          defaultValue={matchedTagData}
          renderInput={params => (
            <TextField {...params} placeholder={label ? label : 'Tags'} />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={index}
                label={option.name || option.user.name}
                {...getTagProps({index})}
                sx={{
                  backgroundColor: green[500],
                  color: 'white',
                  '&:hover': {
                    backgroundColor: green[700],
                  },
                }}
              />
            ))
          }
          sx={{width: 'auto'}}
          onChange={handleTagsChange}
          onBlur={onBlur}
        />
      </>
    );
  } else {
    let matchedTagData = [];
    let filteredTagData = [];

    if (label === 'Patients') {
      matchedTagData = tagData.filter(tag =>
        value?.some(item => item?.user?.id === tag?.user?.id),
      );

      filteredTagData = tagData.filter(
        tag => !value?.some(item => item?.user?.id === tag?.user?.id),
      );
    } else {
      matchedTagData = tagData.filter(tag => value?.includes(tag?.name));
      filteredTagData = tagData.filter(tag => !value?.includes(tag?.name));
    }
    return (
      <>
        <Autocomplete
          multiple
          limitTags={2}
          id="multiple-limit-tags"
          options={tagData}
          getOptionLabel={option => option.name || option.user.name + " " + option.user.last_name}
          renderInput={params => (
            <TextField {...params} placeholder={label ? label : 'Tags'} />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={index}
                label={option.name || option.user.name}
                {...getTagProps({index})}
                sx={{
                  backgroundColor: green[500],
                  color: 'white',
                  '&:hover': {
                    backgroundColor: green[700],
                  },
                }}
              />
            ))
          }
          sx={{width: 'auto'}}
          onChange={handleTagsChange}
        />
      </>
    );
  }
}
