export const categories = [
  {
    id: 4,
    label: 'Health',
    idx: 0,
    color: '#FFE68F',
    textColor: '#FAC300',
  },
  {
    id: 1,
    label: 'Family',
    idx: 1,
    color: '#8FD7FF',
    textColor: '#62B2DF',
  },
  {
    id: 2,
    label: 'Community',
    idx: 2,
    color: '#87F1DE',
    textColor: '#67DBC6',
  },
  {
    id: 3,
    label: 'YGBRConnect App',
    // idx: 3,
    idx: -1,
    color: '#87F191',
    textColor: '#87F191',
  },

  {
    id: 5,
    label: 'Finance',
    idx: 4,
    color: '#FFA48F',
    textColor: '#FFA48F',
  },
  {
    id: 6,
    label: 'Employment',
    idx: 5,
    color: '#8791F1',
    textColor: '#8791F1',
  },
  {
    id: 7,
    label: 'Environment',
    idx: 6,
    color: '#CF6CB9',
    textColor: '#CF6CB9',
  },
  {
    id: 8,
    label: 'Self Care',
    idx: 7,
    color: '#87F1DE',
    textColor: '#87F1DE',
  },
];

const getProgressByType = {
  boolean: challenge => (challenge.progress.current ? 100 : 0),
  number: challenge =>
    Math.round((challenge.progress.current * 100) / challenge.progress.total),
  multiple: challenge =>
    Math.round((challenge.progress.current * 100) / challenge.progress.total),
};

const getMessageByType = {
  boolean: (challenge, scaleDetail) => {
    const freq = {
      d: 'today',
      w: 'this week',
      m: 'this month',
    };
    if (challenge.progress.current) {
      return `Completed ${freq[challenge.frequency]}!`;
    }
    return "Let's do this!";
  },
  number: (challenge, scaleDetail) => {
    const {current, total} = challenge.progress;
    const isPlural = total > 0 ? 1 : 0;
    const unity = scaleDetail.unity[isPlural];
    const result = `${current} of ${total} ${unity}`;
    return result;
  },
};
// not being used
export const getProgressMessage = challenge => {
  const scaleDetail = getScaleDetail(challenge);

  const result = getMessageByType[scaleDetail.type](challenge, scaleDetail);
  return result;
};

export const getProgressPercentage = challenge => {
  const scaleDetail = getScaleDetail(challenge);
  const result = getProgressByType[scaleDetail.type](challenge);
  return result;
};

const getScaleDetail = challenge => scaleDetailDefs[challenge.scaleType];
export const scaleDetailDefs = {
  yes_no: {
    type: 'boolean',
    unity: ['Yes', 'No'],
  },
  faces: {
    type: 'multiple',
    unity: ['sad', 'neu', 'hap'],
  },
  steps: {
    type: 'number',
    unity: ['step', 'steps'],
  },
  count: {
    type: 'number',
    unity: ['count', 'counts'],
  },
  pounds: {
    type: 'number',
    unity: ['lb', 'lbs'],
  },
};

export const todayDate = '2021-10-22';
export const pointsMap = [18, 20, 24];

export const serverConfigData = {
  todayDate,
  pointsMap,
};

/**
 * pointsMap: 0, -> low
 * pointsMap: 1, -> medium
 * pointsMap: 2, -> high
 *
 * yCoins recieved for challenges:
 * https://www.figma.com/file/gDug3i9fDWH22nnMq9vsfF/Yellowstone-youth-app?node-id=948%3A396
 */

export const userEntryData = {
  faces: [
    {date: '2021-10-21', answer: 'neu'},
    {date: '2021-10-20', answer: 'hap', journal: 'it was nice'},
    {date: '2021-10-19', answer: 'neu'},
    {date: '2021-10-18', answer: 'sad'},
    {date: '2021-10-17', answer: 'sad'},
    {date: '2021-10-16', answer: 'hap'},
    {date: '2021-10-15', answer: 'hap'},
    {date: '2021-10-14', answer: 'neu'},
    {date: '2021-10-13', answer: 'sad'},
  ],
  yes_no: [
    {date: '2021-10-21', answer: 'yes'},
    {date: '2021-10-20', answer: 'yes', journal: 'it was nice'},
    {date: '2021-10-19', answer: 'yes'},
    {date: '2021-10-18', answer: 'no'},
    {date: '2021-10-17', answer: 'no'},
    {date: '2021-10-16', answer: 'yes'},
    {date: '2021-10-15', answer: 'yes'},
    {date: '2021-10-14', answer: 'yes'},
    {date: '2021-10-13', answer: 'no'},
  ],
  '0004': [
    {date: '2021-10-21', answer: 8},
    {date: '2021-10-20', answer: 6, journal: 'it was nice'},
    {date: '2021-10-19', answer: 2},
    {date: '2021-10-18', answer: 2},
    {date: '2021-10-17', answer: 0},
  ],
  '0005': [
    {date: '2021-10-21', answer: 8},
    {date: '2021-10-20', answer: 8, journal: 'it was niceeee'},
    {date: '2021-10-19', answer: 10},
    {date: '2021-10-18', answer: 4},
    {date: '2021-10-17', answer: 2},
    {date: '2021-10-16', answer: 4},
    {date: '2021-10-15', answer: 8},
    {date: '2021-10-14', answer: 9},
    {date: '2021-10-13', answer: 10},
  ],
};

export const getTimeSectionConfig = timeSectionConfig => {
  const [stepRange, ...timeSelect] = timeSectionConfig.split('');

  const hasDailySelect = timeSelect.includes('d');
  const hasWeeklySelect = timeSelect.includes('w');

  return {
    stepRange,
    hasDailySelect,
    hasWeeklySelect,
  };
};

const challengesTypesExamples = [
  {
    id: '0001',
    categoryId: 'ygbrconnect',
    title: 'Daily Challenge - faces',
    question: 'challenge question',

    frequency: 'dailyFreq',
    timeSectionConfig: 'dd',
    points: 0,

    scale: 'faces',
    scaleType: 'faces',

    startDay: '2021-10-12',
    progress: {
      current: 4800,
      total: 6000, // challenge duration
    },
  },
  {
    id: '0002',
    categoryId: 'ygbrconnect',
    title: 'Daily Challenge - yes_no with-link',
    question: 'challenge question',

    frequency: 'dailyFreq',
    timeSectionConfig: 'dd',
    points: 0,

    scale: 'yes_no',
    scaleType: 'yes_no',

    startDay: '2021-10-12',
    progress: {
      current: true,
    },

    extra: {
      link: {
        src: 'http://www.quizzaddress.com/quizzname',
        title: 'Complete quizz',
        label: 'quizzaddress.com',
      },
    },
  },
  {
    id: '0003',
    categoryId: 'ygbrconnect',
    title: 'Weekly Challenge - yes_no',
    question: 'goal: more_than 60% yes',

    frequency: 'weeklyFreq',
    timeSectionConfig: 'wdw',
    points: 0,

    scale: 'yes_no',
    scaleType: 'yes_no',
    goal: {
      scale: 'yes_no',
      name: 'yes',
      comparison_choice: 'more_than',
      value: 60,
    },

    startDay: '2021-10-12',
    progress: {
      current: true,
    },
  },
  {
    id: '0004',
    categoryId: 'ygbrconnect',
    title: 'Monthly Challenge - number (line)',
    question: 'challenge question',

    frequency: 'monthlyFreq',
    timeSectionConfig: 'mdw',
    points: 0,

    scale: 'number',
    scaleType: 'pounds',

    goal: {
      scale: 'number',
      name: 'Lbs',
      comparison_choice: 'more_than',
      value: 0,
    },

    // startDay: "2021-10-12",
    startDay: '2021-10-17',
    progress: {
      current: 4850,
      total: 6000,
    },
  },
  {
    id: '0005',
    categoryId: 'ygbrconnect',
    title: 'Daily Challenge - number (bar)',
    question: 'goal: Glasses | more_than 8',

    frequency: 'monthlyFreq',
    timeSectionConfig: 'dd',
    points: 0,

    scale: 'number',
    scaleType: 'count',

    goal: {
      scale: 'number',
      name: 'Glasses',
      comparison_choice: 'more_than',
      value: 8,
    },

    startDay: '2021-10-12',
    progress: {
      current: 4850,
      total: 6000,
    },
  },
];

const compType = {
  more_than: (answer, value) => answer >= value,
  less_than: (answer, value) => answer <= value,
  equal: (answer, value) => answer === value,
};
// goal: {
//     scale: "yes_no",
//     name: "hap",
//     comparison_choice: "more_than",
//     value: 75,
// },
export const isGoalAccomplished = (goal, answers) => {
  const { scale, name, comparison_choice, value } = goal;
  const n = answers?.length;

  if (n === 1) {
    if (scale === 'number') {
      // Assuming compType is a valid object with comparison functions
      return compType[comparison_choice](answers[0], value);
    }
  } else if (n > 1) {
      const goalCount = answers.filter((ans) => ans === name).length;
    if (scale !== 'number') {
      // Assuming there was a missing opening curly brace here
      return {
        goalCount,
        n,
        value,
        perc: (100 * goalCount) / n,
      };
    }
    // Assuming there was a missing opening curly brace here
    return (100 * goalCount) / n >= value;
  }
  // Assuming there was a missing closing curly brace here
};

// const styleOptions = ['sad', 'neu', 'hap', 'yes', 'no', 'none']
export const goalAccomplishedStyle = (goal, answers) => {
  const n = answers?.length;
  if (n === 1) {
    if (goal.scale === 'number') {
      return isGoalAccomplished(goal, answers) ? 'yes' : 'none';
    }
    return answers[0];
  } else if (n > 1) {
    return isGoalAccomplished(goal, answers) ? goal.name : 'none';
  }
};

export const challenges = [
  {
    id: '9239',
    categoryId: 'community',
    title: 'Start being more involved in your community',
    question: 'Did you help out your neighbour?',

    frequency: 'dailyFreq',
    timeSectionConfig: 'dd',
    points: 0,

    scale: 'yes_no',
    scaleType: 'yes_no',

    startDay: '2021-10-12',
    progress: {
      current: true,
    },
  },
  {
    id: '9239',
    categoryId: 'health',
    title: 'Walk 6000 steps per day',
    question: 'Did you walked 6000 steps today?',

    frequency: 'dailyFreq',
    timeSectionConfig: 'dd',
    points: 0,
    status: 'red',

    scale: 'number',
    scaleType: 'steps',

    startDay: '2021-10-12',
    progress: {
      current: 4850,
      total: 6000,
    },
  },
  {
    id: '9229',
    categoryId: 'health',
    title: 'Keep my lungs clean',
    question: 'Is my lungs clean today?',
    // question: "I have my lungs clean today?",

    frequency: 'dailyFreq',
    timeSectionConfig: 'dd',
    points: 0,

    scale: 'yes_no',
    scaleType: 'yes_no',

    startDay: '2021-10-12',
    progress: {
      current: true,
    },
  },
  {
    id: '9219',
    categoryId: 'health',
    title: 'Loose 10 pounds',
    question: 'What is my current weight?',

    frequency: 'dailyFreq',
    timeSectionConfig: 'dd',
    points: 0,
    status: 'new',

    scale: 'number',
    scaleType: 'pounds',

    startDay: '2021-10-12',
    progress: {
      current: 3.4,
      total: 10,
    },
  },
  {
    id: '1212',
    categoryId: 'family',
    title: 'Call a family member daily',
    question: 'Did I call my family today?',

    frequency: 'dailyFreq',
    timeSectionConfig: 'dd',
    points: 0,

    scale: 'yes_no',
    scaleType: 'yes_no',

    startDay: '2021-10-12',
    progress: {
      current: false,
    },
  },
  {
    id: '12122',
    categoryId: 'family',
    title: 'Call a family member daily',
    question: 'Did I call my family today?',
    frequency: 'dailyFreq',
    timeSectionConfig: 'dd',
    points: 0,
    scale: 'yes_no',
    scaleType: 'yes_no',
    startDay: '2021-10-12',
    progress: {current: false},
  },
  {
    id: '12123',
    categoryId: 'family',
    title: 'Call a family member daily',
    question: 'Did I call my family today?',
    frequency: 'dailyFreq',
    timeSectionConfig: 'dd',
    points: 0,
    scale: 'yes_no',
    scaleType: 'yes_no',
    startDay: '2021-10-12',
    progress: {current: false},
  },
  {
    id: '12124',
    categoryId: 'family',
    title: 'Call a family member daily',
    question: 'Did I call my family today?',
    frequency: 'dailyFreq',
    timeSectionConfig: 'dd',
    points: 0,
    scale: 'yes_no',
    scaleType: 'yes_no',
    startDay: '2021-10-12',
    progress: {current: false},
  },
  {
    id: '12125',
    categoryId: 'family',
    title: 'Call a family member daily',
    question: 'Did I call my family today?',
    frequency: 'dailyFreq',
    timeSectionConfig: 'dd',
    points: 0,
    scale: 'yes_no',
    scaleType: 'yes_no',
    startDay: '2021-10-12',
    progress: {current: false},
  },
  ...challengesTypesExamples,
];

export const notificationTemplate = [ 
    {
        id: 1,
        name: "Missed Check-ins"
    },
    {
        id: 2,
        name: "Percentage of Sad/Neutral/Happy"
    },
    {
        id: 3,
        name: "Time Spent"
    },
    {
        id: 4,
        name: "Weight"
    },
    {
        id: 5,
        name: "Enter a value"
    },
]
