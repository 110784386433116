import React from 'react';
import Loading from '../assets/loading.gif';

export default function ActivityIndicator({size}) {
  if (size == null) {
    size = 'medium';
  }

  let width = 15;

  if (size == 'small') {
    width = 15;
  } else if (size == 'medium') {
    width = 30;
  } else if (size == 'large') {
    width = 45;
  }

  return <img src={Loading} style={{width: width, height: width}} />;
}

const styles = {};
