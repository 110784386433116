import {Box, Tooltip} from '@mui/material';
import React from 'react';
import {LineChart, Line, YAxis, CartesianGrid} from 'recharts';
import {ReactComponent as NotesIcon} from './../../assets/icons/notes.svg';
import styled from '@emotion/styled';

const LineChartSection = ({data}) => {
  const convertedData = data?.map(item => ({
    ...item,
    value: parseFloat(item?.data?.value),
  }));
  
  return (
    <>
      <LineChart width={480} height={150} data={convertedData}>
        <Line
          dataKey="value"
          stroke="#9CD272"
          strokeWidth={3}
          dot={{strokeWidth: 2, r: 6}}
        />
        <CartesianGrid stroke="#ccc" vertical={false} />
        <YAxis
          axisLine={false}
          tickLine={false}
        //   ticks={[0, 2000, 4000, 6000]}
        />
      </LineChart>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 2,
          ml: 7,
        }}>
        {data.map((data, index) => {
          return (
            <Box key={index}>
              <Box sx={{mt: 2, textAlign: 'center'}}>
              <Tooltip title={data?.data?.journal_activity} arrow>
                  <NotesIcon
                    style={{
                      fill: data?.data?.journal_activity ? '#274F66' : '#ACB9C2',
                      cursor: data?.data?.journal_activity ? 'pointer' : '',
                    }}
                  />
                </Tooltip>
              </Box>
              <Text today={data?.today}>{data?.day?.charAt(0)}</Text>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default LineChartSection;

const Text = styled.div`
  margin-top: 5px;
  width: 25px;
  height: 25px;
  font-size: 14px;
  display: flex;
  alignitems: center;
  border-radius: 50%;
  justify-content: center;
  border-width: 2px;
  border-color: ${props => (props.today ? '#274F66' : 'transparent')};
  border-style: solid;
`;
