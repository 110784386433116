import React, {useEffect, useCallback, useState} from 'react';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Paper} from '@mui/material';
import Row from './PatientTableRow';
import {HttpRequest} from '@utils/http';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import {Box} from '@mui/material';
import UISelect from '@components/UISelect';
import ActivityIndicator from '@components/ActivityIndicator';
import './PatientChallengesHead.css';
import './Table.css';
import Swal from 'sweetalert2';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCaretLeft,
  faCaretRight,
  faSort,
} from '@fortawesome/free-solid-svg-icons';
import {useSelector} from 'react-redux';

export default function PatientChallengesList() {
  const user = useSelector(state => state.loginReducer.user);

  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedChallenge, setSelectedChallenge] = useState('All');
  const [selectedProvider, setSelectedProvider] = useState('All');
  const [userChallenges, setUserChallenges] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [countPages, setCountPages] = useState(1);
  const [nextPage, setNextPage] = useState(null);
  const [previusPage, setPreviousPage] = useState(null);
  const [providers, setProviders] = useState([]);
  const [challengesGoal, setChallengesGoal] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);

  const loadUserChallenges = useCallback(
    offset => {
      setIsLoading(true);
      HttpRequest.getUserChallenges(offset, user?.user?.id)
        .then(res => {
          let data = res.data.results;
          let nextPage = res.data.next;
          const nextPageNumber = nextPage;
          const count = res.data.count;
          const roundedPage = Math.ceil(count / 9);

          setUserChallenges(data);
          setFilteredPatients(data);
          setNextPage(nextPageNumber);
          setPreviousPage(res.data.previous);
          setCountPages(roundedPage);
          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
          Swal.fire('Error', 'Cannot load User Challenges', 'error');
        });
    },
    [selectedProvider],
  );

  const loadChallengesGoal = useCallback(() => {
    setIsLoading(true);
    HttpRequest.getChallengesGoal()
      .then(res => {
        let data = res.data.results;
        setChallengesGoal(data);
      })
      .catch(err => {
        Swal.fire('Error', 'Cannot load Challenges', 'error');
      });
  }, []);

  const loadProviders = useCallback(() => {
    setIsLoading(true);
    HttpRequest.providerList()
      .then(res => {
        let data = res.data.results;
        setProviders(data);
      })
      .catch(err => {
        Swal.fire('Error', 'Cannot load Providers', 'error');
      });
  }, []);

  useEffect(() => {
    loadUserChallenges();
  }, []);
  useEffect(() => {
    loadUserChallenges();
    loadProviders();
    loadChallengesGoal();
    document.getElementsByTagName('iframe')[0]?.remove();
  }, []);
  const filterPatients = () => {
    const sanitizedSearchTerm = searchText?.toLowerCase().replace(/\s+/g, '');
  
    const filteredData = userChallenges.filter(data => {
      const sanitizedUserName =
        `${data?.user?.name}${data?.user?.last_name}`
          ?.toLowerCase()
          ?.replace(/\s+/g, '');
  
      const nameMatch = sanitizedUserName?.includes(sanitizedSearchTerm);
  
      const challengeMatch =
        selectedChallenge === 'All' ||
        data?.all_challenges.some(
          item => item?.challenge?.challenge_goal?.id === selectedChallenge
        );
      const providerMatch =
        selectedProvider === 'All' ||
        data?.all_challenges.some(
          item => item?.challenge?.provider === selectedProvider
        );
  
      return nameMatch && challengeMatch && providerMatch;
    });
  
    setFilteredPatients(filteredData);
  };
  

  useEffect(() => {
    filterPatients();
  }, [searchText, selectedChallenge, selectedProvider]);

  const loadPage = offset => {
    setCurrentPage(offset);
    loadUserChallenges(offset, selectedProvider);
  };

  const loadNextPage = () => {
    if (nextPage !== null) {
      loadPage(currentPage + 9);
    }
    setSearchText('');
    setSelectedChallenge('All');
    setSelectedProvider('All');
  };

  const loadPrevPage = () => {
    if (currentPage > 1) {
      loadPage(currentPage - 9);
    }
    setSearchText('');
    setSelectedChallenge('All');
    setSelectedProvider('All');
  };

  const [sortConfig, setSortConfig] = useState({
    key: '',
    direction: '',
  });

  const requestSort = key => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    sortData(key, direction);
  };
  
  const sortData = (key, direction) => {
    const sortedPatients = [...filteredPatients];
    sortedPatients.sort((a, b) => {
      let valueA, valueB;
  
      if (key === 'user') {
        valueA = a.user.name;
        valueB = b.user.name;
      } else if (key === 'all_challenges') {
        valueA = a[key].length;
        valueB = b[key].length;
      } else if (key === 'status') {
        const statusOrder = { done: 1, pending: 0 };
        valueA = statusOrder[a[key]];
        valueB = statusOrder[b[key]];
      } else if (key === 'activity') {
        // Sort based on the length of filtered challenges with progress !== 0
        valueA = a.all_challenges.filter(item => item.progress !== 0).length;
        valueB = b.all_challenges.filter(item => item.progress !== 0).length;
      } else {
        valueA = a[key];
        valueB = b[key];
      }
  
      // Convert both values to strings for comparison
      const stringValueA = String(valueA);
      const stringValueB = String(valueB);
  
      if (direction === 'asc') {
        return stringValueA.localeCompare(stringValueB);
      } else {
        return stringValueB.localeCompare(stringValueA);
      }
    });
  
    setFilteredPatients(sortedPatients);
  };
  
  
  const deleteUserChallenge = id => {
    HttpRequest.deleteUserChallenge(id)
      .then(() => {
        Swal.fire('Success', 'Delete user challenge', 'success');
        loadUserChallenges(currentPage);
      })
      .catch(err => {
        Swal.fire('Error', 'Cannot delete user challenge', 'error');
      });
  };

  const reAssignUserChallenge = data => {
    HttpRequest.deleteUserChallenge(data?.id)
      .then(() => {
        let raw = {patient: data?.patient, challenge: data?.challenge};
        HttpRequest.assignChallenge(raw).then(() => {});
        Swal.fire('Success', 'Re-assigned user challenge', 'success');
        loadUserChallenges(currentPage);
      })
      .catch(err => {
        Swal.fire('Error', 'Cannot re-assign user challenge', 'error');
      })
      .catch(err => {
        Swal.fire('Error', 'Cannot re-assign user challenge', 'error');
      });
  };

  return (
    <TableContainer component={Paper} sx={{boxShadow: 'none'}}>
      <Box sx={{p: 1}}>
        <h4 className="main-heading">All Patients</h4>
        <div className="top-section">
          <div className="search-section">
            <label htmlFor="search" className="label-style">
              Search contacts
            </label>
            <div className="search-div">
              <SearchIcon sx={{fontSize: '18px'}} />
              <input
                type="text"
                id="search-input"
                placeholder="Enter name"
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
              />
            </div>
          </div>
          <div className="top-section-left">
            <div className="challenge-section">
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  gap: '10%',
                  alignItems: 'center',
                }}>
                <label htmlFor="search" className="label-style">
                  Challenges
                </label>
                <UISelect
                  value={selectedChallenge}
                  onChange={e => setSelectedChallenge(e.target.value)}>
                  <MenuItem value={'All'}>All</MenuItem>
                  {challengesGoal.map(item => (
                    <MenuItem value={item?.id}>{item?.goal}</MenuItem>
                  ))}
                </UISelect>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  gap: '10%',
                  alignItems: 'center',
                }}>
                <label htmlFor="search" className="label-style">
                  Provider
                </label>
                <UISelect
                  value={selectedProvider}
                  onChange={e => setSelectedProvider(e.target.value)}>
                  <MenuItem value={'All'}>All</MenuItem>
                  {providers.map(item => (
                    <MenuItem value={item?.user?.id}>
                      {item?.user?.name}
                    </MenuItem>
                  ))}
                </UISelect>
              </Box>
            </div>
          </div>
        </div>
      </Box>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow sx={{display: 'flex', flexDirection: 'row'}}>
            <StyledTableCell
              sx={{...styles.sorting, minWidth: '550px'}}
              onClick={() => requestSort('user')}>
              User
              <FontAwesomeIcon style={{marginTop: '5px'}} icon={faSort} />
            </StyledTableCell>
            <StyledTableCell
              sx={{...styles.sorting, width: '150px'}}
              onClick={() => requestSort('all_challenges')}>
              Challenges
              <FontAwesomeIcon style={{marginTop: '5px'}} icon={faSort} />
            </StyledTableCell>
            <StyledTableCell
              sx={{...styles.sorting, width: '150px'}}
              onClick={() => requestSort('activity')}>
              Activity
              <FontAwesomeIcon style={{marginTop: '5px'}} icon={faSort} />
            </StyledTableCell>
            <StyledTableCell
              sx={{...styles.sorting, width: '130px'}}
              onClick={() => requestSort('status')}>
              Completed
              <FontAwesomeIcon style={{marginTop: '5px'}} icon={faSort} />
            </StyledTableCell>
            <StyledTableCell sx={styles.sorting}>Action</StyledTableCell>
            <StyledTableCell sx={{width: '100%'}}></StyledTableCell>

          </TableRow>
        </TableHead>

        {isLoading ? (
          <ActivityIndicator />
        ) : filteredPatients.length === 0 ? (
          <TableRow>
            <TableCell colSpan={5} align="center">
              No data found
            </TableCell>
          </TableRow>
        ) : (
          <TableBody>
            {filteredPatients.map(data => (
              <Row
                key={data.id}
                row={data}
                deleteUserChallenge={deleteUserChallenge}
                reAssignUserChallenge={reAssignUserChallenge}
              />
            ))}
          </TableBody>
        )}
      </Table>
      {/* Pagination */}
      <div className="d-flex justify-content-end my-2 mr-2">
        <FontAwesomeIcon
          onClick={!previusPage ? null : loadPrevPage}
          icon={faCaretLeft}
          style={{
            ...styles.pagination,
            color: !previusPage ? '#ACB9C2' : '#274F66',
          }}
          disabled={previusPage}
        />
        Page {Math.ceil(currentPage) / 9 + 1} of {countPages}
        <FontAwesomeIcon
          onClick={!nextPage ? null : loadNextPage}
          icon={faCaretRight}
          style={{
            ...styles.pagination,
            color: !nextPage ? '#ACB9C2' : '#274F66',
          }}
          disabled={nextPage}
        />
      </div>
    </TableContainer>
  );
}

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#C7CED5ff',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: '500',
    padding: '12px 20px',
    cursor: 'pointer',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '',
  },
}));
const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'grey',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const styles = {
  control: {
    marginLeft: 8,
    cursor: 'pointer',
  },
  pagination: {
    height: 24,
    width: 28,
    cursor: 'pointer',
    fontSize: '26px',
  },
  sorting: {display: 'flex', gap: '15px'},
};
