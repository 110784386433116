import React from 'react';
import DataGrid from '../components/DataGrid';
import Panel from '../components/Panel';
import moment from 'moment';
import {faChevronLeft, faSave} from '@fortawesome/free-solid-svg-icons';
import CircleImage from '../components/CircleImage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {HttpRequest, HttpResponse} from '../utils/http';
import ActivityIndicator from '../components/ActivityIndicator';
import {Link, withRouter} from 'react-router-dom';
import {store} from '../store';
import RichTextEditor from '../components/RichTextEditor';
import Swal from 'sweetalert2';
import LimitTagAutoComplete from '../components/LimitTagAutoComplete';

import eye from '../assets/icons/eye.svg';

class PatientEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      title: '',
      name: '',
      last_name: '',
      username: '',
      password: '',
      email: '',
      address: '',
      birth_date: '',
      gender: '',
      phone: '',
      security_question: '',
      security_answer: '',
      contact_info: '',
      tags: '',
      tagdata: [],
      selectedTags: [],
      errorMessage: '',
      patientId: null,
      showPassword: false,
      phoneNumberValid: true,
      today: new Date().toISOString().split('T')[0],
    };

    this.id = props.match?.params?.id;
    this.formRef = React.createRef();
    if (this.id == null) {
      this.editable = true;
    } else {
      this.editable = false;
    }
  }

  componentDidMount() {
    this.loadTagData();
    let user = store.getState().loginReducer.user;
    if (user && this.id != null) {
      this.loadUser();
    }
  }

  handlePhoneNumberChange = event => {
    const phone = event.target.value;
    const phoneNumberValid = this.validatePhoneNumber(phone);

    if (/^[0-9-]*$/.test(phone)) {
      this.setState({phone, phoneNumberValid});
    }
  };
  assignProvider(patient) {
    let user = store.getState().loginReducer.user;
    let data = {
      providers: [parseInt(user.user.id)],
    };
    HttpRequest.saveAssignedProvider(patient, data)
      .then(res => {
        let data = res.data.results;
      })
      .catch(err => {});
  }

  validatePhoneNumber = phone => {
    const regex = /^1\d{3}\d{3}\d{4}$/;
    return regex.test(phone);
  };
  isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  loadProfile(q) {
    let user = store.getState().loginReducer.user;

    this.setState({isLoading: true});

    HttpRequest.getProfile(this.id)
      .then(res => {})
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load patient data.', 'error');
      });
  }

  loadUser() {
    this.setState({isLoading: true});

    HttpRequest.getProfile(this.id)
      .then(res => {
        let data = res.data.results[0];
        this.setState({
          isLoading: false,
          patientId: data.id,
          name: data.user.name,
          last_name: data.user.last_name,
          username: data.user.username,
          email: data.user.email,
          address: data.address,
          birth_date: data.birth_date,
          gender: data.gender,
          phone: data.phone,
          security_question: data.security_question,
          security_answer: data.security_answer,
          contact_info: data.contact_info,
          tags: data.tags?.join(','),
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load user data.', 'error');
      });
  }

  save() {
    let user = store.getState().loginReducer.user;

    this.setState({isLoading: true});

    let {
      title,
      name,
      last_name,
      password,
      tags,
      email,
      address,
      birth_date,
      gender,
      phone,
      security_question,
      security_answer,
      contact_info,
      username,
    } = this.state;

    let data = {
      user: {
        username: username,
        name: name,
        last_name: last_name,
        birth_date: birth_date,
      },
      tags: this.state.selectedTags,
      address: address,
      contact_info: contact_info,
      phone: phone,
      gender: gender,
      security_question: security_question,
      security_answer: security_answer,
      last_contact: moment().format('YYYY-MM-DD'),
      last_activity: new Date(),
    };

    if (this.id == null) {
      data.user.username = username;
      data.user.password = password;
      data.user.email = email;
    }

    if (password != '') {
      data.user.password = password;
    }

    HttpRequest.createPatient(this.state.patientId, data)
      .then(res => {
        this.setState({
          isLoading: false,
        });
        this.assignProvider(res.data.id);
        Swal.fire('Information', 'Patient data saved.', 'success').then(res => {
          // window.location = "/patient";
          this.props.history.push('/patient');
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire(
          'Error',
          HttpResponse.processError(
            err.response,
            'Cannot update profile data.',
          ),
          'error',
        );
      });
  }

  loadTagData() {
    HttpRequest.getallTag()
      .then(res => {
        let data = res.data.data;
        this.setState({
          tagdata: data,
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load tags data.', 'error');
        console.log("Hello", err);
      });
  }
  // LimitTagAutoComplete Function for getting selected tags array
  handleTagsChange = (event, value) => {
    this.setState({
      selectedTags: value.map(option => option.name),
    });
  };
  handlePassword = event => {
    let newPassword = event.target.value;
    this.setState({password: newPassword});

    // Validate password
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /\d/;
    const minLength = 8; // Minimum length requirement

    if (
      newPassword.length < minLength ||
      !uppercaseRegex.test(newPassword) ||
      !lowercaseRegex.test(newPassword) ||
      !specialCharRegex.test(newPassword) ||
      !numberRegex.test(newPassword)
    ) {
      this.setState({
        errorMessage:
          'Password must be at least 8 characters long and contain at least one uppercase letter (A), one lowercase letter(b), one special character(#), and one number(0)',
      });
    } else {
      this.setState({errorMessage: ''});
    }
  };

  render() {
    if (this.state.isLoading) {
      return <ActivityIndicator />;
    }

    return (
      <>
        <div className="flex-row" style={{marginBottom: 10}}>
          <Link to="/patient" className="btn btn-secondary btn-round">
            <FontAwesomeIcon icon={faChevronLeft} /> Back to patients
          </Link>
          <div className="flex-column flex" />
          <a
            href="#"
            className="btn btn-warning btn-round"
            onClick={e => {
              e.preventDefault();
              if (this.formRef.current.checkValidity()) {
                this.save();
              } else {
                this.formRef.current.reportValidity();
              }
            }}>
            <FontAwesomeIcon icon={faSave} /> Save
          </a>
          <Link
            to="/patient"
            className="btn btn-primary btn-round"
            style={{marginLeft: 10}}>
            Cancel
          </Link>
        </div>
        <Panel>
          <form ref={this.formRef}>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">
                Tags (Separate by Comma)
              </label>
              <div className="col-sm-3">
                <LimitTagAutoComplete
                  tagData={this.state.tagdata}
                  handleTagsChange={this.handleTagsChange}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">First Name</label>
              <div className="col-sm-3">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.name}
                  required
                  onChange={event => {
                    this.setState({name: event.target.value});
                  }}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Last Name</label>
              <div className="col-sm-3">
                <input
                  required
                  type="text"
                  className="form-control"
                  value={this.state.last_name}
                  onChange={event => {
                    this.setState({last_name: event.target.value});
                  }}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Username</label>
              <div className="col-sm-3">
                <input
                  required
                  type="text"
                  className="form-control"
                  readOnly={this.editable ? false : true}
                  value={this.state.username}
                  onChange={event => {
                    this.setState({username: event.target.value});
                  }}
                />
              </div>
            </div>
            <div
              style={{
                position: 'relative',
              }}>
              <div
                style={{
                  width: '1.5em',
                  height: '1.5em',
                  position: 'absolute',
                  top: 8,
                  left: '37%',
                  zIndex: 2,
                }}
                onClick={() => {
                  this.setState({showPassword: !this.state.showPassword});
                }}>
                <img
                  src={eye}
                  alt="eye"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    cursor: 'pointer',
                  }}
                />
              </div>
              {this.id == null && (
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">Password</label>
                  <div className="col-sm-3">
                    <input
                      required
                      type={this.state.showPassword ? 'text' : 'password'}
                      className="form-control"
                      maxLength="8"
                      value={this.state.password}
                      onChange={this.handlePassword}
                    />
                  </div>
                </div>
              )}
            </div>
            {this.state.errorMessage && (
              <div className="row">
                <div className="col-sm-2"></div>
                <div className="col-sm-3" style={{color: 'red'}}>
                  {this.state.errorMessage}
                </div>
              </div>
            )}
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Email</label>
              <div className="col-sm-3">
                <input
                  required
                  type="text"
                  className="form-control"
                  readOnly={this.editable ? false : true}
                  value={this.state.email}
                  onBlur={event => {
                    const email = event.target.value;
                    if (this.isValidEmail(email)) {
                      this.setState({emailError: ''});
                    } else {
                      this.setState({
                        emailError: 'Please enter a valid email address',
                      });
                    }
                  }}
                  onChange={event => {
                    this.setState({email: event.target.value});
                  }}
                />
                {this.state.emailError && (
                  <div className="text-danger">{this.state.emailError}</div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Address</label>
              <div className="col-sm-10">
                <input
                  required
                  type="text"
                  className="form-control"
                  value={this.state.address}
                  onChange={event => {
                    this.setState({address: event.target.value});
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Date of birth</label>
              <div className="col-sm-2">
                <input
                  required
                  type="date"
                  className="form-control"
                  value={this.state.birth_date}
                  max={this.state.today}
                  onChange={event => {
                    this.setState({birth_date: event.target.value});
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Gender</label>
              <div>
                <div class="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    value="He"
                    onChange={e => this.setState({gender: e.target.value})}
                  />
                  <label class="form-check-label" for="exampleRadios1">
                    Male
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios2"
                    value="She"
                    onChange={e => this.setState({gender: e.target.value})}
                  />
                  <label class="form-check-label" for="exampleRadios2">
                    Female
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios2"
                    value="Other"
                    onChange={e => this.setState({gender: e.target.value})}
                  />
                  <label class="form-check-label" for="exampleRadios2">
                    Other
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Phone number</label>
              <div className="col-sm-3">
                <input
                  maxLength="11"
                  type="text"
                  className={`form-control ${
                    this.state.phoneNumberValid === false ? 'is-invalid' : ''
                  }`}
                  value={this.state.phone}
                  onChange={this.handlePhoneNumberChange}
                />
                {this.state.phoneNumberValid === false && (
                  <div className="invalid-feedback">
                    Invalid phone number (e.g. 12345678910)
                  </div>
                )}
              </div>
            </div>
          </form>
        </Panel>
      </>
    );
  }
}

const styles = {
  control: {
    marginLeft: 8,
  },
};

export default withRouter(PatientEdit);
