export const SET_USER = 'SET_USER';
export const SET_BOTTOM_BAR = 'SET_BOTTOM_BAR';
export const SET_IS_DEBUG = 'SET_IS_DEBUG';
export const SET_RIBBON = 'SET_RIBBON';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_PROFILES = 'SET_PROFILES';
export const SET_USER_2_PATIENT = 'SET_USER_2_PATIENT';
export const SET_EXPORT = 'SET_EXPORT';

export const MILESTONE_NEGATIVE = 3;
export const MILESTONE_NEUTRAL = 2;
export const MILESTONE_POSITIVE = 1;

export const ROLE_ADMIN = 1;
export const ROLE_AGENT = 2;
export const ROLE_PROVIDER = 3;

export const BOOKING_DAILY = 4;
export const BOOKING_WEEKLY = 3;
export const BOOKING_MONTHLY = 2;
export const BOOKING_YEARLY = 1;
