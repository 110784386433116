import React from 'react';
import StringHelper from '../utils/StringHelper';

export default function CircleImage({src, size, style, maxSize}) {
  if (maxSize == null) {
    maxSize = size;
  }

  if (size == null) {
    size = 50;
  }

  if (src == null) {
    src = '/favicon.png';
  } else {
    src = StringHelper.clearUrl(src);
  }

  return (
    <img
      src={src}
      style={{
        width: size,
        height: size,
        borderRadius: size / 2,
        maxWidth: maxSize,
        maxHeight: maxSize,
        objectFit: 'cover',
        ...style,
      }}
    />
  );
}
