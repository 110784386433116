export default {
  slugify(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
    var to = 'aaaaeeeeiiiioooouuuunc------';
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  },
  pathBaseName(str) {
    str = new String(str);

    let beforeQuestionMark = str.lastIndexOf('?');

    let base = null;
    if (beforeQuestionMark == -1) {
      //no ? sign
      base = new String(str).substring(str.lastIndexOf('/') + 1);
    } else {
      base = str.substring(0, beforeQuestionMark);
      base = base.substring(base.lastIndexOf('/') + 1);
    }
    return base;
  },
  isValidURL(string) {
    if (string == null) {
      return false;
    }
    var res = string?.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    );
    return res !== null;
  },
  clearUrl(url) {
    if (url.lastIndexOf('?') > 0) {
      url = url.substring(0, url.lastIndexOf('?'));
    }
    return url;
  },
};
