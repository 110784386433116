import React, { useCallback, useEffect, useState } from 'react';
import ChallengeOption from './ChallengeOption';
import {
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  useRadioGroup,
} from '@mui/material';
import CategoryItem from './CategoryItem';
import { HttpRequest } from '@utils/http';
import Swal from 'sweetalert2';
export default function FieldsetCategory({value, onChange = () => {}, id}) {
  const radioGroup = useRadioGroup();
  const checked = radioGroup?.value === id;

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");

  
  const loadChallengesCategories = useCallback(() => {
    HttpRequest.getChallengesGoalCategories()
      .then(res => {
        let data = res.data.results;
        setCategories(data);
      })
      .catch(err => {
        Swal.fire('Error', 'Cannot Challenges Goal Providers', 'error');
      });
  }, []);

  useEffect(() => {
    loadChallengesCategories();
  }, []);


  const handleChange = event => {
      onChange(event.target.value);
      let content = categories.find((category) => category.id == event.target.value);
      setCategory(content);
  };
  const getCategoryColor = goal => {
    let color = categories.filter(item => item.id === goal);
    return color[0].color;
  };


  function ConditionalRendering() {
    if (checked) {
      return (
        <FormControl component="fieldset">
          <RadioGroup row value={value} onChange={handleChange}>
            {categories.map(item => (
              <CategoryItem key={item.id} {...item} />
            ))}
          </RadioGroup>
        </FormControl>
      );
    } else {
      return (
        <FormControl component="fieldset" sx={{marginTop: 1}}>
          <FormLabel component="legend">{category.name}</FormLabel>
        </FormControl>
      );
    }
  }

  return (
    <>
      <Box>
        <ChallengeOption label="Challenge category" value={id} categorydata={"category"}>
          <ConditionalRendering />
        </ChallengeOption>
      </Box>
    </>
  );
}
