import React from 'react';
import DataGrid from '../components/DataGrid';
import Panel from '../components/Panel';
import moment from 'moment';
import {
  faCommentAlt,
  faEdit,
  faEyeSlash,
  faPaste,
  faPlus,
  faSearch,
  faTrash,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import CircleImage from '../components/CircleImage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {HttpRequest} from '../utils/http';
import ActivityIndicator from '../components/ActivityIndicator';
import {Link} from 'react-router-dom';
import {store} from '../store';
import Swal from 'sweetalert2';
import {
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  parseISO,
} from 'date-fns';

class Plan extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      plans: [],
      dateFilter: '',
      query: '',
      type: '',
    };
  }

  componentDidMount() {
    let user = store.getState().loginReducer.user;
    if (user) {
      this.loadPlan();
    }
  }

  // Define a function to filter plans based on the selected date range
  filterPlansByDate(plans, dateFilter) {
    const currentDate = new Date();

    return plans.filter(plan => {
      const planDate = parseISO(plan.created); // Parse the ISO date string
      if (dateFilter === '1day') {
        return differenceInDays(currentDate, planDate) < 1;
      } else if (dateFilter === '1week') {
        return differenceInWeeks(currentDate, planDate) < 1;
      } else if (dateFilter === '1month') {
        return differenceInMonths(currentDate, planDate) < 1;
      } else {
        return true; // Return all plans if no date filter is selected
      }
    });
  }

  loadPlan() {
    this.setState({isLoading: true});

    let {query, type, dateFilter} = this.state;

    HttpRequest.planList(query, type)
      .then(res => {
        let data = res.data.results;

        // Filter the plans based on the selected date filter
        const filteredPlans = this.filterPlansByDate(data, dateFilter);

        this.setState({
          isLoading: false,
          plans: filteredPlans,
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load resource data.', 'error');
      });
  }

  render() {
    return (
      <>
        <div className="flex-row" style={{marginBottom: 10}}>
          <Link to="/plan/create" className="btn btn-secondary btn-round">
            <FontAwesomeIcon icon={faPlus} /> Add plan
          </Link>
          <div className="flex-column flex" />
        </div>
        <Panel>
          <DataGrid
            isLoading={this.state.isLoading}
            filters={[
              {
                type: 'textinput',
                label: 'Search plan',
                icon: faSearch,
                onChange: query => {
                  this.setState({query}, () => {
                    this.loadPlan();
                  });
                },
                className: 'col-sm-12 col-md-12',
              },
              {
                type: 'switch',
                label: 'Show plans',
                data: [
                  {id: '', label: 'All'},
                  {id: 'Discharge', label: 'Discharge'},
                  {id: 'Aftercare', label: 'Aftercare'},
                  {id: 'Crisis', label: 'Crisis'},
                  {id: 'Archived', label: 'Archived'},
                ],
                onChange: type => {
                  this.setState({type}, () => {
                    this.loadPlan();
                  });
                },
                className: 'col-md-6 col-sm-12',
              },
              {
                type: 'select',
                label: 'Date',
                data: [
                  {id: '', label: 'Any'},
                  {id: '1day', label: 'Less than 1 day'},
                  {id: '1week', label: 'Less than 1 week'},
                  {id: '1month', label: 'Less than 1 month'},
                ],
                onChange: dateFilter => {
                  this.setState({dateFilter}, () => {
                    this.loadPlan();
                  });
                },
                className: 'offset-md-4 col-md-2 col-sm-6',
                style: {width: 200},
              },
            ]}
            columns={[
              {name: 'title', label: 'Plan Name'},
              {
                label: 'Tags',
                renderItem: item => {
                  return item.tags.map((tag, key) => {
                    return (
                      <span
                        className="badge badge-success"
                        key={key}
                        style={{marginRight: 5}}>
                        {tag}
                      </span>
                    );
                  });
                },
              },
              {
                label: 'Date posted',
                renderItem: item => {
                  return moment(item.created).format('MMM DD, YYYY');
                },
              },
              {
                label: 'Type',
                renderItem: item => {
                  return item.type;
                },
              },
              {
                label: 'Controls',
                renderItem: item => {
                  return (
                    <>
                      <Link
                        to={'/plan/detail/' + item.id}
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Plan Management"
                        style={styles.control}>
                        <FontAwesomeIcon icon={faUsers} />
                      </Link>
                      <Link
                        to={'/plan/edit/' + item.id}
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Edit Plan"
                        style={styles.control}>
                        <FontAwesomeIcon icon={faEdit} />
                      </Link>
                      <Link
                        to={'#'}
                        style={styles.control}
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Delete Plan"
                        onClick={e => {
                          e.preventDefault();

                          // Use SweetAlert to confirm the deletion
                          Swal.fire({
                            title: 'Are you sure?',
                            text: 'You will not be able to recover this plan!',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Yes, delete it!',
                            cancelButtonText: 'No, cancel',
                          }).then(result => {
                            if (result.isConfirmed) {
                              // If the user confirms the deletion, proceed with the delete action
                              HttpRequest.deletePlan(item.id)
                                .then(() => {
                                  this.loadPlan();
                                })
                                .catch(err => {
                                  Swal.fire(
                                    'Error',
                                    'Cannot delete plan data.',
                                    'error',
                                  );
                                });
                            }
                          });
                        }}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Link>
                    </>
                  );
                },
              },
            ]}
            data={this.state.plans}
          />
        </Panel>
      </>
    );
  }
}

const styles = {
  control: {
    marginLeft: 8,
  },
};

export default Plan;
