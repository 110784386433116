import React from 'react';
import {faChevronLeft, faSave} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';

export default function ChallengeCreateHeader({onSave}) {
  return (
    <div className="flex-row" style={{marginBottom: 10}}>
      <Link to="/challenges" className="btn btn-secondary btn-round">
        <FontAwesomeIcon icon={faChevronLeft} /> Back to challenges
      </Link>
      <div className="flex-column flex" />
      <a
        href="/challenges"
        className="btn btn-warning btn-round"
        onClick={e => {
          e.preventDefault();
          onSave();
        }}
      >
        <FontAwesomeIcon icon={faSave} /> Save
      </a>
      <Link
        to="/challenges"
        className="btn btn-primary btn-round"
        style={{marginLeft: 10}}
      >
        Cancel
      </Link>
    </div>
  );
}
