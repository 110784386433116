import { Box } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import LimitTagAutoComplete from '@components/LimitTagAutoComplete';
import { HttpRequest } from '@utils/http';
import Swal from 'sweetalert2';
import UserIcon from "../../assets/logo192.png"
import ReactPatientSelect from '@components/ReactPatientSelect';
import { useParams } from 'react-router-dom';

export default function UIImageList({ handleChange, checked }) {
  const [patients, setPatients] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [showAutoComplete, setShowAutoComplete] = useState(false);
  const { id } = useParams();

  const loadPatients = useCallback(() => {
    return HttpRequest.getAllPatients()
      .then((res) => {
        return res.data.results;
      })
      .catch((err) => {
        Swal.fire('Error', 'Cannot Get Patients', 'error');
        return [];
      });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const patientsData = await loadPatients();
      setPatients(patientsData);

      if (id && patientsData.length > 0) {
        const foundPatient = patientsData.find((patient) => patient.user.id === parseInt(id, 10));

        if (foundPatient) {
          setSelectedPatients([foundPatient]);
        }
      }
    };

    fetchData();
  }, [id, loadPatients]);

//   const handlePatientSelect = (value) => {
//     setSelectedPatients([...selectedPatients, ...value]);
//     const assigned = [...selectedPatients, ...value].map((item) => item.user.id);
//     console.log("🚀 ~ handlePatientSelect ~ assigned:", assigned)
//     handleChange(assigned);
//   };

const handlePatientSelect = useMemo(() => (value) => {
    setSelectedPatients(value);
    const assigned = value.map((item) => item.user.id);
    handleChange(assigned);
  }, [selectedPatients, handleChange]);
  
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {selectedPatients?.map((patient) => (
        <Box sx={{ mr: -1 }} key={patient?.id}>
          <img src={patient?.user?.image || UserIcon} style={styles.root} alt="user" />
        </Box>
      ))}
      {checked && (
        <IconButton aria-label="add" style={styles.root} onClick={() => setShowAutoComplete(true)}>
          <AddIcon fontSize="small" style={{ color: 'blue' }} />
        </IconButton>
      )}
      {showAutoComplete && (
        <Box sx={{ minWidth: '15vw', ml: 4 }}>
          {/* <ReactPatientSelect options={patients} handleChange={handlePatientSelect} /> */}
          <ReactPatientSelect options={patients} handleChange={handlePatientSelect} />
        </Box>
      )}
    </Box>
  );
}

const styles = {
  root: {
    color: '#FFF',
    background: '#DDE9F3',
    border: 'solid',
    borderWidth: '2px',
    borderRadius: '50%',
    position: 'relative',
    width: '40px',
    height: '40px',
  },
};
