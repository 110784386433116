import React from 'react';
import {Modal, Button, Paper} from '@mui/material';
import Panel from '../components/Panel';
import moment from 'moment';
import {faChevronLeft, faSave} from '@fortawesome/free-solid-svg-icons';
import CircleImage from '../components/CircleImage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {HttpRequest} from '../utils/http';
import ActivityIndicator from '../components/ActivityIndicator';
import {Link, withRouter} from 'react-router-dom';
import {store} from '../store';
import RichTextEditor from '../components/RichTextEditor';
import Swal from 'sweetalert2';
import LimitTagAutoComplete from '@components/LimitTagAutoComplete';
import {TextField} from '@mui/material';
import styled from '@emotion/styled';
import AddIcon from '@material-ui/icons/Add';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '15px',
  boxShadow: 24,
};
class RewardEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      is_active: false,
      title: '',
      points: '',
      inventory: '',
      description: '',
      image: null,
      tagdata: [],
      selectedTags: [],
      isImagePreviewOpen: false,
      isImageUploaded: false,
    };

    this.id = props.match?.params?.id;
  }

  componentDidMount() {
    let user = store.getState().loginReducer.user;
    if (user) {
      this.loadTagData();
    }
    if (user && this?.id != null) {
      this.loadCampaigns();
    }
  }

  loadCampaigns() {
    this.setState({isLoading: true});
    HttpRequest.getCampaign(this.id)
      .then(res => {
        let campaign = res.data.data;

        this.setState({
          isLoading: false,
          title: campaign.title,
          is_active: campaign.is_active,
          points: campaign.points,
          inventory: campaign.starting_inventory,
          image: campaign.image,
          selectedTags: campaign.tags,
          description: campaign.description,
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load campaign data.', 'error');
      });
  }
  saveCampaign() {
    let user = store.getState().loginReducer.user;

    if (
      !this.state.title ||
      !this.state.points ||
      !this.state.inventory ||
      !this.state.selectedTags.length ||
      !this.state.description ||
      (!this.id && !this.state.image)
    ) {
      Swal.fire('Error', 'Please fill in all fields.', 'error');
      return;
    }

    this.setState({isLoading: true});
    const formdata = new FormData();
    formdata.append('tags', JSON.stringify(this.state.selectedTags));
    formdata.append('is_removed', false);
    formdata.append('is_active', this.state.is_active);
    formdata.append('title', this.state.title);
    formdata.append('points', this.state.points);
    formdata.append('starting_inventory', this.state.inventory);
    formdata.append('description', this.state.description);
    if (!this.id) {
      formdata.append('image', this.state.image);
    }
    formdata.append('view_count', this.state.inventory);
    formdata.append('publisher', user.user.id);
    let id = this.id;
    let body = formdata;

    HttpRequest.saveCampaign(id, body)
      .then(res => {
        let data = res.data.data;
        this.setState({
          isLoading: false,
          title: '',
          points: '',
          inventory: '',
          is_active: false,
          selectedTags: [],
          description: '',
        });
        Swal.fire('Information', 'Campaign saved.', 'success').then(res => {
          // window.location = '/reward';

          this.props.history.push('/reward');
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot save campaign data.', 'error');
      });
  }

  handleImageUpload = e => {
    this.setState({image: e.target.files[0]});
    this.setState({isImageUploaded: true});
  };
  handleImagePreviewOpen = () => {
    this.setState({
      isImagePreviewOpen: true,
    });
  };

  handleImagePreviewClose = () => {
    this.setState({
      isImagePreviewOpen: false,
    });
  };

  deleteCampaign(campaignId) {
    this.setState({isLoading: true});

    HttpRequest.deleteCampaign(campaignId)
      .then(() => {
        Swal.fire('Success', 'Campaign deleted successfully.', 'success');
        this.props.history.push('/reward');
      })
      .catch(err => {
        Swal.fire('Error', 'Cannot delete campaign.', 'error');
        this.props.history.push('/reward');
      });
  }
  loadTagData() {
    HttpRequest.getallTag()
      .then(res => {
        let data = res.data.data;
        this.setState({
          tagdata: data,
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load user data.', 'error');
      });
  }
  handleTagsChange = (event, value) => {
    this.setState({
      selectedTags: value.map(option => option.name),
    });
  };

  render() {
    if (this.state.isLoading) {
      return <ActivityIndicator />;
    }

    return (
      <>
        <div className="flex-row" style={{marginBottom: 10}}>
          <Link to="/reward" className="btn btn-secondary btn-round">
            <FontAwesomeIcon icon={faChevronLeft} /> Back to rewards
          </Link>
          <div className="flex-column flex" />
          <a
            href="#"
            className="btn btn-warning btn-round"
            onClick={e => {
              e.preventDefault();
              this.saveCampaign();
            }}>
            <FontAwesomeIcon icon={faSave} /> Save
          </a>
          <a
            href="#"
            className="btn btn-primary btn-round"
            style={{marginLeft: 10}}
            onClick={e => {
              e.preventDefault();
              this.deleteCampaign(this.id);
            }}>
            Delete
          </a>
          <a
            onClick={this.handleImagePreviewOpen}
            className="btn btn-primary btn-round"
            style={{marginLeft: 10, color: 'white'}}>
            Preview
          </a>
          <Link
            to="/reward"
            className="btn btn-primary btn-round"
            style={{marginLeft: 10}}>
            Cancel
          </Link>
        </div>
        <Panel>
          <form>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">
                Assigned to tags
              </label>
              <div className="col-sm-3">
                <LimitTagAutoComplete
                  tagData={this.state.tagdata}
                  value={this.state.selectedTags}
                  handleTagsChange={this.handleTagsChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Name</label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.title}
                  onChange={event => {
                    this.setState({title: event.target.value});
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">yCoin cost</label>
              <div className="col-sm-2">
                <input
                  type="number"
                  min="0"
                  max="20"
                  value={this.state.points}
                  onChange={event => {
                    let value = event.target.value;
                    value = Math.min(Math.max(parseInt(value)), 20);
                    this.setState({points: value});
                  }}
                  className="form-control"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Max 20"
                />
              </div>
              <label className="col-sm-2 col-form-label">
                Starting Inventory
              </label>
              <div className="col-sm-2">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.inventory}
                  onChange={event => {
                    this.setState({inventory: event.target.value});
                  }}
                />
              </div>
              <div className="col-sm-2 d-flex align-items-center">
                <label className="text-nowrap col-form-label mr-5">
                  Is Active
                </label>
                <div>
                  <input
                    type="checkbox"
                    checked={this.state.is_active}
                    className="form-control"
                    value={this.state.is_active}
                    onChange={event => {
                      this.setState({is_active: event.target.checked});
                    }}
                  />
                </div>
              </div>
            </div>
            <RichTextEditor
              defaultEditorState={this.state.description}
              onEditorStateChange={description => {
                this.setState({description: description});
              }}
            />
            <div>
              {this.id ? (
                ' '
              ) : (
                <>
                  <StyledContainer>
                    <p style={{whiteSpace: 'nowrap', color: 'black'}}>
                      Upload Image
                    </p>
                    <div
                      style={{
                        margin: '1% 0 2% 10%',
                        color: 'green',
                        position: 'absolute',
                      }}>
                      {this.state.isImageUploaded && (
                        <div>Image is uploaded</div>
                      )}
                    </div>
                    <UploadSection htmlFor="file">
                      <LabelStyle htmlFor="file">Browse</LabelStyle>
                    </UploadSection>
                    <TextField
                      id="file"
                      type="file"
                      variant="outlined"
                      onChange={this.handleImageUpload}
                      sx={{width: '100%', display: 'none'}}
                    />
                  </StyledContainer>
                  <label className="col-sm-1 col-form-label"></label>
                  <LabelStyle htmlFor="file" style={{margin: '1% 0 0 2%'}}>
                    <AddIcon style={{fontSize: '16px'}} /> Add media
                  </LabelStyle>
                </>
              )}
            </div>
          </form>
        </Panel>
        <Modal
          open={this.state.isImagePreviewOpen}
          onClose={this.handleImagePreviewClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description">
          <div style={style}>
            <Paper elevation={3}>
              {this.state.image ? (
                typeof this.state.image === 'object' ? (
                  <img
                    src={
                      URL.createObjectURL(this.state.image) || this.state.image
                    }
                    alt="Preview"
                    style={{height: 300, width: 400}}
                  />
                ) : (
                  <img
                    src={this.state.image}
                    alt="Preview"
                    style={{height: 300, width: 400}}
                  />
                )
              ) : (
                <img
                  src="https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                  alt="Preview"
                  style={{height: 300, width: 400}}
                />
              )}
            </Paper>
          </div>
        </Modal>
      </>
    );
  }
}

const styles = {
  control: {
    marginLeft: 8,
  },
};

export default withRouter(RewardEdit);

const StyledContainer = styled.div`
  padding: 10px;
  margin-top: 15px;
  display: flex;
  height: 40px;
  justify-content: space-between;
  gap: 1%;
  align-items: center;
`;
const UploadSection = styled.label`
  display: flex;
  justify-content: flex-end;
  outline: 1px solid grey;
  height: 40px;
  padding: 7px;
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
`;
const LabelStyle = styled.label`
  color: #0e82c8;
  font-size: 14px;
  cursor: pointer;
`;
