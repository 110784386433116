import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function BasicSelect({setNotificationTempValues}) {
    const [selectedValues, setSelectedValues] = React.useState({
        "Happy": "",
        "Neutral": "",
        "Sad": ""
    });

    const handleChange = (category, value) => {
        setSelectedValues((prevValues) => ({
            ...prevValues,
            [category]: value
        }));
        setNotificationTempValues((prevValues) => ({
            ...prevValues,
            [category]: value
        }));
    };

    return (
        <Box sx={styles.main}>
            <Box sx={styles.sad}>
                <Box sx={{ padding: '3%', width: '40%', textAlign: 'center' }}>
                    <p className='mb-0'>
                        Sad
                    </p>
                </Box>
                <TextField
                    sx={styles.textFieldStyle}
                    size="small"
                    hiddenLabel
                    id="sad"
                    variant="outlined"
                    type="number"
                    inputProps={{ min: 0, max: 100 }}
                    min={0}
                    max={100}
                    InputProps={{
                        endAdornment: '%',
                    }}
                    value={selectedValues.Sad}
                    onChange={(e) => handleChange("Sad", e.target.value)}
                />
            </Box>
            <Box sx={styles.neutral}>
                <Box sx={{ padding: '3%', width: '40%', textAlign: 'center' }}>
                    Neutral
                </Box>
                <TextField
                    sx={styles.textFieldStyle}
                    size="small"
                    hiddenLabel
                    id="neutral"
                    variant="outlined"
                    type="number"
                    inputProps={{ min: 0, max: 100 }}
                    min={0}
                    max={100}
                    InputProps={{
                        endAdornment: '%',
                    }}
                    value={selectedValues.Neutral}
                    onChange={(e) => handleChange("Neutral", e.target.value)}
                />
            </Box>
            <Box sx={styles.happy}>
                <Box sx={{ padding: '3%', width: '40%', textAlign: 'center' }}>Happy</Box>
                <TextField
                    sx={styles.textFieldStyle}
                    size="small"
                    hiddenLabel
                    id="happy"
                    variant="outlined"
                    type="number"
                    inputProps={{ min: 0, max: 100 }}
                    min={0}
                    max={100}
                    InputProps={{
                        endAdornment: '%',
                    }}
                    value={selectedValues.Happy}
                    onChange={(e) => handleChange("Happy", e.target.value)}
                />
            </Box>
        </Box>
    );
}

const styles = {
    main: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1.5em',
        width: '100%',
        '@media (max-width: 1000px)': {
            flexDirection: 'column',
        },
    },
    sad: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#E9E9E9',
        borderRadius: '0.3em',
        height: '100%',
        width: '33.3%',
        '@media (max-width: 920px)': {
            width: '60%',
            marginTop: '0.5em'
        },
    },
    neutral: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#E9E9E9',
        borderRadius: '0.3em',
        height: '100%',
        width: '33.4%',
        '@media (max-width: 920px)': {
            width: '60%',
        },
    },
    happy: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#E9E9E9',
        borderRadius: '0.3em',
        height: '100%',
        width: '33.3%',
        '@media (max-width: 920px)': {
            width: '60%',
            marginBottom: '0.5em'
        },
    },
    textFieldStyle: {
        width: '60%',
        backgroundColor: 'white',
        borderRadius: '0.3em',
        height: '100%',
    }
}
