import {store} from 'src/store';
import {setProfiles} from 'src/store/actions';
import {HttpRequest} from './http';

export default {
  findDoctor(id, dispatch) {
    HttpRequest.getDoctor(id)
      .then(res => {
        let allProfiles = {...store.getState().profiles};
        allProfiles[id] = res.data.data;
        dispatch(setProfiles(allProfiles));
      })
      .catch(error => {});
  },
  findPatient(id, dispatch) {
    HttpRequest.patientDetail(id)
      .then(res => {
        let allProfiles = {...store.getState().profiles};
        let patientData = res.data.data;
        allProfiles[patientData.user.id + ''] = patientData;
        dispatch(setProfiles(allProfiles));
      })
      .catch(error => {});
  },
};
