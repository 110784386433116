import moment from 'moment';
import React from 'react';
import LocalStorage from 'local-storage';
import {setUser} from '../store/actions';
import {connect} from 'react-redux';

class Logout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.dispatchSignin(null);
    LocalStorage.clear();
    window.location = '/';
  }

  render() {
    return null;
  }
}

const styles = {
  title1: {
    fontSize: 16,
  },
  bottomLink: {
    textAlign: 'center',
    color: '#0077bd',
    fontSize: 11,
    marginBottom: 5,
  },
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  dispatchSignin: data => dispatch(setUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
