import React from 'react';
import ChallengeOption from './ChallengeOption';
import { Box, FormControl, MenuItem, useRadioGroup } from '@mui/material';
import UISelect from '@components/UISelect';
import { ReactComponent as RewardIcon } from '../../assets/icons/Reward.svg';

export default function FieldsetYCoinsReward({ value, onChange = () => { }, id }) {
    const radioGroup = useRadioGroup();
    const checked = radioGroup?.value === id;
    const handleChange = event => {
        onChange(event.target.value);
    };
    const selectbgColor = {
        backgroundColor: '000'
    }
    return (
        <ChallengeOption sx={{ minWidth: 120 }} label="yCoins Reward" value={id} help="Tell what will be the reward of the challenge?">
            {checked ? (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: '#E9E9E9',
                        borderRadius: '0.3em',
                        height: '100%',
                        width: 175,
                        marginLeft: '6%',
                    }}>
                    <Box
                        sx={{
                            padding: '3%',
                            width: '30%',
                            display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }}>
                        <RewardIcon />
                    </Box>
                    <Box
                        sx={{
                            width: '67%',
                            backgroundColor: 'white',
                        }}>
                        <UISelect
                            value={value}
                            onChange={handleChange}
                            displayEmpty
                            style={{ color: selectbgColor.backgroundColor }}
                            inputProps={{ 'aria-label': 'Without label' }}
                            fullHeight
                        >
                            <MenuItem value="low">Low</MenuItem>
                            <MenuItem value="medium">Medium</MenuItem>
                            <MenuItem value="high">High</MenuItem>
                        </UISelect>

                    </Box>
                </Box>
            ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '1em', marginLeft: '6%' }}>
                    <FormControl component="fieldset" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <RewardIcon />
                    </FormControl>
                    <FormControl component="fieldset">{value}</FormControl>
                </Box>
            )}
        </ChallengeOption>
    );
}
