import axios from 'axios';
import {store} from '../store';
import qs from 'qs';
import FormData from 'form-data';
import {appConfig} from '../config/app';
import Swal from 'sweetalert2';
import {DataArrayRounded} from '@mui/icons-material';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

//let baseURL = "https://shrill-silence-24189-staging.herokuapp.com/api/v1";
// let baseURL = 'https://shrill-silence-24189-staging.botics.co/api/v1';
let baseURL = 'https://ybgrconnect-24189.botics.co/api/v1';
// let baseURL = "https://shrill-silence-24189-staging.botics.co/api/v1";

const request = () => {
  let debug = store.getState().loginReducer.debug;

  return axios.create({
    //baseURL: debug ? appConfig.stagingBaseUrl : appConfig.productionBaseUrl,
    baseURL,
    // baseURL: "https://shrill-silence-24189.botics.co/api/v1",
    // timeout: 9000,
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // "Content-Type": "application/x-www-form-urlencoded",
      //"Access-Control-Allow-Origin": '*',
      //"Access-Control-Allow-Methods": 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
    },
  });
};

const requestWithAuth = () => {
  let debug = store.getState().loginReducer.debug;
  let user = store.getState().loginReducer.user;

  return axios.create({
    //baseURL: debug ? appConfig.stagingBaseUrl : appConfig.productionBaseUrl,
    baseURL,
    // baseURL: "https://shrill-silence-24189.botics.co/api/v1",
    // timeout: 5000,
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + user.token,
    },
  });
};

export const HttpRequest = {
  signup(data, role_id) {
    if (role_id == 3) {
      data.role = 3;
      return request().post('/provider/', data);
    } else {
      data.role = 2;
      return request().post('/agent/', data);
    }
  },
  login(data) {
    return request().post('/login/', data);
  },
  invitePatient(phone) {
    let data = {phone};
    return request().post('/invite-patient/', data);
  },
  getActivityLog(id) {
    return requestWithAuth().get('/activity-logs/?user__id=' + id);
  },
  patientDetail(id) {
    return requestWithAuth().get('/patient/' + id + '/');
  },
  unassignedPatient(provider = null) {
    let params = {};
    if (provider) {
      params.providers = provider;
    }
    return requestWithAuth().get(
      '/patient/unassigned_patients/?' + qs.stringify(params),
    );
  },
  providerList(query) {
    let params = {};
    if (query) {
      params.q = query;
    }

    return requestWithAuth().get('/provider/?' + qs.stringify(params) + '/');
  },
  patientProvidersList(id) {
    return requestWithAuth().get('/patient/' + id + '/list_providers/');
  },
  providerDetail(id) {
    return requestWithAuth().get('/provider/' + id + '/');
  },
  deleteProvider(id) {
    return requestWithAuth().delete('/provider/' + id + '/');
  },
  deleteMilestone(id) {
    return requestWithAuth().delete('/milestone/' + id + '/');
  },
  agentList(query) {
    let params = {};
    if (query) {
      params.q = query;
    }

    return requestWithAuth().get('/agent/?' + qs.stringify(params));
  },
  createPatient(id, data) {
    if (id == null) {
      return requestWithAuth().post('/signup-patient/', data);
    } else {
      return requestWithAuth().put('/profile/' + id + '/', data);
    }
  },
  agentDetail(id) {
    return requestWithAuth().get('/agent/' + id + '/');
  },
  adminDetail(id) {
    return requestWithAuth().get('/admin/' + id + '/');
  },
  patientList(q = null) {
    if (q == null) {
      q = '';
    }
    return requestWithAuth().get(
      '/patient/?' + qs.stringify({q, page_size: 10}),
    );
  },
  showPatientList(page, provider_id = null) {
    let obj = {};
    if (provider_id) {
      obj.providers = provider_id;
    }
    if (page != null) {
      obj.page = page;
    }
    if (page !== null) {
      return requestWithAuth().get(`/patient/?` + qs.stringify(obj));
    } else {
      return requestWithAuth().get('/patient/?');
    }
  },
  resourceList() {
    return requestWithAuth().get('/resources-article/');
  },
  getResource(id) {
    return requestWithAuth().get('/resources-article/' + id + '/');
  },
  saveResource(id, data) {
    if (id == null) {
      return requestWithAuth().post('/resources-article/', data);
    } else {
      return requestWithAuth().put('/resources-article/' + id + '/', data);
    }
  },
  deleteResource(id) {
    return requestWithAuth().delete('/resources-article/' + id + '/');
  },
  getPatientSurvey(id) {
    return requestWithAuth().get('/patient-surveys/?survey__id=' + id);
  },
  addPatientSurvey(data) {
    return requestWithAuth().post('/patient-surveys/', data);
  },
  removePatientSurvey(id) {
    return requestWithAuth().delete('/patient-surveys/' + id + '/');
  },
  surveyList(page) {
    if (page !== null) {
      return requestWithAuth().get(`/survey/?page=${page}`);
    } else {
      return requestWithAuth().get('/survey/');
    }
  },
  getSurvey(id) {
    return requestWithAuth().get('/survey/' + id + '/');
  },
  saveSurvey(id, data) {
    if (id == null) {
      return requestWithAuth().post('/survey/', data);
    } else {
      return requestWithAuth().put('/survey/' + id + '/', data);
    }
  },
  deleteSurvey(id) {
    return requestWithAuth().delete('/survey/' + id + '/');
  },
  saveQuestion(id, data) {
    if (id == undefined || id == null) {
      return requestWithAuth().post('/question/', data);
    } else {
      return requestWithAuth().put('/question/' + id + '/', data);
    }
  },
  saveChoice(id, data) {
    if (id == null) {
      return requestWithAuth().post('/choice/', data);
    } else {
      return requestWithAuth().put('/choice/' + id + '/', data);
    }
  },
  //   rewardList() {
  //     return requestWithAuth().get('/reward/');
  //   },
  taskList() {
    return requestWithAuth().get('/task/');
  },
  rewardList(page) {
    if (page !== null) {
      return requestWithAuth().get(`/reward/?page=${page}`);
    } else {
      return requestWithAuth().get('/reward/');
    }
  },
  getReward(id) {
    return requestWithAuth().get('/reward/' + id + '/');
  },
  saveReward(id, data) {
    if (id == null) {
      return requestWithAuth().post('/reward/', data);
    } else {
      return requestWithAuth().put('/reward/' + id + '/', data);
    }
  },
  deleteReward(id) {
    return requestWithAuth().delete('/reward/' + id + '/');
  },
  //   getCampaignList() {
  //     return requestWithAuth().get('/campaign/');
  //   },
  getCampaignList(page) {
    if (page !== null) {
      return requestWithAuth().get(`/campaign/?page=${page}`);
    } else {
      return requestWithAuth().get('/campaign/');
    }
  },
  getCampaign(id) {
    return requestWithAuth().get('/campaign/' + id + '/');
  },
  getAnalytics() {
    let user = store.getState().loginReducer.user;
    if (user.user.role !== 1) {
      let data = {provider_id: user.user.id};
      return requestWithAuth().get('/analytics/', data);
    }
    return requestWithAuth().get('/analytics/');
  },
  pointHistoriesAdd(data) {
    return requestWithAuth().post(`/reward-history/`, data);
  },
  saveCampaign(id, data) {
    if (id == null) {
      return axios.post(baseURL + '/campaign/', data, {
        headers: {
          accept: 'application/json',
          'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        },
      });
    } else {
      return axios.put(baseURL + '/campaign/' + id + '/', data, {
        headers: {
          accept: 'application/json',
          'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        },
      });
      // return requestWithAuth().put('/campaign/' + id + '/', data);
    }
  },
  deleteCampaign(id) {
    return requestWithAuth().delete('/campaign/' + id + '/');
  },

  planList(query, type) {
    let params = {};
    if (query) {
      params.plan_name = query;
    }
    if (type) {
      params.type = type;
    }

    return requestWithAuth().get('/plan/?' + qs.stringify(params));
  },
  getPlan(id) {
    return requestWithAuth().get('/plan/' + id + '/');
  },
  patientPlanList(plan_id) {
    return requestWithAuth().get('/patient-plan/?plan__id=' + plan_id);
  },
  savePlan(id, data) {
    if (id == null) {
      return requestWithAuth().post('/plan/', data);
    } else {
      return requestWithAuth().put('/plan/' + id + '/', data);
    }
  },
  savePatientPlan(id, data) {
    if (id == null) {
      return requestWithAuth().post('/patient-plan/', data);
    } else {
      return requestWithAuth().put('/patient-plan/' + id + '/', data);
    }
  },
  deletePlan(id) {
    return requestWithAuth().delete('/plan/' + id + '/');
  },
  assignPatientPlan(data) {
    return requestWithAuth().post('/patient-plan/', data);
  },
  deletePatientPlan(id) {
    return requestWithAuth().delete('/patient-plan/' + id + '/');
  },
  milestoneList(provider_id = null, page_size = 6) {
    let params = {
      page_size: page_size,
    };
    if (provider_id) {
      //params.provider__id = provider_id;
    }
    return requestWithAuth().get('/milestone/?' + qs.stringify(params));
  },
  getMilestone(id) {
    return requestWithAuth().get('/milestone/?patient__id=' + id);
  },
  getPatient(id) {
    return requestWithAuth().get(`/patient/${id}/`);
  },
  getProfile1(id) {
    return requestWithAuth().get(`/profile/${id}/`);
  },
  getProfile(id) {
    return requestWithAuth().get(`/profile/?user__id=${id}`);
  },
  getUserChallenges(offset, provider, providerFilter) {
    let user = store.getState().loginReducer.user;
    let params = {};
    if (offset) {
      params.offset = offset;
    }
    if (user.user.role !== 1) {
      if (provider) {
        params.challenge__provider__id = provider;
      }
      return requestWithAuth().get(`/user-challenge/?` + qs.stringify(params));
    } else {
        if(providerFilter) {
            params.challenge__provider__id = providerFilter;
          }
      return requestWithAuth().get(`/user-challenge/?` + qs.stringify(params));
    }
  },
  deleteUserChallenge(id) {
    return requestWithAuth().delete(`/user-challenge/${id}/`);
  },
  getChallenges() {
    return requestWithAuth().get(`/challenge/`);
  },
  assignChallenge(data) {
    return requestWithAuth().post(`/user-challenge/`, data);
  },
  getChallengeAnswer(userChallenge, user, challenge) {
    let params = {};
    if (userChallenge) {
      params.user_challenge__id = userChallenge;
    }
    if (user) {
      params.user_challenge__patient__id = user;
    }
    if (challenge) {
      params.user_challenge__challenge__id = challenge;
    }

    return requestWithAuth().get(`/challenge-answer/?` + qs.stringify(params));
  },
  getChallengesGoal() {
    return requestWithAuth().get(`/goal/`);
  },
  getChallengesGoalCategories() {
    return requestWithAuth().get(`/goal-category/`);
  },
  addChallengesGoal(data) {
    return requestWithAuth().post(`/goal/`, data);
  },
  deleteChallengesGoal(id) {
    return requestWithAuth().delete(`/goal/${id}/`);
  },
  getChallengesScaleTemplate() {
    return requestWithAuth().get(`/scale-template/`);
  },
  getChallengesNotiicationTemplate() {
    return requestWithAuth().get(`/notification-template/`);
  },
  getAllPatients() {
    return requestWithAuth().get(`/patient/?page_size=1000`);
  },
  addChallenge(data) {
    return requestWithAuth().post(`/challenge/`, data);
  },
  assigedPatients(id) {
    return requestWithAuth().get(`/patient/?providers=${id}`);
  },
  savePatient(id, data) {
    if (id == null) {
      return requestWithAuth().post('/patient/', data);
    } else {
      return requestWithAuth().put('/patient/' + id + '/', data);
    }
  },
  savePatchPatient(id, data) {
    return requestWithAuth().patch('/patient/' + id + '/', data);
  },
  getProvider(id) {
    return requestWithAuth().get('/provider/' + id + '/');
  },
  getProviderLog(id) {
    return requestWithAuth().get('/provider-logs/' + id);
  },
  saveProvider(id, data) {
    if (id == null) {
      return requestWithAuth().post('/provider/', data);
    }
    return requestWithAuth().put('/provider/' + id + '/', data);
  },
  getAgent(id) {
    return requestWithAuth().get('/agent/' + id);
  },
  getAgentLog(id) {
    return requestWithAuth().get('/agent-logs/' + id);
  },
  saveAgent(id, data) {
    if (id == null) {
      return requestWithAuth().post('/agent/', data);
    }
    return requestWithAuth().put('/agent/' + id + '/', data);
  },
  getMessage(sender_id, receiver_id) {
    let params = {};
    if (sender_id) {
      params.sender = sender_id;
    }
    if (receiver_id) {
      params.receiver = receiver_id;
    }
    return requestWithAuth().get('/message/?' + qs.stringify(params));
  },
  // sendMessage(sender_id, receiver_id, message) {
  // 	let obj = {
  // 		is_removed: false,
  // 		title: "Message",
  // 		body: message,
  // 		seen: true,
  // 		sender: sender_id,
  // 		receiver: receiver_id
  // 	};
  // 	return requestWithAuth().post("/message/", obj);
  // },
  taskList(startDate, endDate) {
    let obj = {
      created_lt: endDate,
      created_gt: startDate,
    };
    return requestWithAuth().get('/task/?' + qs.stringify(obj));
  },
  saveTask(data) {
    return requestWithAuth().post('/task/', data);
  },
  getBooking(provider_id, startOfWeek, endOfWeek) {
    let params = {};
    if (provider_id) {
      params.provider = provider_id;
    }
    if (startOfWeek) {
      params.end = endOfWeek;
      params.start = startOfWeek;
    }
    return requestWithAuth().get('/booking/?' + qs.stringify(params));
  },
  saveBooking(data) {
    return requestWithAuth().post('/booking/', data);
  },
  editBooking(id, data) {
    return requestWithAuth().put('/booking/' + id + '/', data);
  },
  deleteBooking(id) {
    return requestWithAuth().delete('/booking/' + id + '/');
  },
  roomList(
    provider_id = null,
    patient_id = null,
    is_accepted = null,
    page = null,
  ) {
    let obj = {};
    if (patient_id) {
      obj.sender__id = patient_id;
    }
    if (provider_id) {
      obj.receiver__id = provider_id;
    }
    if (is_accepted != null) {
      obj.is_accepted = is_accepted;
    }
    if (page != null) {
      obj.page = page;
    }
    return requestWithAuth().get('/fcm-room/?' + qs.stringify(obj));
  },
  loadRoomList(page) {
    if (page !== null) {
      return requestWithAuth().get(`/fcm-room/?page=${page}`);
    } else {
      return requestWithAuth().get('/fcm-room/');
    }
  },
  createRoom(patient_id, provider_id) {
    let data = {
      is_removed: false,
      title: 'room-' + patient_id + '-' + provider_id,
      sender: patient_id,
      is_accepted: true,
      receiver: [provider_id],
    };

    return requestWithAuth().post('/fcm-room/', data);
  },

  getProviderPerformance(start, end) {
    let user = store.getState().loginReducer.user;
    return requestWithAuth().get(
      '/provider-performance/?' + qs.stringify({start, end}),
    );
  },
  getProviderReachVolume(start, end) {
    return requestWithAuth().get(
      '/provider-reach-volume/?' + qs.stringify({start, end}),
    );
  },
  getProviderTeamPerformance(start, end) {
    return requestWithAuth().get(
      '/provider-team-performance/?' + qs.stringify({start, end}),
    );
  },
  getChatRequest(id) {
    return requestWithAuth().get('/chat-request/');
  },
  assignedPatient(patient_id) {
    return requestWithAuth().get(
      '/assigned-patient/?contact__id=' + patient_id,
    );
  },
  assignedProvider(patient_id) {
    return requestWithAuth().get('/patient/' + patient_id + '/list_providers/');
  },
  saveAssignedPatient(data) {
    return requestWithAuth().post('/assigned-patient/', data);
  },
  saveAssignedProvider(id, data) {
    return requestWithAuth().post('/patient/' + id + '/assign_provider/', data);
  },
  saveAssignedProvider(id, data) {
    return requestWithAuth().post('/patient/' + id + '/assign_provider/', data);
  },
  deleteAssignedPatient(id) {
    return requestWithAuth().delete('/assigned-patient/' + id + '/');
  },
  deleteAssignedProvider(patientId, provider) {
    return requestWithAuth().post(
      'patient/' + patientId + '/delete_provider/',
      provider,
    );
  },
  searchPatient(query) {
    return requestWithAuth().get('/patient/?q=' + query);
  },
  getBlockedProvider(id) {
    return requestWithAuth().get('/block-provider/');
  },
  blockProvider(id, data) {
    return requestWithAuth().put('/block-provider/' + id + '/', data);
  },
  approveProvider(data) {
    return requestWithAuth().post('/user-active-inactive/', data);
  },
  getInactiveProvider() {
    return request().get('inactive-provider/');
  },
  checkInactiveProvider(id) {
    return request().get('inactive-provider/' + id + '/');
  },
  unblockProvider(id, data) {
    return requestWithAuth().put('/block-provider/' + id + '/', data);
  },
  voteMilestone(data) {
    return requestWithAuth().post('/vote-milestone/', data);
  },
  getPatientDetail(id) {
    return requestWithAuth().get('/patient/?user__id=' + id);
  },
  careMilestone(data) {
    return requestWithAuth().post('/care-milestone/', data);
  },

  noteList(id) {
    return requestWithAuth().get('/note/?patient__id=' + id);
  },
  saveNote(id, data) {
    if (id != null) {
      return requestWithAuth().post('/note/' + id + '/', data);
    } else {
      return requestWithAuth().post('/note/', data);
    }
  },
  getallTag() {
    return requestWithAuth().get('/tags/');
  },
  patientTag(id) {
    return requestWithAuth().get('/patient-tags/' + id + '/');
  },
  savePatientTag(id, tags) {
    let data = {
      tags,
    };

    return requestWithAuth().put('/patient-tags/' + id + '/', data);
  },
  uploadImage(file) {
    let data = new FormData();
    data.append('default_photo', file, file.name);

    let user = store.getState().loginReducer.user;

    return axios
      .patch(baseURL + '/provider-photo/' + user.user.id + '/', data, {
        headers: {
          accept: 'application/json',
          'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        },
      })
      .then(response => {
        Swal.fire('Success', 'Profile Image Updated.', 'success');
      })
      .catch(error => {
        //handle error
        Swal.fire('Error', 'Cannot Update Profile Image.', 'error');
      });
  },
  sendMessage(msg, is_file = false, patient_id) {
    let user = store.getState().loginReducer.user;

    if (is_file) {
      let data = new FormData();
      data.append('file', msg, msg.name);
      data.append('is_removed', 'false');
      data.append('title', 'FILE');
      data.append('body', '🌁');
      data.append('seen', 'false');
      data.append('sender', patient_id + '');
      data.append('receiver', user.user.id + '');
      // https://shrill-silence-24189-staging.botics.co/api/v1
      return axios.post(baseURL + '/message/', data, {
        headers: {
          accept: 'application/json',
          'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        },
      });
    } else {
      let data = {
        is_removed: false,
        title: 'Message',
        body: msg,
        seen: true,
        sender: patient_id,
        receiver: user.user.id,
      };
      return requestWithAuth().post('/message/', data);
    }
  },

  getNotificationSetting() {
    return requestWithAuth().get('/notification-settings/');
  },
  updateNotificationSetting(id, stateVarName, value) {
    return requestWithAuth().patch('/notification-settings/' + id + '/', {
      [stateVarName]: value,
    });
  },
  searchGiphy(data) {
    return requestWithAuth().post('/giphy-search/', data);
  },
  createZoomMeeting(title, time) {
    return new Promise((resolve, reject) => {
      this.getZoomAccessToken()
        .then(res => {
          let accessToken = res.data.data.access_token;
          this.getZoomMeeting(title, time, accessToken)
            .then(res2 => {
              resolve(res2);
            })
            .catch(err => {
              reject(err);
            });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getZoomAccessToken() {
    return request().post('/zoom-callback/', {});
  },
  getZoomMeeting(topic, start_time, zoom_access_token) {
    return request().post('/zoom-schedule/', {
      topic,
      start_time,
      zoom_access_token,
    });
  },
};

export const HttpResponse = {
  processError(error, defaultMessage = 'Something went wrong') {
    let globalErrorMessageArray = [];

    let errorObject = error?.data?.error;
    if (errorObject) {
      Object.keys(errorObject).forEach(key => {
        let errorObject2 = errorObject[key];

        Object.keys(errorObject2).forEach(key2 => {
          let errorMessage = errorObject2[key2];
          if (Array.isArray(errorMessage)) {
            errorMessage.forEach(msg => {
              globalErrorMessageArray.push('<b>' + key2 + '</b> ' + msg);
            });
          } else {
            globalErrorMessageArray.push('<b>' + key + '</b> ' + errorMessage);
          }
        });
      });
    }

    if (globalErrorMessageArray.length != 0) {
      return globalErrorMessageArray.join('<br />');
    }

    return defaultMessage;
  },
};
