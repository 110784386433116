import React, {useState, useEffect} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CancelIcon from '@mui/icons-material/Cancel';
import Typography from '@mui/material/Typography';
import {Divider} from '@mui/material';
import UserIcon from '../assets/logo192.png';
import {Link} from 'react-router-dom/cjs/react-router-dom.min';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: '15px',
  boxShadow: 24,
  p: 2,
  maxHeight: '90vh',
  overflowY: 'auto',
};

const ProviderAnalyticsModal = ({isOpen, closeModal, providers, label}) => {
  const [filteredProviders, setFilteredProviders] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setFilteredProviders(providers);
  }, [providers]);

  const filterPatients = () => {
    const sanitizedSearchTerm = searchTerm?.toLowerCase().replace(/\s+/g, '');

    const filtered = providers.filter(provider => {
      const sanitizedUserName =
        `${provider?.user?.name}${provider?.user?.last_name}`
          ?.toLowerCase()
          ?.replace(/\s+/g, '');

      return sanitizedUserName?.includes(sanitizedSearchTerm);
    });

    setFilteredProviders(filtered);
  };

  useEffect(() => {
    filterPatients();
  }, [searchTerm]);

  const handleChange = e => {
    setSearchTerm(e.target.value);
  };

  const exportToCSV = () => {
    const csvData = [
      ['User ID', 'Name', 'Last Name', 'Email'],
      ...filteredProviders.map(provider => [
        provider?.user?.id,
        provider?.user?.name,
        provider?.user?.last_name,
        provider?.user?.email,
      ]),
    ];

    const csvContent = csvData.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8'});

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'providers_data.csv';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={{...style, width: '550px'}}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Typography
            variant="body2"
            sx={{color: '#23465a', fontSize: '16px', fontWeight: 'bold'}}>
            {label}
          </Typography>
          <Box>
            <CancelIcon
              style={{fontSize: '30px', color: '#274F66ff', cursor: 'pointer'}}
              onClick={closeModal}
            />
          </Box>
        </Box>
        <Divider sx={{my: 1}} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <TextField
            label="Search Provider"
            variant="outlined"
            sx={{width: '70%'}}
            value={searchTerm}
            onChange={handleChange}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{borderRadius: '30px'}}
            onClick={exportToCSV}
            disabled={filteredProviders.length === 0}>
            Export
          </Button>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{fontWeight: 'bold'}}>Image</TableCell>
                <TableCell sx={{fontWeight: 'bold'}}>Name</TableCell>
                <TableCell sx={{fontWeight: 'bold'}}>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredProviders.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <Typography variant="body2" color="textSecondary">
                      No data found.
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                filteredProviders?.map((provider, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <img
                        src={provider?.user?.image || UserIcon}
                        alt={provider?.user?.name}
                        style={{
                          height: '50px',
                          width: '50px',
                          borderRadius: '100%',
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Link to={`/patient`}>
                        {provider?.user?.name + ' ' + provider?.user?.last_name}
                      </Link>
                    </TableCell>
                    <TableCell>{provider?.user?.email}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

export default ProviderAnalyticsModal;
