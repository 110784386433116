import React from 'react';
import DataGrid from '../components/DataGrid';
import Panel from '../components/Panel';
import moment from 'moment';
import {
    faChevronLeft,
    faEdit,
    faEnvelopeOpenText,
    faSearch,
    faTrash,
} from '@fortawesome/free-solid-svg-icons';
import CircleImage from '../components/CircleImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HttpRequest } from '../utils/http';
import ActivityIndicator from '../components/ActivityIndicator';
import { Link, withRouter } from 'react-router-dom';
import { store } from '../store';
import Swal from 'sweetalert2';
import ImageIcon from "../assets/logo512.png"

class PlanDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoadingPlan: false,
            isLoadingList: false,
            plan: null,
            patientPlans: [],
            patient_id: null,
            patients: [],
            provider_id: null,
            providers: [],
            searchText: '',
            selectedPlans: null,
        };

        this.id = props.match?.params?.id;
    }

    componentDidMount() {
        let user = store.getState().loginReducer.user;
        if (user) {
            this.loadPlan();
            this.loadPatientPlan();
            this.loadPatients();
            this.loadProviders();
        }
    }

    loadPatients() {
        HttpRequest.patientList('')
            .then(res => {
                this.setState({ patients: res.data.results });
            })
            .catch(err => {
                Swal.fire('Error', 'Cannot load patient list.', 'error');
            });
    }

    loadProviders() {
        HttpRequest.providerList('')
            .then(res => {
                this.setState({ providers: res.data.results });
            })
            .catch(err => {
                Swal.fire('Error', 'Cannot load provider list. dfsvdv', 'error');
            });
    }

    loadPlan() {
        this.setState({ isLoadingPlan: true });

        HttpRequest.getPlan(this.id)
            .then(res => {
                let plan = res.data.data;
                this.setState({ isLoadingPlan: false, plan });
            })
            .catch(err => {
                this.setState({ isLoadingPlan: false });
                Swal.fire('Error', 'Cannot load plan.', 'error');
            });
    }

    loadPatientPlan() {
        this.setState({ isLoadingList: true });

        HttpRequest.patientPlanList(this.id)
            .then(res => {
                let patientPlans = res.data.results;

                this.setState({ isLoadingList: false, patientPlans });
            })
            .catch(err => {
                this.setState({ isLoadingList: false });
                Swal.fire('Error', 'Cannot load plan.', 'error');
            });
    }

    deletePatientPlan(id) {
        Swal.fire({
            title: 'Confirm Deletion',
            text: 'Are you sure you want to delete this patient plan?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
        }).then(result => {
            if (result.isConfirmed) {
                // User clicked 'Yes, delete it!'
                HttpRequest.deletePatientPlan(id)
                    .then(res => {
                        Swal.fire('Information', 'Delete success.', 'success');
                        this.loadPatientPlan();
                    })
                    .catch(err => {
                        Swal.fire('Error', 'Delete failed.', 'error');
                    });
            }
        });
    }


    assignPatient() {
        if (!this.state.patient_id || !this.state.provider_id) {
            Swal.fire('Error', 'Please select both a patient and a provider.', 'error');
            return;
        }

        window.jQuery('#modal-assign').modal('hide');


        let data = {
            is_removed: false,
            plan: this.id,
            assigned_to: this.state.provider_id,
            patient: this.state.patient_id,
        };

        HttpRequest.assignPatientPlan(data)
            .then(res => {
                Swal.fire('Information', 'Assign success.', 'success');
                this.setState({ provider_id: null, patient_id: null });
                this.loadPatientPlan();
            })
            .catch(err => {
                Swal.fire('Error', 'Assign failed.', 'error');
            });
    }




    applyPlanFilters() {
        const { searchText, patientPlans } = this.state;
        let filteredPlans = patientPlans;

        if (searchText) {
            filteredPlans = filteredPlans.filter(
                plan =>
                    plan.patient_name.toLowerCase().includes(searchText.toLowerCase())
            );
        }

        this.setState({ selectedPlans: filteredPlans });
    }

    render() {
        let plan = this.state.plan;
        return (
            <>
                <div className="flex-row" style={{ marginBottom: 10 }}>
                    <Link to="/plan" className="btn btn-secondary btn-round">
                        <FontAwesomeIcon icon={faChevronLeft} /> Back to plan management
                    </Link>
                    <div className="flex-column flex" />
                    <Link
                        to="#"
                        className="btn btn-secondary btn-round"
                        onClick={e => {
                            e.preventDefault();
                            window.jQuery('#modal-assign').modal('show');
                        }}>
                        <FontAwesomeIcon icon={faEnvelopeOpenText} /> Assign plan
                    </Link>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <Panel>
                            {this.state.isLoadingList && <ActivityIndicator />}
                            {!this.state.isLoadingList && (
                                <DataGrid
                                    filters={[
                                        {
                                            type: 'textinput',
                                            label: 'Search plan',
                                            icon: faSearch,
                                            onChange: text => {
                                                this.setState({ searchText: text }, this.applyPlanFilters)
                                            },
                                            className: 'col-sm-12 col-md-12',
                                        },
                                    ]}
                                    columns={[
                                        {
                                            label: 'Picture',
                                            renderItem: item => {
                                                return <CircleImage src={ImageIcon} size={30} />;
                                            },
                                        },
                                        {
                                            name: 'patient_name',
                                            label: 'Name',
                                            renderItem: item => {
                                                return item.patient_name

                                            }
                                        },
                                        { name: 'assigned_to_name', label: 'Assigned By' },
                                        {
                                            label: 'Controls',
                                            renderItem: item => {
                                                return (
                                                    <>
                                                        <a
                                                            href="#"
                                                            style={styles.control}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                this.deletePatientPlan(item.id);
                                                            }}>
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </a>
                                                    </>
                                                );
                                            },
                                        },
                                    ]}
                                    data={this.state.selectedPlans ? this.state.selectedPlans : this.state.patientPlans || []}
                                />
                            )}
                        </Panel>
                    </div>
                    <div className="col-md-4">
                        <Panel>
                            {this.state.isLoadingPlan && <ActivityIndicator />}
                            {!this.state.isLoadingPlan && plan !== null && (
                                <>
                                    <h5>{plan?.title}</h5>
                                    <span className="badge badge-info">{plan?.type}</span>
                                    {
                                        // Corrected the JSON.parse and variable declaration outside JSX
                                        (() => {
                                            const data = JSON.parse(plan.description);
                                            let extractedText = '';
                                            for (const block of data.blocks) {
                                                extractedText += block.text + ' ';
                                            }
                                            return <p>{extractedText}</p>;
                                        })()
                                    }
                                </>
                            )}

                        </Panel>
                    </div>
                </div>

                <div className="modal" tabindex="-1" role="dialog" id="modal-assign">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Assign a Plan</h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Patient</label>
                                        <div className="col-sm-8">
                                            <select
                                                className="form-control"
                                                value={this.state.patient_id || ''}
                                                onChange={event => {
                                                    this.setState({ patient_id: event.target.value });
                                                }}>
                                                <option value="">Choose Contact</option>
                                                {this.state.patients.map((user, key) => {
                                                    return (
                                                        <option value={user.user.id} key={key}>
                                                            {user.user.name} {user.user.last_name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-4 col-form-label">Provider</label>
                                        <div className="col-sm-8">
                                            <select
                                                className="form-control"
                                                value={this.state.provider_id || ''}
                                                onChange={event => {
                                                    this.setState({ provider_id: event.target.value });
                                                }}>
                                                <option value="">Choose Provider</option>
                                                {this.state.providers.map((user, key) => {
                                                    return (
                                                        <option value={user.user.id} key={key}>
                                                            {user.user.name} {user.user.last_name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        this.assignPatient();
                                    }}>
                                    Assign
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const styles = {
    control: {
        marginLeft: 8,
    },
};

export default withRouter(PlanDetail);
