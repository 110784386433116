import React from 'react';
import DataGrid from '../components/DataGrid';
import Panel from '../components/Panel';
import moment from 'moment';
import {
    faCommentAlt,
    faEdit,
    faEyeSlash,
    faPaste,
    faPlus,
    faSearch,
    faTrash,
    faUsers,
    faChevronLeft,
    faChevronRight,
    faCaretRight,
    faCaretLeft
} from '@fortawesome/free-solid-svg-icons';
import CircleImage from '../components/CircleImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HttpRequest } from '../utils/http';
import ActivityIndicator from '../components/ActivityIndicator';
import { Link } from 'react-router-dom';
import { store } from '../store';
import Swal from 'sweetalert2';
import { subDays, isWithinInterval, isEqual } from 'date-fns';


class Survey extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            patients: [],
            surveys: [],
            SearchBy: 'Powerup Name',
            dateFilter: '',
            next: null,
            previous: null,
            currentPage: 1,
            totalPages: 1,
            selectedSurveys: null,
            searchText: ''
        };
    }

    componentDidMount() {
        let user = store.getState().loginReducer.user;
        if (user) {
            this.loadSurvey();
        }
    }

    loadSurvey() {
        this.setState({ isLoading: true });

        HttpRequest.surveyList(this.state.currentPage)
            .then(res => {
                let next = res.data.next;
                let previous = res.data.previous;
                const nextPageNumber = next ? next.split('page=')[1] : null;
                const previousPageNumber = previous ? previous.split('page=')[1] : null;
                const count = res.data.count;
                const roundedPage = Math.ceil(count / 20)
                let surveys = res.data.results;
                surveys.sort((a, b) => new Date(b.created) - new Date(a.created));
                this.setState({
                    isLoading: false,
                    surveys,
                    next: nextPageNumber,
                    previous: previousPageNumber,
                    totalPages: roundedPage
                });
            })
            .catch(err => {
                this.setState({ isLoading: false });
                Swal.fire('Error', 'Cannot load Surveys', 'error');
            });
    }
    SearchloadProfile(q) {
        const { SearchBy, surveys } = this.state; // Destructure SearchBy and surveys from state

        if (q === '') {
            this.setState({
                selectedSurveys: null, // Reset selectedSurveys
            });
            this.loadSurvey(); // Reload all surveys
        }

        if (SearchBy === 'Powerup Name') {
            const result = surveys.filter(item =>
                item.title.toLowerCase().includes(q.toLowerCase()),
            );

            this.setState({ surveys: result });
        }

        if (SearchBy === 'Point') {
            const result = surveys.filter(item => item.point.toString().includes(q));
            this.setState({ surveys: result });
        }

        if (SearchBy === 'Tags') {
            const result = surveys.map(item => ({
                ...item,
                tags_desc:
                    item.tags_desc.toLowerCase().includes(q.toLowerCase()) === true
                        ? item.tags_desc
                        : item.tags_desc.includes(q),
            }));
            this.setState({ surveys: result.filter(item => item.tags_desc !== false) });
        }
    }

    applySurveyFilters() {
        const { surveys, dateFilter, searchText, SearchBy } = this.state;
        let filteredSurveys = surveys.slice(); // Create a copy of the surveys array

        // Apply search filter if searchText is provided
        if (searchText) {
            if (SearchBy === 'Powerup Name') {
                filteredSurveys = filteredSurveys.filter(item =>
                    item.title.toLowerCase().includes(searchText.toLowerCase())
                );
            } else if (SearchBy === 'Tags') {
                filteredSurveys = filteredSurveys.filter(item =>
                    item.tags_desc.some(tag =>
                        tag.toLowerCase().includes(searchText.toLowerCase())
                    )
                );
            }
            if (SearchBy === 'Point') {
                const pointValue = parseFloat(searchText);
                if (!isNaN(pointValue)) {
                    filteredSurveys = filteredSurveys.filter(item =>
                        parseFloat(item.point) === pointValue
                    );
                } else {
                    // If searchText is not a valid number, clear the filter
                    filteredSurveys = surveys.slice();
                }
            }
        }

        // Apply date filter if dateFilter is selected
        if (dateFilter && dateFilter !== 'All') {
            const today = new Date();
            const selectedDateRanges = {
                '0-5': {
                    start: subDays(today, 5),
                    end: today,
                },
                '6-10': {
                    start: subDays(today, 10),
                    end: subDays(today, 5),
                },
                '11-15': {
                    start: subDays(today, 15),
                    end: subDays(today, 10),
                },
            };

            const selectedRange = selectedDateRanges[dateFilter]; // Get the selected date range

            if (selectedRange) {
                filteredSurveys = filteredSurveys.filter(item => {
                    const itemDate = new Date(item.created);

                    if (!isNaN(itemDate)) {
                        if (
                            isWithinInterval(itemDate, {
                                start: selectedRange.start,
                                end: selectedRange.end,
                            })
                        ) {
                            return true;
                        } else if (isEqual(itemDate, selectedRange.end)) {
                            return true; // Include the end date in the interval
                        }
                        return false;
                    }
                });
            }
        }

        this.setState({ selectedSurveys: filteredSurveys });
    }

    handleDeleteSurvey = (id) => {
        // Display a Swal confirmation dialog
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this item!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                // User confirmed the deletion, proceed with deleting the item
                HttpRequest.deleteSurvey(id)
                    .then(() => {
                        this.loadSurvey();
                       
                    Swal.fire('Success', 'The item has been deleted.', 'success');
                    })
                    .catch(err => {
                        Swal.fire('Error', 'Cannot delete survey data.', 'error');
                    });
            }
        });
    };

    loadPage = page => {
        this.setState({ currentPage: page }, this.loadSurvey);
    };

    loadNextPage = () => {
        this.loadPage(this.state.currentPage + 1);
    };

    loadPrevPage = () => {
        this.loadPage(this.state.currentPage - 1);
    };

    render() {
        return (
            <>
                <div className="flex-row" style={{ marginBottom: 10 }}>
                    <Link to="/survey/create" className="btn btn-secondary btn-round">
                        <FontAwesomeIcon icon={faPlus} /> Add new PowerUp
                    </Link>
                    <div className="flex-column flex" />
                    {/* <Link to='/survey/create' className='btn btn-secondary btn-round'>
                        <FontAwesomeIcon icon={faPlus} /> Add new survey
                    </Link> */}
                </div>
                <Panel>
                    {this.state.isLoading && <ActivityIndicator />}
                    {!this.state.isLoading && (
                        <DataGrid
                            filters={[
                                {
                                    type: 'textinput',
                                    label: 'Search surveys',
                                    icon: faSearch,
                                    onChange: text => {
                                        this.setState({ searchText: text }, this.applySurveyFilters);
                                    },
                                    className: 'col-sm-12 col-md-7',
                                },
                                {
                                    type: 'select',
                                    label: 'Search by',
                                    data: [
                                        { id: 'Powerup Name', label: 'Powerup Name' },
                                        { id: 'Tags', label: 'Tags' },
                                        { id: 'Point', label: 'Point' },
                                    ],
                                    onChange: text => {
                                        this.setState({ SearchBy: text });
                                    },
                                    className: 'col-sm-6 col-md-3',
                                },
                                {
                                    type: 'select',
                                    label: 'Date',
                                    data: [
                                        { id: 'All', label: 'All' },
                                        { id: '0-5', label: '0 - 5 days ago' },
                                        { id: '6-10', label: '6 - 10 days ago' },
                                        { id: '11-15', label: '11 - 15 days ago' },
                                    ],
                                    onChange: text => {
                                        this.setState(
                                            { dateFilter: text },
                                            this.applySurveyFilters,
                                        );
                                    },
                                    className: 'col-md-2 col-sm-6',
                                    style: { width: 200 },
                                },
                                // {
                                //   type: 'select',
                                //   label: 'Created by',
                                //   data: [{id: '', label: 'Any'}],
                                //   onChange: text => {},
                                //   className: 'col-sm-6 col-md-2',
                                // },
                            ]}
                            columns={[
                                { name: 'title', label: 'PowerUp Name' },
                                {
                                    label: 'Tags',
                                    renderItem: item => {
                                        return item.tags_desc.map((tag, key) => {
                                            return (
                                                <span
                                                    className="badge badge-success"
                                                    key={key}
                                                    style={{ marginRight: 5 }}>
                                                    {tag}
                                                </span>
                                            );
                                        });
                                    },
                                },
                                {
                                    label: 'Date posted',
                                    renderItem: item => {
                                        return moment(item.created).format('MMM DD, YYYY');
                                    },
                                },
                                {
                                    label: 'Responses',
                                    renderItem: item => {
                                        return item.total;
                                    },
                                },
                                {
                                    label: 'Reward',
                                    renderItem: item => {
                                        return item.point;
                                    },
                                },
                                {
                                    label: 'Controls',
                                    renderItem: item => {
                                        return (
                                            <>
                                                <Link
                                                    to={'/survey/member/' + item.id}
                                                    data-toggle="tooltip" data-placement="bottom" title="members"
                                                    style={styles.control}>
                                                    <FontAwesomeIcon icon={faUsers} />
                                                </Link>
                                                <Link
                                                    to={'/survey/edit/' + item.id}
                                                    data-toggle="tooltip" data-placement="bottom" title="view"
                                                    style={styles.control}>
                                                    <FontAwesomeIcon icon={faEyeSlash} />
                                                </Link>
                                                <Link
                                                    to={'/survey/edit/' + item?.id}
                                                    data-toggle="tooltip" data-placement="bottom" title="edit"
                                                    style={styles.control}>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Link>
                                                <Link
                                                    to={'#'}
                                                    data-toggle="tooltip" data-placement="bottom" title="delete"
                                                    style={styles.control}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        this.handleDeleteSurvey(item.id); 
                                                    }}
                                                    >
                                                    <span>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </span>
                                                </Link>
                                            </>
                                        );
                                    },
                                },
                            ]}
                            data={this.state.selectedSurveys
                                ? this.state.selectedSurveys
                                : this.state.surveys || []}
                        />
                    )}
                    <div className="d-flex justify-content-end align-items-center gap-8">

                        <FontAwesomeIcon
                            onClick={this.state.currentPage === 1 ? null : this.loadPrevPage}
                            icon={faCaretLeft}
                            style={{
                                ...styles.pagination,
                                color: this.state.currentPage === 1 ? '#ACB9C2' : '#274F66',
                            }}
                            disabled={this.state.currentPage === 1}
                        />

                        Page {this.state.currentPage} of {this.state.totalPages}

                        <FontAwesomeIcon
                            onClick={!this.state.next ? null : this.loadNextPage}
                            icon={faCaretRight}
                            style={{
                                ...styles.pagination,
                                color: !this.state.next ? '#ACB9C2' : '#274F66',
                            }}
                            disabled={!this.state.next}
                        />

                    </div>
                </Panel>
            </>
        );
    }
}

const styles = {
    control: {
        marginLeft: 8,
    },
    pagination: {
        height: 24,
        width: 28,
        cursor: 'pointer',
        fontSize: '26px',
    }
};

export default Survey;
