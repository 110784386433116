import React, {useState} from 'react';
import {get, set} from 'lodash/fp';
import {withRouter} from 'react-router-dom';
import {HttpRequest, HttpResponse} from '../utils/http';
import {CssBaseline, RadioGroup} from '@mui/material';
import ChallengeCreateHeader from '@components/challenge/ChallengeCreateHeader';
import FieldsetCategory from '@components/challenges2/FieldsetCategory';
import FieldsetGoal from '@components/challenges2/FieldsetGoal/FieldsetGoal';
import FieldsetAssignedTo from '@components/challenges2/FieldsetAssignedTo';
import FieldsetYCoinsReward from '@components/challenges2/FieldsetYCoinsReward';
import FieldsetTarget from '@components/challenges2/FieldsetTarget/FieldsetTarget';
import FieldsetDuration from '@components/challenges2/FieldsetDuration';
import FieldsetFrequency from '@components/challenges2/FieldsetFrequency';
import FieldsetNotifications from '@components/challenges2/FieldsetNotifications/FieldsetNotifications';
import FieldsetScoringScale from '@components/challenges2/FieldsetScoringScale/FieldsetScoringScale';
import FieldsetQuestion from '@components/challenges2/FieldsetQuestion';
import Swal from 'sweetalert2';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom/';

const defaultData = {
  category: '',
  goal: '',
  assignedTo: '',
  yCoinsReward: '',
  target: null,
  duration: '',
  frequency: '',
  notifications: '',
  scoringScale: '',
  question: '',
};

function ChallengeCreate(props) {
  const [data, setData] = useState(defaultData);
  const [selectedScore, setSelectedScore] = useState(0);
  const [notifyChallengeCompletion, setNotifiyChallengeCompletion] =
    useState(false);
  const [scoreTempValues, setScoreTempValues] = useState('');
  const [notificationTempValues, setNotificationTempValues] = useState({});

  const user = useSelector(state => state.loginReducer.user);
  const history = useHistory();

  const handleSave = () => {
    const emptyFields = [];
    for (const key in data) {
      if (!data[key]) {
        emptyFields.push(key);
      }
    }

    if (!data.scoringScale || !data.scoringScale.scoreTemplate) {
      emptyFields.push('Scoring Scale');
    }

    if (emptyFields.length > 0) {
      const errorMessage = `Please fill in the following fields: ${emptyFields.join(
        ', ',
      )}.`;
      Swal.fire('Error', errorMessage, 'error');
      return;
    }

    let challengeData = {
      goal: data?.goal,
      scale_measurement: data.scoringScale?.scoreTemplateValues?.name,
      scale_measurement_values: [
        {
          [data?.scoringScale?.scoreTemplateValues?.name]: scoreTempValues,
        },
      ],
      scale_template: data?.scoringScale?.scoreTemplate,
      notification_template: data?.scoringScale?.scoreTemplate,
      question: data?.question,
      target: data?.target,
      y_coins: data?.yCoinsReward,
      provider: user.user.id,
      frequency: data?.frequency,
      assigned_patients: data?.assignedTo,
      hyperlink: '',
      notification_values: [notificationTempValues],
      notify_if_challenge_is_completed: notifyChallengeCompletion,
      start: data?.duration?.startDate,
      end: data?.duration?.endDate,
      end_recurring_period: null,
      provider_note: '',
    };

    HttpRequest.addChallenge(challengeData)
      .then(res => {
        Swal.fire('Information', 'Challenge saved.', 'success').then(res => {
          history.push('/challenges');
        });
      })
      .catch(err => {
        Swal.fire(
          'Error',
          HttpResponse.processError(err.response, 'Cannot save challenge.'),
          'error',
        );
      });
  };

  const handleFieldUpdate = key => value => {
    setData(set(key, value));
  };

  return (
    <>
      <CssBaseline />
      <ChallengeCreateHeader onSave={handleSave} />
      <RadioGroup name="use-radio-group" defaultValue="1">
        <FieldsetCategory
          value={get('category')(data)}
          onChange={handleFieldUpdate('category')}
          id="1"
        />
        <FieldsetGoal
          value={get('goal')(data)}
          choosedCategory={data.category}
          onChange={handleFieldUpdate('goal')}
          id="2"
        />
        <FieldsetQuestion
          value={get('question')(data)}
          onChange={handleFieldUpdate('question')}
          id="3"
        />
        <FieldsetScoringScale
          value={get('scoringScale')(data)}
          onChange={handleFieldUpdate('scoringScale')}
          scoreTempValues={scoreTempValues}
          setScoreTempValues={setScoreTempValues}
          setSelectedScore={setSelectedScore}
          id="4"
        />
        <FieldsetFrequency
          value={get('frequency')(data)}
          onChange={handleFieldUpdate('frequency')}
          id="5"
        />
        <FieldsetDuration
          value={get('duration')(data)}
          onChange={handleFieldUpdate('duration')}
          id="6"
        />
        <FieldsetTarget
          selectedScore={selectedScore}
          value={get('target')(data)}
          onChange={handleFieldUpdate('target')}
          id="7"
        />
        <FieldsetYCoinsReward
          value={get('yCoinsReward')(data)}
          onChange={handleFieldUpdate('yCoinsReward')}
          id="8"
        />
        <FieldsetNotifications
          selectedScore={selectedScore}
          value={get('notifications')(data)}
          onChange={handleFieldUpdate('notifications')}
          notifyChallengeCompletion={notifyChallengeCompletion}
          setNotifiyChallengeCompletion={setNotifiyChallengeCompletion}
          notificationTempValues={notificationTempValues}
          setNotificationTempValues={setNotificationTempValues}
          id="9"
        />
        <FieldsetAssignedTo
          value={get('assignedTo')(data)}
          onChange={handleFieldUpdate('assignedTo')}
          id="10"
        />
      </RadioGroup>
    </>
  );
}

export default withRouter(ChallengeCreate);
