import {faSearch} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import React from 'react';
import Swal from 'sweetalert2';
import BigCalendar from '../components/BigCalendar';
import CircleImage from '../components/CircleImage';
import DataGrid from '../components/DataGrid';
import Panel from '../components/Panel';
import PanelTab from '../components/PanelTab';
import {store} from '../store';
import {HttpRequest} from '../utils/http';
import DoctorImage from '../assets/icon-doctor.jpg';
import ActivityIndicator from '@components/ActivityIndicator';

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingBooking: false,
      bookingList: [],
      searchCallBy: 'Call With',
      searchTaskBy: 'Patient Name',
      task: [],
      searchCall: '',
      searchTask: '',
      call: [],
      selectedCalls: null,
      selectedTasks: null,
      selectedIndex: 0,
      searchText: '',
    };
  }

  componentDidMount() {
    let user = store.getState().loginReducer.user;
    if (user) {
      this.loadBooking();
      // this.loadTask();
    }
  }

  loadTask() {
    this.setState({isLoading: true});

    HttpRequest.taskList()
      .then(res => {
        let data = res.data.results;

        this.setState({
          isLoading: false,
          tasks: data,
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load task data.', 'error');
      });
  }

  SearchCallsloadProfile() {
    const {call, searchCall, searchCallBy} = this.state;

    let filteredData = call;
    if (searchCallBy === 'Call With') {
      filteredData = call.filter(
        item =>
          item.patient.name &&
          item.patient.name.toLowerCase().includes(searchCall.toLowerCase()),
      );
    }
    if (searchCallBy === 'Message') {
      filteredData = call.filter(
        item =>
          item.title &&
          item.title.toLowerCase().includes(searchCall.toLowerCase()),
      );
    }

    this.setState({selectedCalls: filteredData});
  }
  SearchTasksloadProfile() {
    const {task, searchTask, searchTaskBy} = this.state;

    let filteredData = task;

    if (searchTaskBy === 'Patient Name') {
      filteredData = task.filter(
        item =>
          item.patient.name &&
          item.patient.name.toLowerCase().includes(searchTask.toLowerCase()),
      );
    }
    if (searchTaskBy === 'Task Name') {
      filteredData = task.filter(
        item =>
          item.title &&
          item.title.toLowerCase().includes(searchTask.toLowerCase()),
      );
    }

    this.setState({selectedTasks: filteredData});
  }

  loadBooking() {
    let user = store.getState().loginReducer.user;

    this.setState({isLoadingBooking: true});

    HttpRequest.getBooking(user.user.id)
      .then(res => {
        const data = res.data.results;
        let tasks = [];
        let calls = [];
        for (let i = 0; i < data.length; i++) {
          if (data[i].meeting_uri === null) {
            tasks.push(data[i]);
          } else {
            calls.push(data[i]);
          }
        }
        this.setState({
          isLoadingBooking: false,
          bookingList: res.data.results,
          call: calls,
          task: tasks,
        });
      })
      .catch(err => {
        this.setState({isLoadingBooking: false});
        Swal.fire('Error', 'Cannot get booking data.', 'error');
      });
  }

  render() {
    return (
      <>
        <PanelTab
          tabs={['Calls', 'Tasks']}
          selectedIndex={this.state.selectedIndex}
          onChange={selectedIndex => {
            this.setState({selectedIndex});
          }}
        />
        <Panel>
          {this.state.isLoadingBooking && <ActivityIndicator />}
          {!this.state.isLoadingBooking && this.state.selectedIndex == 0 && (
            <DataGrid
              filters={[
                {
                  type: 'textinput',
                  label: 'Search calls',
                  icon: faSearch,
                  onChange: text => {
                    this.setState(
                      {searchCall: text},
                      this.SearchCallsloadProfile,
                    );
                  },
                  className: 'col-sm-12 col-md-6',
                },
                {
                  type: 'select',
                  label: 'Filter',
                  data: [
                    {id: 'Call With', label: 'Call With'},
                    {id: 'Message', label: 'Message'},
                  ],
                  onChange: text => {
                    this.setState({searchCallBy: text});
                  },
                  className: 'col-sm-6 col-md-2',
                },
              ]}
              columns={[
                {
                  name: 'message',
                  label: 'Message',
                  renderItem: item => {
                    return item.title;
                  },
                },
                {
                  label: 'Call with',
                  renderItem: item => {
                    return (
                      <>
                        <CircleImage src={item.patient.image} size={30} />
                        {' ' + item.patient.name}
                      </>
                    );
                  },
                },
                {
                  label: 'Time',
                  renderItem: item => {
                    return moment(item.start).format('h:mm a');
                  },
                },
              ]}
              data={
                this.state.selectedCalls
                  ? this.state.selectedCalls
                  : this.state.call || []
              }
            />
          )}

          {!this.state.isLoadingBooking && this.state.selectedIndex == 1 && (
            <DataGrid
              filters={[
                {
                  type: 'textinput',
                  label: 'Search Task',
                  icon: faSearch,
                  onChange: text => {
                    this.setState(
                      {searchTask: text},
                      this.SearchTasksloadProfile,
                    );
                  },
                  className: 'col-sm-12 col-md-6',
                },
                {
                  type: 'select',
                  label: 'Filter',
                  data: [
                    {id: 'Patient Name', label: 'Patient Name'},
                    {id: 'Task Name', label: 'Task Name'},
                  ],
                  onChange: text => {
                    this.setState({searchTaskBy: text});
                  },
                  className: 'col-sm-6 col-md-2',
                },
              ]}
              columns={[
                {
                  name: 'name',
                  label: 'Task Name',
                  renderItem: item => {
                    return item.title;
                  },
                },
                {
                  label: 'Patient',
                  renderItem: item => {
                    return (
                      <>
                        <CircleImage src={item.patient.image} size={30} />
                        {' ' + item.patient.name}
                      </>
                    );
                  },
                },
                {
                  label: 'Time',
                  renderItem: item => {
                    return moment(item.start).format('h:mm A');
                  },
                },
              ]}
              data={
                this.state.selectedTasks
                  ? this.state.selectedTasks
                  : this.state.task || []
              }
            />
          )}
        </Panel>

        <Panel>
          <BigCalendar />
        </Panel>
      </>
    );
  }
}

const styles = {};

export default Calendar;
