import React from 'react';
import {Box, Tooltip} from '@mui/material';
import {ReactComponent as FaceSadIcon} from './../../assets/icons/faceSad.svg';
import {ReactComponent as FaceNeuIcon} from './../../assets/icons/faceNat.svg';
import {ReactComponent as FaceHapIcon} from './../../assets/icons/faceHap.svg';
import {ReactComponent as FaceDisableIcon} from './../../assets/icons/faceDisable.svg';
import {ReactComponent as NotesIcon} from './../../assets/icons/notes.svg';
import styled from '@emotion/styled';

const FacesSection = ({data}) => {
  return (
    <Box sx={styles.container}>
      {data?.map((data, index) => (
        <Box key={index} sx={styles.faces}>
          {data?.data?.value === 'sad' ? (
            <FaceSadIcon />
          ) : data?.data?.value === 'neu' ? (
            <FaceNeuIcon />
          ) : data?.data?.value === 'hap' ? (
            <FaceHapIcon />
          ) : (
            <FaceDisableIcon />
          )}
          <Box sx={{mt: 2}}>
          <Tooltip title={data?.data?.journal_activity} arrow>
                  <NotesIcon
                    style={{
                      fill: data?.data?.journal_activity ? '#274F66' : '#ACB9C2',
                      cursor: data?.data?.journal_activity ? 'pointer' : '',
                    }}
                  />
                </Tooltip>
          </Box>
          <Text today={data?.today}>{data?.day?.charAt(0)}</Text>
        </Box>
      ))}
    </Box>
  );
};

export default FacesSection;

const Text = styled.div`
  margin-top: 5px;
  width: 25px;
  height: 25px;
  font-size: 14px;
  display: flex;
  alignitems: center;
  border-radius: 50%;
  justify-content: center;
  border-width: 2px;
  border-color: ${props => (props?.today ? '#274F66' : 'transparent')};
  border-style: solid;
`;
const styles = {
  container: {display: 'flex', justifyContent: 'space-between', my: 2, px: 3},
  faces: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
  },
};
