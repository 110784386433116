import React from 'react';
import DataGrid from '../components/DataGrid';
import Panel from '../components/Panel';
import moment from 'moment';
import {faChevronLeft, faSave} from '@fortawesome/free-solid-svg-icons';
import CircleImage from '../components/CircleImage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {HttpRequest, HttpResponse} from '../utils/http';
import ActivityIndicator from '../components/ActivityIndicator';
import {Link, withRouter} from 'react-router-dom';
import {store} from '../store';
import RichTextEditor from '../components/RichTextEditor';
import Swal from 'sweetalert2';
import StringHelper from '../utils/StringHelper';
import LimitTagAutoComplete from '../components/LimitTagAutoComplete';

class ResourceEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      title: '',
      description: '',
      selectedTags: [],
      tagdata:[],
    };

    this.id = props.match?.params?.id;
  }

  componentDidMount() {
    this.loadTagData();
    let user = store.getState().loginReducer.user;
    if (user && this.id != null) {
      this.loadResource();
    }
  }

  loadResource() {
    this.setState({isLoading: true});

    HttpRequest.getResource(this.id)
      .then(res => {
        let data = res.data.data;

        this.setState({
          isLoading: false,
          title: data?.title,
          description: data?.description,
          tags: data.tags,
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load resource data.', 'error');
      });
  }

  loadTagData() {

    HttpRequest.getallTag()
      .then(res => {
        let data = res.data.data;
        this.setState({
          tagdata: data,
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load user data.', 'error');
      });
  }
  handleTagsChange = (event, value) => {
    this.setState({
      selectedTags: value.map(option => option.name),
    });
  };
  
  save() {
    let user = store.getState().loginReducer.user;

    
    let { title, description, selectedTags } = this.state;
    
    if (!title || !description || selectedTags.length === 0) {
        Swal.fire('Error', 'Please fill in all fields.', 'error');
        return; 
    }
    this.setState({isLoading: true});

    let data = {
      is_removed: false,
      title: this.state.title,
      description: this.state.description,
      url_link:
        'https://shrill-silence-24189-staging.herokuapp.com/' +
        StringHelper.slugify(this.state.title) +
        '/' +
        Math.floor(Math.random() * 10000),
      author: user.user.id,
      shared_permission: [1, 2, 3, 4, 5],
      tags: this.state.selectedTags,
    };

    HttpRequest.saveResource(this.id, data)
      .then(res => {
        let data = res.data.data;

        this.setState({isLoading: false});
        Swal.fire('Information', 'Resource saved.', 'success').then(res => {
        this.props.history.push('/resource');
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire(
          'Error',
          HttpResponse.processError(err.response, 'Cannot save resource data.'),
          'error',
        );
      });
  }

  render() {
    if (this.state.isLoading) {
      return <ActivityIndicator />;
    }

    return (
      <>
        <div className="flex-row" style={{marginBottom: 10}}>
          <Link to="/resource" className="btn btn-secondary btn-round">
            <FontAwesomeIcon icon={faChevronLeft} /> Back to resource
          </Link>
          <div className="flex-column flex" />
          <a
            href="#"
            className="btn btn-warning btn-round"
            onClick={e => {
              e.preventDefault();
              this.save();
            }}>
            <FontAwesomeIcon icon={faSave} /> Save
          </a>
          <Link
            to="/resource"
            className="btn btn-primary btn-round"
            style={{marginLeft: 10}}>
            Cancel
          </Link>
        </div>
        <Panel>
          <form>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">Resource Title</label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.title}
                  onChange={event => {
                    this.setState({title: event.target.value});
                  }}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-2 col-form-label">
                Tags
              </label>
              <div className="col-sm-10">
              <LimitTagAutoComplete
                    tagData={this.state.tagdata}
                    value={this.state.selectedTags}
                    handleTagsChange={this.handleTagsChange}
                                />
                {/* <input
                  type="text"
                  className="form-control"
                  value={this.state.tags}
                  onChange={event => {
                    this.setState({tags: event.target.value});
                  }}
                /> */}
              </div>
            </div>
            <RichTextEditor
             defaultEditorState={this.state.description}
             onEditorStateChange={description => {
                 this.setState({ description: description });
             }}
            />
          </form>
        </Panel>
      </>
    );
  }
}

const styles = {
  control: {
    marginLeft: 8,
  },
};

export default withRouter(ResourceEdit);


  