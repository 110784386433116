import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  ContentState,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
class RichTextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: this.convertValueToState(props.value),
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const editorState = this.convertValueToState(this.props.value);
      this.setState({ editorState });
    }
  }

  convertValueToState(value) {
    if (!value) {
      return EditorState.createEmpty();
    }
    const jsonObject = JSON.parse(value);
    const contentState = convertFromRaw(jsonObject);
    return EditorState.createWithContent(contentState);
  }
  handleEditorChange = (editorState) => {
    this.setState({ editorState });
    if (this.props.onEditorStateChange) {
      const contentState = editorState.getCurrentContent();
      const rawContentState = convertToRaw(contentState);
      this.props.onEditorStateChange(JSON.stringify(rawContentState));
    }
  };

  render() {
    return (
      <Editor
        editorState={this.state.editorState}
        onEditorStateChange={this.handleEditorChange}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
      />
    );
  }
}
export default RichTextEditor;