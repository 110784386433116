import {faEllipsisV} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import Panel from './Panel';

export default function ImagePanel({
  imgSource,
  title,
  description,
  highlight,
  actions = null,
}) {
  const [showMenu, setShowMenu] = useState(false);

  let name = 'Guest';
  let className = 'text-normal';
  let border = {};
  if (title != null) {
    name = title;
  }
  if (highlight == true) {
    className = 'text-danger';
    border = {border: '2px solid #c75a59'};
  }
  if (imgSource == null) {
    imgSource = '/favicon.png';
  }
  return (
    <Panel
      shadow={true}
      style={{display: 'flex', flexDirection: 'row'}}
      onmouseover={() => {
        setShowMenu(true);
      }}
      onmouseout={() => {
        setShowMenu(false);
      }}
    >
      <img src={imgSource} style={{...styles.img, ...border}} />
      <div style={styles.textWrapper}>
        <div style={styles.title} className={className}>
          {name}
        </div>
        <div style={styles.description}>{description}</div>
      </div>
      {actions != null && Array.isArray(actions) && (
        <>
          <a
            style={styles.dotMenu}
            onClick={e => {
              setShowMenu(true);
              e.preventDefault();
            }}
          >
            <FontAwesomeIcon icon={faEllipsisV} />
          </a>

          {showMenu && (
            <>
              <div
                style={styles.overlayDown}
                onClick={() => {
                  setShowMenu(false);
                }}
              />

              <div style={styles.menuList}>
                {actions.map((action, index) => {
                  return (
                    <a
                      key={index}
                      style={styles.menuListItem}
                      href="#"
                      onClick={e => {
                        setShowMenu(false);
                        e.preventDefault();
                        action.onClick();
                      }}
                    >
                      {action.label}
                    </a>
                  );
                })}
              </div>
            </>
          )}
        </>
      )}
    </Panel>
  );
}

let styles = {
  img: {
    borderRadius: 25,
    width: 50,
    height: 50,
    objectFit: 'cover',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginLeft: 10,
    justifyContent: 'center',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  description: {
    fontSize: 12,
  },
  dotMenu: {
    position: 'absolute',
    right: 33,
    top: 10,
  },
  overlayDown: {
    zIndex: 1030,
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100vw',
    height: '100vh',
    background: 'rgba(0,0,0,0.3)',
  },
  menuList: {
    zIndex: 1040,
    background: '#fff',
    padding: 10,
    borderRadius: 5,
    position: 'absolute',
    top: 36,
    right: 30,
  },
  menuListItem: {
    cursor: 'pointer',
  },
};
