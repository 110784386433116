import React from 'react';
import {Box, Tooltip} from '@mui/material';
import {ReactComponent as NoIcon} from './../../assets/icons/No.svg';
import {ReactComponent as YesIcon} from './../../assets/icons/Yes.svg';
import {ReactComponent as NotAvaIcon} from './../../assets/icons/NA.svg';
import {ReactComponent as NotesIcon} from './../../assets/icons/notes.svg';
import styled from '@emotion/styled';

const AnswerSection = ({data}) => {
  return (
    <Box sx={{display: 'flex', justifyContent: 'space-between', my: 2, px: 3}}>
      {data?.map((data, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          {data?.data?.value === 'no' ? (
            <NoIcon />
          ) : data?.data?.value === 'yes' ? (
            <YesIcon />
          ) : (
            <NotAvaIcon />
          )}
          <Box sx={{mt: 2}}>
          <Tooltip title={data?.data?.journal_activity} arrow>
                  <NotesIcon
                    style={{
                      fill: data?.data?.journal_activity ? '#274F66' : '#ACB9C2',
                      cursor: data?.data?.journal_activity ? 'pointer' : '',
                    }}
                  />
                </Tooltip>
          </Box>
          <Text today={data?.today}>{data?.day?.charAt(0)}</Text>
        </Box>
      ))}
    </Box>
  );
};

export default AnswerSection;

const Text = styled.div`
  margin-top: 5px;
  width: 25px;
  height: 25px;
  font-size: 14px;
  display: flex;
  alignitems: center;
  border-radius: 50%;
  justify-content: center;
  border-width: 2px;
  border-color: ${props => (props.today ? '#274F66' : 'transparent')};
  border-style: solid;
`;
