import * as types from './constants';

export function setUser(payload) {
  return {type: types.SET_USER, payload};
}

export function setBottomBar(payload) {
  return {type: types.SET_BOTTOM_BAR, payload};
}

export function setDebug(payload) {
  return {type: types.SET_IS_DEBUG, payload};
}

export function setRibbon(payload) {
  return {type: types.SET_RIBBON, payload};
}

export function setProfile(payload) {
  return {type: types.SET_PROFILE, payload};
}

export function setProfiles(payload) {
  return {type: types.SET_PROFILES, payload};
}

export function setUser2Patient(payload) {
  return {type: types.SET_USER_2_PATIENT, payload};
}
export function setExport(payload) {
  return {type: types.SET_EXPORT, payload};
}
