import React, { useState } from 'react';
import Swal from 'sweetalert2'; 
import Zoom from './Zoom';
import ZoomCreate from './ZoomCreate';

const ZoomIntegration = () => {
  const [joinMeeting, setJoinMeeting] = useState(false);
  const [createMeeting, setCreateMeeting] = useState(false);
  const [meetingId, setMeetingId] = useState('');
  const [meetingUsername, setMeetingUsername] = useState('');
  const [meetingPassword, setMeetingPassword] = useState('');
  const [meetingTopic, setMeetingTopic] = useState(''); 

  const handleCreateMeeting = () => {
    if (!meetingUsername || !meetingTopic) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Username and Topic fields are required!',
      });
    } else {
      setCreateMeeting(true);
    }
  };

  const handleJoinMeeting = () => {
    if (!meetingUsername || !meetingId || !meetingPassword) {
      // Check if any of the fields are empty
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'All fields are required!',
      });
    } else {
      // Proceed with joining the meeting
      setJoinMeeting(true);
    }
  };

  return (
    <>
      <div className="d-flex justify-center">
        <ZoomCreate/>
      </div>
      <div className="d-flex">
        <hr />
        <span>OR</span>
        <hr />
      </div>
      <div className="d-flex justify-center">
        <div className="d-flex justify-center align-center flex-column">
          <input
            type="text"
            placeholder="Username"
            onChange={(e) => setMeetingUsername(e.target.value)}
            style={{ width: '600px', padding: '8px', margin: '5px 0px' }}
          />
          <input
            type="text"
            placeholder="Meeting id"
            onChange={(e) => setMeetingId(e.target.value)}
            style={{ width: '600px', padding: '8px', margin: '5px 0px' }}
          />
          <input
            type="text"
            placeholder="Meeting password"
            onChange={(e) => setMeetingPassword(e.target.value)}
            style={{ width: '600px', padding: '8px', margin: '5px 0px' }}
          />
          <button
            onClick={handleJoinMeeting}
            style={{
              backgroundColor: '#438BF3',
              color: 'white',
              outline: 'none',
              border: 'none',
              padding: '8px 20px',
              borderRadius: '10px',
              cursor: 'pointer',
            }}
          >
            Join a meeting
          </button>
        </div>
      </div>
        
      {joinMeeting && (
        <Zoom
          meetingId={meetingId}
          meetingUsername={meetingUsername}
          meetingPassword={meetingPassword}
        />
      )}
    </>
  );
};

export default ZoomIntegration;
