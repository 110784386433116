import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {Link} from 'react-router-dom';

const Preview = () => {
  let myImage = localStorage.getItem('Image');
  return (
    <>
      <div className="flex-row" style={{marginBottom: 10}}>
        <Link to="/reward/create" className="btn btn-secondary btn-round">
          <FontAwesomeIcon icon={faChevronLeft} /> Back to Create rewards
        </Link>
      </div>
      <div>
        <img src={myImage} alt="My Image" height={400} width={400} />
      </div>
    </>
  );
};

export default Preview;
