import { Box, TextField } from '@mui/material'
import React from 'react'

const TimeSpentNotification = ({setTarget}) => {
    const [selectedValues, setSelectedValues] = React.useState(null);

    const handleChange = (category, value) => {
      setSelectedValues((prevValues) => ({
        ...prevValues,
        [category]: value
      }));
      setTarget((prevValues) => ({
          ...prevValues,
          [category]: value
        }));
    };
  
    return (
        <Box sx={styles.main}>
            <Box sx={styles.boxStyle}>
                <Box sx={styles.timeType}>
                    Hrs
                </Box>
                <TextField
                    sx={styles.textField}
                    size="small"
                    hiddenLabel
                    id="question_challenge"
                    variant="outlined"
                    type="text"
                    value={selectedValues?.Hour}
                    onChange={(event) => handleChange('Hour', event.target.value)}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#E9E9E9',
                    borderRadius: '0.3em',
                    height: '100%',
                    width: '50%',
                }}>
                <Box sx={styles.timeType}>
                    Min
                </Box>
                <TextField
                    sx={styles.textField}
                    size="small"
                    hiddenLabel
                    id="question_challenge"
                    variant="outlined"
                    type="text"
                    value={selectedValues?.Minute}
                    onChange={(event) => handleChange('Minute', event.target.value)}
                />
            </Box>
            <Box
                sx={styles.boxStyle}>
                <Box sx={styles.timeType}>
                    Sec
                </Box>
                <TextField
                    sx={styles.textField}
                    size="small"
                    hiddenLabel
                    id="question_challenge"
                    variant="outlined"
                    type="text"
                    value={selectedValues?.Second}
                    onChange={(event) => handleChange('Second', event.target.value)}
                />
            </Box>
        </Box>
    )
}

export default TimeSpentNotification

const styles = {
    main: {
        display: 'flex',
        alignItems: 'center',
        width: '65%',
        gap: '0.5em',
        '@media (max-width: 1000px)': {
            flexDirection: 'column',
        },
    },
    boxStyle: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#E9E9E9',
        borderRadius: '0.3em',
        height: '100%',
        width: '50%',
        '@media (max-width: 1000px)': {
            marginTop: '0.5em'
        },
    },
    timeType: { padding: '3%', width: '35%', textAlign: 'center' },
    textField: {
        width: '65%',
        backgroundColor: 'white',
        borderRadius: '0.3em',
        height: '100%',
    },
}
