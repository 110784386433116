import {faArrowLeft, faSearch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect } from 'react';
import TextInput from '../components/TextInput';
import {HttpRequest, HttpResponse} from '../utils/http';
import LocalStorage from 'local-storage';
import {setUser} from '../store/actions';
import {connect} from 'react-redux';
import Combobox from '../components/Combobox';
import ActivityIndicator from '../components/ActivityIndicator';
import Swal from 'sweetalert2';
import {ROLE_ADMIN, ROLE_AGENT, ROLE_PROVIDER} from '../store/constants';

import Logo from '../assets/ygbr-logo.png';
import LogoWhite from '../assets/ygbr-logo-white.png';
import eye from '../assets/icons/eye.svg';
class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 'Home',
      username: '',
      password: '',
      authType: 'google',
      code: '',
      dateofbirth: '',
      rUsername: '',
      rPassword: '',
      rPassword2: '',
      rName: '',
      rLastName: '',
      rAs: 'provider',
      errorMessage: '',
      fEmail: '',
      showPassword: false,
      showRegisterPass: false,
      showRegisterConfirmPass: false,
    };
    this.handleLoginPassword = this.handleLoginPassword.bind(this);
    this.handleRegisterPassword = this.handleRegisterPassword.bind(this);
  }

  componentDidMount() {
    // this.props.dispatchSignin(null);
    // LocalStorage.clear();
  }

  handleLoginPassword(event) {
    const newPassword = event.target.value;
    this.setState({password: newPassword});

    // Validate password
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /\d/;
    const minLength = 8; // Minimum length requirement

    const isLengthValid = newPassword.length >= minLength;
    const hasUppercase = uppercaseRegex.test(newPassword);
    const hasLowercase = lowercaseRegex.test(newPassword);
    const hasSpecialChar = specialCharRegex.test(newPassword);
    const hasNumber = numberRegex.test(newPassword);

    const errorMessageParts = [];
    if (!isLengthValid) {
      errorMessageParts.push('Password must be at least 8 characters long');
    }
    if (!hasUppercase) {
      errorMessageParts.push('one uppercase letter (A)');
    }
    if (!hasLowercase) {
      errorMessageParts.push('one lowercase letter (b)');
    }
    if (!hasSpecialChar) {
      errorMessageParts.push('one special character (#)');
    }
    if (!hasNumber) {
      errorMessageParts.push('one number (0)');
    }

    if (errorMessageParts.length > 0) {
      this.setState({
        errorMessage:
          'Password must contain ' + errorMessageParts.join(', and '),
      });
    } else {
      this.setState({errorMessage: ''});
    }
  }
  handleRegisterPassword(event) {
    const newPassword = event.target.value;
    this.setState({rPassword: newPassword});

    // Validate password
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /\d/;
    const minLength = 8; // Minimum length requirement

    if (
      newPassword.length < minLength ||
      !uppercaseRegex.test(newPassword) ||
      !lowercaseRegex.test(newPassword) ||
      !specialCharRegex.test(newPassword) ||
      !numberRegex.test(newPassword)
    ) {
      this.setState({
        errorMessage:
          'Password must be at least 8 characters long and contain at least one uppercase letter (A), one lowercase letter(b), one special character(#), and one number(0)',
      });
    } else {
      this.setState({errorMessage: ''});
    }
  }
  async login() {
    this.setState({isLoading: true, isError: false});
    let {username, password} = this.state;
    let data = {username, password};
    HttpRequest.login(data)
      .then(res => {
        let userData = res.data;
        console.log("🚀 ~ Login ~ login ~ userData:", userData)
        if (userData.message === 'wrong password or email') {
          this.setState({isLoading: false});
          Swal.fire('Error', userData.message, 'error');
          return;
        }
        if (userData?.token === 'access denied') {
          this.setState({isLoading: false});
          Swal.fire('Error', userData?.token, 'error');
          return;
        }
        if(userData.user.role !== 1) {
        HttpRequest.getInactiveProvider()
        .then(res => {
          this.setState({isLoading: false});
          if (res.data?.results.map(user => user?.user?.id).includes(userData.user.id)) {
            Swal.fire(
              'Error',
              'Admin has not approved your profile yet',
              'error',
            );
            return;
          }

          this.props.dispatchSignin(userData);
          LocalStorage.set('user', JSON.stringify(userData));
          LocalStorage.set('userID', JSON.stringify(userData.user.id));
          window.location = '/';
        })
        .catch(err => {
          console.log('Error in Login', err);
        });
    }
    if(userData.user.role === 1) {
        
        this.props.dispatchSignin(userData);
        LocalStorage.set('user', JSON.stringify(userData));
        LocalStorage.set('userID', JSON.stringify(userData.user.id));
        window.location = '/';
    }
    
      }) 
      .catch(err => {
        this.setState({isLoading: false, isError: true});
        console.log("ErrorError", err)
        Swal.fire('Error', 'Error during login', 'error');
      });
  }

  register() {
    console.log('working');
    let {
      rUsername,
      email,
      rPassword,
      rPassword2,
      rName,
      rLastName,
      rAs,
      dateofbirth,
    } = this.state;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      Swal.fire('Error', 'Invalid email format.', 'error');
      return;
    }

    // Ensure the email ends with '@ybgr.com'
    if (!email.toLowerCase().endsWith('@ybgr.org')) {
      Swal.fire('Error', 'Email must end with @ybgr.org', 'error');
      return;
    }

    if (rPassword != rPassword2) {
      Swal.fire('Error', "Password doesn't match.", 'error');
      return;
    }
    
    if (!dateofbirth) {
      Swal.fire('Error', "Date of Birth is required!", 'error');
      return;
    }

    const currentDate = new Date();
    const birthDate = new Date(dateofbirth);
    const ageDiff = currentDate.getFullYear() - birthDate.getFullYear();

    if (ageDiff < 18) {
      Swal.fire(
        'Error',
        'You must be at least 18 years old to register.',
        'error',
      );
      return;
    }
    this.setState({isLoading: true, isError: false});

    let data = {
      user: {
        username: rUsername,
        name: rName,
        last_name: rLastName,
        email: email,
        password: rPassword,
        birth_date: dateofbirth,
      },
      image: null,
      birth_date: null,
      address: '-',
      contact_info: '-',
      phone: '',
      gender: 'Male',
      security_question: '-',
      security_answer: '-',
    };

    if (rAs == 'provider') {
      data.provider = {
        title: 'YBGRConnect Specialist',
        default_photo: null,
        department: 1,
      };
    }

    HttpRequest.signup(data, rAs == 'provider' ? 3 : 2)
      .then(res => {
        this.setState({isLoading: false});

        // this.props.dispatchSignin(res.data);
        // LocalStorage.set("user", JSON.stringify(res.data));
        // window.location = "/";
        this.setState({
          page: 'Login',
          username: email,
          password: rPassword,
        });
      })
      .catch(err => {
        this.setState({isLoading: false, isError: true});
        Swal.fire(
          'Error',
          HttpResponse.processError(err.response, 'Cannot save data.'),
          'error',
        );
      });
  }

  renderLoginForm() {
    const {password, errorMessage} = this.state;
    return (
      <div style={{overflowY: 'auto', padding: 16}}>
        <a
          href="#"
          onClick={e => {
            e.preventDefault();
            this.setState({page: 'Home'});
          }}>
          <FontAwesomeIcon icon={faArrowLeft} /> Login
        </a>

        <div style={{height: 20}} />

        <TextInput
          value={this.state.username}
          title="Email"
          placeholder="Enter your email"
          onChange={event => {
            this.setState({username: event.target.value});
          }}
        />

        <div
          style={{
            position: 'relative',
          }}>
          <div
            style={{
              width: '1.5em',
              height: '1.5em',
              position: 'absolute',
              top: 8,
              right: 5,
              zIndex: 2,
            }}
            onClick={() => {
              this.setState({showPassword: !this.state.showPassword});
            }}>
            <img
              src={eye}
              alt="eye"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                cursor: 'pointer',
              }}
            />
          </div>
          <TextInput
            value={this.state.password}
            title="Password"
            placeholder="Enter your password"
            type={this.state.showPassword ? 'text' : 'password'}
            // onChange={event => {
            //   this.setState({password: event.target.value});
            // }}
            onChange={this.handleLoginPassword}
          />
        </div>
        {errorMessage && <div style={styles.ErrorMessage}>{errorMessage}</div>}

        <div style={{height: 20}} />

        <div style={styles.title1}>2fA Authentication</div>
        <div style={styles.title2}>Select a secure login verification</div>

        <div style={{height: 30}} />

        <Combobox
          value={this.state.authType}
          title="Authentication type"
          data={[
            {id: 'google', label: 'Google Auth'},
            {id: 'facebook', label: 'Facebook Auth'},
          ]}
          onChange={event => {
            this.setState({authType: event.target.value});
          }}
        />

        <TextInput
          value={this.state.code}
          title="Code"
          placeholder="Enter your code"
          onChange={event => {
            this.setState({code: event.target.value});
          }}
        />

        {this.state.isLoading && <ActivityIndicator />}
        {!this.state.isLoading && (
          <a
            href="#"
            className="btn btn-primary btn-block"
            onClick={e => {
              e.preventDefault();
              this.login();
            }}>
            Sign in
          </a>
        )}

        <div style={{height: 30}} />

        <a
          href="#"
          className="flex-row align-center justify-center"
          style={styles.bottomLink}
          onClick={e => {
            e.preventDefault();
            this.setState({page: 'Register'});
          }}>
          Don't have an account? Sign up now
        </a>
        <a
          href="#"
          className="flex-row align-center justify-center"
          style={styles.bottomLink}
          onClick={e => {
            e.preventDefault();
            this.setState({page: 'Forgot'});
          }}>
          Forgot your password?
        </a>
      </div>
    );
  }

  renderRegisterForm() {
    const {rPassword, errorMessage} = this.state;

    return (
      <div style={{overflowY: 'auto', padding: 16}}>
        <a
          href="#"
          onClick={e => {
            e.preventDefault();
            this.setState({page: 'Home'});
          }}>
          <FontAwesomeIcon icon={faArrowLeft} /> Register new account
        </a>
        <div style={{height: 20}} />
        <Combobox
          value={this.state.rAs}
          title="Register as"
          data={[{id: 'provider', label: 'Provider'}]}
          onChange={event => {
            this.setState({rAs: event.target.value});
          }}
        />
        <TextInput
          value={this.state.rUsername}
          title="Username"
          placeholder="Enter username"
          onChange={event => {
            this.setState({rUsername: event.target.value});
          }}
        />
        <TextInput
          value={this.state.rName}
          title="Name"
          placeholder="Enter your name"
          onChange={event => {
            this.setState({rName: event.target.value});
          }}
        />
        <TextInput
          value={this.state.rLastName}
          title="Last Name"
          placeholder="Enter your last name"
          onChange={event => {
            this.setState({rLastName: event.target.value});
          }}
        />
        <TextInput
          value={this.state.email}
          title="Email"
          placeholder="Enter your email"
          onChange={event => {
            this.setState({email: event.target.value});
          }}
        />
        <div
          style={{
            position: 'relative',
          }}>
          <div
            style={{
              width: '1.5em',
              height: '1.5em',
              position: 'absolute',
              top: 8,
              right: 5,
              zIndex: 2,
            }}
            onClick={() => {
              this.setState({showRegisterPass: !this.state.showRegisterPass});
            }}>
            <img
              src={eye}
              alt="eye"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                cursor: 'pointer',
              }}
            />
          </div>
          <TextInput
            value={this.state.rPassword}
            title="Password"
            placeholder="Enter your password"
            type={this.state.showRegisterPass ? 'text' : 'password'}
            //   onChange={event => {
            //     this.setState({rPassword: event.target.value});
            //   }}
            onChange={this.handleRegisterPassword}
          />
          {errorMessage && (
            <div style={{marginBottom: '1em'}}>
              {' '}
              <div style={styles.ErrorMessage}>{errorMessage}</div>
            </div>
          )}
        </div>{' '}
        <div
          style={{
            position: 'relative',
          }}>
          <div
            style={{
              width: '1.5em',
              height: '1.5em',
              position: 'absolute',
              top: 8,
              right: 5,
              zIndex: 2,
            }}
            onClick={() => {
              this.setState({
                showRegisterConfirmPass: !this.state.showRegisterConfirmPass,
              });
            }}>
            <img
              src={eye}
              alt="eye"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                cursor: 'pointer',
              }}
            />
          </div>
          <TextInput
            value={this.state.rPassword2}
            title="Confirm Password"
            placeholder="Enter your password"
            type={this.state.showRegisterConfirmPass ? 'text' : 'password'}
            onChange={event => {
              this.setState({rPassword2: event.target.value});
            }}
          />
        </div>
        <TextInput
          value={this.state.dateofbirth}
          title="Enter Date of Birth"
          placeholder="Enter date of birth"
          type="date"
          onChange={event => {
            this.setState({dateofbirth: event.target.value});
          }}
        />
        {this.state.isLoading && <ActivityIndicator />}
        {!this.state.isLoading && (
          <a
            href="#"
            className="btn btn-primary btn-block"
            onClick={e => {
              e.preventDefault();
              this.register();
            }}>
            Sign up now
          </a>
        )}
        <div style={{height: 30}} />
        <a
          href="#"
          className="flex-row align-center justify-center"
          style={styles.bottomLink}
          onClick={e => {
            e.preventDefault();
            this.setState({page: 'Login'});
          }}>
          Already have an account? Sign in now
        </a>
        <a
          href="#"
          className="flex-row align-center justify-center"
          style={styles.bottomLink}
          onClick={e => {
            e.preventDefault();
            this.setState({page: 'Forgot'});
          }}>
          Forgot your password?
        </a>
      </div>
    );
  }

  renderForgetPassword() {
    return (
      <div style={{overflowY: 'auto', padding: 16}}>
        <a
          href="#"
          onClick={e => {
            e.preventDefault();
            this.setState({page: 'Home'});
          }}>
          <FontAwesomeIcon icon={faArrowLeft} /> Reset my password
        </a>

        <div style={{height: 20}} />

        <TextInput
          value={this.state.fEmail}
          title="Enter your email"
          placeholder="Enter your email"
          onChange={event => {
            this.setState({fEmail: event.target.value});
          }}
        />

        <div style={{height: 20}} />

        <a
          href="javascript:void(0)"
          className="btn btn-primary btn-block"
          onClick={() => {
            Swal.fire('Error', 'Feature not implemented yet.', 'error');
          }}>
          Reset my password
        </a>

        <div style={{height: 30}} />

        <a
          href="#"
          className="flex-row align-center justify-center"
          style={styles.bottomLink}
          onClick={e => {
            e.preventDefault();
            this.setState({page: 'Login'});
          }}>
          Already have an account? Sign in now
        </a>
        <a
          href="#"
          className="flex-row align-center justify-center"
          style={styles.bottomLink}
          onClick={e => {
            e.preventDefault();
            this.setState({page: 'Forgot'});
          }}>
          Forgot your password?
        </a>
      </div>
    );
  }

  render() {
    let page = this.state.page;

    if (page == 'Home') {
      return (
        <div className="login-page flex-column align-center justify-center">
          <div className="login-box flex-column justify-center">
            <img src={Logo} style={{width: 200, margin: '0px auto 30px'}} />

            <a
              href="#"
              className="btn btn-primary btn-block"
              onClick={e => {
                e.preventDefault();
                this.setState({page: 'Login'});
              }}>
              Sign in
            </a>
            <a
              href="#"
              className="btn btn-warning btn-block"
              onClick={e => {
                e.preventDefault();
                this.setState({page: 'Register'});
              }}>
              Sign up now
            </a>
          </div>
        </div>
      );
    } else if (page == 'Login' || page == 'Register' || page == 'Forgot') {
      return (
        <>
          <div className="d-none d-md-block">
            <div className="login-page flex-column align-center justify-center">
              <div className="login-form flex-row">
                <div className="flex-column align-center justify-center flex">
                  <img src={LogoWhite} style={{width: 200}} />
                </div>
                <div className="flex-column align-center justify-center flex"></div>
              </div>
            </div>
            <div className="login-form-overlay flex-column align-center justify-center">
              <div
                className={`${
                  page === 'Register' ? 'register-form' : 'login-form'
                } flex-row`}>
                <div className="flex-column align-center justify-center flex"></div>
                <div className="flex-column flex has-white">
                  {page == 'Login' && this.renderLoginForm()}
                  {page == 'Register' && this.renderRegisterForm()}
                  {page == 'Forgot' && this.renderForgetPassword()}
                </div>
              </div>
            </div>
          </div>
          <div className="d-md-none">
            <div className="flex-column align-center justify-center">
              <img src={Logo} style={{width: 200, marginTop: 50}} />
            </div>

            {page == 'Login' && this.renderLoginForm()}
            {page == 'Register' && this.renderRegisterForm()}
            {page == 'Forgot' && this.renderForgetPassword()}
          </div>
        </>
      );
    }
  }
}

const styles = {
  title1: {
    fontSize: 16,
  },
  bottomLink: {
    textAlign: 'center',
    color: '#0077bd',
    fontSize: 11,
    marginBottom: 5,
  },
  ErrorMessage: {
    color: 'red',
  },
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  dispatchSignin: data => dispatch(setUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
