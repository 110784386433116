import { Button } from '@mui/material';
import React, {useState} from 'react';

const TabOptions = () => {
  const NavButtonStyleActive = {
    height: '30px',
    minWidth: '65px',
    textAlign: 'center',
    borderRadius: '50px',
    color: 'white',
    backgroundColor: '#274F66',
    textTransform: 'none',
    fontSize: '13px',
    outline: 'none',
    padding: '5px 15px',
  };
  const NavButtonStyleDeactive = {
    height: '30px',
    minWidth: '65px',
    textAlign: 'center',
    color: 'grey',
    borderRadius: '50px',
    backgroundColor: 'white',
    textTransform: 'none',
    fontSize: '13px',
    outline: 'none',
    padding: '8px 15px',
  };

  const [activeLabelButton, setActiveLabelButton] = useState('Daily');
 const buttonLabel = ['Daily', 'Weekly', 'Monthly']
  const handleButtonClick = buttonLabel => {
    setActiveLabelButton(buttonLabel);
  };

  return (
    <div>
      <div className="select-options">
        {buttonLabel.map(label => {
          return (
            <Button
              style={
                activeLabelButton === label
                  ? NavButtonStyleActive
                  : NavButtonStyleDeactive
              }
              key={label}
              onClick={() => handleButtonClick(label)}>
              {label}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default TabOptions;
