import * as React from 'react';
import {deepPurple} from '@mui/material/colors';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import {FormControl, InputLabel} from '@mui/material';
export default function UISelect({children, ...props}) {
  return (
    <FormControl fullWidth>
      <Select
        {...props}
        input={
          <OutlinedInput
            inputProps={{'aria-label': 'Without label', hiddenlabel: "true"}}
            hiddenlabel
            label={''}
            // placeholder="Please enter text"
          />
        }
        sx={{
          width: "100%",
          height: props.fullHeight ? null : 30,
          background: 'white',
          // color: 'success.main',
          '& .MuiSelect-select': {
            fontSize: 17,
            outline: 0,
            // minWidth: 200,
            // background: 'white',
            color: 'darkGray',
            fontWeight: 600,
            borderStyle: 'none',
            paddingTop: 1,
            paddingBottom: 1,
            // boxShadow: '0px 5px 8px -3px rgba(0,0,0,0.14)',
            '&:focus': {
              // borderRadius: 12,
              // background: 'white',
              // borderColor: deepPurple[100],
            },
          },
        }}
      >
        {children}
      </Select>
    </FormControl>
  );
}











