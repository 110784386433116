
import React, {useEffect} from 'react';

let payload = {
  meetingNumber: 73311871877,
  role: 0,
  sdkKey: 'gUyHF71AJjssM4roIYW23CSS00RBgl9jOILM',
  sdkSecret: '7Xvla9LviBvRn45hDJ6TWLwrClW7lZQrAgxX',
  passWord: 'iDwuM2',
  userName: 'Testing',
  userEmail: '',
  leaveUrl: 'http://localhost:3000',
};

const Zoom = ({meetingId, meetingUsername, meetingPassword}) => {
  useEffect(async () => {
    const {ZoomMtg} = await import('@zoomus/websdk');
    ZoomMtg.setZoomJSLib('https://source.zoom.us/lib', '/av');
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();

    ZoomMtg.generateSDKSignature({
      meetingNumber: meetingId,
      role: payload.role,
      sdkKey: payload.sdkKey,
      sdkSecret: payload.sdkSecret,
      success: function (signature) {
        ZoomMtg.init({
          leaveUrl: payload.leaveUrl,
          success: function (data) {
            ZoomMtg.join({
              meetingNumber: meetingId,
              signature: signature.result,
              sdkKey: payload.sdkKey,
              userName: meetingUsername,
              userEmail: payload.userEmail,
              passWord: meetingPassword,
              tk: '',
              success: function () {},
              error: function (error) {},
            });
          },
          error: function (error) {},
        });
      },
      error: function (error) {},
    });
  }, []);

  return <></>;
};

export default Zoom;
