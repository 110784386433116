import * as React from 'react';
import {FormControlLabel, Radio, useRadioGroup, Tooltip} from '@mui/material';
import {Box} from '@mui/system';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export default function ChallengeOption({
  notificationCompleted,
  categorydata,
  children,
  help,
  ...props
}) {
  const radioGroup = useRadioGroup();
  const filled = +radioGroup?.value <= +props.value;
  const checked = radioGroup?.value === props.value;

  return (
    <Box
      sx={{
        // https://mui.com/system/properties/#properties-reference-table
        borderRadius: 2,
        boxShadow: notificationCompleted ? 1 : 1,
        padding: '0 1em 0 1em',
        minHeight: 60,
        marginY: notificationCompleted ? 0 : 0.5,
        color: filled ? '#274F66' : '#ACB9C2',
        bgcolor: checked ? 'background.checked' : 'background.paper',
        display: 'flex',
        alignItems: 'center',
        gridTemplateColumns: 'repeat(4, 1fr)',
      }}>
      <FormControlLabel
        sx={{
          mb: 0,
          gridColumn: '1 / 1',
          flex: categorydata === 'category' ? 0.2 : 0.23,
          color: checked ? '#274F66' : '#ACB9C2',
        }}
        control={
          <Radio size="small" sx={{color: checked ? '#274F66' : '#ACB9C2'}} />
        }
        {...props}
      />
      <Box
        sx={{
          display: 'flex',
          flex: categorydata === 'category' ? 0.9 : 1,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        {children}
      </Box>
      {categorydata === 'category' ? (
        ''
      ) : (
        <Tooltip title={help} arrow>
          <HelpOutlineIcon
            style={{flex: categorydata === 'category' ? 0.03 : ''}}
          />
        </Tooltip>
      )}
    </Box>
  );
}

// import * as React from 'react';
// import { FormControlLabel, Radio, useRadioGroup, Box } from '@mui/material';
// import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// import { ThemeProvider, createTheme } from '@mui/material/styles';

// const theme = createTheme();

// export default function ChallengeOption({ notificationCompleted, categorydata, children, ...props }) {
//   const radioGroup = useRadioGroup();
//   const filled = +radioGroup?.value <= +props.value;
//   const checked = radioGroup?.value === props.value;

//   return (
//     <ThemeProvider theme={theme}>
//       <Box
//         sx={{
//           borderRadius: 2,
//           boxShadow: notificationCompleted ? 1 : 1,
//           padding: "0 1em 0 1em",
//           minHeight: 60,
//           marginY: notificationCompleted ? 0 : 0.5,
//           color: filled ? '#274F66' : '#ACB9C2',
//           bgcolor: checked ? 'background.checked' : 'background.paper',
//           display: 'flex',
//           alignItems: "center",
//           gridTemplateColumns: 'repeat(4, 1fr)',
//           fontSize: {
//             xs: '1.5vw', // Font size for extra small screens
//             sm: '1.2vw', // Font size for small screens
//             md: '2.8vw', // Font size for medium screens
//             lg: '2.5vw', // Font size for large screens
//             xl: '2.2vw', // Font size for extra large screens
//           }[theme.breakpoints.up('md')], // Change 'md' to the breakpoint you want to use as the cutoff for the font size
//         }}
//       >
//         <FormControlLabel
//           sx={{ mb: 0, gridColumn: '1 / 1', flex: categorydata === "category" ? 0.2 : 0.23 }}
//           control={<Radio size="small" sx={{ color: checked ? '#274F66' : '#ACB9C2' }} />}
//           {...props}
//         />
//         <Box
//           sx={{
//             display: 'flex',
//             flex: categorydata === "category" ? 0.9 : 1,
//             alignItems: 'center',
//             justifyContent: 'space-between',
//           }}
//         >
//           {children}
//         </Box>
//         {categorydata === "category" ? "" : <HelpOutlineIcon style={{ flex: categorydata === "category" ? 0.03 : '' }} />}
//       </Box>
//     </ThemeProvider>
//   );
// }
