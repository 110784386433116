import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import {ReactComponent as PhoneIcon} from "./../assets/icons/phone.svg"
import {ReactComponent as AddNumberIcon} from "./../assets/icons/add-number.svg"
import {ReactComponent as AddEventIcon} from "./../assets/icons/add-event.svg"
import {ReactComponent as RemindEventIcon} from "./../assets/icons/remind-event.svg"
import {ReactComponent as AddNoteIcon} from "./../assets/icons/add-note.svg"
import {ReactComponent as RecordIcon} from "./../assets/icons/record.svg"
import moment from 'moment';
import CircleImage from './CircleImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPaperclip,
    faPaperPlane,
    faSearch,
    faSmile,
    faGift,
    faStar
} from '@fortawesome/free-solid-svg-icons';
import Picker from 'emoji-picker-react';
import Loading from '../assets/loading.gif';
import { useSelector } from 'react-redux';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { IconButton } from '@mui/material';
import { yellow } from '@mui/material/colors';
import IconImg from './favicon.png';
import { HttpRequest } from '../utils/http';
import HappyImage from '../assets/icon-happy.png';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

export default function Chat(props) {
    const [message, setMessage] = useState('');
    const [file, setFile] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [filteredMessages, setFilteredMessages] = useState([]);
    const audioRef = useRef();
    const [isPlaying, setIsPlaying] = useState(false);
    const [giphySearchQuery, setGiphySearchQuery] = useState('');
    const [giphyResults, setGiphyResults] = useState([]);
    const userId = props.selectedUserId;
    const handlePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
            setIsPlaying(false);
        } else {
            audioRef.current.play();
            setIsPlaying(true);
        }
    };

    const scrollRef = useRef();

    const patients = useSelector(state => state.profiles);

      useEffect(() => {
        scrollToBottom();
      }, [props.messages]);

      useEffect(() => {
        scrollToBottom();
      }, [filteredMessages]);
      
    useEffect(() => {
        // Filter messages based on the search text
        if (searchText !== "") {
            const filtered = props.messages.filter(message =>
                message.message?.text?.toLowerCase().includes(searchText.toLowerCase())
            );
            setFilteredMessages(filtered);
        } else {
            setFilteredMessages(props.messages);
        }
    }, [props.messages, searchText]);
     
    useEffect(() => {
        if (file != null) {
            sendMessage(true);
        }
    }, [file]);

    const sendGif = useCallback(
        original => {
            props.onSend(original, true);
            window.jQuery('#modal-gif').modal('hide');
        },
    );
    /// Gif
    const handleChange = (event) => {
        setGiphySearchQuery(event.target.value);
        setTimeout(() => {
            searchGiphy();
        }, 500);
    };

    const searchGiphy = useCallback(
        () => {
            // setIsLoadingGiphy(true);
            let data = {
                q: giphySearchQuery,
            };

            HttpRequest.searchGiphy(data)
                .then(result => {
                    let giphyResults = result.data.data;
                    if (Array.isArray(giphyResults)) {
                        setGiphyResults(giphyResults);
                    } else {
                        setGiphyResults([]);
                    }
                    // setIsLoadingGiphy(false);
                })
                .catch(err => {
                    // setIsLoadingGiphy(false);
                });
        },
        [giphySearchQuery]
    );

    /// Gif
    const scrollToBottom = useCallback(() => {
        scrollRef.current.scrollIntoView({ block: 'end' });
    }, []);

    const sendMessage = useCallback(
        (isFile = false) => {
            if (isFile) {
                props.onSend(file, isFile);
                setFile(null);
            } else {
                if (message !== '') {
                    props.onSend(message, isFile);
                    setMessage('');
                } else {
                    Swal.fire(
                        'Error',
                        'Cannot send empty message.',
                        'error',
                    );
                }
            }
        },
        [file, message, props.onSend]
    );
    const patientName = useMemo(() => {
        let patient = patients[userId + ''];
        if (patient) {
          return patient.user.name + ' ' + patient.user.last_name;
        }
        return 'Guest';
      }, [userId, patients]);

    const messageTopMargin = useMemo(() => {
        return (8 - props.messages.length) * 92;
    }, [props.messages]);

    const isUploading = useMemo(() => {
        return props.isUploading ?? false;
    }, [props.isUploading]);

    return (
        <>
            My ID: {props.userId}
            <div className="d-flex justify-content-between align-items-center mb-2" style={{width:"100%"}}>
                <p className='mb-0'>Message history for {patientName}
                <FontAwesomeIcon icon={faStar} style={styles.starIcon}/> </p>
                    <div className="d-flex justify-content-between align-items-center " style={{width:"30%"}}>
                        <PhoneIcon style={styles.navIcons}/>
                        <RecordIcon style={styles.navIcons}/>
                        <AddEventIcon style={styles.navIcons}/>
                        <RemindEventIcon style={styles.navIcons}/>
                        <AddNoteIcon style={styles.navIcons}/>
                        <AddNumberIcon style={styles.navIcons}/>
                    </div>
            </div>
            <div className="flex flex-row align-center" style={styles.searchBox}>
                <FontAwesomeIcon icon={faSearch} style={styles.searchIcon} />
                <input
                    className="flex flex-column"
                    style={styles.searchInput}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    type="text"
                    placeholder="Search in discussion"
                />
            </div>

            <div style={styles.chatArea} className="hide-scrollbar">
                <div style={{ height: messageTopMargin > 0 ? messageTopMargin : 0 }} />
                {filteredMessages.map((message, key) => {
                    // audio = new Audio(message.message?.audio);

                    if (message.uuid != props.userId) {
                        let patient = patients[message.uuid + ''];
                        //left
                        return message.message.image ? (
                            <div className="flex-column" style={{ marginBottom: 10 }} key={key}>
                                <div className="flex-row" style={styles.chatLine}>
                                    <CircleImage
                                        src={patient?.user.image ? patient?.user.image : IconImg}
                                        size={40}
                                    />
                                    <div className="flex-column" style={styles.bubbleLeft}>
                                        {message.message.image != null && (
                                            <img 
                                                src={message.message?.image}
                                                style={{ width: 200, borderRadius: 10 }}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div
                                    className="text-left"
                                    style={{ fontSize: 10, marginLeft: 50 }}>
                                    {moment(message.createdAt).format('MMM DD, hh:mm a')}
                                </div>
                            </div>
                        ) : message.message?.audio ? (
                            <div className="flex-column" style={{ marginBottom: 10 }} key={key}>
                                <div className="flex-row" style={styles.chatLine}>
                                    <CircleImage
                                        src={patient?.user.image ? patient?.user.image : IconImg}
                                        size={40}
                                    />
                                    <div className="flex-column" style={styles.bubbleLeft}>
                                        {message.message?.audio != null && (
                                            <audioPlayer>
                                                <IconButton onClick={handlePlayPause}>
                                                    {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                                                </IconButton>
                                                <audio
                                                    ref={audioRef}
                                                    src={message.message?.audio}
                                                    onEnded={() => setIsPlaying(false)}
                                                    onPlay={() => setIsPlaying(true)}
                                                    onPause={() => setIsPlaying(false)}
                                                />
                                            </audioPlayer>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className="text-left"
                                    style={{ fontSize: 10, marginLeft: 50 }}>
                                    {moment(message.createdAt).format('MMM DD, hh:mm a')}
                                </div>
                            </div>
                        ) : message.message?.text ? (
                            <div className="flex-column" style={{ marginBottom: 10 }} key={key}>
                                <div className="flex-row" style={styles.chatLine}>
                                    <CircleImage
                                        src={patient?.user.image ? patient?.user.image : IconImg}
                                        size={40}
                                    />
                                    <div className="flex-column" style={styles.bubbleLeft}>
                                        {message.message.text}
                                    </div>
                                </div>
                                <div
                                    className="text-left"
                                    style={{ fontSize: 10, marginLeft: 50 }}>
                                    {moment(message.createdAt).format('MMM DD, hh:mm a')}
                                </div>
                            </div>
                        ) : null;
                    } else {
                        //right
                        return message.message.image ? (
                            <div
                                className="flex-column"
                                style={{ marginBottom: 10, alignItems: 'flex-end' }}
                                key={key}>
                                <div className="flex-row" style={styles.chatLine}>
                                    <div className="flex-column" style={styles.bubbleRight}>
                                        {message.message.image != null && (
                                            <img
                                                src={message.message.image}
                                                style={{ width: 200, borderRadius: 10 }}
                                                alt="ybrg"
                                            />
                                        )}
                                    </div>
                                    <CircleImage
                                        src={
                                            message.message.avatar ? message.message.avatar : IconImg
                                        }
                                        size={40}
                                    />
                                </div>
                                <div
                                    className="text-right"
                                    style={{ fontSize: 10, marginRight: 50 }}>
                                    {moment(message.createdAt).format('MMM DD, hh:mm a')}
                                </div>
                            </div>
                        ) : message.message?.audio ? (
                            <div
                                className="flex-column"
                                style={{ marginBottom: 10, alignItems: 'flex-end' }}
                                key={key}>
                                <div className="flex-row" style={styles.chatLine}>
                                    <div className="flex-column" style={styles.bubbleRight}>
                                        {message.message?.audio != null && (
                                            <audioPlayer>
                                                <IconButton onClick={handlePlayPause}>
                                                    {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                                                </IconButton>
                                                <audio
                                                    ref={audioRef}
                                                    src={message.message?.audio}
                                                    onEnded={() => setIsPlaying(false)}
                                                    onPlay={() => setIsPlaying(true)}
                                                    onPause={() => setIsPlaying(false)}
                                                />
                                            </audioPlayer>
                                        )}
                                    </div>
                                    <CircleImage
                                        src={
                                            message.message.avatar ? message.message.avatar : IconImg
                                        }
                                        size={40}
                                    />
                                </div>
                                <div
                                    className="text-right"
                                    style={{ fontSize: 10, marginRight: 50 }}>
                                    {moment(message.createdAt).format('MMM DD, hh:mm a')}
                                </div>
                            </div>
                        ) : message.message?.text ? (
                            <div
                                className="flex-column"
                                style={{ marginBottom: 10, alignItems: 'flex-end' }}
                                key={key}>
                                <div className="flex-row" style={styles.chatLine}>
                                    <div className="flex-column" style={styles.bubbleRight}>
                                        {message.message.text}
                                    </div>
                                    <CircleImage
                                        src={
                                            message.message.avatar ? message.message.avatar : IconImg
                                        }
                                        size={40}
                                    />
                                </div>
                                <div
                                    className="text-right"
                                    style={{ fontSize: 10, marginRight: 50 }}>
                                    {moment(message.createdAt).format('MMM DD, hh:mm a')}
                                </div>
                            </div>
                        ) : null;
                    }
                })}
                <div ref={scrollRef}></div>
            </div>
            <div style={styles.inputGroup} className="flex-row">
                <div href="#" style={{ ...styles.buttonIcon, paddingTop: 6 }}>
                    {isUploading && <img src={Loading} style={{ width: 30 }} />}

                    {!isUploading && (
                        <>
                            <FontAwesomeIcon icon={faPaperclip} />
                            <input
                                style={styles.clickArea}
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={e => {
                                    let file = e.target.files[0];
                                    setFile(file);
                                }}
                            />
                        </>
                    )}
                </div>
                <div style={styles.inputGroupTextWrap} className="flex-row flex">
                    <input
                        className="flex flex-column"
                        value={message}
                        style={styles.searchInput}
                        type="text"
                        autoFocus
                        placeholder="Type message here..."
                        onKeyPress={event => {
                            if (event.code == 'Enter') {
                                sendMessage();
                            }
                        }}
                        onChange={event => {
                            setMessage(event.target.value);
                        }}
                    />
                    <a
                        href="#"
                        style={styles.buttonIcon}
                        onClick={e => {
                            window.jQuery('#modal-gif').modal('show');
                            e.preventDefault();
                            searchGiphy();
                        }}>
                        {/* <FontAwesomeIcon icon="fa-solid fa-gif" beat style={{color: "#1f3251",}} /> */}
                        <FontAwesomeIcon icon={faGift} style={{ color: "#1f3251", }} />
                    </a>
                    <a
                        href="#"
                        style={styles.buttonIcon}
                        onClick={e => {
                            window.jQuery('#modal-emoji').modal('show');
                            e.preventDefault();
                        }}>
                        <FontAwesomeIcon icon={faSmile} />
                    </a>
                    <a
                        href="#"
                        style={styles.buttonIcon}
                        onClick={e => {
                            e.preventDefault();
                            sendMessage(false);
                        }}>
                        <FontAwesomeIcon icon={faPaperPlane} />
                    </a>
                </div>
            </div>

            <div className="modal" tabindex="-1" role="dialog" id="modal-emoji">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Choose Emoji</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <Picker
                                pickerStyle={{ width: '100%' }}
                                onEmojiClick={(event, emojiObject) => {
                                    setMessage(prevMessage => prevMessage + emojiObject.emoji);
                                    window.jQuery('#modal-emoji').modal('hide');
                                }}
                            />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal" tabindex="-1" role="dialog" id="modal-gif">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Choose GIF</h5>
                        </div>
                        <input
                            type="text"
                            style={{
                                width: '80%',
                                marginLeft: '10%',
                                marginTop: 10,
                                padding: '10px',
                                fontSize: '16px',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                outline: 'none',
                            }}
                            onChange={handleChange}
                        />
                        <div className="modal-body">
                            {giphyResults.map((e, i) => (
                                <img style={{ width: 100, height: 100, marginTop: 10, marginLeft: 10 }} src={e.images.original.url} onClick={() => {
                                    sendGif(e.images.original);
                                }} />
                            ))}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

const styles = {
    searchBox: {
        border: '1px solid #ccc',
        padding: '5px 10px',
        borderRadius: 4,
        marginBottom: 10,
    },
    searchIcon: {
        marginRight: 5,
    },
    starIcon: {
        marginLeft: 5,
        cursor: 'pointer'
    },
    navIcons: {
        cursor: 'pointer'
    },
    searchInput: {
        border: '0px',
    },

    wrapper: {
        marginBottom: 10,
    },
    chatLine: {
        alignItems: 'flex-end',
    },
    bubbleLeft: {
        background: '#EAF1F4',
        borderRadius: 20,
        borderBottomLeftRadius: 0,
        marginLeft: 8,
        padding: '16px 16px',
    },
    bubbleRight: {
        background: '#EAF1F4',
        borderRadius: 20,
        borderBottomRightRadius: 0,
        marginRight: 8,
        padding: '16px 16px',
    },
    time: {
        fontSize: 12,
        padding: '0px 16px',
    },

    inputGroup: {
        background: '#e6e6e6',
        padding: 10,
        margin: '0px -16px -16px',
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
    },
    inputGroupTextWrap: {
        background: '#fff',
        borderRadius: 10,
        padding: '4px 14px',
    },
    buttonIcon: {
        fontSize: 20,
        marginRight: 10,
        position: 'relative',
        width: 30,
        cursor: 'pointer',
    },
    clickArea: {
        width: 30,
        height: 50,
        background: 'red',
        position: 'absolute',
        left: 0,
        top: 0,
        opacity: 0,
        cursor: 'pointer',
    },
    audioPlayer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    audio: {
        width: '100%',
        height: 50,
        margin: '0 10px',
    },
    playButton: {
        color: yellow,
    },
    chatArea: {
        height: '55vh',
      },
};
