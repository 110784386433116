export  default {
  registerDevice(pubnub, deviceToken, channels) {
    // FCM
    pubnub.push.addChannels(
      {
        channels: channels,
        device: deviceToken,
        pushGateway: 'gcm',
      },
      status => {},
    );

    // APNs2
    pubnub.push.addChannels(
      {
        channels: channels,
        device: deviceToken,
        pushGateway: 'apns2',
        environment: 'production', // Required for APNs2
        topic: 'com.crowdbotics.YBGRConnect', // Required for APNs2
      },
      status => {},
    );
  },

  getPushNotifObject(title, message) {
    return {
      pn_apns: {
        aps: {
          alert: {
            title: title,
            body: message,
          },
        },
        pn_push: [
          {
            push_type: 'alert',
            targets: [
              {
                environment: 'production',
                topic: 'com.crowdbotics.YBGRConnect',
              },
            ],
            version: 'v2',
          },
        ],
      },
      pn_gcm: {
        notification: {
          title: title,
          body: message,
          sound: 'default',
        },
      },
    };
  },

  sendChatNotification(pubnub, targetId, title, message) {
    const messagePayload = {
      pn_apns: {
        aps: {
          alert: {
            title: title,
            body: message,
          },
        },
        pn_push: [
          {
            push_type: 'alert',
            targets: [
              {
                environment: 'production',
                topic: 'com.crowdbotics.YBGRConnect',
              },
            ],
            version: 'v2',
          },
        ],
      },
      pn_gcm: {
        notification: {
          title: title,
          body: message,
          sound: 'default',
        },
      },
      text: message,
    };

    pubnub.publish(
      {
        message: messagePayload,
        channel: 'notification.' + targetId,
      },
      status => {},
    );
  },

  uploadFile(obj) {
    let {file, pubnub, channel} = obj;

    return new Promise((resolve, reject) => {
      pubnub
        .sendFile({channel, file})
        .then(res => {
          const result = pubnub.getFileUrl({
            channel,
            id: res.id,
            name: res.name,
          });
          resolve(result);
        })
        .catch(err => {
          reject('Cannot upload file');
        });
    });
  },

  sendMessage(obj) {
    let {
      notificationTitle,
      notificationText,
      channel,
      type, //image, video, audio, file
      content, //text or url
      channelMetaData,
      pubnub,
      approve,
      nameMetaData,
      callback,
    } = obj;

    let _message = this.getPushNotifObject(notificationTitle, notificationText);
    if (type === 'text') {
      _message.text = content;
    } else if (type === 'image') {
      _message.image = content;
    } else if (type === 'video') {
      _message.video = content;
    } else if (type === 'audio') {
      _message.audio = content;
    }

    pubnub
      .publish({channel: channel, message: _message})
      .then(res => {
        let metaData = {...channelMetaData};
        if (type === 'text') {
          metaData.lastMessage = _message.text;
        } else if (type === 'image') {
          metaData.lastMessage = '🏞 (Image)';
        } else if (type === 'video') {
          metaData.lastMessage = '🎥 (Video)';
        } else if (type === 'audio') {
          metaData.lastMessage = '🎵 (Audio)';
        }

        if (approve) {
          metaData.approve = true;
        }

        if (nameMetaData) {
          metaData.name = nameMetaData;
        }

        //update last message
        pubnub.objects
          .setChannelMetadata({
            channel: channel,
            data: {
              custom: metaData,
            },
            include: {
              customFields: true,
            },
          })
          .then(res => {
            if (callback) {
              callback();
            }
          })
          .catch(err => {});
      })
      .catch(err => {});
  },
};
