import React, {useEffect, useState} from 'react';
import ChallengeOption from '../ChallengeOption';
import {
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
  useRadioGroup,
} from '@mui/material';
import UISelect from '@components/UISelect';
import YesNoSelect from './YesNoSelect';
import SadHappySelect from './SadHappySelect';
import EnterValueSelect from './../FieldsetNotifications/EnterValueSelect';
import TimeSpentNotification from './TimeSpentNotification';
import WeightSelect from '../FieldsetNotifications/WeightSelect';
import {SignalCellularNullSharp} from '@material-ui/icons';

export default function FieldsetTarget({
  value,
  onChange = () => {},
  id,
  selectedScore,
}) {
  const radioGroup = useRadioGroup();
  const checked = radioGroup?.value === id;
  const [target, setTarget] = useState({});

  const handleTargetChange = value => {
    setTarget(value);
    onChange(value);
  };

  const handleChange = event => {
    setTarget({});
    onChange({});
  };

  useEffect(() => {
    handleChange();
  }, [selectedScore]);

  return (
    <ChallengeOption label="Challenge Target" value={id} help="Tell what will be the target of the challenge?">
      {checked ? (
        <Box sx={styles.main}>
          <Box
            sx={{
              width: '35%',
            }}>
            <UISelect value={selectedScore} onChange={handleChange} fullHeight>
              <MenuItem value={1}>Weight</MenuItem>
              <MenuItem value={2}>Percentage of yes/no</MenuItem>
              <MenuItem value={4}>Percentage of Sad/Neutral/Happy</MenuItem>
              <MenuItem value={5}>Time spent</MenuItem>
              <MenuItem value={3}>Enter </MenuItem>
              <MenuItem value={6}>Enter your custom value</MenuItem>
            </UISelect>
          </Box>
          {selectedScore === 2 ? (
            <Box
              sx={{
                width: '50%',
              }}>
              <YesNoSelect
                setTarget={handleTargetChange}
                target={target}
                checked={checked}
              />
            </Box>
          ) : selectedScore === 4 ? (
            <Box
              sx={{
                width: '65%',
              }}>
              <SadHappySelect setNotificationTempValues={handleTargetChange} />
            </Box>
          ) : selectedScore === 5 ? (
            <TimeSpentNotification setTarget={setTarget} />
          ) : selectedScore === 1 ? (
            <WeightSelect setNotificationTempValues={handleTargetChange} />
          ) : selectedScore === 6 ? (
            // <WeightSelect setNotificationTempValues={handleTargetChange} />
            <EnterValueSelect setNotificationTempValues={handleTargetChange} />
          ) : selectedScore === 3 ? (
            // <WeightSelect setNotificationTempValues={handleTargetChange} />
            <EnterValueSelect setNotificationTempValues={handleTargetChange} />
          ) : (
            ''
          )}
        </Box>
      ) : (
        <FormControl component="fieldset" sx={{marginTop: 1}}>
          {selectedScore === 2 && target ? (
            <FormLabel component="legend">
              {/* {value} */}
              <span style={{padding: '0 1em', color: '#ACB9C2'}}>Yes</span>
              {target?.Yes ? target?.Yes?.toString() + '%' : '-'}
              <span style={{padding: '0 1em', color: '#ACB9C2'}}>No</span>
              {target?.No ? target?.No?.toString() + '%' : '-'}
            </FormLabel>
          ) : selectedScore === 4 && target ? (
            <FormLabel component="legend">
              {/* {value} */}
              <span style={{padding: '0 1em', color: '#ACB9C2'}}>Sad</span>
              {target?.Sad ? target?.Sad + '%' : '-'}
              <span style={{padding: '0 1em', color: '#ACB9C2'}}>Neutral</span>
              {target?.Neutral ? target?.Neutral + '%' : '-'}
              <span style={{padding: '0 1em', color: '#ACB9C2'}}>Happy</span>
              {target?.Happy ? target?.Happy + '%' : '-'}
            </FormLabel>
          ) : selectedScore === 5 && target ? (
            <FormLabel component="legend">
              {/* {value} */}
              <span style={{padding: '0 1em', color: '#ACB9C2'}}>Hours</span>
              {target?.Hour ? target?.Hour : '-'}
              <span style={{padding: '0 1em', color: '#ACB9C2'}}>Minutes</span>
              {target?.Minute ? target?.Minute : '-'}
              <span style={{padding: '0 1em', color: '#ACB9C2'}}>Seconds</span>
              {target?.Second ? target?.Second : '-'}
            </FormLabel>
          ) : selectedScore === 1 && target ? (
            <Box sx={{display: 'flex', alignItems: 'center', gap: '1em'}}>
              <FormControl component="fieldset">
                {`Weight ${Object?.keys(target)[0] || ''} - ${
                  target[Object?.keys(target)[0]] || ''
                }`}
              </FormControl>
            </Box>
          ) : selectedScore === 3 && target ? (
            <Box sx={{display: 'flex', alignItems: 'center', gap: '1em'}}>
              {/* <FormControl component="fieldset">{value}</FormControl> */}
              <FormControl component="fieldset">
                {Object?.keys(target)[0]} - {target[Object?.keys(target)[0]]}
              </FormControl>
            </Box>
          ) : selectedScore === 6 && target ? (
            <Box sx={{display: 'flex', alignItems: 'center', gap: '1em'}}>
              {/* <FormControl component="fieldset">{value}</FormControl> */}
              <FormControl component="fieldset">
                {Object?.keys(target)[0]} - {target[Object?.keys(target)[0]]}
              </FormControl>
            </Box>
          ) : (
            ''
          )}
        </FormControl>
      )}
    </ChallengeOption>
  );
}

const styles = {
  main: {
    display: 'flex',
    alignItems: 'center',
    gap: '4%',
    width: '100%',
  },
};
