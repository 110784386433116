import React, {useEffect, useState} from 'react';
import ChallengeOption from '../ChallengeOption';
import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  MenuItem,
  useRadioGroup,
} from '@mui/material';
import UISelect from '@components/UISelect';
import SadHappySelect from '../FieldsetTarget/SadHappySelect';
import TimeSpentSelect from './TimeSpentSelect';
import WeightSelect from './WeightSelect';
import EnterValueSelect from './EnterValueSelect';
import {notificationTemplate} from '@utils/mockData';
import MissedCheckIns from './MissedCheckIns';
import YesNoSelect from '../FieldsetTarget/YesNoSelect';

export default function FieldsetNotifications({
  value,
  onChange = () => {},
  id,
  notifyChallengeCompletion,
  setNotifiyChallengeCompletion,
  setNotificationTempValues,
  notificationTempValues,
  selectedScore,
}) {
  const radioGroup = useRadioGroup();
  const checked = radioGroup?.value === id;

  const noMargin = 'false';

  const handleChange = event => {
    const selectedId = event?.target?.value;
    const selectedName = notificationTemplate?.find(
      data => data?.id === selectedId,
    );
    onChange({
      notificationTemplate: selectedScore,
      notificationTemplateValues: {
        name: selectedName?.name,
      },
    });
    setNotificationTempValues(null);
  };
  const handleScoreChange = event => {
    onChange({});
    setNotificationTempValues(null);
  };

  useEffect(() => {
    handleScoreChange();
  }, [selectedScore]);

  return (
    <Box sx={styles.main}>
      <ChallengeOption
        label="Notifications"
        value={id}
        notificationCompleted={noMargin} help="Tell what will be the notification values of the challenge?">
        {checked ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              gap: '5%',
            }}>
            <Box
              sx={{
                width: '35%',
                display: 'flex',
                alignItems: 'center',
              }}>
              <UISelect
                value={selectedScore}
                onChange={handleChange}
                fullHeight>
                <MenuItem value={1}>Weight</MenuItem>
                <MenuItem value={2}>Percentage of yes/no</MenuItem>
                <MenuItem value={4}>Percentage of Sad/Neutral/Happy</MenuItem>
                <MenuItem value={5}>Time spent</MenuItem>
                <MenuItem value={3}>Enter a value</MenuItem>
                <MenuItem value={6}>Enter your custom value</MenuItem>
              </UISelect>
            </Box>
            {selectedScore === 6 && (
              <MissedCheckIns setNotificationTempValues={setNotificationTempValues} />
            )}
            {selectedScore === 4 && (
              <SadHappySelect setNotificationTempValues={setNotificationTempValues} />
            )}
            {selectedScore === 5 && (
              <TimeSpentSelect setNotificationTempValues={setNotificationTempValues} />
            )}
            {selectedScore === 1 && (
              <WeightSelect setNotificationTempValues={setNotificationTempValues} />
            )}
            {selectedScore === 3 && (
              <EnterValueSelect setNotificationTempValues={setNotificationTempValues} />
            )}
            {selectedScore === 2 && (
              <Box sx={{ width: '50%' }}>
                <YesNoSelect target={notificationTempValues} setTarget={setNotificationTempValues} />
              </Box>
            )}
          </Box>
        ) :
        (
         
        <FormControl component="fieldset" sx={{marginTop: 1}}>
        {selectedScore === 2 && notificationTempValues ? (
          <FormLabel component="legend">
            {/* {value} */}
            <span style={{padding: '0 1em', color: '#ACB9C2'}}>Yes</span>
            {notificationTempValues?.Yes ? notificationTempValues?.Yes + '%' : '-'}
            <span style={{padding: '0 1em', color: '#ACB9C2'}}>No</span>
            {notificationTempValues?.No ? notificationTempValues?.No + '%' : '-'}
          </FormLabel>
        ) : selectedScore === 4 && notificationTempValues ? (
          <FormLabel component="legend">
            {/* {value} */}
            <span style={{padding: '0 1em', color: '#ACB9C2'}}>Sad</span>
            {notificationTempValues?.Sad ? notificationTempValues?.Sad + '%' : '-'}
            <span style={{padding: '0 1em', color: '#ACB9C2'}}>Neutral</span>
            {notificationTempValues?.Neutral ? notificationTempValues?.Neutral + '%' : '-'}
            <span style={{padding: '0 1em', color: '#ACB9C2'}}>Happy</span>
            {notificationTempValues?.Happy ? notificationTempValues?.Happy + '%' : '-'}
          </FormLabel>
        ) : selectedScore === 5 && notificationTempValues ? (
          <FormLabel component="legend">
            {/* {value} */}
            {/* <span style={{padding: '0 1em', color: '#ACB9C2'}}>Hours</span> */}
            {Object?.keys(notificationTempValues)[0] ? Object?.keys(notificationTempValues)[0] : '-'}
            {/* <span style={{padding: '0 1em', color: '#ACB9C2'}}>Minutes</span> */}
            {notificationTempValues[Object?.keys(notificationTempValues)[0]]?.value ? notificationTempValues[Object?.keys(notificationTempValues)[0]]?.value : '-'}
            {notificationTempValues[Object?.keys(notificationTempValues)[0]]?.unit ? notificationTempValues[Object?.keys(notificationTempValues)[0]]?.unit : '-'}
            {/* <span style={{padding: '0 1em', color: '#ACB9C2'}}>Seconds</span> */}
            {/* {notificationTempValues?.Second ? notificationTempValues?.Second : '-'} */}
          </FormLabel>
        ) : selectedScore === 1 && notificationTempValues ? (
          <Box sx={{display: 'flex', alignItems: 'center', gap: '1em'}}>
            <FormControl component="fieldset">
             {`Weight ${Object?.keys(notificationTempValues)[0] || ''} - ${notificationTempValues[Object?.keys(notificationTempValues)[0]] || ''}`}
            </FormControl>
          </Box>
        ) : selectedScore === 3 && notificationTempValues ? (
            <Box sx={{display: 'flex', alignItems: 'center', gap: '1em'}}>
            <FormControl component="fieldset">
              {Object?.keys(notificationTempValues)[0]} - {notificationTempValues[Object?.keys(notificationTempValues)[0]]}
            </FormControl>
          </Box>
        ) : selectedScore === 6 && notificationTempValues ? (
          <Box sx={{display: 'flex', alignItems: 'center', gap: '1em'}}>
            <FormControl component="fieldset">
              {Object?.keys(notificationTempValues)[0]} - {notificationTempValues[Object?.keys(notificationTempValues)[0]]}
            </FormControl>
          </Box>
        ) : (
          ''
        )}
      </FormControl>)}    
      </ChallengeOption>
      <div style={{...styles.notifyStyles, display: checked ? 'flex' : 'none'}}>
        <Checkbox
          value={notifyChallengeCompletion}
          onChange={e => setNotifiyChallengeCompletion(e.target.checked)}
        />
        <FormControl>Notify me when the challenge is completed</FormControl>
      </div>
    </Box>
  );
}

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 2,
    backgroundColor: 'white',
  },
  option1Styles: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#E9E9E9',
    borderRadius: '0.3em',
    height: '100%',
    width: '20%',
    '@media (max-width: 1000px)': {
      width: '15vw',
    },
    '@media (max-width: 720px)': {
      width: '20vw',
    },
  },
  option1SelectStyles: {
    width: '100%',
    background: 'white',
    '& .MuiSelect-select': {
      fontSize: 12,
      outline: 0,
      color: 'darkGray',
      fontWeight: 600,
      borderStyle: 'none',
      paddingY: 1,
      backgroundColor: '#E9E9E9',
    },
  },
  notifyStyles: {
    alignItems: 'center',
    gap: '1%',
    boxShadow: 'rgba(0, 0, 0, 0.09) 0px 10px 10px 0px inset',
    padding: '0.5em',
    height: 60,
    borderRadius: '0 0 0.5em 0.55em',
  },
  textFieldStyle: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '0.3em',
    height: '90%',
  },
};
