import React from 'react';
import ChallengeOption from './ChallengeOption';
import UIImageList from './UIImageList';

import IconUser from '../../assets/icon-user.jpg';
import { useRadioGroup } from '@mui/material';

export default function FieldsetAssignedTo({value, onChange = () => {}, id}) {
    
    const radioGroup = useRadioGroup();
    const checked = radioGroup?.value === id;

  return (
    <ChallengeOption label="Assigned To" value={id} help="Tell to whom this challenge will be assigned?">
      <UIImageList handleChange={onChange} checked={checked} />
    </ChallengeOption>
  );
}
