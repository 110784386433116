import React from 'react';
import DataGrid from '../components/DataGridSorting';
import Panel from '../components/Panel';
import { isEqual, isWithinInterval, subDays } from 'date-fns';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment';
import {
  faCheckCircle,
  faCommentAlt,
  faEdit,
  faEyeSlash,
  faPaste,
  faPencilAlt,
  faPlus,
  faSearch,
  faChevronLeft,
  faChevronRight,
  faTimesCircle,
  faCaretRight,
  faCaretLeft
} from '@fortawesome/free-solid-svg-icons';
import CircleImage from '../components/CircleImage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {HttpRequest} from '../utils/http';
import ActivityIndicator from '../components/ActivityIndicator';
import {Link} from 'react-router-dom';
import {store} from '../store';
import Swal from 'sweetalert2';
import PanelTab from '../components/PanelTab';

class Reward extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      selectedIndex: 0,
      rewards: [],
      campaigns: [],
      rewardName: '',
      userId: '',
      campaignPoints: '',
      selectedCampaigns: null,
      selectedRewards: null,
      searchReward: '',
      rewardStatus: '',
      tagdata: [],
      searchText: '',
      tagsFilter: '',
      rangeFilter: '',
      statusFilter: 'any',
      dateFilter: '',
      nextReward: null,
      nextCampaign: null,
      currentPageReward: 1,
      currentPageCampaign: 1,
      totalRewardPages: 1,
      totalCampaignPages: 1
    };
  }

  componentDidMount() {
    let user = store.getState().loginReducer.user;
    if (user) {
      this.loadReward();
      this.loadCampaigns();
      this.loadTagData();
    }
  }

  loadTagData() {
    HttpRequest.getallTag()
      .then(res => {
        let data = res.data.data;
        const newItem = {id: '', name: 'Any', slug: ''};

        data.unshift(newItem);
        this.setState({
          tagdata: data,
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load tags data.', 'error');
      });
  }
  // Load Rewards from API
  loadReward() {
    this.setState({isLoading: true});

    //   ---- working
    HttpRequest.rewardList(this.state.currentPageReward)
      .then(res => {
        let rewards = res.data.results;
        let nextReward = res.data.next;
        const nextPageNumber = nextReward ? nextReward.split('page=')[1] : null;
        rewards.sort((a, b) => new Date(b.modified) - new Date(a.modified));
        const count = res.data.count;
        const roundedPage = Math.ceil(count / 20)
        this.setState({
          isLoading: false,
          rewards,
          nextReward: nextPageNumber,
          totalRewardPages: roundedPage
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load reward data.', 'error');
      });
  }

  //Load Campaigns from API
  loadCampaigns() {
    this.setState({isLoading: true});

    HttpRequest.getCampaignList(this.state.currentPageCampaign)
      .then(res => {
        let campaigns = res.data.results;
        // campaigns.push(res.data.results);
        let nextCampaign = res.data.next;
        const nextPageNumber = nextCampaign ? nextCampaign.split('page=')[1] : null;
        const count = res.data.count;
        const roundedPage = Math.ceil(count / 20)
        this.setState({
          isLoading: false,
          campaigns,
          nextCampaign: nextPageNumber,
          totalCampaignPages: roundedPage
        });
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot load campaign data.', 'error');
      });
  }

  deleteCampaign(campaignId) {
    this.setState({isLoading: true});

    HttpRequest.deleteCampaign(campaignId)
      .then(() => {
        const updatedCampaigns = this.state.campaigns.filter(
          campaign => campaign.id !== campaignId,
        );

        this.setState({
          isLoading: false,
          campaigns: updatedCampaigns,
        });
        Swal.fire('Success', 'Campaign deleted successfully.', 'success');
      })
      .catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot delete campaign.', 'error');
      });
  }
  handleRewardFinished = rewardId => {
    this.setState({isLoading: true});
    const updatedRewards = this.state.rewards.map(reward =>
      reward.id === rewardId ? {...reward, status: 'finished'} : reward,
    );
    HttpRequest.getReward(rewardId).then(res => {
      const resdata = res.data.data;
      const formdata = new FormData();
      formdata.append('id', resdata.id);
      formdata.append('campaign', resdata.campaign);
      formdata.append('campaign_name', resdata.campaign_name);
      formdata.append('patient', resdata.patient);
      formdata.append('patient_profile', resdata.patient_profile);
      formdata.append('status', 'finished');
      formdata.append('is_claim', resdata.is_claim);
      let id = rewardId;
      let body = formdata;
      this.setState({
        rewardName: resdata.campaign_name,
        userId: resdata.patient_profile.user.id,
      });
      HttpRequest.getCampaign(resdata.campaign).then(res => {
        const campRes = res.data.data;
        this.setState({campaignPoints: campRes.points});

        let data2 = {
            points: campRes.points,
            change: this.state.rewardName,
            reward: rewardId,
            user: this.state.userId,
          };
          HttpRequest.saveReward(id, body)
          .then(res => {
            let data = res.data.data;
            HttpRequest.pointHistoriesAdd(data2).then(res => {
            })
          }).then(() => {
            this.setState({isLoading: false});
            Swal.fire('Information', 'Reward Updated', 'success').then(res => {});
            })
          .catch(err => {
            this.setState({isLoading: false});
            Swal.fire('Error', 'Cannot update reward data.', 'error');
          });
      }).catch(err => {
        this.setState({isLoading: false});
        Swal.fire('Error', 'Cannot update reward data.', 'error');
      });;
      });
      this.setState({rewards: updatedRewards});
    }

  handleRewardCanceled = rewardId => {
    this.setState({isLoading: true});
    const updatedRewards = this.state.rewards.map(reward =>
      reward.id === rewardId ? {...reward, status: 'canceled'} : reward,
    );
    HttpRequest.getReward(rewardId).then(res => {
      const resdata = res.data.data;
      const formdata = new FormData();
      formdata.append('id', resdata.id);
      formdata.append('campaign', resdata.campaign);
      formdata.append('campaign_name', resdata.campaign_name);
      formdata.append('patient', resdata.patient);
      formdata.append('patient_profile', resdata.patient_profile);
      formdata.append('status', 'canceled');
      formdata.append('is_claim', resdata.is_claim);
      let id = rewardId;
      let body = formdata;

      HttpRequest.saveReward(id, body)
        .then(res => {
          let data = res.data.data;
          this.setState({isLoading: false});
          Swal.fire('Information', 'Reward Updated', 'success').then(res => {});
        })
        .catch(err => {
          this.setState({isLoading: false});
          Swal.fire('Error', 'Cannot update reward data.', 'error');
        });
    });

    this.setState({rewards: updatedRewards});
  };

  filterCampaignsByRange(campaigns, rangeStart, rangeEnd) {
    return campaigns.filter(item => {
      const campaignRange = item.points;
      return campaignRange >= rangeStart && campaignRange <= rangeEnd;
    });
  }

  applyFilters = () => {
    const {searchText, tagsFilter, rangeFilter, statusFilter, campaigns} =
      this.state;
    let filteredCampaigns = campaigns;

    // Apply the search text filter
    if (searchText) {
      filteredCampaigns = filteredCampaigns.filter(
        item =>
          item.title &&
          item.title.toLowerCase().includes(searchText.toLowerCase()),
      );
    }

    // Apply the tags filter
    if (tagsFilter && tagsFilter !== 'Any') {
      filteredCampaigns = filteredCampaigns.filter(
        item => item.tags && item.tags.includes(tagsFilter),
      );
    }

    // Apply the cost range filter
    if (rangeFilter) {
      switch (rangeFilter) {
        case '0-10':
          filteredCampaigns = this.filterCampaignsByRange(
            filteredCampaigns,
            0,
            10,
          );
          break;
        case '11-20':
          filteredCampaigns = this.filterCampaignsByRange(
            filteredCampaigns,
            11,
            20,
          );
          break;
        case '21-30':
          filteredCampaigns = this.filterCampaignsByRange(
            filteredCampaigns,
            21,
            30,
          );
          break;
        case '31-40':
          filteredCampaigns = this.filterCampaignsByRange(
            filteredCampaigns,
            31,
            40,
          );
          break;
        default:
          break;
      }
    }
    // Apply the status filter
    if (statusFilter !== 'any') {
      const statusToFilter = statusFilter === 'claimed';
      filteredCampaigns = filteredCampaigns.filter(
        item => item.is_active === statusToFilter,
      );
    }

    // Update the selectedCampaigns state with the filtered result
    this.setState({selectedCampaigns: filteredCampaigns});
  };
  applyRewardFilters() {
    const {searchReward, rewardStatus, rewards, dateFilter} = this.state;
    let filteredRewards = rewards;

    if (searchReward) {
      filteredRewards = filteredRewards.filter(
        item =>
          item.patient_profile?.user.name &&
          item.patient_profile?.user.name
            .toLowerCase()
            .includes(searchReward.toLowerCase()),
      );
    }

    if (rewardStatus && rewardStatus !== 'any') {
      filteredRewards = filteredRewards.filter(
        item => item.status == rewardStatus,
      );
    }

    if (dateFilter && dateFilter !== 'All') {
        const today = new Date();

        // Define the selected date ranges using date-fns functions
        const selectedDateRanges = {
          '0-5': {
            start: subDays(today, 5),
            end: today,
          },
          '6-10': {
            start: subDays(today, 10),
            end: subDays(today, 5),
          },
          '11-15': {
            start: subDays(today, 15),
            end: subDays(today, 10),
          },
        };

        const selectedRange = selectedDateRanges[dateFilter]; // Get the selected date range

        if (selectedRange) {
          filteredRewards = filteredRewards.filter((item) => {
            const itemDate = new Date(item.modified);

            if (!isNaN(itemDate)) {
            if (isWithinInterval(itemDate, { start: selectedRange.start, end: selectedRange.end })) {
                return true;
              } else if (isEqual(itemDate, selectedRange.end)) {
                return true; // Include the end date in the interval
              }
              return false;
            }
          });
        }
      }

    this.setState({selectedRewards: filteredRewards});
  }
  //Pagination for Reward
  loadPage = (page) => {
    this.setState(
      { currentPageReward: page },
      this.loadReward
    );
  };

  loadNextPage = () => {
    this.loadPage(this.state.currentPageReward + 1);
    this.setState({selectedRewards: null, searchReward: "", rewardStatus: "", dateFilter: ""})
  };

  loadPrevPage = () => {
    this.loadPage(this.state.currentPageReward - 1);
    this.setState({selectedRewards: null, searchReward: "", rewardStatus: "", dateFilter: ""})
  };
  //Pagination for Campaigns
  loadCampaignPage = (page) => {
    this.setState(
      { currentPageCampaign: page },
      this.loadCampaigns
    );
  };

  loadNextPageCampaign = () => {
    this.loadCampaignPage(this.state.currentPageCampaign + 1);
    this.setState({selectedCampaigns: null, searchText: "", tagsFilter: "", rangeFilter: "", statusFilter: "any"})
  };

  loadPrevPageCampaign = () => {
    this.loadCampaignPage(this.state.currentPageCampaign - 1);
    this.setState({selectedCampaigns: null, searchText: "", tagsFilter: "", rangeFilter: "", statusFilter: ""})
  };
  render() {
    return (
      <>
        <div className="flex-row " style={{justifyContent: 'space-between'}}>
          <PanelTab
            tabs={['Reward Management', 'Reward Fullfillment']}
            selectedIndex={this.state.selectedIndex}
            onChange={selectedIndex => {
              this.setState({selectedIndex});
            }}
          />
          <div>
            <Link to="/reward/create" className="btn btn-secondary btn-round">
              <FontAwesomeIcon icon={faPlus} /> Add reward
            </Link>
          </div>
        </div>

        {this.state.selectedIndex === 0 && (
          <Panel>
            {this.state.isLoading && <ActivityIndicator />}
            {!this.state.isLoading && (
              <DataGrid
                filters={[
                  {
                    type: 'textinput',
                    label: 'Search reward',
                    icon: faSearch,
                    onChange: text => {
                      this.setState({searchText: text}, this.applyFilters);
                    },
                    className: 'col-sm-12 col-md-6',
                  },
                  {
                    type: 'select',
                    label: 'Tags',
                    data: this.state.tagdata.map(d => ({
                      id: d.name,
                      label: d.name,
                    })),
                    onChange: text => {
                      this.setState({tagsFilter: text}, this.applyFilters);
                    },
                    className: 'col-md-2 col-sm-6',
                    style: {width: 200},
                  },
                  {
                    type: 'select',
                    label: 'Cost Range',
                    data: [
                      {id: 'All', label: 'All'},
                      {id: '0-10', label: '0 - 10'},
                      {id: '11-20', label: '11 - 20'},
                      {id: '21-30', label: '21 - 30'},
                      {id: '31-40', label: '31 - 40'},
                    ],
                    onChange: text => {
                      this.setState({rangeFilter: text}, this.applyFilters);
                    },
                    className: 'col-md-2 col-sm-6',
                    style: {width: 200},
                  },
                  {
                    type: 'select',
                    label: 'Status',
                    data: [
                      {id: 'any', label: 'Any'},
                      {id: 'claimed', label: 'Claimed'},
                      {id: 'not_claimed', label: 'Not Claimed'},
                    ],
                    onChange: text => {
                      this.setState({statusFilter: text}, this.applyFilters);
                    },
                    className: 'col-md-2 col-sm-6 my-3',
                    style: {width: 200},
                  },
                ]}
                columns={[
                  {
                    name: "title",
                    label: 'Reward Name',
                    sortable: true,
                    renderItem: campaign => {
                      return campaign.title;
                    },
                  },
                  {
                    label: 'Tags',
                    renderItem: campaign => {
                      return campaign.tags && campaign.tags.length > 0 ? (
                        campaign.tags.map(data => (
                          <span
                            key={data}
                            className="badge badge-light"
                            style={{
                              padding: '10px 20px',
                              border: '1px solid #D2DCE2',
                              background: 'none',
                              borderRadius: '20px',
                            }}>
                            {data}
                          </span>
                        ))
                      ) : (
                        <span className="badge badge-light">No Tags</span>
                      );
                    },
                  },
                  {
                    label: 'Cost',
                    renderItem: item => {
                      return item.points;
                    },
                  },
                  {
                    label: 'Inventory',
                    renderItem: item => {
                      return item.starting_inventory;
                    },
                  },
                  {
                    label: 'Claimed',
                    renderItem: item => {
                      return item.is_active ? (
                        'Claimed'
                      ) : (
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          Not Claimed <CancelIcon sx={{color: 'red'}} />
                        </div>
                      );
                    },
                  },
                  {
                    label: 'Status',
                    renderItem: item => {
                      return item.is_active ? 'In Stock' : 'Stock Out';
                    },
                  },
                  {
                    label: 'Controls',
                    renderItem: item => {
                      return (
                        <>
                          <Link
                            to={`/campaign/edit/${item.id}`}
                            style={styles.control}>
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </Link>
                          <span
                            style={styles.deleteBtn}
                            onClick={() => this.deleteCampaign(item.id)}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                          </span>
                        </>
                      );
                    },
                  },
                ]}
                data={
                  this.state.selectedCampaigns
                    ? this.state.selectedCampaigns
                    : this.state.campaigns || []
                }
              />
            )}
             <div className='d-flex justify-content-end'>
             <FontAwesomeIcon
                            onClick={this.state.currentPageCampaign === 1 ? null : this.loadPrevPageCampaign}
                            icon={faCaretLeft}
                            style={{
                                ...styles.pagination,
                                color: this.state.currentPageCampaign === 1 ? '#ACB9C2' : '#274F66',
                            }}
                            disabled={this.state.currentPageCampaign === 1}
                        />

                        Page {this.state.currentPageCampaign} of {this.state.totalCampaignPages}

                        <FontAwesomeIcon
                            onClick={!this.state.nextCampaign ? null : this.loadNextPageCampaign}
                            icon={faCaretRight}
                            style={{
                                ...styles.pagination,
                                color: !this.state.nextCampaign ? '#ACB9C2' : '#274F66',
                            }}
                            disabled={this.state.nextCampaign}
                        />
          </div>
          </Panel>
        )}

        {this.state.selectedIndex == 1 && (
          <Panel>
            {this.state.isLoading && <ActivityIndicator />}
            {!this.state.isLoading && (
              <DataGrid
                filters={[
                  {
                    type: 'textinput',
                    label: 'Search reward',
                    icon: faSearch,
                    onChange: text => {
                      this.setState(
                        {searchReward: text},
                        this.applyRewardFilters,
                      );
                    },
                    className: 'col-sm-12 col-md-6',
                  },
                  {
                    type: 'select',
                    label: 'Date',
                    data: [
                      {id: 'All', label: 'All'},
                      {id: '0-5', label: '0 - 5 days ago'},
                      {id: '6-10', label: '6 - 10 days ago'},
                      {id: '11-15', label: '11 - 15 days ago'},
                    ],
                    onChange: text => {
                      this.setState(
                        {dateFilter: text},
                        this.applyRewardFilters,
                      );
                    },
                    className: 'col-md-2 col-sm-6',
                    style: {width: 200},
                  },
                  //   {
                  //     type: 'select',
                  //     label: 'Cost Range',
                  //     data: [{id: '', label: 'Any'}],
                  //     onChange: text => {},
                  //     className: 'col-md-2 col-sm-6',
                  //     style: {width: 200},
                  //   },
                  {
                    type: 'select',
                    label: 'Status',
                    data: [
                      {id: 'any', label: 'Any'},
                      {id: 'created', label: 'Created'},
                      {id: 'processed', label: 'Processed'},
                      {id: 'finished', label: 'Finished'},
                      {id: 'canceled', label: 'Canceled'},
                    ],
                    onChange: text => {
                      this.setState(
                        {rewardStatus: text},
                        this.applyRewardFilters,
                      );
                    },
                    className: 'col-md-2 col-sm-6',
                    style: {width: 200},
                  },
                ]}
                columns={[
                  {
                    name: "patient_profile",
                    label: 'Claimed By',
                    sortable: true,
                    renderItem: item => {
                      return item.patient_profile?.user.name;
                    },
                  },
                  {
                    label: 'Reward Claimed',
                    renderItem: item => {
                      return item.is_claim ? 'Claimed' : 'Not Claimed';
                    },
                  },
                  //   {
                  //     label: 'Inventory',
                  //     renderItem: item => {
                  //       return '0';
                  //     },
                  //   },
                  {
                    label: 'Claimed On',
                    renderItem: item => {
                      if (!item || typeof item.modified !== 'string') {
                        return ''; // Return an empty string or some default value if item is undefined or has invalid data.
                      }

                      // The rest of the code to format the date (assuming item.modified is in the format "YYYY-MM-DDTHH:mm:ss.sssZ")
                      return item.modified.slice(0, 10).replace(/-/g, '.');
                    },
                  },
                  {
                    label: 'Status',
                    renderItem: item => {
                      return item.status === 'created' ||
                        item.status === 'processed' ? (
                        <span className="badge badge-primary">
                          {item.status}
                        </span>
                      ) : item.status === 'finished' ? (
                        <span className="badge badge-success">
                          {item.status}
                        </span>
                      ) : item.status === 'canceled' ? (
                        <span className="badge badge-danger">
                          {item.status}
                        </span>
                      ) : (
                        ''
                      ); // Empty string if status is not recognized
                    },
                  },
                  {
                    label: 'Controls',
                    renderItem: item => {
                      return item.status !== 'canceled' &&
                        item.status !== 'finished' ? (
                        <>
                          <span
                            onClick={() => this.handleRewardFinished(item.id)}
                            style={styles.control}>
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </span>
                          <span
                            style={styles.deleteBtn}
                            onClick={() => this.handleRewardCanceled(item.id)}>
                            <FontAwesomeIcon icon={faTimesCircle} />
                          </span>
                        </>
                      ) : null;
                    },
                  },
                ]}
                data={
                  this.state.selectedRewards
                    ? this.state.selectedRewards
                    : this.state.rewards || []
                }
              />
            )}
            <div className='d-flex justify-content-end'>
            <FontAwesomeIcon
                            onClick={this.state.currentPageReward === 1 ? null : this.loadPrevPage}
                            icon={faCaretLeft}
                            style={{
                                ...styles.pagination,
                                color: this.state.currentPageReward === 1 ? '#ACB9C2' : '#274F66',
                            }}
                            disabled={this.state.currentPageReward === 1}
                        />

                        Page {this.state.currentPageReward} of {this.state.totalRewardPages}

                        <FontAwesomeIcon
                            onClick={!this.state.nextReward ? null : this.loadNextPage}
                            icon={faCaretRight}
                            style={{
                                ...styles.pagination,
                                color: !this.state.nextReward ? '#ACB9C2' : '#274F66',
                            }}
                            disabled={this.state.nextReward}
                        />
          </div>
          </Panel>
        )}
      </>
    );
  }
}

const styles = {
  control: {
    marginLeft: 8,
    cursor: 'pointer'
  },
  deleteBtn: {
    marginLeft: 8,
    cursor: 'pointer',
  },
  pagination: {
    height: 24,
    width: 28,
    cursor: 'pointer',
    fontSize: '26px',
}
};

export default Reward;
