import UISelect from '@components/UISelect';
import { Box, MenuItem, TextField } from '@mui/material';
import React, { useState } from 'react';

export default function TimeSpentSelect({ setNotificationTempValues }) {
  const [selectedType, setSelectedType] = useState('');
  const [value, setValue] = useState('');

  const handleTypeChange = (event) => {
    const newSelectedType = event.target.value;
    const weightScale = {
      [newSelectedType]: value !== '' ? parseInt(value, 10) : null,
    };
    setNotificationTempValues(weightScale);
    setSelectedType(newSelectedType);
  };

  const handleValueChange = (event) => {
    const newValue = event.target.value;
    const weightScale = {
      [selectedType]: newValue !== '' ? parseInt(newValue, 10) : null,
    };
    setNotificationTempValues(weightScale);
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        width: '32%',
        gap: '0.15em',
        '@media (max-width: 1000px)': {
          width: '25vw',
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: '#E9E9E9',
          width: '45%',
          borderRadius: '0.3em 0 0 0.3em',
        }}
      >
        <UISelect
          value={selectedType}
          onChange={handleTypeChange}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          fullHeight
        >
          <MenuItem value="More than">More than</MenuItem>
          <MenuItem value="Less than">Less than</MenuItem>
          <MenuItem value="Equal to">Equal to</MenuItem>
        </UISelect>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '60%',
          backgroundColor: '#E9E9E9',
          borderRadius: '0.3em',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#E9E9E9',
            width: '35%',
            borderRadius: '0 0 0.3em',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Lbs
        </Box>
        <TextField
          sx={{
            width: '65%',
            backgroundColor: 'white',
            borderRadius: '0.3em',
            height: '100%',
          }}
          size="small"
          hiddenLabel
          id="question_challenge"
          variant="outlined"
          value={value}
          onChange={handleValueChange}
        />
      </Box>
    </Box>
  );
}
